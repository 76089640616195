import React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import Label from '../Label/Label';
import Avatar from '../Avatar/Avatar';
import { Template } from '../../common/type';

type TickContainerProps = {
  selected: boolean;
  hasError?: boolean;
};

type ControlContainerProps = {
  hasError: boolean;
};

type MultiSelectInput = {
  items: Template[];
  label: string;
  value: string[];
  onSelectionChange: Function;
  hasError: boolean;
  hasAvatar?: boolean;
};

const ListContainer = styled.div<ControlContainerProps>`
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${(props) =>
    props.hasError
      ? props.theme.colors.error
      : props.theme.colors.pageControl.border};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
`;

const TemplateItem = styled.div<TickContainerProps>`
  color: ${(props) => props.theme.colors.theme};
  border: 1px solid;
  border-radius: 5px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.textfield.focusBackground
      : props.theme.colors.white};
  border-color: ${(props) => {
    if (props.selected) return props.theme.colors.textfield.focusBorder;
    if (props.hasError) return props.theme.colors.error;
    return props.theme.colors.pageControl.border;
  }};
  padding: 25px;
  margin-bottom: 16px;
  :last-child {
    margin: 0;
  }
  max-width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxCollumn = styled.div`
  width: 10%;
`;

const NameCollumn = styled.div`
  width: 60%;
`;

const AvatarColumn = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
`;

const StatusColumn = styled.div`
  width: 30%;
  text-transform: capitalize;
  text-align: right;
`;

const TickContainer = styled.div<TickContainerProps>`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  min-width: 20px;
  padding: 3px;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${(props) =>
    props.selected
      ? props.theme.colors.textfield.focusBorder
      : props.theme.colors.pageControl.border};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.textfield.focusBorder};
`;

const isSelected = (selectedItems: string[], itemId: string): boolean =>
  _.includes(selectedItems, itemId);

const SubfieldMultiSelect: React.FC<MultiSelectInput> = ({
  items,
  label,
  value: selectedItems = [],
  onSelectionChange,
  hasError,
  hasAvatar = false,
}) => {
  const handleSelectionChange = (item: Template) => {
    const nextSelectedItems = isSelected(selectedItems, item.id)
      ? selectedItems.filter(
          (selectedItemId: string) => selectedItemId !== item.id,
        )
      : selectedItems.concat([item.id]);
    onSelectionChange(nextSelectedItems);
  };

  return (
    <>
      <Label hasError={hasError}>{label}</Label>

      <ListContainer hasError={hasError}>
        {items.map((item, index) => (
          <TemplateItem
            key={index}
            selected={isSelected(selectedItems, item.id)}
            onClick={() => handleSelectionChange(item)}
            hasError={hasError}
          >
            <CheckboxCollumn>
              <TickContainer selected={isSelected(selectedItems, item.id)}>
                {isSelected(selectedItems, item.id) && <Inner />}
              </TickContainer>
            </CheckboxCollumn>
            {hasAvatar && (
              <AvatarColumn>
                <Avatar id={item.id} />
              </AvatarColumn>
            )}
            <NameCollumn>{item.name}</NameCollumn>
            {item.stage && (
              <StatusColumn>{_.startCase(item.stage)}</StatusColumn>
            )}
          </TemplateItem>
        ))}
      </ListContainer>
    </>
  );
};

export default SubfieldMultiSelect;
