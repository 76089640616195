import styled from "styled-components";

const BaseListItem = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.listitem.border};
  border-bottom-color: ${(props) => props.theme.colors.listitem.bottom};
  border-top: none;
  padding: 15px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export default BaseListItem;
