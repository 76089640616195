import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckMark } from '../Checkbox/checkmark.svg';

const CheckBoxOuter = styled.div<Partial<CheckBoxProps>>`
  width: 16px;
  height: 16px;
  border: 1px solid
    ${(props) => (props.isSelected ? props.bgColor : props.borderColor)};
  border-radius: 5px;
  overflow: hidden;
  background-color: ${(props) =>
    props.isSelected ? props.bgColor : 'transparent'};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  box-sizing: border-box;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
  color: #2d3a45;
  margin-left: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
`;

export type CheckBoxProps = {
  bgColor: string;
  isSelected?: boolean;
  borderColor?: string;
  onSelect?: (id: string) => void;
  label?: string;
  value?: string;
  testID?: string;
};

const KanbanFilterCheckbox: React.FC<CheckBoxProps> = ({
  isSelected = false,
  onSelect,
  borderColor = '#c8cad1',
  bgColor,
  label,
  value,
  testID,
}) => {
  const [isChoosen, setIsChoosen] = useState(isSelected);

  return (
    <StyledContainer onClick={() => {
        setIsChoosen(!isChoosen);
        !!value && !!onSelect && onSelect(value);
    }}>
      <CheckBoxOuter
        bgColor={bgColor}
        isSelected={isChoosen}
        borderColor={borderColor}
        data-testid={testID}
      >
        {isChoosen && <CheckMark />}
      </CheckBoxOuter>
      <CheckboxLabel>{label}</CheckboxLabel>
    </StyledContainer>
  );
};

export default KanbanFilterCheckbox;
