import React, { useState, useEffect } from "react";
import styled from "styled-components";

type MessageContainerProps = {
  shouldDisplay: boolean
}

type GlobalErrorMessageInput = {
  message: string
}
const MessageContainer = styled.div<MessageContainerProps>`
  position: fixed;
  display: ${(props) => (props.shouldDisplay ? "block" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: ${(props) => props.theme.colors.globalError};
  color: ${(props) => props.theme.colors.white};
`;

const GlobalErrorMessage: React.FC<GlobalErrorMessageInput> = ({ message }) => {
  const [display, setDisplay] = useState(!!message);

  useEffect(() => {
    setDisplay(!!message);
  }, [message]);

  return (
    <MessageContainer shouldDisplay={display} onClick={() => setDisplay(false)}>
      <span>{message}</span>
    </MessageContainer>
  );
};

export default GlobalErrorMessage;
