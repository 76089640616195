import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import Gravatar from 'react-gravatar';
import { ReactComponent as PropellaBrand } from '../../images/PropellaBrand.svg';
import UserControlPopover from './UserControlPopover';
import { useBoundStore } from '../../states';

const StyledBrand = styled(PropellaBrand)`
  height: 30px;
`;

const NavigationBar = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 12px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  background-color: ${(props) => props.theme.colors.pageHeader.darkBg};
  color: ${(props) => props.theme.colors.white};
`;

const UserControlTrigger = styled.button`
  height: 30px;
  width: 30px;
  border: 0;
  border-radius: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;

  cursor: pointer;
`;

export type DesignerNavigationProps = {
  logoLink?: string;
};

const DesignerNavigation = ({ logoLink = '/' }: DesignerNavigationProps) => {
  const { profile } = useBoundStore((state) => state.auth);
  const [userControlIsOpen, setUserControlIsOpen] = useState(false);

  return (
    <NavigationBar>
      <Link to={logoLink}>
        <StyledBrand />
      </Link>
      <Popover
        isOpen={userControlIsOpen}
        onClickOutside={() => setUserControlIsOpen(false)}
        positions={['bottom']}
        align="end"
        content={<UserControlPopover />}
      >
        <UserControlTrigger onClick={() => setUserControlIsOpen(true)}>
          {profile && <Gravatar email={profile.id} default="retro" size={30} />}
        </UserControlTrigger>
      </Popover>
    </NavigationBar>
  );
};

export default DesignerNavigation;
