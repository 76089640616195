import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import { createObjective as CreateObjectiveMutation } from '../../graphql/mutations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDistanceToNow, parseJSON } from 'date-fns';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import DatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { faCheck, faTimes, faPalette } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as TripleStar } from './triplestar.svg';
import { ReactComponent as Plus } from './plus.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  MutationCreateObjectiveArgs,
  Objective as GraphQLObjective,
} from '../../graphql/types';
import { ObjectiveStore } from '../../pages/OKR/CurrentObjectives';
import ColorsPalette from '../ColorPalette/ColorPalette';

export type NewObjectiveProps = {};

const NewObjectiveContainer = styled.button`
  border: 1px dashed #c8cece;
  border-radius: 5px;
  background-color: #eff2f4;
  min-height: 100px;
  width: 100%;
  outline: 0;
  padding-left: 30px;
  padding-right: 36px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  margin-top: 22px;
  margin-bottom: 26px;
`;

const NewObjectiveSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NewObjectiveText = styled.h1`
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  margin-left: 24px;
  color: #aeafaf;
`;

type FormContainerProps = {
  bgColor?: string;
  textColor?: string;
};

const FormContainer = styled.form<FormContainerProps>`
  background-color: ${(props) => props.bgColor || '#ffffff'};
  color: ${(props) => props.textColor};
  border: 1px solid #eaeff4;
  border-radius: 6px;
  padding-left: 30px;
  padding-right: 36px;
  min-height: 100px;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 22px;
  margin-bottom: 26px;
`;

type InputProps = {
  textColor?: string;
};

const NewObjectiveInput = styled.input<InputProps>`
  width: 100%;
  border: 0;
  resize: none;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  background-color: transparent;
  color: ${(props) => props.textColor || '#000000'};

  margin-bottom: 10px;

  &:focus {
    outline: none;
  }
`;

interface MenuButtonProps {
  textColor?: string;
}

const MenuButton = styled.button<MenuButtonProps>`
  background-color: transparent;
  color: ${(props) => props.textColor || '#fffffff'};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};

const ColorPicker = styled(MenuButton)`
  margin-right: 22px;
`;

const SaveButton = styled(MenuButton)`
  /* pointer-events: none; */
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const NameAndDueDateSection = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const DueDateButton = styled.button<InputProps>`
  background-color: transparent;
  border: 0;
  font-weight: 700;
  color: ${(props) => props.textColor || '#ffffff'};

  max-width: 360px;
  height: 20px;

  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
    filter: brightness(1.25);
  }
`;

const newObjSchema = yup.object().shape({
  name: yup.string().required(),
  color: yup.string().required(),
  expectedEndDate: yup.string(),
});

const NewObjective: React.FC<NewObjectiveProps> = () => {
  const [objectiveScope, dispatch] = useContext(ObjectiveStore);
  const [editing, setEditing] = useState<boolean>(false);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const newObjFormMethods = useForm({
    resolver: yupResolver(newObjSchema),
    defaultValues: {
      name: '',
      color: '#ffffff',
      expectedEndDate: undefined,
    },
  });

  const bgColor = useMemo(() => {
    return newObjFormMethods.getValues().color || '#ffffff';
  }, [newObjFormMethods.watch('color')]);

  function hexToRgbb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: 0, g: 0, b: 0 };
  }

  const textColor = useMemo(() => {
    const bgRgb = hexToRgbb(bgColor);
    const brightness = Math.round(
      (bgRgb.r * 299 + bgRgb.g * 587 + bgRgb.b * 114) / 1000,
    );
    return brightness > 125 ? '#000000' : '#ffffff';
  }, [bgColor]);

  const createObjective = async (
    name: string,
    color: string,
    expectedEndDate?: string,
  ) => {
    const finalColor = color === '#ffffff' ? '#5C3797' : color;

    const createObjectiveArgs: MutationCreateObjectiveArgs = {
      input: {
        color: finalColor,
        name,
        expectedEndDate,
        iterationID: objectiveScope.iteration?.id,
      },
    };

    const queryResult = (await API.graphql(
      graphqlOperation(CreateObjectiveMutation, createObjectiveArgs),
    )) as GraphQLResult<{
      createObjective: GraphQLObjective;
    }>;

    const createdObjective = queryResult?.data?.createObjective;

    if (createdObjective) {
      dispatch({
        type: 'addObjective',
        payload: createdObjective,
      });
    }
  };

  if (editing)
    return (
      <FormContainer
        bgColor={bgColor}
        textColor={textColor}
        onSubmit={newObjFormMethods.handleSubmit((data) => {
          createObjective(data.name, data.color, data.expectedEndDate);
          newObjFormMethods.reset();
          setEditing(false);
        })}
      >
        <Controller
          name="color"
          control={newObjFormMethods.control}
          render={(fieldProps) => (
            <Popover
              isOpen={colorPickerOpen}
              positions={['bottom', 'top']}
              onClickOutside={() => setColorPickerOpen(false)}
              align="start"
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={'#ffffff'}
                  arrowSize={0}
                >
                  <ColorsPalette
                    onChange={(newColor) => fieldProps.onChange(newColor)}
                  />
                </ArrowContainer>
              )}
            >
              <ColorPicker
                textColor={textColor}
                tabIndex={2}
                onClick={() => setColorPickerOpen(!colorPickerOpen)}
                disabled={newObjFormMethods.formState.isSubmitting}
              >
                <FontAwesomeIcon icon={faPalette} size="1x" />
              </ColorPicker>
            </Popover>
          )}
        />
        <NameAndDueDateSection>
          <Controller
            name="name"
            control={newObjFormMethods.control}
            render={(fieldProps) => (
              <NewObjectiveInput
                {...fieldProps}
                autoFocus
                tabIndex={1}
                disabled={newObjFormMethods.formState.isSubmitting}
                placeholder="Enter a title for the objective"
                textColor={textColor}
              />
            )}
          />

          <Controller
            name="expectedEndDate"
            control={newObjFormMethods.control}
            render={(fieldProps) => (
              <Popover
                isOpen={datePickerOpen}
                positions={['bottom', 'top']}
                onClickOutside={() => setDatePickerOpen(false)}
                align="start"
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#ffffff'}
                    arrowSize={0}
                  >
                    <DatePicker
                      inline
                      minDate={moment().toDate()}
                      selected={
                        fieldProps.value
                          ? new Date(fieldProps.value)
                          : new Date()
                      }
                      onChange={(date) =>
                        fieldProps.onChange(moment(date).toISOString())
                      }
                    />
                  </ArrowContainer>
                )}
              >
                <DueDateButton
                  type="button"
                  textColor={textColor}
                  tabIndex={3}
                  onClick={() => setDatePickerOpen(!datePickerOpen)}
                  disabled={newObjFormMethods.formState.isSubmitting}
                >
                  {fieldProps.value && fieldProps.value !== ''
                    ? 'Due in ' +
                      formatDistanceToNow(parseJSON(fieldProps.value)) +
                      ` (${moment(fieldProps.value).format('MMM DD, YYYY')})`
                    : 'Add due date'}
                </DueDateButton>
              </Popover>
            )}
          />
        </NameAndDueDateSection>

        <SaveButton
          type="submit"
          textColor={textColor}
          tabIndex={4}
          disabled={newObjFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faCheck} size="1x" />
        </SaveButton>
        <CancelButton
          textColor={textColor}
          tabIndex={5}
          onClick={() => setEditing(false)}
          disabled={newObjFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </CancelButton>
      </FormContainer>
    );

  return (
    <NewObjectiveContainer onClick={() => setEditing(true)}>
      <NewObjectiveSection>
        <TripleStar />
        <NewObjectiveText>Add a new OKR</NewObjectiveText>
      </NewObjectiveSection>
      <NewObjectiveSection>
        <Plus />
      </NewObjectiveSection>
    </NewObjectiveContainer>
  );
};

export default NewObjective;
