/* eslint-disable */

export const onBoardOrganization = /* GraphQL */ `
  mutation OnBoardOrganization($input: OnBoardOrganizationInput!) {
    onBoardOrganization(input: $input) {
      id
      name
      avatarUrl
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      roles {
        nextToken
      }
      users
      iterations {
        nextToken
      }
      currentIterationID
      owner
      createdAt
      updatedAt
      invitations {
        nextToken
      }
      memberships {
        nextToken
      }
    }
  }
`;

export const createEntry = /* GraphQL */ `
  mutation CreateEntry($input: CreateEntryInput!) {
    createEntry(input: $input) {
      id
      name
      overview
      tags
      meta
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      lastEditorID
      activities {
        nextToken
      }
      accessPermissions
      editPermissions
      createdAt
      updatedAt
    }
  }
`;

export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      id
      organization {
        id
        name
        avatarUrl
        colorsPalette
        currentIterationID
        enabledModules
        roles {
          items {
            id
            grantedActions
          }
        }
      }
      role {
        id
        name
      }
    }
  }
`;

export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      id
      email
      fullName
      organizationID
      createdAt
      updatedAt
    }
  }
`;
export const updateEntry = /* GraphQL */ `
  mutation UpdateEntry($input: UpdateEntryInput!) {
    updateEntry(input: $input) {
      id
      name
      overview
      tags
      meta
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      lastEditorID
      activities {
        nextToken
      }
      accessPermissions
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntry = /* GraphQL */ `
  mutation DeleteEntry($input: DeleteEntryInput!) {
    deleteEntry(input: $input) {
      id
      name
      overview
      tags
      meta
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      lastEditorID
      activities {
        nextToken
      }
      accessPermissions
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const createOrganizationRole = /* GraphQL */ `
  mutation CreateOrganizationRole($input: CreateOrganizationRoleInput!) {
    createOrganizationRole(input: $input) {
      id
      name
    }
  }
`;
export const deleteOrganizationRole = /* GraphQL */ `
  mutation DeleteOrganizationRole($input: DeleteOrganizationRoleInput!) {
    deleteOrganizationRole(input: $input) {
      id
    }
  }
`;
export const updateOrganizationRole = /* GraphQL */ `
  mutation UpdateOrganizationRole($input: UpdateOrganizationRoleInput!) {
    updateOrganizationRole(input: $input) {
      id
      name
      grantedActions
      organizationID
      accessPermissions
      editPermissions
    }
  }
`;
export const appendOrganizationColors = /* GraphQL */ `
  mutation AppendOrganizationColors($input: AppendOrganizationColorsInput!) {
    appendOrganizationColors(input: $input) {
      id
      colorsPalette
    }
  }
`;
export const transitEntry = /* GraphQL */ `
  mutation TransitEntry($input: EntryTransitionInput!) {
    transitEntry(input: $input) {
      documentID
      entryID
      topicKey
      rankBeforeEntryID
      rankAfterEntryID
    }
  }
`;
export const startIteration = /* GraphQL */ `
  mutation StartIteration($input: StartIterationInput!) {
    startIteration(input: $input) {
      id
      name
      tasks {
        id
        name
        type
        isUserTemplate
        resourceID
        stage
      }
      organizationID
      startAt
      startedBy
      endAt
      endedBy
      createdAt
      updatedAt
    }
  }
`;

export const finalizeIteration = /* GraphQL */ `
  mutation FinalizeIteration($id: ID!) {
    finalizeIteration(id: $id) {
      id
      name
      tasks {
        id
        name
        type
        resourceID
        stage
        finalized
      }
      stages
      organizationID
      startAt
      startedBy
      endAt
      endedBy
      createdAt
      updatedAt
    }
  }
`;

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      avatarUrl
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      roles {
        nextToken
      }
      users
      iterations {
        nextToken
      }
      currentIterationID
      owner
      createdAt
      updatedAt
      invitations {
        nextToken
      }
      memberships {
        nextToken
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      avatarUrl
      roles {
        nextToken
      }
      users
      iterations {
        nextToken
      }
      currentIterationID
      owner
      createdAt
      updatedAt
      invitations {
        nextToken
      }
      memberships {
        nextToken
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      id
      name
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      avatarUrl
      roles {
        nextToken
      }
      users
      iterations {
        nextToken
      }
      currentIterationID
      owner
      createdAt
      updatedAt
      invitations {
        nextToken
      }
      memberships {
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      fullName
      email
      owner
      contributions {
        nextToken
      }
      assignments {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      fullName
      email
      owner
      contributions {
        nextToken
      }
      assignments {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        nextToken
      }
    }
  }
`;
export const setUserAvatar = /* GraphQL */ `
  mutation SetUserAvatar($input: SetUserAvatarInput!) {
    setUserAvatar(input: $input) {
      id
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      fullName
      email
      owner
      contributions {
        nextToken
      }
      assignments {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        nextToken
      }
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership(
    $input: CreateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    createMembership(input: $input, condition: $condition) {
      id
      organizationID
      userID
      invitationID
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership(
    $input: UpdateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    updateMembership(input: $input, condition: $condition) {
      id
      organizationID
      userID
      invitationID
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership(
    $input: DeleteMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    deleteMembership(input: $input, condition: $condition) {
      id
      organizationID
      userID
      invitationID
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;

export const createObjective = /* GraphQL */ `
  mutation CreateObjective($input: CreateObjectiveInput!) {
    createObjective(input: $input) {
      id
      name
      color
      parentObjectiveID
      organizationID
      expectedEndDate
      createdAt
    }
  }
`;

export const createKeyResult = /* GraphQL */ `
  mutation CreateKeyResult($input: CreateKeyResultInput!) {
    createKeyResult(input: $input) {
      id
      name
      progress
      objectiveID
      parentObjectiveID
      organizationID
      createdAt
    }
  }
`;

export const updateKeyResultProgress = /* GraphQL */ `
  mutation UpdateKeyResultProgress($input: UpdateKeyResultProgressInput!) {
    updateKeyResultProgress(input: $input) {
      id
      name
      progress
      objectiveID
      parentObjectiveID
      organizationID
      createdAt
    }
  }
`;

export const updateKeyResultName = /* GraphQL */ `
  mutation UpdateKeyResultName($input: UpdateKeyResultNameInput!) {
    updateKeyResultName(input: $input) {
      id
      name
      progress
      objectiveID
      parentObjectiveID
      organizationID
      createdAt
    }
  }
`;

export const updateObjective = /* GraphQL */ `
  mutation UpdateObjective($input: UpdateObjectiveInput!) {
    updateObjective(input: $input) {
      id
      name
      expectedEndDate
      parentObjectiveID
      organizationID
      color
      keyResults {
        id
        name
        progress
      }
      parentObjectiveID
      subObjectives {
        id
        name
        keyResults {
          id
          name
          progress
        }
        assignees {
          id
          fullName
          avatarUrl
        }
      }
      assignees {
        id
        fullName
        avatarUrl
      }
    }
  }
`;

export const updateObjectiveAssignee = /* GraphQL */ `
  mutation UpdateObjectiveAssignee($input: UpdateObjectiveAssigneeInput!) {
    updateObjectiveAssignee(input: $input) {
      id
      name
      expectedEndDate
      organizationID
      color
      keyResults {
        id
        name
        progress
      }
      parentObjectiveID
      subObjectives {
        id
        name
        keyResults {
          id
          name
          progress
        }
        assignees {
          id
          fullName
          avatarUrl
        }
      }
      assignees {
        id
        fullName
        avatarUrl
      }
    }
  }
`;

export const updateUserRole = /* GraphQL */ `
  mutation updateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      id
      role {
        id
        name
      }
    }
  }
`;

export const deleteKeyResult = /* GraphQL */ `
  mutation DeleteKeyResult($input: DeleteKeyResultInput!) {
    deleteKeyResult(input: $input) {
      id
      objectiveID
      parentObjectiveID
      organizationID
    }
  }
`;

export const shareDocumentTemplate = /* GraphQL */ `
  mutation ShareDocumentTemplate($input: ShareDocumentTemplateInput) {
    shareDocumentTemplate(input: $input) {
      templates {
        id
        name
        domains {
          key
          name
          bgColor
          fgColor
          topicNameColor
          ordering
        }
        topics {
          key
          name
          domain
          description
          nameBgColor
          descriptionBgColor
          selectors
        }
        createdAt
        updatedAt
        version
        isPublished
      }
    }
  }
`;

export const createDocumentTemplate = /* GraphQL */ `
  mutation CreateDocumentTemplate($input: CreateDocumentTemplateInput) {
    createDocumentTemplate(input: $input) {
      id
    }
  }
`;

export const updateDocumentTemplate = /* GraphQL */ `
  mutation UpdateDocumentTemplate($input: UpdateDocumentTemplateInput) {
    updateDocumentTemplate(input: $input) {
      id
    }
  }
`;
export const deleteObjective = /* GraphQL */ `
  mutation DeleteObjective($input: DeleteObjectiveInput!) {
    deleteObjective(input: $input) {
      id
      parentObjectiveID
      organizationID
    }
  }
`;

export const updateActiveTemplate = /* GraphQL */ `
  mutation UpdateActiveTemplate($input: UpdateActiveTemplateInput!) {
    updateActiveTemplate(input: $input) {
      activeTemplates {
        id
        order
      }
    }
  }
`;

export const updateEnabledModules = /* GraphQL */ `
  mutation UpdateEnabledModules($input: UpdateEnabledModulesInput!) {
    updateEnabledModules(input: $input) {
      enabledModules
    }
  }
`;

export const updateOrganizationDetails = /* GraphQL */ `
  mutation UpdateOrganizationDetails($input: UpdateOrganizationDetailsInput!) {
    updateOrganizationDetails(input: $input) {
      id
      name
      addressLine
      avatarUrl
      businessNumber
      countryCode
      postalCode
      region
      suburb
    }
  }
`;

export const createInitiative = /* GraphQL */ `
  mutation CreateInitiative($input: CreateInitiativeInput!) {
    createInitiative(input: $input) {
      id
      name
      __typename
      topicKey
      tags {
        id
        name
        color
      }
    }
  }
`;

export const createInitiativeKeyResult = /* GraphQL */ `
  mutation CreateInitiativeKeyResult($input: CreateInitiativeKeyResultInput!) {
    createInitiativeKeyResult(input: $input) {
      id
      name
      initiativeID
    }
  }
`;

export const createTextEntry = /* GraphQL */ `
  mutation CreateTextEntry($input: CreateTextEntryInput!) {
    createTextEntry(input: $input) {
      __typename
      id
      name
      topicKey
      documentID
      createdAt
    }
  }
`;

export const deleteTextEntry = /* GraphQL */ `
  mutation DeleteTextEntry($input: DeleteTextEntryInput!) {
    deleteTextEntry(input: $input) {
      id
      name
      documentID
      topicKey
      updatedAt
    }
  }
`;

export const transitEntryV2 = /* GraphQL */ `
  mutation TransitEntryV2($input: TransitEntryV2Input!) {
    transitEntryV2(input: $input) {
      id
      entryRank
    }
  }
`;

export const transitInitiativeKanban = /* GraphQL */ `
  mutation TransitInitiativeKanban($input: TransitInitiativeKanbanInput!) {
    transitInitiativeKanban(input: $input) {
      id
      status
    }
  }
`;

export const updateInitiativeTags = /* GraphQL */ `
  mutation UpdateInitiativeTags($input: UpdateInitiativeTagsInput!) {
    updateInitiativeTags(input: $input) {
      id
      tags {
        id
        name
        color
      }
    }
  }
`;

export const updateInitiativeDueDate = /* GraphQL */ `
  mutation UpdateInitiativeDueDate($input: UpdateInitiativeDueDateInput!) {
    updateInitiativeDueDate(input: $input) {
      id
      dueDate
    }
  }
`;

export const updateInitiativeName = /* GraphQL */ `
  mutation UpdateInitiativeName($input: UpdateInitiativeNameInput!) {
    updateInitiativeName(input: $input) {
      id
      name
    }
  }
`;

export const createDocument = /* GraphQL */ `
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
      name
      organizationID
    }
  }
`;

export const createDocumentV2 = /* GraphQL */ `
  mutation CreateDocumentV2($input: CreateDocumentV2Input!) {
    createDocumentV2(input: $input) {
      id
      type
    }
  }
`;

export const updateInitiativeAttributes = /* GraphQL */ `
  mutation UpdateInitiativeAttributes(
    $input: UpdateInitiativeAttributesInput!
  ) {
    updateInitiativeAttributes(input: $input) {
      id
      name
      topicKey
      tags {
        id
        name
        color
      }
    }
  }
`;

export const updateInitiativeAssignee = /* GraphQL */ `
  mutation UpdateInitiativeAssignee($input: UpdateInitiativeAssigneeInput!) {
    updateInitiativeAssignee(input: $input) {
      id
      assignees {
        id
        fullName
        avatarUrl
      }
      assigneeIDs
    }
  }
`;

export const updateDocumentOHDP = /* GraphQL */ `
  mutation UpdateDocumentOHDP($input: UpdateDocumentOHDPInput!) {
    updateDocumentOHDP(input: $input) {
      id
    }
  }
`;

export const deleteInitiative = /* GraphQL */ `
  mutation DeleteInitiative($input: DeleteInitiativeInput!) {
    deleteInitiative(input: $input) {
      id
    }
  }
`;

export const deleteInitiativeKeyResult = /* GraphQL */ `
  mutation DeleteInitiativeKeyResult($input: DeleteInitiativeKeyResultInput!) {
    deleteInitiativeKeyResult(input: $input) {
      id
    }
  }
`;

export const upsertMasterList = /* GraphQL */ `
  mutation UpsertMasterList($input: UpsertMasterlistScopeInput!) {
    upsertMasterlist(input: $input) {
      id
      includedDocumentIds
      prioritizedInitiativeIds
    }
  }
`;
