import styled from 'styled-components';
import Gravatar from 'react-gravatar';
import { Img } from 'react-image';
import ReactLoading from 'react-loading';
import { COLORS } from '../../common/color';
import { useBoundStore } from '../../states';

type AvatarProps = {
  id: string;
  size?: string;
};

type AvatarContainerProps = {
  size: number;
};
const OrganizationAvatarContainer = styled.span<AvatarContainerProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  align-items: stretch;
  background-color: transparent;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  position: static;
  border: none;
  padding: 0;
  flex-shrink: 0;
`;

const StyledGravatar = styled(Gravatar)`
  height: 100%;
  width: 100%;
`;

const Avatar = ({ id, size }: AvatarProps) => {
  const innerSize = size === 'large' ? 85 : size === 'small' ? 24 : 36;
  const { profile } = useBoundStore((state) => state.auth);
  const imageUrl = `${profile?.avatarUrl}`;

  return (
    <OrganizationAvatarContainer size={innerSize}>
      {profile?.id === id ? (
        <Img
          src={imageUrl}
          loader={<ReactLoading type="spin" color={COLORS.loading} />}
          unloader={<StyledGravatar email={id} default="retro" />}
        />
      ) : (
        <StyledGravatar email={id} default="retro" size={innerSize} />
      )}
    </OrganizationAvatarContainer>
  );
};

export default Avatar;
