import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export type StarsProps = {
  level: number;
  color?: string;
};

type StarProps = {
  iconSize: number;
  color?: string;
};

const StarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StarsInnerContainer = styled.div`
  display: flex;
`;

const StyledStar = styled(FontAwesomeIcon)<Partial<StarProps>>`
  font-size: ${(props) => props.iconSize}px;
  color: ${(props) => props.color};

  align-self: center;
`;

const Stars: React.FC<StarsProps> = ({ level, color }) => {
  const innerSize = level === 1 ? 21 : level === 2 ? 13 : 10;

  if (level === 1) {
    return (
      <StarsContainer>
        <StyledStar icon={faStar} iconSize={innerSize} color={color} />
      </StarsContainer>
    );
  } else if (level === 2) {
    return (
      <StarsContainer>
        <StyledStar icon={faStar} iconSize={innerSize} color={color} />
        <StyledStar icon={faStar} iconSize={innerSize} color={color} />
      </StarsContainer>
    );
  } else {
    return (
      <StarsContainer>
        <StyledStar icon={faStar} iconSize={innerSize} color={color} />
        <StarsInnerContainer>
          <StyledStar icon={faStar} iconSize={innerSize} color={color} />
          <StyledStar icon={faStar} iconSize={innerSize} color={color} />
        </StarsInnerContainer>
      </StarsContainer>
    );
  }
};

export default Stars;
