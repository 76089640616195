import _ from 'lodash';
import { withDefault } from '../../maybe';

export const calculateProgressFromKeyResult = (keyResults: any[]) => {
  const defaultKeyResults = withDefault(keyResults || [], []);

  if (defaultKeyResults.length === 0) return 0;
  return (
    _.round(defaultKeyResults.reduce(
      (sumProgress, currKeyResult) =>
        sumProgress + (currKeyResult.progress || 0),
      0,
    ) / defaultKeyResults.length, 1)
  );
};

export const calculateProgressFromKRandSubObj = (
  keyResults: any[],
  subObjectives: any[],
) => {
  const keyResultProgress = _.sum(
    withDefault(keyResults, []).map((kR) => kR.progress),
  );
  const subObjectivesProgress = subObjectives.reduce(
    (totalProgress, subObj) =>
      totalProgress + calculateProgressFromKeyResult(subObj.keyResults),
    0,
  );

  const totalProgress = keyResultProgress + subObjectivesProgress;
  const totalNumberOfItems = keyResults.length + subObjectives.length;

  if (totalProgress === 0 || totalNumberOfItems === 0) return 0;

  return _.round(totalProgress / totalNumberOfItems, 1);
};
