import React from 'react';
import _ from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from '../Select/Select';
import StyledErrorMessage from '../ErrorMessage/ErrorMessage';
import FieldContainer from '../FieldContainer/FieldContainer';
import { SelectOptions } from '../../common/type';

type ContextSelectProps = {
  name: string;
  label: string;
  defaultValue?: any;
  options: SelectOptions;
  dataAttribute?: string;
};

const ContextSelect: React.FC<ContextSelectProps> = ({
  name,
  label,
  defaultValue,
  options,
  dataAttribute,
}) => {
  const { control, errors } = useFormContext();
  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue?.id}
        render={(controllerProps) => (
          <Select
            label={label}
            onSelectionChange={controllerProps.onChange}
            options={options}
            hasError={_.get(errors, name, false)}
            defaultValue={defaultValue}
            dataAttribute={dataAttribute}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <StyledErrorMessage>
            {message.replace(name, label)}
          </StyledErrorMessage>
        )}
      />
    </FieldContainer>
  );
};

export default ContextSelect;
