import { API, graphqlOperation } from 'aws-amplify';
import { StateCreator } from 'zustand';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  getOrganizationForSetting,
  getAllOrgInitiatives,
} from '../graphql/queries';
import produce, { applyPatches, Patch } from 'immer';
import {
  MasterlistSlice,
  OrganizationSlice,
  StoreInterface,
} from './interfaces';
import {
  Membership,
  Organization,
  OrganizationRole,
  Initiative,
  InitiativeKeyResult,
} from '@propella/core';
import {
  updateOrganizationDetails,
  updateUserRole,
  deleteOrganizationRole,
  deleteOrganization,
  transitInitiativeKanban,
  updateInitiativeTags,
  updateInitiativeName,
  updateInitiativeDueDate,
  deleteInitiativeKeyResult,
  createInitiativeKeyResult,
} from '../graphql/mutations';
import { isJust, withDefault } from '../maybe';
import _ from 'lodash';
import moment from 'moment';
import { WritableDraft } from 'immer/dist/internal';

export const createOrganizationSlice: StateCreator<
  StoreInterface,
  [],
  [],
  OrganizationSlice
> = (set, get) => ({
  organization: {
    iterations: [],
    currentIteration: undefined,
    id: undefined,
    name: undefined,
    isReady: false,
    roles: [],
    invitations: [],
    memberships: [],
    enabledModules: [],
    initiatives: [],
  },
  fetchAllOrgInitiatives: async () => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(getAllOrgInitiatives),
      )) as GraphQLResult<{
        getAllOrgInitiatives: Initiative[];
      }>;
      const fetchedInitiatives = queryResult.data?.getAllOrgInitiatives;
      set(
        produce<OrganizationSlice>((draft) => {
          draft.organization.initiatives = fetchedInitiatives || [];
        }),
      );
    } catch (error: any) {
      console.log(error.errors[0]?.message || error.message);
    }
  },
  fetchOrganization: async (organizationId: string) => {
    try {
      set(
        produce<OrganizationSlice>((draft) => {
          draft.organization.isReady = false;
        }),
      );

      const orgQueryResult = (await API.graphql(
        graphqlOperation(getOrganizationForSetting, {
          id: organizationId,
        }),
      )) as GraphQLResult<{
        getOrganization: Organization;
      }>;

      const fetchedOrg = orgQueryResult.data?.getOrganization;

      if (fetchedOrg) {
        set(
          produce<OrganizationSlice>((draft) => {
            draft.organization.id = fetchedOrg.id;
            draft.organization.name = fetchedOrg.name;
            draft.organization.roles = withDefault(
              fetchedOrg.roles?.items,
              [],
            ).filter(isJust);
            draft.organization.invitations = withDefault(
              fetchedOrg.invitations?.items,
              [],
            ).filter(isJust);
            draft.organization.memberships = withDefault(
              fetchedOrg.memberships?.items,
              [],
            ).filter(isJust);
            draft.organization.enabledModules = withDefault(
              fetchedOrg.enabledModules,
              [],
            ).filter(isJust);
            draft.organization.isReady = true;
          }),
        );
      }
    } catch (error) {
      throw new Error(
        'Error occured while fetching organization . Please try again later.',
      );
    }
  },
  updateOrganizationDetails: async (input) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(updateOrganizationDetails, {
          input: {
            organizationID: get().organization.id,
            name: input.name,
            avatarUrl: input.avatarUrl,
            businessNumber: input.businessNumber,
            countryCode: input.countryCode,
            addressLine: input.addressLine,
            postalCode: input.postalCode,
            suburb: input.suburb,
            region: input.region,
          },
        }),
      )) as GraphQLResult<{
        updateOrganizationDetails: Organization;
      }>;

      const updatedOrg = queryResult.data?.updateOrganizationDetails;

      set(
        produce<OrganizationSlice>((draft) => {
          draft.organization.name = updatedOrg?.name;
        }),
      );
    } catch (error) {
      console.log(error);
      throw new Error(
        'Error occured while update user organization detail. Please try again later.',
      );
    }
  },
  updateMemberRole: async (updateMembershipID, roleID) => {
    try {
      (await API.graphql(
        graphqlOperation(updateUserRole, {
          input: {
            updateMembershipID: updateMembershipID,
            roleID: roleID,
          },
        }),
      )) as GraphQLResult<{
        updateUserRole: Membership;
      }>;

      set(
        produce<OrganizationSlice>((draft) => {
          const membershipIndex = _.findIndex(get().organization.memberships, [
            'id',
            updateMembershipID,
          ]);
          const role = _.find(get().organization.roles, ['id', roleID]);
          if (draft.organization.memberships) {
            draft.organization.memberships[membershipIndex].role = {
              id: role?.id,
              name: role?.name,
            };
          }
        }),
      );
    } catch (error) {
      console.log('Error occured while update user role', error);
      throw new Error(
        'Error occured while update user role. Please try again later.',
      );
    }
  },
  deleteOrganization: async (id) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(deleteOrganization, {
          input: {
            id: id,
          },
        }),
      )) as GraphQLResult<{
        deleteOrganization: Organization;
      }>;
    } catch (error) {
      throw new Error(
        'Error occured while delete organization. Please try again later.',
      );
    }
  },
  deleteOrganizationRole: async (id) => {
    try {
      (await API.graphql(
        graphqlOperation(deleteOrganizationRole, {
          input: {
            id: id,
          },
        }),
      )) as GraphQLResult<{
        deleteOrganizationRole: OrganizationRole;
      }>;

      set(
        produce<OrganizationSlice>((draft) => {
          const rolesList = draft.organization.roles?.filter(
            (role) => role.id !== id,
          );
          draft.organization.roles = rolesList;
        }),
      );
    } catch (error: any) {
      throw new Error(error.errors[0].message);
    }
  },
  handleTransitInitiativeKanban: async (initiativeId, source, destination) => {
    if (!destination || !destination.status) {
      return;
    }

    set(
      produce<MasterlistSlice>((draft) => {
        const oldInitiative = draft.masterlist.initiatives?.find(
          (init) => init.id === initiativeId,
        );

        draft.masterlist.initiatives = draft.masterlist.initiatives?.map(
          (init) => {
            if (init.id === initiativeId) {
              return {
                ...init,
                status: destination.status,
              };
            } else {
              return init;
            }
          },
        );

        try {
          API.graphql(
            graphqlOperation(transitInitiativeKanban, {
              input: {
                initiativeID: initiativeId,
                status: destination.status,
              },
            }),
          ) as GraphQLResult<{
            transitInitiativeKanban: Initiative;
          }>;
        } catch (error) {
          console.log('Error while moving entry', error);
          draft.masterlist.initiatives = draft.masterlist.initiatives?.map(
            (init) => {
              if (init.id === initiativeId) {
                return {
                  ...init,
                  status: oldInitiative?.status,
                };
              } else {
                return init;
              }
            },
          );
        }
      }),
    );
  },
  handleKanbanFilterChange: async (
    selectedUserIds: string[],
    dateRange: string,
    types: string[],
    horizons: string[],
    priorities: string[],
  ) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(getAllOrgInitiatives),
      )) as GraphQLResult<{
        getAllOrgInitiatives: Initiative[];
      }>;
      const allInitiatives = queryResult.data?.getAllOrgInitiatives;

      set(
        produce<MasterlistSlice>((draft) => {
          let filteredInititives = allInitiatives;
          if (selectedUserIds.length) {
            filteredInititives = filteredInititives?.filter(
              (item) =>
                _.intersection(item.assigneeIDs, selectedUserIds).length > 0,
            );
          }

          if (dateRange) {
            const startDate = moment(dateRange.split('-')[0], 'DD/MM/YY');
            const endDate = moment(dateRange.split('-')[1], 'DD/MM/YY');

            filteredInititives = filteredInititives?.filter(
              (item) =>
                item.dueDate &&
                startDate.isBefore(moment(item.dueDate), 'seconds') &&
                endDate.isAfter(moment(item.dueDate), 'seconds'),
            );
          }

          if (types.length) {
            filteredInititives = filteredInititives?.filter((init) =>
              init.tags?.some((tag) => isJust(tag) && types.includes(tag.id))
            );
          }

          if (horizons.length) {
            filteredInititives = filteredInititives?.filter((init) =>
              horizons.filter((horizon) => init.topicKey?.includes(horizon))
                .length > 0
            )
          }

          if (priorities.length) {
            filteredInititives = filteredInititives?.filter((init) =>
              init.tags?.some((tag) => isJust(tag) && priorities.includes(tag.id))
            );
          }

          draft.masterlist.initiatives =
            filteredInititives?.filter(isJust).map((init) => ({
              ...init,
              documentId: '',
              documentName: '',
            })) || [];
        }),
      );
    } catch (error: any) {
      console.log(error.errors[0]?.message || error.message);
    }
  },
  updateInitiativeTagsLocallyKanban: (initiativeId, tags) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        if (draft.masterlist.initiatives) {
          const initiativeIndex = _.findIndex(draft.masterlist.initiatives, [
            'id',
            initiativeId,
          ]);
          if (initiativeIndex !== -1) {
            draft.masterlist.initiatives[initiativeIndex].tags = tags;
          }
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateInitiativeTagsKanban: async (initiativeId, tags) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeTagsLocallyKanban(
        initiativeId,
        tags,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeTags, {
          input: {
            entryID: initiativeId,
            tags,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeTags: Initiative;
      }>;
    } catch (error) {
      console.log('update tags', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },

  updateInitiativeNameLocallyKanban: (initiativeId, name) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        if (draft.masterlist.initiatives) {
          const initiativeIndex = _.findIndex(draft.masterlist.initiatives, [
            'id',
            initiativeId,
          ]);
          if (initiativeIndex !== -1) {
            draft.masterlist.initiatives[initiativeIndex].name = name;
          }
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateInitiativeNameKanban: async (initiativeId, name) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeNameLocallyKanban(
        initiativeId,
        name,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeName, {
          input: {
            entryID: initiativeId,
            name,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeName: Initiative;
      }>;
    } catch (error) {
      console.log('update name', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },

  updateInitiativeDueDateKanban: async (initiativeId, dueDate) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeDueDateLocallyKanban(
        initiativeId,
        dueDate,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeDueDate, {
          input: {
            entryID: initiativeId,
            dueDate,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeDueDate: Initiative;
      }>;
    } catch (error) {
      console.log('update due date', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateInitiativeDueDateLocallyKanban: (initiativeId, dueDate) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        if (draft.masterlist.initiatives) {
          const initiativeIndex = _.findIndex(draft.masterlist.initiatives, [
            'id',
            initiativeId,
          ]);
          if (initiativeIndex !== -1) {
            const nextDueDate = isJust(dueDate)
              ? dueDate.toISOString()
              : undefined;
            draft.masterlist.initiatives[initiativeIndex].dueDate = nextDueDate;
          }
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  deleteInitiativeKeyResultKanban: async (initiativeID, keyResultID) => {
    let changes: Patch[] = [];
    let inverseChanges: Patch[] = [];

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        // const initIndex = _.findIndex(
        //   withDefault(draft.documentV2.entries, []),
        //   (entry) => entry.id === initiativeID,
        // );

        // (
        //   draft.documentV2.entries[initIndex] as WritableDraft<Initiative>
        // ).keyResults = withDefault(
        //   (draft.documentV2.entries[initIndex] as WritableDraft<Initiative>)
        //     .keyResults,
        //   [],
        // ).filter((keyResult) => keyResult?.id !== keyResultID);
        if (draft.masterlist.initiatives) {
          const initIndex = _.findIndex(
            draft.masterlist.initiatives,
            (initiative) => initiative.id === initiativeID,
          );

          (
            draft.masterlist.initiatives[initIndex] as WritableDraft<Initiative>
          ).keyResults = withDefault(
            (
              draft.masterlist.initiatives[
                initIndex
              ] as WritableDraft<Initiative>
            ).keyResults,
            [],
          ).filter((keyResult) => keyResult?.id !== keyResultID);
        }
      },
      (patches, inversePatches) => {
        changes = patches;
        inverseChanges = inversePatches;
      },
    );

    set(applyPatches(get(), changes));

    try {
      await API.graphql(
        graphqlOperation(deleteInitiativeKeyResult, {
          input: {
            initiativeID: initiativeID,
            keyResultID,
          },
        }),
      );
    } catch (error) {
      console.log('Error occurs while deleting key result', error);
      set(applyPatches(get(), inverseChanges));
    }
  },
  createInitiativeKeyResultKanban: async (name, initiativeID) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(createInitiativeKeyResult, {
          input: { name, initiativeID },
        }),
      )) as GraphQLResult<{
        createInitiativeKeyResult: InitiativeKeyResult;
      }>;

      const newKeyResult = queryResult.data?.createInitiativeKeyResult;

      if (newKeyResult) {
        set(
          produce<MasterlistSlice>((draft) => {
            if (draft.masterlist.initiatives) {
              const initiativeIndex = _.findIndex(
                draft.masterlist.initiatives,
                (initiative) => initiative.id === initiativeID,
              );

              (
                draft.masterlist.initiatives[
                  initiativeIndex
                ] as WritableDraft<Initiative>
              ).keyResults = withDefault(
                (
                  draft.masterlist.initiatives[
                    initiativeIndex
                  ] as WritableDraft<Initiative>
                ).keyResults,
                [],
              ).concat([newKeyResult]);
            }
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
});
