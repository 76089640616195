import React from 'react';
import styled from 'styled-components';

const JustTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const JustTextTitle = styled.h3<Pick<JustTextProps, 'color'>>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ color }) => color || '#000000'};
`;

const JustTextContent = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

export type JustTextProps = {
  children?: React.ReactNode;
  name: string;
  color?: string;
  className?: string;
};

const JustText = ({ name, children, className, color }: JustTextProps) => {
  return (
    <JustTextContainer className={className}>
      <JustTextTitle color={color}>{name}</JustTextTitle>
      <JustTextContent>{children}</JustTextContent>
    </JustTextContainer>
  );
};

export default JustText;
