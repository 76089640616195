import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Img } from 'react-image';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Gravatar from 'react-gravatar';
import ReactLoading from 'react-loading';
import { ReactComponent as AssignUser } from './assign.svg';
import {
  User as GraphQLUser,
  Objective as GraphQLObjective,
} from '../../graphql/types';
import { COLORS } from '../../common/color';
import MultiSelect from '../MultiSelect/MultiSelect';
import { withDefault } from '../../maybe';
import { API, graphqlOperation } from 'aws-amplify';
import { updateObjectiveAssignee } from '../../graphql/mutations';
import { ObjectiveStore } from '../../pages/OKR/CurrentObjectives';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useAlert } from 'react-alert';

const AssigneeSection = styled.div`
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

const AddNewAssignee = styled.span<Partial<AssigneeProps>>`
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.parentObjectiveId ? '#1B2E53' : '#ffffff')};
`;

const AssigneeList = styled.span`
  margin-right: 5px;
  display: inline-flex;
`;

const AssignedUserContainer = styled.div<Partial<AssigneeProps>>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid;
  border-color: ${(props) => (props.parentObjectiveId ? '#1B2E53' : '#ffffff')};

  margin-left: -7px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const AssignableListContainer = styled.div`
  background: #ffffff;
  border-radius: 6px;
  border: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  padding: 10px;
`;

type AssigneeProps = {
  objectiveId: string;
  parentObjectiveId?: string;
  assignableUsers?: GraphQLUser[];
  assignees?: GraphQLUser[];
};

const Assignee: React.FC<AssigneeProps> = ({
  assignableUsers = [],
  objectiveId,
  parentObjectiveId,
  assignees = [],
}) => {
  const alert = useAlert();
  const [, dispatch] = useContext(ObjectiveStore);
  const [popOverOpen, setPopoverOpen] = useState<boolean>(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
    assignees.map((assignee) => assignee.id),
  );

  useEffect(() => {
    setSelectedUserIds(assignees.map((assignee) => assignee.id));
  }, [assignees]);

  const saveChanges = async () => {
    setPopoverOpen(false);

    let updatedAssignees;
    try {
      const updateResult = (await API.graphql(
        graphqlOperation(updateObjectiveAssignee, {
          input: {
            objectiveID: objectiveId,
            assigneeIDs: selectedUserIds,
          },
        }),
      )) as GraphQLResult<{
        updateObjectiveAssignee: GraphQLObjective;
      }>;

      updatedAssignees = updateResult?.data?.updateObjectiveAssignee?.assignees;
    } catch (error: any) {
      if (error.errors[0].message) {
        alert.error(error.errors[0].message);
      } else {
        alert.error('Something went wrong. Please try again later.');
      }
    }

    if (updatedAssignees) {
      if (parentObjectiveId) {
        dispatch({
          type: 'updateSubObjectiveAssignee',
          payload: {
            objectiveId,
            parentObjectiveId,
            assignees: updatedAssignees,
          },
        });
      } else {
        dispatch({
          type: 'updateObjectiveAssignee',
          payload: {
            objectiveId,
            assignees: updatedAssignees,
          },
        });
      }
    }
  };

  return (
    <Popover
      isOpen={popOverOpen}
      positions={['bottom', 'left', 'right']}
      onClickOutside={() => saveChanges()}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#ffffff'}
          arrowSize={10}
        >
          <AssignableListContainer>
            <MultiSelect
              items={assignableUsers.map((user) => ({
                id: user.id,
                name: `${withDefault(user.fullName, '')} <${withDefault(
                  user.email,
                  '',
                )}>`,
              }))}
              value={selectedUserIds}
              label="Assign user to objective"
              onSelectionChange={(data: string[]) => setSelectedUserIds(data)}
              hasError={false}
            />
          </AssignableListContainer>
        </ArrowContainer>
      )}
    >
      <AssigneeSection onClick={() => setPopoverOpen(!popOverOpen)}>
        <AssigneeList>
          {assignees.map((user) => (
            <AssignedUserContainer
              parentObjectiveId={parentObjectiveId}
              key={user.id}
            >
              <Img
                src={user.avatarUrl || ''}
                loader={<ReactLoading type="spin" color={COLORS.loading} />}
                unloader={<Gravatar email={user.id} default="retro" />}
              />
            </AssignedUserContainer>
          ))}
        </AssigneeList>
        <AddNewAssignee parentObjectiveId={parentObjectiveId}>
          <AssignUser />
        </AddNewAssignee>
      </AssigneeSection>
    </Popover>
  );
};

export default Assignee;
