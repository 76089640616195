import { GraphQLResult } from '@aws-amplify/api';
import produce, { createDraft, finishDraft } from 'immer';
import { User } from '@propella/core';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { StateCreator } from 'zustand';
import { getUser } from '../graphql/queries';
import { isJust, withDefault } from '../maybe';
import { AuthSlice } from './interfaces';

export const createAuthSlice: StateCreator<AuthSlice, [], [], AuthSlice> = (
  set,
  get,
) => ({
  auth: {
    profile: undefined,
    currentMembership: undefined,
    organizations: undefined,
    isReady: undefined,
    cognitoUser: undefined,
    isCustomerSupport: false,
    enabledModules: [],
  },
  setAuthReady: (isReady: boolean) => {
    set(
      produce<AuthSlice>((draft) => {
        draft.auth.isReady = isReady;
      }),
    );
  },
  initialize: async () => {
    try {
      get().setAuthReady(false);
      const authenticatedUser = await Auth.currentAuthenticatedUser();

      if (authenticatedUser) {
        const authSession = await Auth.userSession(authenticatedUser);
        const claimedPayload = authSession.getIdToken().decodePayload();

        if (
          claimedPayload &&
          (claimedPayload['cognito:groups'] || []).includes('customer-support')
        ) {
          set(
            produce<AuthSlice>((draft) => {
              draft.auth.isCustomerSupport = true;
            }),
          );
        }

        const userProfileQueryResult = (await API.graphql(
          graphqlOperation(getUser, { id: claimedPayload.sub }),
        )) as GraphQLResult<{
          getUser: User;
        }>;
        const userProfile = userProfileQueryResult?.data?.getUser ?? undefined;

        let organizationID;

        if (userProfile) {
          if (claimedPayload['custom:current_organization']) {
            organizationID = claimedPayload['custom:current_organization'];
          } else {
            if (
              userProfile.memberships &&
              userProfile.memberships.items &&
              userProfile.memberships.items.length > 0
            ) {
              organizationID =
                userProfile.memberships.items[0]?.organization?.id;
              await Auth.updateUserAttributes(authenticatedUser, {
                'custom:current_organization': organizationID,
              });
            }
          }
          get().getUserProfile(userProfile, organizationID ?? '');
        }
      }
    } catch (error) {
      Auth.signOut();
    } finally {
      get().setAuthReady(true);
    }
  },
  getUserProfile: async (userProfile, currentOrganizationId) => {
    const nextAuth = createDraft(get().auth);

    nextAuth.profile = userProfile;

    const userMembership = (userProfile.memberships?.items || []).filter(
      isJust,
    );

    nextAuth.organizations = userMembership
      .map((membership) => membership.organization)
      .filter(isJust);

    const currentMembership =
      userMembership.find(
        (m) => m?.organization?.id === currentOrganizationId,
      ) || userMembership[0];

    if (
      isJust(currentMembership?.organization) &&
      isJust(currentMembership?.role)
    ) {
      const organizationRole = (
        currentMembership?.organization.roles?.items || []
      )
        .filter(isJust)
        .find((r) => r?.id === currentMembership?.role?.id);
      nextAuth.currentMembership = {
        organization: currentMembership?.organization,
        role: organizationRole,
      };

      nextAuth.enabledModules = withDefault(
        currentMembership.organization.enabledModules,
        [],
      ).filter(isJust);
    }

    set({
      auth: finishDraft(nextAuth),
    });
  },
  nukeAllThing: () => {
    set(
      produce<AuthSlice>((draft) => {
        draft.auth = {
          enabledModules: [],
          isCustomerSupport: undefined,
          profile: undefined,
          organizations: undefined,
          currentMembership: undefined,
          cognitoUser: undefined,
          isReady: true,
        };
      }),
    );
  },
  appendOrganization: (organization) => {
    set(
      produce<AuthSlice>((draft) => {
        draft.auth.organizations?.push(organization);
      }),
    );
  },
  changeCurrentMembership: async (organizationId) => {
    const availableOrganizations = get()
      .auth.profile?.memberships?.items?.filter(isJust)
      .map((m) => m?.organization?.id);

    const isCustomerSupport = get().auth.isCustomerSupport;

    if (availableOrganizations?.includes(organizationId) || isCustomerSupport) {
      const authenticatedUser = await Auth.currentAuthenticatedUser();

      await Auth.updateUserAttributes(authenticatedUser, {
        'custom:current_organization': organizationId,
      });
    }
    get().initialize();
  },
});
