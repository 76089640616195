import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { faPlus, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as DoubleEmptyStar } from './doubleemptystar.svg';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  MutationCreateObjectiveArgs,
  Objective as GraphQLObjective,
} from '../../graphql/types';
import { ObjectiveStore } from '../../pages/OKR/CurrentObjectives';
import { createObjective as CreateObjectiveMutation } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const SubObjectiveForm = styled.form`
  margin-top: 16px;
  background: #ffffff;
  border: 1px dashed #cbcccc;
  border-radius: 5px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 22px;
  padding-right: 24px;
`;

const NonEditingContainer = styled.div`
  margin-top: 16px;
  background: #ffffff;
  border: 1px dashed #cbcccc;
  border-radius: 5px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 22px;
  padding-right: 24px;
`;

const StarAndTextSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NewSubObjectiveText = styled.span`
  font-family: inherit;
  font-size: 16px;
  color: #aeafaf;
  letter-spacing: 0;
  margin-left: 16px;
`;

const NewKeyResultInput = styled.input`
  width: 100%;
  border: 0;
  resize: none;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.item.container};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};

const ColorPicker = styled(MenuButton)`
  margin-right: 22px;
`;

const SaveButton = styled(MenuButton)`
  /* pointer-events: none; */
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const newSubObjectiveSchema = yup.object().shape({
  name: yup.string().required(),
});

export type NewSubObjectiveProps = {
  parentObjectiveID: string;
};

const NewSubObjective: React.FC<NewSubObjectiveProps> = ({
  parentObjectiveID,
}) => {
  const [objectiveScope, dispatch] = useContext(ObjectiveStore);
  const [editing, setEditing] = useState<boolean>(false);
  const newSubObjectiveFormMethods = useForm({
    resolver: yupResolver(newSubObjectiveSchema),
    defaultValues: {
      name: '',
    },
  });

  const createSubObjective = async (name: string) => {
    const createObjectiveArgs: MutationCreateObjectiveArgs = {
      input: {
        name,
        parentObjectiveID,
        iterationID: objectiveScope.iteration?.id,
      },
    };

    const queryResult = (await API.graphql(
      graphqlOperation(CreateObjectiveMutation, createObjectiveArgs),
    )) as GraphQLResult<{
      createObjective: GraphQLObjective;
    }>;

    const createdObjective = queryResult?.data?.createObjective;

    if (createdObjective) {
      dispatch({
        type: 'addSubObjective',
        payload: {
          ...createdObjective,
          keyResults: [],
        },
        meta: {
          parentObjectiveID,
        },
      });
    }
  };

  if (editing) {
    return (
      <SubObjectiveForm
        onSubmit={newSubObjectiveFormMethods.handleSubmit(async (data) => {
          await createSubObjective(data.name);
          newSubObjectiveFormMethods.reset();
        })}
      >
        <Controller
          control={newSubObjectiveFormMethods.control}
          name="name"
          render={(fieldProps) => (
            <NewKeyResultInput
              tabIndex={1}
              autoFocus
              disabled={newSubObjectiveFormMethods.formState.isSubmitting}
              placeholder="Enter a title for the objective"
              required
              {...fieldProps}
            />
          )}
        />

        <SaveButton
          type="submit"
          tabIndex={2}
          disabled={newSubObjectiveFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faCheck} size="1x" />
        </SaveButton>
        <CancelButton
          tabIndex={3}
          onClick={() => setEditing(false)}
          disabled={newSubObjectiveFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </CancelButton>
      </SubObjectiveForm>
    );
  }

  return (
    <NonEditingContainer onClick={() => setEditing(true)}>
      <StarAndTextSection>
        <DoubleEmptyStar />
        <NewSubObjectiveText>Add a new objective</NewSubObjectiveText>
      </StarAndTextSection>
      <FontAwesomeIcon icon={faPlus} size="1x" color="#aeafaf" />
    </NonEditingContainer>
  );
};

export default NewSubObjective;
