import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { API, graphqlOperation } from 'aws-amplify';
import Loading from '../../components/AppLoading';
import { getIteration } from '../../graphql/queries';
import Button from '../../components/Button/Button';
import ContextSelect from '../../components/ContextSelect/ContextSelect';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useBoundStore } from '../../states';
import { useEffect, useState } from 'react';
import { Iteration } from '@propella/core';
import { GraphQLResult } from '@aws-amplify/api-graphql';

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

type GetIterationOutput = {
  getIteration: Iteration;
};

const createDocumentSchema = yup.object({
  name: yup.string(),
  type: yup.string().required(),
});

type CreateDocumentFormValues = yup.InferType<typeof createDocumentSchema>;

const FormContainer = styled.div`
  flex-grow: 1;
  padding-top: 30px;
  width: 100%;
  max-width: 880px;
  align-self: center;
`;

const allDocumentTypes = [
  {
    id: 'businessModelCanvas',
    name: 'Business Model Canvas',
  },
  {
    id: 'competitiveLandscape',
    name: 'Competitive Landscape',
  },
  {
    id: 'strategicAgilityPlan',
    name: 'Strategic Drivers',
  },
  {
    id: 'strategicInsights',
    name: 'Strategic Insights',
  },
  {
    id: 'onePageStrategicAgilityPlan',
    name: 'One Page Strategic Agility Plan',
  },
  {
    id: 'ONE_HUNDRED_DAY_PLAN',
    name: '100 Day Plan',
  },
];

const CreateDocument = () => {
  const [currentIteration, setCurrentIteration] = useState<Iteration>();
  const { currentMembership } = useBoundStore((state) => state.auth);
  const history = useHistory();
  const [fetching, setFetching] = useState<boolean>(false);
  const [createDocument, createDocumentV2] = useBoundStore((store) => [
    store.createDocument,
    store.createDocumentV2,
  ]);

  const createDocumentForm = useForm<CreateDocumentFormValues>({
    resolver: yupResolver(createDocumentSchema),
    defaultValues: {
      type: '',
    },
  });

  useEffect(() => {
    const fetchCurrentIteration = async () => {
      setFetching(true);
      if(currentMembership?.organization.currentIterationID) {
        const fetchResult = (await API.graphql(
          graphqlOperation(getIteration, {
            id: currentMembership?.organization.currentIterationID,
          }),
        )) as GraphQLResult<GetIterationOutput>;
        setCurrentIteration(fetchResult.data?.getIteration);
      }
      setFetching(false);
    };
    fetchCurrentIteration();
  }, []);

  const createDocumentFormHandler: SubmitHandler<CreateDocumentFormValues> =
    async (data) => {
      if (data.type === 'ONE_HUNDRED_DAY_PLAN') {
        const newDocumentId = await createDocumentV2(data.type, data.name);

        if (newDocumentId) {
          history.replace(`/v2/documents/${newDocumentId}`);
        }
      } else {
        const newDocumentId = await createDocument(data.type, data.name);

        if (newDocumentId) {
          history.replace(`/documents/${newDocumentId}`);
        }
      }
    };

  if (fetching) {
    return <Loading />;
  }

  return (
    <FormProvider {...createDocumentForm}>
      <PageContainer
        onSubmit={createDocumentForm.handleSubmit(createDocumentFormHandler)}
      >
        <PageHeader
          variant="light"
          header="Create Document"
          actionComponent={
            <Button
              variant="primary"
              type="submit"
              disabled={createDocumentForm.formState.isSubmitting}
            >
              Create
            </Button>
          }
        />
        <FormContainer>
          <ContextSelect
            name="type"
            label="Type*"
            options={
              currentIteration && !currentIteration?.endedBy
                ? allDocumentTypes
                : allDocumentTypes.filter(
                  (type) => type.id === 'ONE_HUNDRED_DAY_PLAN',
                )
            }
          />
          <ContextTextField name="name" label="Name" />
        </FormContainer>
      </PageContainer>
    </FormProvider>
  );
};

export default CreateDocument;
