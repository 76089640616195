import { useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import Loading from '../components/AppLoading';
import { useBoundStore } from '../states';

const SwitchOrganization = () => {
  const { currentMembership, isCustomerSupport } = useBoundStore(
    (state) => state.auth,
  );
  const { changeCurrentMembership } = useBoundStore();

  const queryString = new URLSearchParams(useLocation().search);
  const organizationID = queryString.get('organizationID');
  const nextPage = queryString.get('continue') || '/plan';

  useEffect(() => {
    if (organizationID) {
      changeCurrentMembership(organizationID);
    }
  }, []);

  if (
    !organizationID ||
    currentMembership?.organization?.id === organizationID ||
    isCustomerSupport
  ) {
    return (
      <Redirect
        to={{
          pathname: nextPage,
        }}
      />
    );
  }

  return <Loading />;
};

export default SwitchOrganization;
