import React from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import OwnerOption, { AvatarRoundedContainer } from './OnwerOption';
import { useBoundStore } from '../../states';
import { isJust, withDefault } from '../../maybe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { Img } from 'react-image';
import ReactLoading from 'react-loading';
import Gravatar from 'react-gravatar';
import { COLORS } from '../../common/color';

const OwnerDropDown = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;

  background: #ffffff;
  border: 1px solid rgba(220, 223, 227, 0.5);
  border-radius: 4px;
`;

const StyledAvatarContainer = styled(AvatarRoundedContainer)`
  margin-right: 0;
`;

const StyledButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  height: 30px;
  width: 100%;

  padding-left: 8px;
  padding-right: 8px;

  /* Main Purple */

  color: #5e4979;
  border-radius: 4px;

  span {
    margin: 0;
  }

  ${StyledAvatarContainer} {
    margin-left: -7px;

    &:first-child {
      margin-left: 0;
    }
  }

  &:hover {
    background-color: #f7f7f7;
  }
`;

const OwnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
`;

export type InitOwnerProps = {
  selectedUserIds?: string[];
  onSelect?: (id: string[]) => Promise<void>;
};

const InitOwner = ({ onSelect, selectedUserIds = [] }: InitOwnerProps) => {
  const assignableUsers = useBoundStore((state) =>
    withDefault(state.masterlist.assignableUsers, []).filter(isJust),
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const updateAssigneeIds = (id: string) => {
    if (selectedUserIds?.includes(id)) {
      onSelect?.(selectedUserIds.filter((userId) => userId !== id));
    } else {
      onSelect?.([...(selectedUserIds || []), id]);
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top']}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={() => (
        <OwnerDropDown>
          {assignableUsers.map((user) => (
            <OwnerOption
              key={user.id}
              isSelected={selectedUserIds.includes(user.id)}
              id={user.id}
              name={user.fullName}
              email={user.email}
              avatarUrl={user.avatarUrl}
              onSelect={(id) => {
                updateAssigneeIds(id);
              }}
            />
          ))}
        </OwnerDropDown>
      )}
    >
      <StyledButton onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        {selectedUserIds.length > 0 ? (
          <OwnerContainer>
            {selectedUserIds.map((id) => {
              return (
                <StyledAvatarContainer key={id}>
                  <Img
                    src={withDefault(
                      assignableUsers.find((user) => user.id === id)?.avatarUrl,
                      '',
                    )}
                    loader={<ReactLoading type="spin" color={COLORS.loading} />}
                    unloader={
                      <Gravatar
                        email={id}
                        default="retro"
                        style={{ width: '100%', height: '100%' }}
                      />
                    }
                  />
                </StyledAvatarContainer>
              );
            })}
          </OwnerContainer>
        ) : (
          <>
            <StyledAvatarContainer>
              <FontAwesomeIcon icon={faUser} color="#5e4979" size="sm" />
            </StyledAvatarContainer>
            <span>Unassigned</span>
          </>
        )}
        <StyledAvatarContainer>
          <FontAwesomeIcon icon={faPlus} color="#5e4979" size="sm" />
        </StyledAvatarContainer>
      </StyledButton>
    </Popover>
  );
};

export default InitOwner;
