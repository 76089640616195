import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import CanvasTopicHeader from '../../components/CanvasTopicHeader/CanvasTopicHeader';
import { NewBlockFormOutput } from './SelectedCell';
import BlockForm from './BlockForm';

type PreviewDataCellProps = Cell & {
  onDeleteBlock: (id: string) => void;
  onEditBlock: (data: NewBlockFormOutput) => void;
};

type PreviewContainerProps = {
  gridArea: string;
  topBorderColor: string;
};

const PreviewContainer = styled.div<PreviewContainerProps>`
  grid-area: ${(props) => props.gridArea};
  background-color: ${(props) => props.theme.colors.listitem.border};

  display: grid;
  grid-template-areas:
    'topicName'
    'topicDescription'
    'formControl';
  grid-template-rows: minmax(min-content, 93px) 1fr auto;
  border-radius: 6px;
  min-height: 300px;
  border-radius: 6px;
  border-top: 10px solid;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-top-color: ${(props) => props.topBorderColor};
`;

const TopicDescription = styled.div`
  grid-area: topicDescription / topicDescription / topicDescription /
    topicDescription;
  padding: 15px;
  padding-top: 0;
  color: ${(props) => props.theme.colors.topic.additional};
  font-size: 14px;
`;

const FormControlSection = styled.div`
  grid-area: formControl / formControl / formControl / formControl;
  display: flex;
  justify-content: space-between;

  padding: 10px;
`;

interface MenuButtonProps {
  textColor?: string;
}

const MenuButton = styled.button<MenuButtonProps>`
  background-color: transparent;
  color: ${(props) => props.textColor || '#fffffff'};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};
const SaveButton = styled(MenuButton)`
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const PreviewDataCell: React.FC<PreviewDataCellProps> = ({
  name,
  color,
  description = '',
  x,
  y,
  w,
  h,
  onDeleteBlock,
  onEditBlock,
  id,
  type,
}) => {
  const [editing, setEditing] = useState<boolean>(false);

  if (editing) {
    return (
      <PreviewContainer
        gridArea={[y, x, y + h, x + w].join(' / ')}
        className="cube"
        topBorderColor={color || '#ffffff'}
      >
        <BlockForm
          onFormSubmit={(formOutput) => {
            onEditBlock(formOutput);
            setEditing(false);
          }}
          onClearSelection={() => setEditing(false)}
          defaultValue={{ name, color, type }}
        />
      </PreviewContainer>
    );
  }

  return (
    <PreviewContainer
      gridArea={[y, x, y + h, x + w].join(' / ')}
      className="cube"
      topBorderColor={color || '#ffffff'}
    >
      <CanvasTopicHeader>{name}</CanvasTopicHeader>
      <TopicDescription>{description}</TopicDescription>
      <FormControlSection>
        <SaveButton onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon={faEdit} size="1x" />
        </SaveButton>
        <CancelButton onClick={() => id && onDeleteBlock(id)}>
          <FontAwesomeIcon icon={faTrash} size="1x" />
        </CancelButton>
      </FormControlSection>
    </PreviewContainer>
  );
};

export default PreviewDataCell;
