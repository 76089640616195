import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import * as yup from 'yup';
import ContextSelect from '../../components/ContextSelect/ContextSelect';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import PageHeader from '../../components/PageHeader/PageHeader';

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  flex-grow: 1;
  padding-top: 30px;
  width: 100%;
  max-width: 880px;
  align-self: center;
`;

const newDocumentSchema = yup.object({
  name: yup.string().required(),
});

type NewDocumentFormValues = yup.InferType<typeof newDocumentSchema>;

const NewDocument = () => {
  const newDocumentForm = useForm<NewDocumentFormValues>({
    resolver: yupResolver(newDocumentSchema),
  });

  return (
    <FormProvider {...newDocumentForm}>
      <PageContainer
        onSubmit={newDocumentForm.handleSubmit((data) => console.log(data))}
      >
        <PageHeader header="New Document" />
        <FormContainer>
          <ContextTextField name="name" label="Name" />
          <ContextSelect
            name="type"
            options={[{ id: '1', name: 'Type 1' }]}
            label="Type"
          />
        </FormContainer>
      </PageContainer>
    </FormProvider>
  );
};

export default NewDocument;
