import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { NewEntryV2Variant } from '../../pages/DocumentV2/onePage';
import ClearButton from '../ClearButton/ClearButton';
import { ReactComponent as Diamond } from './diamond.svg';
import StarterKit from '@tiptap/starter-kit';
import { useEditor, EditorContent } from '@tiptap/react';

export type NewEntryV2Props = {
  type?: NewEntryV2Variant;
  createNewEntry: (name: string) => Promise<void>;
  className?: string;
};

const StaticNewEntry = styled.div`
  background: #ffffff;
  border: 1px dashed #e3e8e8;
  border-radius: 3px;
  padding: 0 14px;

  min-height: 42px;

  display: flex;
  align-items: center;

  .ProseMirror {
    padding: 5px;
    width: 100%;

    overflow-wrap: anywhere;

    p {
      margin: 0 !important;
    }

    h2,
    h1,
    h3 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .ProseMirror-focused {
    outline: none;
  }
`;

export const NewEntryLabel = styled.span`
  margin-left: 14px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #aeafaf;
`;

const StyledEditor = styled(EditorContent)`
  width: 100%;

  white-space: pre-wrap;
  padding: 5px;
  line-height: 23px;
  overflow-wrap: anywhere;

  p {
    margin: 0 !important;
  }

  h2,
  h1,
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }

  blockquote {
    border-left: 3px solid #dfe1e6;
    padding-left: 10px;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin: 0;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

type StyledClearButtonProps = {
  hoverColor: string;
};

export const StyledClearButton = styled(ClearButton)<StyledClearButtonProps>`
  margin-right: 12px;

  color: #aeafaf;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;

const newEntrySchema = yup.object({
  name: yup.string().required(),
});

export type NewEntryFormValues = yup.InferType<typeof newEntrySchema>;

const NewEntryV2 = (props: NewEntryV2Props) => {
  const placeholder =
    props.type === NewEntryV2Variant.INITIATIVE
      ? 'Add a new Initiative'
      : 'Add a new Entry';

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: false,
        bulletList: false,
        orderedList: false,
        codeBlock: false,
        blockquote: false,
        horizontalRule: false,
        hardBreak: false,
      }),
    ],
    content: '',
    autofocus: 'end',
    editable: true,
  });

  const onSubmit = async () => {
    if (!editor) {
      return;
    }

    if (editor.state.doc.textContent.length === 0) {
      return;
    } else {
      await props.createNewEntry(editor.state.doc.textContent);
      editor.commands.clearContent(true);
      setEditing(false);
    }
  };

  const onClose = () => {
    if (!editor) {
      return;
    }
    editor.commands.clearContent(true);
    setEditing(false);
  };

  const [editing, setEditing] = useState<boolean>(false);

  if (editing) {
    return (
      <StaticNewEntry>
        <Diamond />
        <StyledEditor editor={editor} autoFocus={true} />
        <ButtonGroup>
          <StyledClearButton type="submit" hoverColor="#56B6B9">
            <FontAwesomeIcon icon={faCheck} onClick={() => onSubmit()} />
          </StyledClearButton>
          <StyledClearButton
            type="button"
            hoverColor="#D93B3B"
            onClick={() => onClose()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </StyledClearButton>
        </ButtonGroup>
      </StaticNewEntry>
    );
  }

  return (
    <StaticNewEntry
      onClick={() => setEditing(true)}
      className={props.className}
    >
      <Diamond />
      <NewEntryLabel>{placeholder}</NewEntryLabel>
    </StaticNewEntry>
  );
};

export default NewEntryV2;
