import { useState, useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import axios from 'axios';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { useForm, FormProvider } from 'react-hook-form';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { useAlert } from 'react-alert';
import Gravatar from 'react-gravatar';
import Button from '../../components/Button/Button';
import PageHeader from '../../components/PageHeader/PageHeader';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { setUserAvatar, shareDocumentTemplate } from '../../graphql/mutations';
import { Img } from 'react-image';
import ReactLoading from 'react-loading';
import { v4 } from 'uuid';
import _ from 'lodash';
import Modal from 'styled-react-modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwitchBar from '../../components/SwitchBar';
import { Maybe } from '@propella/core';
import { COLORS } from '../../common/color';
import { isJust } from '../../maybe';
import { useBoundStore } from '../../states';
import { SwitchBarOption } from '../../components/SwitchBar/SwitchBar';
import RectButton from '../../components/Button/RectButton';
import Box from '../../components/Box';
import { Table } from '../../components/Table';
import TableBody from '../../components/Table/TableBody';
import TableCell from '../../components/Table/TableCell';
import TableColumn from '../../components/Table/TableColumn';
import TableHeader from '../../components/Table/TableHeader';
import TableRow from '../../components/Table/TableRow';
import { ReactComponent as ItemIcon } from '../../components/PlanItem/ItemIcon.svg';
import { DocumentTemplateSetting, Membership, Template } from '../../common/type';
import Loading from '../../components/AppLoading';
import { getDocumentTemplates } from '../../graphql/queries';
import ContextSubfieldMultiSelect from '../../components/SubfieldMultiSelect/ContextSubfieldMultiSelect';
import Label from '../../components/Label/Label';

type DocumentTemplateOutput = {
  getDocumentTemplates: {
    templates: DocumentTemplateSetting[];
  };
};

type ShareDocumentInput = {
  organizationList: string[];
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormHeader = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.topic.header};
  margin-top: 32px;
  margin-bottom: 16px;
`;

const AvatarContainer = styled.div`
  border-radius: 34px;
  overflow: hidden;
  height: 211px;
  width: 211px;
  margin-bottom: 16px;
`;

const StyledAvatarPreview = styled(Img)`
  height: 100%;
  width: 100%;
`;

const StyledLoading = styled(ReactLoading)`
  align-self: center;
  height: 211px;
  width: 211px;
`;

const FilterBarSection = styled.section`
  margin: 32px 0;
  width: 100%;
`;

const StyledBox = styled(Box)`
  margin-top: 50px;
`;

const PlusIcon = styled(FontAwesomeIcon)`
  top: 0;
  left: 0;
  color: #ffffff;
  margin-right: 10px;
`;

const DocumentName = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const DocumentIcon = styled(ItemIcon)`
  margin-right: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const EditButton = styled(RectButton)`
  margin-left: 10px;
`;

const StyledModal = styled.div`
  width: 30rem;
  height: 40rem;
  display: block;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 220px;
  margin-bottom: 150px;
  padding-bottom: 30px;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const FormContainer = styled.form`
  grid-area: form / form / form / form;
  display: flex;
  margin: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  ${Label} {
    font-size: 18px;
    font-weight: 500;
  }
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
  margin-top: 10px;
`;

const settingTabs: SwitchBarOption[] = [
  {
    label: 'Profile',
    value: 'profile',
  },
  {
    label: 'Templates',
    value: 'templates',
  },
];

const userProfileSchema = yup.object().shape({
  fullName: yup.string().required(),
});

const uploadAvatarSchema = yup.object().shape({
  avatar: yup.mixed().test('Only PNG or JPEG images are supports', (files) => {
    if (!files) return false;
    const avatarFile = files[0];
    return avatarFile && ['image/png', 'image/jpeg'].includes(avatarFile.type);
  }),
});

type getUserAvatarUrlOutput = {
  getUploadAvatarUrl: {
    avatarUrl: string;
    uploadUrl: string;
  };
};

const getAvatarUrlsQuery = `
  query GetAvatarUrls($input: GetUploadAvatarInput!) {
    getUploadAvatarUrl(input: $input) {
      uploadUrl
      avatarUrl
    }
  }
`;

const updateUserProfileQuery = `
  mutation UpdateUserProfile($input: UpdateUserInfoInput!) {
    updateUserInfo(input: $input) {
      fullName
    }
  }
`;

const shareDocumentSchema = yup.object().shape({
  organizationList: yup.array().of(yup.string()).min(1),
});

const Profile = () => {
  const { profile } = useBoundStore((state) => state.auth);
  const { initialize } = useBoundStore();
  const alert = useAlert();
  const [currentTab, setCurrentTab] = useState<SwitchBarOption>(settingTabs[0]);
  const history = useHistory();
  const [templates, setTemplates] = useState<DocumentTemplateSetting[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<string>('');
  const [submitStatus, setSubmitStatus] = useState<number>(-1);

  const isSubmitted = (): boolean =>
  [200, 400, 403, 404].indexOf(submitStatus) > -1;

  const editUserProfileFormMethods = useForm({
    resolver: yupResolver(userProfileSchema),
    defaultValues: {
      fullName: profile?.fullName || '',
    },
  });

  const shareDocumentFormMethods = useForm({
    resolver: yupResolver(shareDocumentSchema),
    defaultValues: {
      organizationList: [],
    },
  });

  const uploadAvatarForm = useForm({
    resolver: yupResolver(uploadAvatarSchema),
  });

  let membershipOptions;
  if (profile !== undefined) {
    if (profile.memberships) {
      if (profile.memberships.items) {
        membershipOptions = profile.memberships.items.map(
          (item: Maybe<Membership>) =>
            _.pick(item?.organization, ['id', 'name']),
        );
      }
    }
  }

  useEffect(() => {
    const getUserDocuments = async (): Promise<void> => {
      setFetching(true);
      const queryResult = (await API.graphql(
        graphqlOperation(getDocumentTemplates),
      )) as GraphQLResult<DocumentTemplateOutput>;
      const fetchedTemplates =
        queryResult?.data?.getDocumentTemplates.templates;
      if (fetchedTemplates) {
        setTemplates(fetchedTemplates);
      }
      setFetching(false);
    };

    getUserDocuments();
  }, []);

  const updateUserAboutMe = async (data: any) => {
    try {
      await API.graphql(
        graphqlOperation(updateUserProfileQuery, {
          input: {
            fullName: data.fullName,
          },
        }),
      );

      initialize();
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }
  };

  const updateUserAvatarUrl = async (avatarUrl: string) => {
    await API.graphql(
      graphqlOperation(setUserAvatar, {
        input: {
          avatarUrl,
        },
      }),
    );
  };

  const updateAvatar = async (data: any) => {
    const avatarFile = data.avatar[0];
    const fileType = avatarFile.name.split('.').pop();

    try {
      const getAvatarUploadUrlResult = (await API.graphql(
        graphqlOperation(getAvatarUrlsQuery, {
          input: {
            type: fileType,
          },
        }),
      )) as GraphQLResult<getUserAvatarUrlOutput>;

      if (!getAvatarUploadUrlResult.data?.getUploadAvatarUrl) {
        throw new Error('Error occurs while uploading avatar');
      }

      const { avatarUrl, uploadUrl } =
        getAvatarUploadUrlResult.data.getUploadAvatarUrl;

      await axios.put(uploadUrl, avatarFile, {
        headers: {
          'Content-Type': `image/${fileType}`,
        },
      });

      await updateUserAvatarUrl(avatarUrl);

      // getUserData();
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }

    uploadAvatarForm.reset();
  };

  const onClickShare = (documentId: string) => {
    setIsOpenModal(true);
    setCurrentDocument(documentId);
  };

  const shareDocuments = async (data: ShareDocumentInput) => {
    try {
      (await API.graphql(
        graphqlOperation(shareDocumentTemplate, {
          input: {
            organizationIDs: data.organizationList,
            documentTemplateVersionID: currentDocument,
          },
        }),
      )) as GraphQLResult<DocumentTemplateOutput>;
      setSubmitStatus(200);
      setIsOpenModal(false);
      setSubmitStatus(-1);
      alert.success('Template is successfully shared.');
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
      setSubmitStatus(error.response.status);
    }
  };

  if (!profile) {
    return <Redirect to="/login" />;
  }

  if (fetching) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader variant="light" header="User profile" />
      <FilterBarSection>
        <SwitchBar
          options={settingTabs}
          selectedOption={currentTab}
          onSelect={(selectedOpt) => setCurrentTab(selectedOpt)}
        />
      </FilterBarSection>
      {currentTab.value === 'profile' && (
        <Container>
          <form>
            <Row justifyContent="center">
              <Col col={4}>
                <StyledFormHeader>Profile photo</StyledFormHeader>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col col={4}>
                <AvatarContainer>
                  {isJust(profile.avatarUrl) && (
                    <StyledAvatarPreview
                      src={profile.avatarUrl}
                      loader={
                        <StyledLoading type="spin" color={COLORS.loading} />
                      }
                      unloader={
                        <Gravatar
                          email={profile.id}
                          default="retro"
                          size={211}
                        />
                      }
                    />
                  )}
                </AvatarContainer>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col col={4}>
                <input
                  type="file"
                  name="avatar"
                  ref={uploadAvatarForm.register}
                  disabled={uploadAvatarForm.formState.isSubmitting}
                  onChange={() => uploadAvatarForm.handleSubmit(updateAvatar)()}
                />
              </Col>
            </Row>
          </form>
          <Row justifyContent="center">
            <Col col={4}>
              <StyledFormHeader>About you</StyledFormHeader>
            </Col>
          </Row>
          <Row justifyContent="center">
            <Col col={4}>
              <form
                onSubmit={editUserProfileFormMethods.handleSubmit(
                  updateUserAboutMe,
                )}
              >
                <FormProvider {...editUserProfileFormMethods}>
                  <ContextTextField name="fullName" label="Name" />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={editUserProfileFormMethods.formState.isSubmitting}
                  >
                    Save
                  </Button>
                </FormProvider>
              </form>
            </Col>
          </Row>
        </Container>
      )}
      {currentTab.value === 'templates' && (
        <Container>
          <Row>
            <Col col={12}>
              <StyledBox
                header="My Documents"
                actionComponent={
                  <RectButton
                    onClick={() => history.push('/templates/' + v4())}
                  >
                    <PlusIcon size="1x" icon={faPlus} />
                    Create document
                  </RectButton>
                }
              >
                <Table>
                  <TableHeader>
                    <tr>
                      <TableColumn>Document name</TableColumn>
                      <TableColumn>Status</TableColumn>
                      <TableColumn></TableColumn>
                    </tr>
                  </TableHeader>
                  <TableBody>
                    {templates.map((template, index) => (
                      <TableRow key={String(index)}>
                        <TableCell>
                          <DocumentName>
                            <DocumentIcon />
                            {template.name}
                          </DocumentName>
                        </TableCell>
                        <TableCell>
                          {template.isPublished ? 'Active' : 'Draft'}
                        </TableCell>
                        <TableCell>
                          <ButtonGroup>
                            <RectButton
                              onClick={() => onClickShare(template.id)}
                            >
                              Share
                            </RectButton>
                            <Link to={'/templates/' + template.id}>
                              <EditButton variant="secondary">Edit</EditButton>
                            </Link>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledBox>
            </Col>
          </Row>
        </Container>
      )}
      <Modal
          isOpen={isOpenModal}
          onBackgroundClick={() => {
            setIsOpenModal(false);
          }}
          onEscapeKeydown={() => {
            setIsOpenModal(false);
          }}
        >
          <StyledModal>
            {!isSubmitted() && (
              <FormProvider {...shareDocumentFormMethods}>
                <FormContainer>
                  <ContextSubfieldMultiSelect
                    name="organizationList"
                    label="Shared organizations"
                    options={membershipOptions as Template[]}
                  />
                  <SubmitButton
                    type="button"
                    variant="primary"
                    onClick={shareDocumentFormMethods.handleSubmit(
                      shareDocuments,
                    )}
                    disabled={shareDocumentFormMethods.formState.isSubmitting}
                  >
                    Share
                  </SubmitButton>
                </FormContainer>
              </FormProvider>
            )}
          </StyledModal>
        </Modal>
    </PageContainer>
  );
};

export default Profile;
