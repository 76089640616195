import { ReactComponent as TagIcon } from '../Initiative/Tag.svg';
import { ReactComponent as CalendarIcon } from '../Initiative/Calendar.svg';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import diff from 'node-htmldiff';
import KeyResult from '../Initiative/KeyResult';
import 'react-slidedown/lib/slidedown.css';
import { Initiative } from '@propella/core';
import { useBoundStore } from '../../states';
import { format } from 'date-fns';
import ReactDOMServer from 'react-dom/server';
import EntryViewer from '../EntryViewer/EntryViewer';
import { EntryChange } from '../../common/type';

const InitiativeContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const InitiativeCard = styled.div`
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);

  svg {
    margin-right: 12px;
  }
`;

const DueDateGroup = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #aeafaf;
`;

const RealDueDate = styled.span<Pick<CompareInitiativeProps, 'color'>>`
  color: ${(props) => props.color}; ;
`;

const KeyResultList = styled.div`
  padding: 12px 16px;
  padding-bottom: 0;
`;

export type KeyResult = {
  name: string;
};

export type CompareInitiativeProps = {
  id: Initiative['id'];
  name: Initiative['name'];
  color: string;
  keyResults?: Initiative['keyResults'];
  tags?: Initiative['tags'];
  className?: string;
  index?: number;
  change: EntryChange;
};

export const HandlerContainer = styled.div`
  min-width: 34px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InitCardContent = styled.div`
  padding: 12px 0 12px 12px;
  padding-right: 12px;
  width: 100%;
  cursor: text;
`;

const DiffChange = styled.div`
  ins {
    color: green;
  }
  del {
    color: red;
  }
`;

const RemovedTag = styled.div`
  text-decoration: line-through;
  color: red;
`;

const AddedTag = styled.div`
  color: green;
  text-decoration: underline;
`;

const KeyResultContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 12px;
`;

const AddedKeyResultContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 12px;
  color: green;
  text-decoration: underline;
`;

const RemovedKeyResultContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
  text-decoration: line-through;
  color: red;
`;

const ContentChange = styled.p`
  flex-grow: 1;
`;

const generateDiffContent = (entryFrom: any, entryTo: any, type: string) => {
  let from = '<p></p>';
  let to = '<p></p>';
  if (type === 'dueDate') {
    if (!!entryFrom) {
      from = ReactDOMServer.renderToString(
        <p>{format(new Date(entryFrom), 'EEE dd MMM')}</p>,
      );
    }
    if (!!entryTo) {
      to = ReactDOMServer.renderToString(
        <p>{format(new Date(entryTo), 'EEE dd MMM')}</p>,
      );
    }
  } else {
    if (!!entryFrom) {
      from = ReactDOMServer.renderToString(<p>{entryFrom}</p>);
    }
    if (!!entryTo) {
      to = ReactDOMServer.renderToString(<p>{entryTo}</p>);
    }
  }

  const diffResult = diff(from, to);
  return (
    <DiffChange
      dangerouslySetInnerHTML={{
        __html: diffResult,
      }}
    ></DiffChange>
  );
};

const CompareInitiativeComponent = (props: CompareInitiativeProps) => {
  const { name, color = '#aeafaf', id, className, change } = props;
  const [changeTags, setChangeTags] = useState({
    neuturalTags: [],
    addedTags: [],
    removedTags: [],
  });
  const [changeKeyResult, setChangeKeyResult] = useState({
    neuturalKeyResult: [],
    addedKeyResult: [],
    removedKeyResult: [],
  });
  const [openEntryModal] = useBoundStore((state) => [state.openEntryModal]);

  const generateDiffTags = (change: any) => {
    if (change.type === 'update') {
      const changeFrom = JSON.parse(change.from);
      const changeTo = JSON.parse(change.to);

      if (
        changeFrom.hasOwnProperty('tags') &&
        changeTo.hasOwnProperty('tags')
      ) {
        if (changeFrom.tags.length === 0 && changeTo.tags.length !== 0) {
          setChangeTags({
            neuturalTags: [],
            addedTags: changeTo.tags,
            removedTags: [],
          });
        } else if (changeFrom.tags.length !== 0 && changeTo.tags.length === 0) {
          setChangeTags({
            neuturalTags: [],
            addedTags: [],
            removedTags: changeFrom.tags,
          });
        } else if (changeFrom.tags.length !== 0 && changeTo.tags.length !== 0) {
          const neuturalTags = changeFrom.tags.filter((tag: any) =>
            changeTo.tags.includes(tag),
          );
          const addedTags = changeTo.tags.filter(
            (tag: any) => !changeFrom.tags.includes(tag),
          );
          const removedTags = changeFrom.tags.filter(
            (tag: any) => !changeTo.tags.includes(tag),
          );
          setChangeTags({
            neuturalTags,
            addedTags,
            removedTags,
          });
        }
      } else if (
        changeFrom.hasOwnProperty('tags') &&
        !changeTo.hasOwnProperty('tags')
      ) {
        setChangeTags({
          neuturalTags: [],
          addedTags: [],
          removedTags: JSON.parse(change.from).tags,
        });
      } else if (
        !changeFrom.hasOwnProperty('tags') &&
        changeTo.hasOwnProperty('tags')
      ) {
        setChangeTags({
          neuturalTags: [],
          addedTags: changeTo.tags,
          removedTags: [],
        });
      }
    } else if (change.type === 'remove') {
      const tags = JSON.parse(change.name).tags;
      setChangeTags({
        neuturalTags: tags,
        addedTags: [],
        removedTags: [],
      });
    } else if (change.type === 'neutural') {
      const tags = JSON.parse(change.name).tags;
      setChangeTags({
        neuturalTags: tags,
        addedTags: [],
        removedTags: [],
      });
    }
  };

  const generateDiffKeyResult = (change: any) => {
    if (change.type === 'update') {
      const changeFrom = JSON.parse(change.from);
      const changeTo = JSON.parse(change.to);

      if (
        changeFrom.hasOwnProperty('keyResults') &&
        changeTo.hasOwnProperty('keyResults')
      ) {
        if (
          changeFrom.keyResults.length === 0 &&
          changeTo.keyResults.length !== 0
        ) {
          setChangeKeyResult({
            neuturalKeyResult: [],
            addedKeyResult: changeTo.keyResults,
            removedKeyResult: [],
          });
        } else if (
          changeFrom.keyResults.length !== 0 &&
          changeTo.keyResults.length === 0
        ) {
          setChangeKeyResult({
            neuturalKeyResult: [],
            addedKeyResult: [],
            removedKeyResult: changeFrom.keyResults,
          });
        } else if (
          changeFrom.keyResults.length !== 0 &&
          changeTo.keyResults.length !== 0
        ) {
          const neuturalKeyResult = changeFrom.keyResults.filter((kr: any) =>
            changeTo.keyResults.includes(kr),
          );
          const addedKeyResult = changeTo.keyResults.filter(
            (kr: any) => !changeFrom.keyResults.includes(kr),
          );
          const removedKeyResult = changeFrom.keyResults.filter(
            (kr: any) => !changeTo.keyResults.includes(kr),
          );
          setChangeKeyResult({
            neuturalKeyResult,
            addedKeyResult,
            removedKeyResult,
          });
        }
      } else if (
        changeFrom.hasOwnProperty('keyResults') &&
        !changeTo.hasOwnProperty('keyResults')
      ) {
        setChangeKeyResult({
          neuturalKeyResult: [],
          addedKeyResult: [],
          removedKeyResult: JSON.parse(change.from).keyResults,
        });
      } else if (
        !changeFrom.hasOwnProperty('keyResults') &&
        changeTo.hasOwnProperty('keyResults')
      ) {
        setChangeKeyResult({
          neuturalKeyResult: [],
          addedKeyResult: changeTo.keyResults,
          removedKeyResult: [],
        });
      }
    } else if (change.type === 'remove') {
      const keyResults = JSON.parse(change.name).keyResults;
      setChangeKeyResult({
        neuturalKeyResult: keyResults,
        addedKeyResult: [],
        removedKeyResult: [],
      });
    } else if (change.type === 'neutural') {
      const keyResults = JSON.parse(change.name).keyResults;
      setChangeKeyResult({
        neuturalKeyResult: keyResults,
        addedKeyResult: [],
        removedKeyResult: [],
      });
    }
  };


  useEffect(() => {
    generateDiffTags(change);
    generateDiffKeyResult(change);
  }, []);
  const fromValue = JSON.parse(change.from);
  const toValue = JSON.parse(change.to);

  return (
    <InitiativeContainer className={className}>
      <InitiativeCard>
        <InitCardContent>
          <ContentRow>
            <ContentColumn
              onClick={() => openEntryModal(id, color)}
              style={{ cursor: 'pointer', width: '100%' }}
            >
              <>
                {change.type === 'update' && (
                  <ContentChange>
                    {generateDiffContent(
                      fromValue.name,
                      toValue.name,
                      'initiative',
                    )}
                  </ContentChange>
                )}

                {change.type === 'create' && (
                  <ContentChange>
                    <EntryViewer
                      entry={JSON.parse(change.name) as JsonDocument}
                    />
                  </ContentChange>
                )}
                {change.type !== 'update' && change.type !== 'create' && (
                  <ContentChange>
                    <div>{JSON.parse(change.name).name}</div>
                  </ContentChange>
                )}
              </>
            </ContentColumn>
          </ContentRow>
          <ContentRow onClick={() => openEntryModal(id, color)}>
            <ContentColumn style={{ cursor: 'pointer', width: '100%' }}>
              <CalendarIcon />
              <DueDateGroup>
                {change.type === 'update' && (
                  <>
                    {!!toValue.dueDate ? (
                      <ContentChange>
                        {generateDiffContent(
                          fromValue.dueDate,
                          toValue.dueDate,
                          'dueDate',
                        )}
                      </ContentChange>
                    ) : (
                      <RealDueDate color={color}>No due date</RealDueDate>
                    )}
                  </>
                )}

                {change.type !== 'update' && !!JSON.parse(change.name).dueDate && (
                  <ContentChange>
                    <div>
                      {format(
                        new Date(JSON.parse(change.name).dueDate),
                        'EEE dd MMM',
                      )}
                    </div>
                  </ContentChange>
                )}

                {change.type !== 'update' && !JSON.parse(change.name).dueDate && (
                  <ContentChange>
                    <RealDueDate color={color}>No due date</RealDueDate>
                  </ContentChange>
                )}
              </DueDateGroup>
            </ContentColumn>
          </ContentRow>
          <ContentRow>
            <ContentColumn style={{ cursor: 'pointer' }}>
              <TagIcon />
              <ContentColumn style={{ flexWrap: 'wrap' }}>
                <>
                  {change.type === 'update' && (
                    <ContentChange>
                      {!!changeTags &&
                        !!changeTags.removedTags &&
                        changeTags.removedTags.map((tag: any, index) => (
                          <RemovedTag key={index}>{tag.name}</RemovedTag>
                        ))}
                      {!!changeTags &&
                        !!changeTags.addedTags &&
                        changeTags.addedTags.map((tag: any, index) => (
                          <AddedTag key={index}>{tag.name}</AddedTag>
                        ))}
                      {!!changeTags &&
                        !!changeTags.neuturalTags &&
                        changeTags.neuturalTags.map((tag: any, index) => (
                          <div key={index}>{tag.name}</div>
                        ))}
                    </ContentChange>
                  )}
                  {change.type !== 'update' && (
                    <ContentChange>
                      {!!changeTags &&
                        !!changeTags.neuturalTags &&
                        changeTags.neuturalTags.map((tag: any) => (
                          <div>{tag.name}</div>
                        ))}
                    </ContentChange>
                  )}
                </>
              </ContentColumn>
            </ContentColumn>
          </ContentRow>
        </InitCardContent>
      </InitiativeCard>
      {((changeKeyResult.addedKeyResult ?? []).length > 0 ||
        (changeKeyResult.removedKeyResult ?? []).length > 0 ||
        (changeKeyResult.neuturalKeyResult ?? []).length > 0) && (
        <KeyResultList>
          {change.type === 'update' && (
            <div>
              {!!changeKeyResult &&
                !!changeKeyResult.removedKeyResult &&
                changeKeyResult.removedKeyResult.map((kr: any) => (
                  <RemovedKeyResultContainer key={kr.id}>
                    {kr.name}
                  </RemovedKeyResultContainer>
                ))}
              {!!changeKeyResult &&
                !!changeKeyResult.addedKeyResult &&
                changeKeyResult.addedKeyResult.map((kr: any) => (
                  <AddedKeyResultContainer key={kr.id}>
                    {kr.name}
                  </AddedKeyResultContainer>
                ))}
              {!!changeKeyResult &&
                !!changeKeyResult.neuturalKeyResult &&
                changeKeyResult.neuturalKeyResult.map((kr: any) => (
                  <KeyResultContainer key={kr.id}>{kr.name}</KeyResultContainer>
                ))}
            </div>
          )}
          {change.type !== 'update' && (
            <div>
              {!!changeKeyResult &&
                !!changeKeyResult.neuturalKeyResult &&
                changeKeyResult.neuturalKeyResult.map((kr: any) => (
                  <KeyResultContainer key={kr.id}>{kr.name}</KeyResultContainer>
                ))}
            </div>
          )}
        </KeyResultList>
      )}
    </InitiativeContainer>
  );
};

export default CompareInitiativeComponent;
