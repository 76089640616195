import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppLoading from '../../components/AppLoading';
import StaticTopicV2 from '../../components/TopicV2/StaticTopicV2';
import TopicV2 from '../../components/TopicV2/TopicV2';
import { useBoundStore } from '../../states';
import EditingModal from './EditingModal';
import { StaticCell, DataCell, cells } from './onePage';
import PageHeader from '../../components/PageHeader/PageHeader';
import Button from '../../components/Button/Button';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalInitiative } from '../../states/interfaces';
import { isJust, withDefault } from '../../maybe';
import PizZipUtils from 'pizzip/utils';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { TextEntry } from '@propella/core';
import PageControl from '../../components/PageControl/PageControl';
import { DateRangePicker } from 'react-date-range';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const DocumentCanvas = styled.div`
  /* width: 4961px;
  height: 3508px; */
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 10px;

  margin: 10px;
`;

const ExportButton = styled(Button)`
  overflow: hidden;
  border-color: ${(props) => props.theme.colors.white};
  background-color: transparent;
  margin-right: 10px;
  :hover {
    border-color: ${(props) => props.theme.colors.white};
    background-color: transparent;
    cursor: pointer;
  }
  :active {
    border-color: ${(props) => props.theme.colors.white};
    background-color: transparent;
  }
  svg {
    padding-right: 5px;
  }
`;

const ActionBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDateRangePickerDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  border-radius: 15px;
  background: ${(props) => props.theme.colors.toggle.background};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.theme.colors.toggle.active};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const HistoryLabel = styled.p`
  margin-right: 10px;
`;

type DateRangePickerChangeProps = {
  selection: any;
};

const exportDocxQueries = {
  text_strategicShareholderValue: `topicKey := strategic-intent-shareholder-value AND __typename := TextEntry`,
  text_strategicSystemsAndProcesses: `topicKey := strategic-intent-systems-and-processes AND __typename := TextEntry`,
  text_strategicRisk: `topicKey := strategic-intent-risk AND __typename := TextEntry`,
  text_strategicPeople: `topicKey := strategic-intent-people AND __typename := TextEntry`,
  text_strategicCustomersAndMarkets: `topicKey := strategic-intent-customers-and-markets AND __typename := TextEntry`,
  text_strategicProductsAndServices: `topicKey := strategic-intent-products-and-services AND __typename := TextEntry`,
  initiative_strategicShareholderValue: `topicKey := strategic-objectives-shareholder-value AND __typename := Initiative`,
  initiative_strategicSystemsAndProcesses: `topicKey := strategic-objectives-systems-and-processes AND __typename := Initiative`,
  initiative_strategicRisk: `topicKey := strategic-objectives-risk AND __typename := Initiative`,
  initiative_strategicPeople: `topicKey := strategic-objectives-people AND __typename := Initiative`,
  initiative_strategicCustomersAndMarkets: `topicKey := strategic-objectives-customers-and-markets AND __typename := Initiative`,
  initiative_strategicProductsAndServices: `topicKey := strategic-objectives-products-and-services AND __typename := Initiative`,
  initiative_horizon1_ShareholderValue: `topicKey := strategic-execution-initiatives-horizon-1-shareholder-value AND __typename := Initiative`,
  initiative_horizon1_SystemsAndProcesses: `topicKey := strategic-execution-initiatives-horizon-1-systems-and-processes AND __typename := Initiative`,
  initiative_horizon1_Risk: `topicKey := strategic-execution-initiatives-horizon-1-risk AND __typename := Initiative`,
  initiative_horizon1_People: `topicKey := strategic-execution-initiatives-horizon-1-people AND __typename := Initiative`,
  initiative_horizon1_CustomersAndMarkets: `topicKey := strategic-execution-initiatives-horizon-1-customers-and-markets AND __typename := Initiative`,
  initiative_horizon1_ProductsAndServices: `topicKey := strategic-execution-initiatives-horizon-1-products-and-services AND __typename := Initiative`,
  initiative_horizon2_ShareholderValue: `topicKey := strategic-execution-initiatives-horizon-2-shareholder-value AND __typename := Initiative`,
  initiative_horizon2_SystemsAndProcesses: `topicKey := strategic-execution-initiatives-horizon-2-systems-and-processes AND __typename := Initiative`,
  initiative_horizon2_Risk: `topicKey := strategic-execution-initiatives-horizon-2-risk AND __typename := Initiative`,
  initiative_horizon2_People: `topicKey := strategic-execution-initiatives-horizon-2-people AND __typename := Initiative`,
  initiative_horizon2_CustomersAndMarkets: `topicKey := strategic-execution-initiatives-horizon-2-customers-and-markets AND __typename := Initiative`,
  initiative_horizon2_ProductsAndServices: `topicKey := strategic-execution-initiatives-horizon-2-products-and-services AND __typename := Initiative`,
  initiative_horizon3_ShareholderValue: `topicKey := strategic-execution-initiatives-horizon-3-shareholder-value AND __typename := Initiative`,
  initiative_horizon3_SystemsAndProcesses: `topicKey := strategic-execution-initiatives-horizon-3-systems-and-processes AND __typename := Initiative`,
  initiative_horizon3_Risk: `topicKey := strategic-execution-initiatives-horizon-3-risk AND __typename := Initiative`,
  initiative_horizon3_People: `topicKey := strategic-execution-initiatives-horizon-3-people AND __typename := Initiative`,
  initiative_horizon3_CustomersAndMarkets: `topicKey := strategic-execution-initiatives-horizon-3-customers-and-markets AND __typename := Initiative`,
  initiative_horizon3_ProductsAndServices: `topicKey := strategic-execution-initiatives-horizon-3-products-and-services AND __typename := Initiative`,
} as { [key: string]: string };

export type DocumentV2Props = {};

const DocumentV2 = (props: DocumentV2Props) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const history = useHistory();
  const { id: documentID }: { id: string } = useParams();
  const [fetchDocument, handleEntryTransit] = useBoundStore((state) => [
    state.fetchDocumentV2,
    state.handleEntryTransit,
  ]);
  const { currentMembership } = useBoundStore((state) => state.auth);
  const { entries } = useBoundStore((state) => state.documentV2);
  const documentV2 = useBoundStore((state) => state.documentV2);
  const { id, isReady } = useBoundStore((state) => state.documentV2);
  useEffect(() => {
    fetchDocument(documentID);
  }, []);

  const processEntryName = (entry: LocalInitiative | TextEntry) => {
    const entryTagNames = withDefault(entry.tags, [])
      .filter(isJust)
      .map((tag) => tag.name);

    if (entryTagNames.length === 0) return entry.name;

    return entry.name + ' [' + entryTagNames.join(', ') + ']';
  };

  const processEntry = (entry: LocalInitiative) => {
    const keyResults = entry.keyResults?.filter(isJust).map((keyResult) => ({
      name: keyResult.name,
    }));

    return {
      name: processEntryName(entry),
      keyResults,
    };
  };

  const exportDocx = async () => {
    const entriesToBeExported = Object.keys(exportDocxQueries).reduce(
      (acc, key) => {
        const query = exportDocxQueries[key];

        const filterCriteria = query.split('AND').reduce((acc, cur) => {
          const [key, value] = cur.split(':=').map((x) => x.trim());
          return { ...acc, [key]: value };
        }, {});

        const filteredEntries = entries
          .filter(_.matches(filterCriteria))
          .map((entry) => {
            if (entry.__typename === 'TextEntry')
              return {
                name: processEntryName(entry),
              };

            if (entry.__typename === 'Initiative') return processEntry(entry);
          });

        return {
          ...acc,
          [key]: filteredEntries,
        };
      },
      {} as { [key: string]: any },
    );

    const templateContent: string = await new Promise((resolve, reject) => {
      PizZipUtils.getBinaryContent(
        process.env.PUBLIC_URL + '/opsap_v1.docx',
        (error, content) => {
          if (error) {
            reject(error);
          } else {
            resolve(content);
          }
        },
      );
    });

    const zip = new PizZip(templateContent);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    const formattedDate = new Date().toISOString().split('T')[0];

    doc.setData({
      formatted_date: formattedDate,
      organization_name: currentMembership?.organization?.name,
      document_name: documentV2.name,
      ...entriesToBeExported,
    });

    doc.render();

    const out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    saveAs(out, `${currentMembership?.organization.name} - ${documentV2.name} - ${formattedDate}.docx`);
  };

  const renderCell = (cell: StaticCell | DataCell) => {
    const { type, x, y, width, height } = cell;

    const gridArea = [y, x, y + height, x + width]
      .map((x) => `${x}`)
      .join(' / ');

    if (type === 'static') {
      const staticCell = cell as StaticCell;
      const { content, ...rest } = staticCell;

      return (
        <StaticTopicV2 {...rest} gridArea={gridArea} key={gridArea}>
          {content}
        </StaticTopicV2>
      );
    }

    if (type === 'data') {
      const dataCell = cell as DataCell;

      return <TopicV2 gridArea={gridArea} {...dataCell} key={gridArea} />;
    }
  };

  const onComparisionDateChange = (selection: any) => {
    setSelectionRange(new Array(selection as any));
    const startDate = moment(selection.startDate).format('DDMMYYYY');
    const endDate = moment(selection.endDate).format('DDMMYYYY');

    if (startDate !== endDate) {
      history.replace(
        `/v2/documents/${documentID}/compare/${startDate}..${endDate}`,
      );
    }
  };

  if (!isReady) return <AppLoading />;

  return (
    <>
      <PageHeader
        header={documentV2.name}
        variant="dark"
        actionComponent={
          <ExportButton
            variant="primary"
            style={{ borderRadius: '5px' }}
            onClick={() => exportDocx()}
          >
            <FontAwesomeIcon icon={faFileUpload} />
            Export DOCX
          </ExportButton>
        }
        breadcrumb={{ to: '/plan', label: 'Plan' }}
        documentType='100 Days Plan Document'
      />
      <PageControl
        variant='dark'
        rightSideComponent={
          <ActionBarContainer>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowDateRangePicker(false);
              }}
            >
              <StyledDateRangePickerDiv>
                <HistoryLabel>History</HistoryLabel>
                <CheckBoxWrapper>
                  <CheckBox
                    onClick={(e) =>
                      setShowDateRangePicker(
                        (e.target as HTMLInputElement).checked,
                      )
                    }
                    readOnly={true}
                    checked={showDateRangePicker}
                    id="checkbox"
                    type="checkbox"
                  />
                  <CheckBoxLabel htmlFor="checkbox" />
                </CheckBoxWrapper>
                {showDateRangePicker && (
                  <StyledDateRangePicker
                    ranges={selectionRange}
                    onChange={(item) =>
                      onComparisionDateChange(
                        (item as DateRangePickerChangeProps).selection,
                      )
                    }
                    editableDateInputs={true}
                    inputRanges={[]}
                    dateDisplayFormat={'dd/MM/yyyy'}
                  />
                )}
              </StyledDateRangePickerDiv>
            </OutsideClickHandler>
          </ActionBarContainer>
        }
      />
      <DragDropContext
        onDragEnd={(dropResult) =>
          handleEntryTransit(
            dropResult.draggableId,
            {
              topicKey: dropResult.source.droppableId,
              index: dropResult.source.index,
            },
            {
              topicKey: dropResult.destination?.droppableId || '',
              index: dropResult.destination?.index || 0,
            },
          )
        }
      >
        <DocumentCanvas>{cells.map(renderCell)}</DocumentCanvas>
      </DragDropContext>
      <EditingModal />
    </>
  );
};

export default DocumentV2;
