import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

const TextFieldContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StaticText = styled.div`
  padding: 4px 12px;
  background: #ffffff;
  border-radius: 6px;

  width: 100%;
  cursor: text;

  &:hover {
    background: #f7f7f7;
  }

  h1 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    color: #202020;
  }
`;

const EditingTextField = styled.textarea`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #202020;

  resize: none;
  border-radius: 6px;
  padding: 4px 12px;

  width: 100%;

  border: 0;
`;

const ButtonGroup = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: row;
`;

const FloatingButton = styled.button`
  box-sizing: border-box;
  width: 32px;
  z-index: 200;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 14px;
  box-shadow: var(
    --ds-shadow-overlay,
    0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31)
  );

  border: 0;

  height: 32px;
  width: 32px;

  margin-left: 4px;

  cursor: pointer;
`;

export type InlineTextFieldProps = {
  value: string;
  onSave?: (value: string) => Promise<void>;
};

const inlineSchema = yup.object({
  name: yup.string().required(),
});

type InlineFormValues = yup.InferType<typeof inlineSchema>;

const InlineTextField = (props: InlineTextFieldProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const { value, onSave } = props;

  const inlineForm = useForm<InlineFormValues>({
    resolver: yupResolver(inlineSchema),
    defaultValues: {
      name: value,
    },
  });

  return (
    <TextFieldContainer>
      {editing ? (
        <form
          onSubmit={inlineForm.handleSubmit((data) => {
            onSave?.(data.name);
            setEditing(false);
          })}
        >
          <Controller
            name="name"
            control={inlineForm.control}
            render={(field) => (
              <EditingTextField {...field} rows={2} autoFocus />
            )}
          />
          <ButtonGroup>
            <FloatingButton type="submit">
              <FontAwesomeIcon icon={faCheck} />
            </FloatingButton>
            <FloatingButton
              type="button"
              onClick={() => {
                inlineForm.reset();
                setEditing(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </FloatingButton>
          </ButtonGroup>
        </form>
      ) : (
        <StaticText onClick={() => setEditing(true)}>
          <h1>{value}</h1>
        </StaticText>
      )}
    </TextFieldContainer>
  );
};

export default InlineTextField;
