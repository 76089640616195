import React from 'react';
import styled from 'styled-components';

const BoxContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eaeff4;
  border-radius: 6px;
  padding: 0 40px;
  padding-bottom: 48px;
`;

const BoxHeaderSection = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  border-bottom: 1px dotted #c8cad1;
  margin-bottom: 20px;
`;

const BoxHeader = styled.h1`
  font-weight: 400;
  font-size: 18px;
  color: #021740;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
`;

const ActionComponentContainer = styled.div``;

export type BoxProps = {
  header: string;
  actionComponent?: React.ReactElement;
  className?: string;
};

const Box: React.FC<BoxProps> = ({
  header,
  children,
  actionComponent,
  className,
}) => {
  return (
    <BoxContainer className={className}>
      <BoxHeaderSection>
        <BoxHeader>{header}</BoxHeader>
        <ActionComponentContainer>{actionComponent}</ActionComponentContainer>
      </BoxHeaderSection>
      {children}
    </BoxContainer>
  );
};

export default Box;
