import React from "react";
import styled from "styled-components";
import _ from "lodash";
import MultiSelect from "../MultiSelect/MultiSelect";
import StyledErrorMessage from "../ErrorMessage/ErrorMessage";
import FieldContainer from "../FieldContainer/FieldContainer";
import { SelectionInput } from "../../common/type";
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

type ContextMultiSelectInput = {
  name: string,
  label: string,
  defaultValue?: string[],
  options: SelectionInput[]
}

const MultiSelectContainer = styled(FieldContainer)`
  min-height: 0px;
`;

const ContextMultiSelect: React.FC<ContextMultiSelectInput> = ({ name, label, defaultValue = [], options }) => {
  const { control, errors } = useFormContext();
  return (
    <MultiSelectContainer>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={(controllerProps) => (
          <MultiSelect
            label={label}
            onSelectionChange={controllerProps.onChange}
            value={controllerProps.value}
            items={options}
            hasError={_.get(errors, name, false)}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <StyledErrorMessage>{message.replace(name, label)}</StyledErrorMessage>}
      />
    </MultiSelectContainer>
  );
};

export default ContextMultiSelect;
