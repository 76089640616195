import { StateCreator } from 'zustand';
import {
  updateDocumentOHDP,
  updateInitiativeAssignee,
  updateInitiativeAttributes,
  updateInitiativeTags,
  upsertMasterList,
} from '../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { MasterlistSlice, MasterlistState, StoreInterface } from './interfaces';
import produce, { applyPatches, enablePatches, Patch } from 'immer';
import { API, graphqlOperation } from 'aws-amplify';
import { getDocuments, getDocumentV2, getMasterList } from '../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Initiative, Document, DocumentV2, User } from '@propella/core';
import _ from 'lodash';
import { isJust, withDefault } from '../maybe';
import { WritableDraft } from 'immer/dist/types/types-external';

enablePatches();

export const createMasterListSlice: StateCreator<
  StoreInterface,
  [],
  [],
  MasterlistSlice
> = (set, get) => ({
  masterlist: {
    id: undefined,
    prioritizedInitiativeIds: [],
    isReady: false,
    documents: [],
    assignableUsers: [],
    selectedInitiativeIds: [],
    initiatives: [],
  },
  fetchMasterlist: async () => {
    let changes: Patch[] = [];
    let inverseChanges: Patch[] = [];
    set(
      produce<MasterlistSlice>((draft) => {
        draft.masterlist.isReady = false;
      }),
    );

    let detailedDocuments: DocumentV2[] = [];

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(getMasterList),
      )) as GraphQLResult<any>;

      const queryDocumentsResult = (await API.graphql(
        graphqlOperation(getDocuments),
      )) as GraphQLResult<{
        getDocuments: Document[];
      }>;

      const documents = queryDocumentsResult.data?.getDocuments;

      if (documents?.length) {
        detailedDocuments = await Promise.all(
          documents.map(async (document) => {
            const queryResult = (await API.graphql(
              graphqlOperation(getDocumentV2, {
                id: document.id,
              }),
            )) as GraphQLResult<{
              getDocument: DocumentV2;
            }>;
            const { data, errors } = queryResult;
            if (errors) {
              throw new Error(errors[0].message);
            }
            if (!data || !data.getDocument) {
              throw new Error("Couldn't get document");
            }
            return data.getDocument;
          }),
        );
      }

      produce<MasterlistSlice>(
        get(),
        (draft) => {
          const entries = _.flatMap(detailedDocuments, (doc) => {
            const inits = doc.entries
              ?.filter(isJust)
              .filter(
                (entry) => entry.__typename === 'Initiative',
              ) as Initiative[];

            return inits.map((init) => ({
              ...init,
              documentId: doc.id,
              documentName: withDefault(doc.name, ''),
            }));
          });
          draft.masterlist.id = queryResult.data.getMasterlistScope?.id;

          const prioritizedInitiativeIds = queryResult.data.getMasterlistScope?.prioritizedInitiativeIds || [];
          const unprioritizedInitiativeIds = entries.filter((entry) => !prioritizedInitiativeIds.includes(entry.id)).map((entry) => entry.id) || [];
          
          draft.masterlist.prioritizedInitiativeIds = _.concat(prioritizedInitiativeIds, unprioritizedInitiativeIds) ;

          draft.masterlist.initiatives = entries;

          draft.masterlist.documents = detailedDocuments || [];
          draft.masterlist.selectedInitiativeIds = withDefault(
            _.flatten(
              detailedDocuments.map(
                (item) => item.oneHundredDayPlan?.selectedInitiativeIds,
              ),
            ),
            [],
          ).filter(isJust);
          draft.masterlist.assignableUsers = _.uniqBy(
            _.flatten(
              detailedDocuments.map(
                (doc) => doc.oneHundredDayPlan?.assignableUsers,
              ),
            ),
            (obj) => obj?.id,
          ) as User[];
          draft.masterlist.isReady = true;
        },
        (patches) => {
          changes = patches;
        },
      );
    } catch (error) {
      console.log(error);
    }

    set(applyPatches(get(), changes));
  },
  upsertMasterlist: async (prioritizedInitiativeIds: string[]) => {},
  updateMasterlistRank: async (initiativeId: string, rankBeforeId: string) => {
    let changes: Patch[] = [];
    let inverseChanges: Patch[] = [];

    const initiatives = _.flatten(
      get().masterlist.documents.map((doc) => doc.entries),
    );
    const existingPrioritizedInitiativeIds =
      get().masterlist.prioritizedInitiativeIds;
    const masterlistId = get().masterlist.id || uuidv4();

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        let newEntryRank = [] as string[];

        if (
          !existingPrioritizedInitiativeIds ||
          existingPrioritizedInitiativeIds.length === 0
        ) {
          newEntryRank = initiatives
            .filter(isJust)
            .filter((init) => init.__typename === 'Initiative')
            .map((init) => init.id);
        } else {
          newEntryRank = [...existingPrioritizedInitiativeIds];
        }

        const sourceIndex = newEntryRank.indexOf(initiativeId);
        const destinationIndex = newEntryRank.indexOf(rankBeforeId);

        const [removed] = newEntryRank.splice(sourceIndex, 1);

        newEntryRank.splice(destinationIndex, 0, removed);

        draft.masterlist.prioritizedInitiativeIds = newEntryRank;

        draft.masterlist.id = masterlistId;
      },
      (patches, inversePatches) => {
        changes = patches;
        inverseChanges = inversePatches;
      },
    );

    set(applyPatches(get(), changes));

    try {
      await API.graphql(
        graphqlOperation(upsertMasterList, {
          input: {
            id: get().masterlist.id,
            prioritizedInitiativeIds: get().masterlist.prioritizedInitiativeIds,
          },
        }),
      );
    } catch (error) {
      console.log(error);
      applyPatches(get(), inverseChanges);
    }
  },
  updateDocumentInitiativeTagsLocally: (documentId, initiativeId, tags) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    console.log('here');

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        const documentIndex = draft.masterlist.documents.findIndex(
          (doc) => doc.id === documentId,
        );

        const initiatives = draft.masterlist.documents[documentIndex].entries;
        const masterlistInitiativeIndex =
          draft.masterlist.initiatives.findIndex(
            (entry) => entry?.id === initiativeId,
          );

        if (masterlistInitiativeIndex !== undefined) {
          draft.masterlist.initiatives[masterlistInitiativeIndex].tags = tags;
        }

        const initiativeIndex =
          initiatives?.findIndex((entry) => entry?.id === initiativeId) || 0;

        if (initiativeIndex !== undefined) {
          (draft.masterlist.documents[documentIndex].entries as Initiative[])[
            initiativeIndex
          ].tags = tags;
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateDocumentInitiativeTags: async (documentId, initiativeId, tags) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateDocumentInitiativeTagsLocally(
        documentId,
        initiativeId,
        tags,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeTags, {
          input: {
            entryID: initiativeId,
            tags,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeTags: Initiative;
      }>;
    } catch (error) {
      console.log('update tags', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateDocumentInitiativeAssignee: async (
    documentId,
    initiativeId,
    assigneeIds,
  ) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateDocumentInitiativeAssigneeLocally(
        documentId,
        initiativeId,
        assigneeIds,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeAssignee, {
          input: {
            id: initiativeId,
            assigneeIDs: assigneeIds,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeAssignee: Initiative;
      }>;
    } catch (error) {
      console.log('update assignee', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateDocumentInitiativeAssigneeLocally(
    documentId,
    initiativeId,
    assigneeIds,
  ) {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        const documentIndex = draft.masterlist.documents.findIndex(
          (doc) => doc.id === documentId,
        );
        const initiativeIndex = draft.masterlist.documents[
          documentIndex
        ].entries?.findIndex((entry) => entry?.id === initiativeId);

        if (initiativeIndex !== undefined && documentIndex !== undefined) {
          const existingEntry = draft.masterlist.documents[documentIndex]
            .entries?.[initiativeIndex] as Initiative;
          draft.masterlist.documents[documentIndex].entries![initiativeIndex] =
            {
              ...existingEntry,
              assigneeIDs: assigneeIds,
            } as WritableDraft<Initiative>;
        }

        const masterlistInitiativeIndex =
          draft.masterlist.initiatives.findIndex(
            (entry) => entry?.id === initiativeId,
          );

        if (masterlistInitiativeIndex !== undefined) {
          draft.masterlist.initiatives[masterlistInitiativeIndex] = {
            ...draft.masterlist.initiatives[masterlistInitiativeIndex],
            assigneeIDs: assigneeIds,
          };
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateDocumentInitiativeAttributesLocally: (
    documentId,
    initiativeId,
    attributes,
  ) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        const documentIndex = draft.masterlist.documents.findIndex(
          (doc) => doc.id === documentId,
        );
        const initiativeIndex = draft.masterlist.documents[
          documentIndex
        ].entries?.findIndex((entry) => entry?.id === initiativeId);

        if (initiativeIndex !== undefined && documentIndex !== undefined) {
          const existingEntry = draft.masterlist.documents[documentIndex]
            .entries?.[initiativeIndex] as Initiative;
          draft.masterlist.documents[documentIndex].entries![initiativeIndex] =
            {
              ...existingEntry,
              ...attributes,
            } as WritableDraft<Initiative>;
        }

        const masterlistInitiativeIndex =
          draft.masterlist.initiatives.findIndex(
            (entry) => entry?.id === initiativeId,
          );

        if (masterlistInitiativeIndex !== undefined) {
          draft.masterlist.initiatives[masterlistInitiativeIndex] = {
            ...draft.masterlist.initiatives[masterlistInitiativeIndex],
            ...attributes,
          };
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateDocumentInitiativeAttributes: async (
    documentId,
    initiativeId,
    attributes,
  ) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateDocumentInitiativeAttributesLocally(
        documentId,
        initiativeId,
        attributes,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeAttributes, {
          input: {
            id: initiativeId,
            ...attributes,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeAttributes: DocumentV2;
      }>;
    } catch (error) {
      console.log('Error while updating initiative', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  toggleDocumentInitiativeSelection: async (
    documentId: string,
    initiativeId: string,
  ) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];
    produce<MasterlistSlice>(
      get(),
      (draft) => {
        const isSelected =
          draft.masterlist.selectedInitiativeIds.includes(initiativeId);
        let newListOfSelectedInitiativeIds: string[] = [];
        if (isSelected) {
          newListOfSelectedInitiativeIds =
            draft.masterlist.selectedInitiativeIds.filter(
              (id: string) => id !== initiativeId,
            );
        } else {
          newListOfSelectedInitiativeIds =
            draft.masterlist.selectedInitiativeIds.concat([initiativeId]);
        }

        draft.masterlist.selectedInitiativeIds = newListOfSelectedInitiativeIds;
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(updateDocumentOHDP, {
          input: {
            id: documentId,
            selectedInitiativeIds: get().masterlist.selectedInitiativeIds,
          },
        }),
      )) as GraphQLResult<{
        updateDocumentOHDP: DocumentV2;
      }>;

      const { errors } = queryResult;
      if (errors) {
        throw new Error(errors[0].message);
      }
    } catch (error) {
      console.log('Error occurs while updating document', error);
      set(applyPatches(get(), inverseChanges));
    }
  },
  toggleSelectAllDocumentInitatives: async () => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<MasterlistSlice>(
      get(),
      (draft) => {
        const isAllSelected =
          _.flatten(draft.masterlist.documents.map((doc) => doc.entries))
            .length === draft.masterlist.selectedInitiativeIds.length;

        if (isAllSelected) {
          draft.masterlist.selectedInitiativeIds = [];
        } else {
          draft.masterlist.selectedInitiativeIds = _.flatten(
            draft.masterlist.documents.map((doc) => doc.entries),
          ).map((entry) => entry?.id) as string[];
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    await Promise.all(
      get().masterlist.documents.map(async (document) => {
        const selectedInitiatives =
          get().masterlist.selectedInitiativeIds.filter((item) =>
            document.entries?.map((entry) => entry?.id).includes(item),
          );
        const queryResult = (await API.graphql(
          graphqlOperation(updateDocumentOHDP, {
            input: {
              id: document.id,
              selectedInitiativeIds: selectedInitiatives,
            },
          }),
        )) as GraphQLResult<{
          updateDocumentOHDP: DocumentV2;
        }>;
        const { data, errors } = queryResult;
        if (errors) {
          throw new Error(errors[0].message);
        }
        if (!data || !data.updateDocumentOHDP) {
          throw new Error('Error occurs while updating document');
        }
      }),
    );
  },
});
