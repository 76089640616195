import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { Maybe } from '@propella/core';
import { isJust, withDefault } from '../../maybe';
import { Img } from 'react-image';
import ReactLoading from 'react-loading';
import Gravatar from 'react-gravatar';
import { COLORS } from '../../common/color';

const OwnerOptionContainer = styled.div<Pick<OwnerOptionProps, 'isSelected'>>`
  display: flex;
  flex-direction: row;

  height: 42px;
  min-width: 160px;
  align-items: center;

  border-left: 2px solid #5e4979;

  padding-left: 9px;
  padding-right: 12px;
  box-sizing: border-box;
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? '#F9F9FB' : '#ffffff')};
`;

export const AvatarRoundedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #5e4979;

  margin-right: 12px;

  cursor: pointer;
`;

const StyledGravatar = styled(Gravatar)`
  height: 100%;
  width: 100%;
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const Username = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  /* Main Purple */

  color: #5e4979;
`;

const UserEmail = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  /* Main Gray */

  color: #c8cece;
`;

const RemoveButton = styled.button`
  border: 1px solid #5e4979;
  border-radius: 50%;
  width: 18px;
  height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export type OwnerOptionProps = {
  id: string;
  name?: Maybe<string>;
  email?: Maybe<string>;
  avatarUrl?: Maybe<string>;
  onSelect?: (id: string) => void;
  isSelected?: boolean;
};

const OwnerOption = ({
  onSelect,
  isSelected,
  name,
  email,
  avatarUrl,
  id,
}: OwnerOptionProps) => {
  return (
    <OwnerOptionContainer
      isSelected={isSelected}
      onClick={() => onSelect?.(id)}
      data-testid="owner-option"
    >
      <AvatarRoundedContainer>
        {isSelected ? (
          <RemoveButton>
            <FontAwesomeIcon icon={faTimes} color="#5e4979" size="sm" />
          </RemoveButton>
        ) : (
          <Img
            src={withDefault(avatarUrl, '')}
            loader={<ReactLoading type="spin" color={COLORS.loading} />}
            unloader={<StyledGravatar email={id} default="retro" />}
          />
        )}
      </AvatarRoundedContainer>
      <UserInformation>
        <Username>{name}</Username>
        <UserEmail>{email}</UserEmail>
      </UserInformation>
    </OwnerOptionContainer>
  );
};

export default OwnerOption;
