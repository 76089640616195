import styled from 'styled-components';

const TableColumn = styled.th`
  font-size: 12px;
  color: #000000;
  letter-spacing: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;

  border-bottom: 1px solid #0b1b3c;

  &:first-child {
    padding-left: 25px;
  }
`;

export default TableColumn;
