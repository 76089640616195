import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckMark } from './checkmark.svg';

const CheckBoxOuter = styled.div<Partial<CheckBoxProps>>`
  width: 16px;
  height: 16px;
  border: 1px solid
    ${(props) => (props.isSelected ? props.bgColor : props.borderColor)};
  border-radius: 5px;
  overflow: hidden;
  background-color: ${(props) =>
    props.isSelected ? props.bgColor : 'transparent'};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  box-sizing: border-box;
`;

export type CheckBoxProps = {
  bgColor: string;
  isSelected?: boolean;
  borderColor?: string;
  onChange?: (isSelected: boolean) => void;
};

const Checkbox: React.FC<CheckBoxProps> = ({
  isSelected = false,
  onChange,
  borderColor = '#c8cad1',
  bgColor,
}) => {
  return (
    <CheckBoxOuter
      bgColor={bgColor}
      isSelected={isSelected}
      borderColor={borderColor}
    >
      {isSelected && <CheckMark />}
    </CheckBoxOuter>
  );
};

export default Checkbox;
