import styled from 'styled-components';
import { ReactComponent as TagIcon } from './tag.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { Initiative, Tag } from '@propella/core';
import { isJust, withDefault } from '../../maybe';
import TagItem from './TagItem';

const AssignTagsContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 320px;

  background: #ffffff;
  border: 1px solid #e1e4e6;
  border-radius: 20px;
  z-index: 99999;

  display: flex;
  flex-direction: column;
`;

const PopoverHeader = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #202020;

  margin-bottom: 18px;
`;

const TagList = styled.div`
  display: flex;
  flex-direction: column;
`;
type TagNameContainerProps = {
  color?: string;
};

const TagNameContainer = styled.div<TagNameContainerProps>`
  display: flex;
  flex-direction: row;

  span {
    margin-left: 10px;
  }

  svg {
    stroke: ${(props) => props.color || '#AEAFAF'};
  }
`;

export type AssignTagsPopoverProps = {
  existingTags?: Initiative['tags'];
  onTagsChange?: (tags: Tag[]) => Promise<void>;
};

const defaultTags: Tag[] = [
  {
    id: 'tactical',
    name: 'Tactical',
    color: '#EBB537',
  },
  {
    id: 'strategic',
    name: 'Strategic',
    color: '#1D33FF',
  },
];

const AssignTagsPopover = (props: AssignTagsPopoverProps) => {
  const { existingTags = [] } = props;

  const validExistingTags = withDefault(existingTags, []).filter(isJust);

  const onTagSelection = (id: string) => {
    const isTagSelected = validExistingTags.some((tag) => tag.id === id);

    if (isTagSelected) {
      const newTags = validExistingTags.filter((tag) => tag.id !== id);

      props.onTagsChange?.(newTags);
    } else {
      const selectedTag = defaultTags.find((tag) => tag.id === id);

      if (selectedTag) {
        props.onTagsChange?.([...validExistingTags, selectedTag]);
      }
    }
  };

  return (
    <AssignTagsContainer>
      <PopoverHeader>Assign Tags</PopoverHeader>
      <TagList>
        {defaultTags.map((tag) => (
          <TagItem
            {...tag}
            isSelected={validExistingTags.some(
              (existingTag) => tag.id === existingTag.id,
            )}
            onSelect={onTagSelection}
            key={tag.id}
          />
        ))}
      </TagList>
    </AssignTagsContainer>
  );
};

export default AssignTagsPopover;
