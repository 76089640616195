import { useState } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import axios from 'axios';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { onBoardOrganization } from '../../graphql/mutations';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageControl from '../../components/PageControl/PageControl';
import ContextSelect from '../../components/ContextSelect/ContextSelect';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import Button from '../../components/Button/Button';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import rawCountries from '../../data/countries.json';
import StyledErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { withDefault } from '../../maybe';
import { useBoundStore } from '../../states';

const countries = rawCountries.map((country) => ({
  id: country.iso,
  name: country.country,
}));

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 180px;
`;

const FormContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 440px;
  padding-top: 30px;
  padding-bottom: 300px;
  align-self: center;
  background-color: ${(props) => props.theme.colors.white};
`;

const StickyHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9001;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
  color: ${(props) => props.theme.colors.theme};
`;

const IconContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledIconPreview = styled.img`
  height: 100%;
  width: 100%;
`;

const StyledHeader = styled.div`
  color: #5a5f69;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
  margin-bottom: 10px;
  font-size: 12px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIconInput = styled.input`
  width: fit-content;
`

const newOrganizationSchema = yup.object().shape({
  name: yup.string().required(),
  avatarUrl: yup.string(),
  businessNumber: yup.string(),
  primaryEmail: yup.string().email().required(),
  countryCode: yup.string(),
  addressLine: yup.string(),
  postalCode: yup.string(),
  suburb: yup.string(),
  region: yup.string(),
});

type OnBoardOrganizationOutput = {
  onBoardOrganization: {
    id: string;
  };
};

type getUserAvatarUrlOutput = {
  getUploadAvatarUrl: {
    avatarUrl: string;
    uploadUrl: string;
  };
};

const getAvatarUrlsQuery = `
  query GetAvatarUrls($input: GetUploadAvatarInput!) {
    getUploadAvatarUrl(input: $input) {
      uploadUrl
      avatarUrl
    }
  }
`;

const OnboardOrganization = () => {
  const {
    state: locationState,
  }: {
    state: {
      firstOrganization: boolean;
    };
  } = useLocation();

  const { profile } = useBoundStore((state) => state.auth);
  const history = useHistory();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const newOrganizationFormMethods = useForm({
    resolver: yupResolver(newOrganizationSchema),
  });

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const updateAvatar = (setAvatarUrl: any) => async (event: any) => {
    setIsUploading(true);
    const avatarFile = event?.target.files[0];
    const fileType = avatarFile.name.split('.').pop();
    const img = new Image();
    img.src = window.URL.createObjectURL(avatarFile);

    const ratio = await new Promise((resolve) => {
      img.onload = () => {
        resolve(img.height / img.width);
      };
    });

    if (ratio !== 1) {
      newOrganizationFormMethods.setError('avatarUrl', {
        message: 'Image must be square.',
      });
      setIsUploading(false);
      return;
    }

    if (fileType !== 'png' && fileType !== 'jpeg') {
      newOrganizationFormMethods.setError('avatarUrl', {
        message: 'Image must be type PNG or JPEG.',
      });
      setIsUploading(false);
      return;
    }
    try {
      const getAvatarUploadUrlResult = (await API.graphql(
        graphqlOperation(getAvatarUrlsQuery, {
          input: {
            type: fileType,
          },
        }),
      )) as GraphQLResult<getUserAvatarUrlOutput>;

      if (!getAvatarUploadUrlResult.data?.getUploadAvatarUrl) {
        throw new Error('Error occurs while uploading avatar');
      }

      const { avatarUrl, uploadUrl } =
        getAvatarUploadUrlResult.data.getUploadAvatarUrl;

      await axios.put(uploadUrl, avatarFile, {
        headers: {
          'Content-Type': `image/${fileType}`,
        },
      });
      setAvatarUrl(avatarUrl);
    } catch (error: any) {
      newOrganizationFormMethods.setError(
        'avatarUrl',
        error.errors[0]?.message || error.message,
      );
    }
    setIsUploading(false);
  };

  const createOrganization = () => {
    newOrganizationFormMethods.handleSubmit(async (data) => {
      try {
        const onBoardResult = (await API.graphql(
          graphqlOperation(onBoardOrganization, {
            input: data,
          }),
        )) as GraphQLResult<OnBoardOrganizationOutput>;
        const organizationID = onBoardResult.data?.onBoardOrganization.id;

        history.replace(
          `/switch?organizationID=${organizationID}&continue=/organization/settings`,
        );
      } catch (error: any) {
        newOrganizationFormMethods.setError(
          'countryCode',
          error.errors[0]?.message || error.message,
        );
      }
    })();
  };

  return (
    <PageContainer data-cy="onboard-organization-page">
      <StickyHeader>
        <PageHeader
          variant="light"
          header="Add a new organization"
          data-cy="onboard-organization-page"
        />
        <PageControl
          variant="light"
          leftSideComponent={
            locationState?.firstOrganization ? (
              <Button variant="secondary" onClick={() => Auth.signOut()}>
                <StyledIcon size="xs" icon={faChevronLeft} />
                Sign out
              </Button>
            ) : (
              <Link to="/plan">
                <Button variant="secondary">
                  <StyledIcon size="xs" icon={faChevronLeft} />
                  Back
                </Button>
              </Link>
            )
          }
          rightSideComponent={
            <Button
              type="button"
              variant="primary"
              onClick={() => createOrganization()}
              data-cy="create-organization-button"
            >
              Create Organization
            </Button>
          }
        />
      </StickyHeader>
      <FormProvider {...newOrganizationFormMethods}>
        <FormContainer>
          <form>
            <SectionHeader>General information</SectionHeader>
            <ContextTextField
              name="name"
              label="Name*"
              dataAttribute="name-text-field"
            />
            <ContextTextField
              name="businessNumber"
              label="Business number"
              dataAttribute="business-number-text-field"
            />
            <ContextTextField
              name="primaryEmail"
              label="Primary email*"
              defaultValue={withDefault(profile?.email, '')}
              disabled
              dataAttribute="primary-email-text-field"
            />
            <IconContainer>
              <StyledHeader>Organization icon</StyledHeader>
              <StyledIconContainer>
                {newOrganizationFormMethods.watch('avatarUrl') && (
                  <StyledIconPreview
                    src={newOrganizationFormMethods.watch('avatarUrl')}
                  />
                )}
                <Controller
                  name="avatarUrl"
                  control={newOrganizationFormMethods.control}
                  render={(controlerProps) => (
                    <StyledIconInput
                      type="file"
                      name="avatar"
                      disabled={isUploading}
                      onChange={(event) =>
                        updateAvatar(controlerProps.onChange)(event)
                      }
                      data-cy="avatar-file"
                      onMouseOut={() => !visible}
                      onMouseOver={() => visible}
                      ref={setTriggerRef}
                      title=""
                    />
                  )}
                />
                {visible && (
                  <div
                    ref={setTooltipRef}
                    {...getTooltipProps({ className: 'tooltip-container' })}
                  >
                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                    Optionally select a square icon in PNG, JPEG format to
                    represent your organization
                  </div>
                )}
              </StyledIconContainer>
              <ErrorMessage
                errors={newOrganizationFormMethods.errors}
                name="avatarUrl"
                render={(message) => (
                  <StyledErrorMessage>{message.message}</StyledErrorMessage>
                )}
              />
            </IconContainer>
            <SectionHeader>Address</SectionHeader>
            <ContextTextField
              name="addressLine"
              label="Address "
              dataAttribute="address-text-field"
            />
            <ContextTextField
              name="postalCode"
              label="Postal code"
              dataAttribute="postal-code-text-field"
            />
            <ContextTextField
              name="suburb"
              label="Suburb"
              dataAttribute="suburb-text-field"
            />
            <ContextTextField
              name="region"
              label="Region"
              dataAttribute="region-text-field"
            />
            <ContextSelect
              name="countryCode"
              label="Country"
              options={countries}
              dataAttribute="country-code-select"
              data-cy="country-code-select"
            />
          </form>
        </FormContainer>
      </FormProvider>
    </PageContainer>
  );
};

export default OnboardOrganization;
