import { Entry, Initiative, TextEntry } from '@propella/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AppLoading from '../../components/AppLoading';
import InitiativeTable from '../../components/InitiativeTable/InitiativeTable';
import {
  businessRisks,
  completionTargets,
  horizons,
  priorities,
  strategicKRAs,
  status as initiativeStatus,
} from '../../components/InitiativeTable/values';
import JustText from '../../components/Legend/JustText';
import Legend from '../../components/Legend/Legend';
import WithColor from '../../components/Legend/WithColor';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useBoundStore } from '../../states';
import { LocalInitiative, MasterListInitiative } from '../../states/interfaces';
import TableIcon from './Table';
import KanbanIcon from './Kanban';
import InitiativeComponent from '../../components/Initiative/Initative';
import EditingModalKanban from './EditingModalKanban';
import OHDPSwitchTab from '../../components/OHDPSwitchTab/OHDPSwitchTab';
import { isJust, withDefault } from '../../maybe';
import { KanbanFilter } from '../../components/KanbanFilter/KanbanFilter';
import { Document, DocumentV2 } from '@propella/core';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
  height: 100%;
  overflow-x: scroll;

  min-height: 100vh;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 0 40px;

  box-sizing: border-box;
  margin-bottom: 40px;
  background-color: #ffffff;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  > select {
    border: 0;
    background: transparent;

    font-size: 14px;

    &:focus {
      outline: none;
    }
  }
`;

const TableHeader = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  /* Main Black */

  color: #202020;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  padding: 40px;

  gap: 50px;
`;
const HeaderAndFilterContainer = styled.div`
  margin: 0 40px;
  border-bottom: 1px solid #eeeff1;
  display: flex;
  justify-content: space-;
`;

const StyledHeader = styled.h2`
  color: var(--main-black, #202020);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px 0;
`;

const KanbanBoardContainer = styled.div`
  display: flex;
  flex: 1;

  width: 100% - 40px;
  margin: 40px;
`;

const ActionBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
`;

const StatusColumn = styled.div<StatusComlumnProps>`
  width: 100%;
  margin-right: ${(props) => (props.isLastItem ? '0' : '40px')};
  background-color: ${(props) =>
    props.isDraggingOver
      ? props.theme.colors.textfield.focusBackground
      : '#f8fbff'};
  border-radius: ${(props) => (props.isDraggingOver ? '8px' : '0')};
  border: ${(props) => (props.isDraggingOver ? '1px dashed #90d0d0' : 'none')};
`;

const TodoColumnHeader = styled.div`
  background-color: #f1ae53;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  text-align: center;

  padding: 10px 0;
`;

const InProgressColumn = styled.div`
  background-color: #60bfbf;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  text-align: center;

  padding: 10px 0;
`;

const DoneColumn = styled.div`
  background-color: #007cef;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  text-align: center;

  padding: 10px 0;
`;

const StyledInitiativeComponent = styled(InitiativeComponent)`
  margin-bottom: 20px;
`;
const InitiativesColumn = styled.div`
  height: 100%;
  margin-top: 20px;
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px dotted #c8cad1;
`;

const StyledPageHeader = styled(PageHeader)`
  width: calc(100vw - 240px);
  border: none;
`;

const StyledErrorMessage = styled.div`
  margin-top: 20px;
`;

export type StatusComlumnProps = {
  isDraggingOver: boolean;
  isLastItem?: boolean;
};

export const OneHundredDP = () => {
  const [entries, setEntries] = useState<(LocalInitiative | TextEntry)[]>([]);
  const [enabledModules] = useBoundStore((state) => [
    state.auth.enabledModules,
  ]);
  const { initiatives, isReady } = useBoundStore((state) => state.masterlist);
  const [currentTab, setCurrentTab] = useState('table');

  useEffect(() => {
    setEntries([]);
  }, []);

  const [listTodoInitiatives, setListTodoInitiatives] = useState<Initiative[]>(
    [],
  );
  const [listInProgressInitiatives, setListInProgressInitiatives] = useState<
    Initiative[]
  >([]);
  const [listDoneInitiatives, setListDoneInitiatives] = useState<Initiative[]>(
    [],
  );

  const [handleTransitInitiativeKanban] = useBoundStore((state) => [
    state.handleTransitInitiativeKanban,
    state.fetchAllOrgInitiatives,
  ]);

  const prioritizedInitiativeIds = useBoundStore(
    (state) => state.masterlist.prioritizedInitiativeIds,
  );

  const [fetchMasterlist, updateMasterlistRank] = useBoundStore((state) => [
    state.fetchMasterlist,
    state.updateMasterlistRank,
  ]);

  const [initiativeType, setType] = React.useState('all');

  const masterListInitiatives = React.useMemo(() => {
    const filteredInitiatives =
      initiativeType === 'all'
        ? initiatives
        : initiatives.filter((initiative) =>
            _.find(initiative?.tags, (tag) => tag?.id === initiativeType),
          );

    const prioritizedInitiatives = prioritizedInitiativeIds.map((id) =>
      filteredInitiatives.find((initiative) => initiative?.id === id),
    );

    const unprioritizedInitiatives = filteredInitiatives.filter(
      (initiative) =>
        initiative && !prioritizedInitiativeIds.includes(initiative.id),
    );

    return [...prioritizedInitiatives, ...unprioritizedInitiatives].filter(
      isJust,
    );
  }, [initiatives, prioritizedInitiativeIds, initiativeType]);

  const onChangeViewMode = (nextViewMode: string) => {
    setCurrentTab(nextViewMode);
  };

  useEffect(() => {
    if (enabledModules?.includes('100-dp')) {
      fetchMasterlist();
    }
  }, [fetchMasterlist, currentTab]);

  useEffect(() => {
    if (masterListInitiatives) {
      setListTodoInitiatives(
        masterListInitiatives.filter(
          (init) => !init.status || init.status === 'TO_DO',
        ),
      );
      setListInProgressInitiatives(
        masterListInitiatives.filter((init) => init.status === 'IN_PROGRESS'),
      );
      setListDoneInitiatives(
        masterListInitiatives.filter((init) => init.status === 'DONE'),
      );
    }
  }, [prioritizedInitiativeIds, initiatives]);

  if (!isReady && enabledModules?.includes('100-dp')) return <AppLoading />;
  if (!enabledModules?.includes('100-dp'))
    return (
      <PageContainer>
        <StyledHeaderContainer>
          <StyledPageHeader fluid variant="dark" header="Master List" />
        </StyledHeaderContainer>
        <StyledErrorMessage>
          100 Day Plan is not enabled. Please go to Settings and turn on
          '100-dp' module
        </StyledErrorMessage>
      </PageContainer>
    );
  return (
    <PageContainer>
      <StyledHeaderContainer>
        <StyledPageHeader
          fluid
          variant="light"
          header="Master List"
          actionComponent={
            <ActionBarContainer>
              <OHDPSwitchTab
                viewMode={currentTab}
                onViewChange={(nextViewMode) => onChangeViewMode(nextViewMode)}
              />
            </ActionBarContainer>
          }
        />
      </StyledHeaderContainer>
      {currentTab === 'table' && (
        <>
          <LegendContainer>
            <Legend name="Priority" columns={2}>
              {priorities.map((tag) => (
                <WithColor {...tag} key={tag.name} />
              ))}
            </Legend>
            <Legend name="Horizons" columns={3}>
              {horizons.map((horizon) => (
                <JustText
                  key={horizon.name}
                  name={horizon.name}
                  color={horizon.color}
                >
                  {horizon.description}
                </JustText>
              ))}
            </Legend>
            <Legend name="Business Risk" columns={2}>
              {businessRisks.map((risk) => (
                <WithColor {...risk} key={risk.name} />
              ))}
            </Legend>
            <Legend name="Strategic KRA" columns={3}>
              {strategicKRAs.map((kra) => (
                <JustText key={kra.name} name={kra.name} color={kra.color}>
                  {kra.description}
                </JustText>
              ))}
            </Legend>
            <Legend name="Target End" columns={4}>
              {completionTargets.map((target) => (
                <WithColor {...target} key={target.name} />
              ))}
            </Legend>
            <Legend name="Status" columns={3}>
              {initiativeStatus.map((initStatus) => (
                <WithColor {...initStatus} key={initStatus.name} />
              ))}
            </Legend>
          </LegendContainer>
          <div style={{ position: 'relative' }}>
            <TableContainer>
              <HeaderContainer>
                <TableHeader>Strategic / Tactical</TableHeader>
                <select onChange={(e) => setType(e.target.value)}>
                  <option value="all">All</option>
                  <option value="strategic">Strategic</option>
                  <option value="tactical">Tactical</option>
                </select>
              </HeaderContainer>

              <InitiativeTable
                initiatives={masterListInitiatives as Initiative[]}
              />
            </TableContainer>
          </div>
        </>
      )}
      {currentTab === 'kanban' && (
        <DragDropContext
          onDragEnd={(dropResult) => {
            handleTransitInitiativeKanban(
              dropResult.draggableId,
              {
                status: dropResult.source.droppableId,
                index: dropResult.source.index,
              },
              {
                status: dropResult.destination?.droppableId || '',
                index: dropResult.destination?.index || 0,
              },
            );

            if (!dropResult.destination) {
              return;
            }

            let rankBeforeEntry;

            if (dropResult.destination.droppableId === 'TO_DO') {
              rankBeforeEntry =
                listTodoInitiatives[dropResult.destination.index];
            }

            if (dropResult.destination.droppableId === 'IN_PROGRESS') {
              rankBeforeEntry =
                listInProgressInitiatives[dropResult.destination.index];
            }

            if (dropResult.destination.droppableId === 'DONE') {
              rankBeforeEntry =
                listDoneInitiatives[dropResult.destination.index];
            }

            if (!rankBeforeEntry) {
              return;
            }

            updateMasterlistRank(dropResult.draggableId, rankBeforeEntry.id);
          }}
        >
          <div>
            <HeaderAndFilterContainer>
              <StyledHeader>Kanban Board</StyledHeader>
              <KanbanFilter />
            </HeaderAndFilterContainer>
            <KanbanBoardContainer>
              <Droppable droppableId="TO_DO">
                {(provided, snapshot) => (
                  <StatusColumn isDraggingOver={snapshot.isDraggingOver}>
                    <TodoColumnHeader>TO-DO</TodoColumnHeader>
                    <InitiativesColumn
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {listTodoInitiatives.map((initiative, index) => (
                        <StyledInitiativeComponent
                          id={initiative.id}
                          name={initiative.name}
                          dueDate={initiative.dueDate}
                          color="#000000"
                          tags={initiative.tags}
                          index={index}
                          key={initiative.id}
                          keyResults={initiative.keyResults}
                          isWithinKanbanBoard={true}
                          assigneeIDs={withDefault(
                            initiative.assigneeIDs,
                            [],
                          ).filter(isJust)}
                        />
                      ))}
                      {provided.placeholder}
                    </InitiativesColumn>
                  </StatusColumn>
                )}
              </Droppable>
              <Droppable droppableId="IN_PROGRESS">
                {(provided, snapshot) => (
                  <StatusColumn isDraggingOver={snapshot.isDraggingOver}>
                    <InProgressColumn>IN PROGESS</InProgressColumn>
                    <InitiativesColumn
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {listInProgressInitiatives.map((initiative, index) => (
                        <StyledInitiativeComponent
                          id={initiative.id}
                          name={initiative.name}
                          dueDate={initiative.dueDate}
                          color="#000000"
                          tags={initiative.tags}
                          keyResults={initiative.keyResults}
                          index={index}
                          key={initiative.id}
                          isWithinKanbanBoard={true}
                          assigneeIDs={withDefault(
                            initiative.assigneeIDs,
                            [],
                          ).filter(isJust)}
                        />
                      ))}
                      {provided.placeholder}
                    </InitiativesColumn>
                  </StatusColumn>
                )}
              </Droppable>
              <Droppable droppableId="DONE">
                {(provided, snapshot) => (
                  <StatusColumn
                    isDraggingOver={snapshot.isDraggingOver}
                    isLastItem={true}
                  >
                    <DoneColumn>DONE</DoneColumn>
                    <InitiativesColumn
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {listDoneInitiatives.map((initiative, index) => (
                        <StyledInitiativeComponent
                          id={initiative.id}
                          name={initiative.name}
                          dueDate={initiative.dueDate}
                          color="#000000"
                          tags={initiative.tags}
                          keyResults={initiative.keyResults}
                          index={index}
                          key={initiative.id}
                          isWithinKanbanBoard={true}
                          assigneeIDs={withDefault(
                            initiative.assigneeIDs,
                            [],
                          ).filter(isJust)}
                        />
                      ))}
                      {provided.placeholder}
                    </InitiativesColumn>
                  </StatusColumn>
                )}
              </Droppable>
            </KanbanBoardContainer>
          </div>
        </DragDropContext>
      )}
      <EditingModalKanban listInitiatives={initiatives || []} />
    </PageContainer>
  );
};

export default OneHundredDP;
