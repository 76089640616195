import styled from 'styled-components';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import Button from '../../components/Button/Button';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { ReactComponent as PropellaIcon } from './PropellaIcon.svg';
import { ReactComponent as PropellaName } from './PropellaName.svg';
import { useBoundStore } from '../../states';

type StateType = {
  from: { pathname: string };
};

const StyledPropellaIcon = styled(PropellaIcon)`
  width: 100%;
  width: 290px;
  margin-bottom: 40px;
  .frontend-icon {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const StyledPropellaName = styled(PropellaName)`
  width: 400px;
  margin-bottom: 20px;
  .frontend-icon {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const SloganContainer = styled.div`
  font-size: 38px;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 100px;
`;

const PageContainer = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-areas: 'banner form';
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  background-color: ${(props) => props.theme.colors.white};
`;

const BannerContainer = styled.div`
  grid-area: banner / banner / banner / banner;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.signin.container};
`;

const FormContainer = styled.form`
  grid-area: form / form / form / form;
  display: flex;
  margin: 0 128px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
`;

const Header = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 50px;
  color: ${(props) => props.theme.colors.theme};
  font-size: 24px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
`;

const ActionContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  margin: 0 auto 0 0;
  color: ${(props) => props.theme.colors.link.enabled};
`;

const signInSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const StyledButton = styled(Button)`
  border-color: ${(props) => props.theme.colors.signin.styledButton};
  background-color: ${(props) => props.theme.colors.signin.styledButton};
  color: ${(props) => props.theme.colors.white};
  :hover {
    border-color: ${(props) => props.theme.colors.signin.styledButton};
    background-color: ${(props) => props.theme.colors.signin.styledButton};
    color: ${(props) => props.theme.colors.white};
  }
  :active {
    border-color: ${(props) => props.theme.colors.signin.styledButton};
    background-color: ${(props) => props.theme.colors.signin.styledButton};
    color: ${(props) => props.theme.colors.white};
  }
`;

const SignIn = () => {
  const { profile } = useBoundStore((state) => state.auth);
  let history = useHistory();
  let location = useLocation<StateType>();

  const { invitationCode }: { invitationCode: string } = useParams();

  useEffect(() => {
    if (profile) {
      if (invitationCode) {
        return history.replace(`/invitation/accept/${invitationCode}`);
      }

      let { from } = location.state || {
        from: { pathname: '/' },
      };
      return history.replace(from);
    }
  }, []);

  const signInFormMethods = useForm({
    resolver: yupResolver(signInSchema),
  });

  const signIn = async (data: { email: string; password: string }) => {
    try {
      await Auth.signIn(data.email, data.password);
      if (invitationCode) {
        history.replace(`/invitation/accept/${invitationCode}`);
      }
    } catch (error: any) {
      if (error.name === 'QuotaExceededError') {
        window.localStorage.clear();
        signInFormMethods.setError('password', {
          message: 'An unexpected error occured. Please try again.',
        });
      } else {
        console.error('Error while signing in', error);
        signInFormMethods.setError('password', {
          message: error.message,
        });
      }
    }
  };

  return (
    <PageContainer>
      <BannerContainer>
        <StyledPropellaIcon />
        <StyledPropellaName />
        <SloganContainer>Beyond your business</SloganContainer>
        <Link
          to={invitationCode ? `/signup/${invitationCode}` : '/signup'}
          data-cy="signup-link"
        >
          <StyledButton variant="primary">Create an account</StyledButton>
        </Link>
      </BannerContainer>
      <FormProvider {...signInFormMethods}>
        <FormContainer onSubmit={signInFormMethods.handleSubmit(signIn)}>
          <Header>Sign in</Header>
          <ContextTextField
            name="email"
            label="Email address"
            dataAttribute="signin-email-text-field"
          />
          <ContextTextField
            name="password"
            label="Password"
            type="password"
            dataAttribute="signin-password-text-field"
          />
          <ActionContainer>
            <StyledLink to="/resetpassword" data-cy="reset-password-link">
              Reset password
            </StyledLink>
            <Button
              type="submit"
              variant="primary"
              data-cy="signin-button"
              disabled={signInFormMethods.formState.isSubmitting}
            >
              Sign in
            </Button>
          </ActionContainer>
        </FormContainer>
      </FormProvider>
    </PageContainer>
  );
};

export default SignIn;
