import React from 'react';
import styled from 'styled-components';
import { Img } from 'react-image';
import ReactLoading from 'react-loading';
import { COLORS } from '../../common/color';

type AvatarProps = {
  avatarUrl: string | undefined;
  size?: string;
};

type AvatarContainerProps = {
  size: number;
};
const OrganizationAvatarContainer = styled.span<AvatarContainerProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  align-items: stretch;
  background-color: transparent;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  position: static;
  border: none;
  padding: 0;
  flex-shrink: 0;
`;

const OrganizationAvatar: React.FC<AvatarProps> = ({ avatarUrl, size }) => {
  const innerSize = size === 'large' ? 85 : size === 'small' ? 24 : 36;

  return (
    <OrganizationAvatarContainer size={innerSize}>
      <Img
        src={avatarUrl ? avatarUrl : ''}
        loader={<ReactLoading type="spin" color={COLORS.loading} />}
      />
    </OrganizationAvatarContainer>
  );
};

export default OrganizationAvatar;
