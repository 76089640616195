import styled from 'styled-components';

const TopicHeader = styled.div`
  grid-area: topicName / topicName / topicName / topicName;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.topic.header};
  padding-bottom: 0;
`;

export default TopicHeader;
