import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  background-color: none;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 10px;
`;
const HeaderContent = styled.span`
  font-size: 18px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.pageHeader.text};
`;

type SectionHeaderProps = {
  children: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  return (
    <HeaderContainer>
      <HeaderContent>{props.children}</HeaderContent>
    </HeaderContainer>
  );
};
export default SectionHeader;
