import styled from 'styled-components';

export type TableCellProps = {
  align?: 'left' | 'right';
};

const TableCell = styled.td<TableCellProps>`
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  padding: 20px 0;
  text-align: ${(props) => (props.align === 'right' ? 'right' : 'left')};
  vertical-align: middle;

  border: 1px solid #dcdee3;
  border-right: 0;
  border-left: 0;

  &:first-child {
    padding-left: 20px;
    border-top-left-radius: 6px;
    border-left: 1px solid #dcdee3;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-right: 1px solid #dcdee3;
    padding-right: 20px;
  }
`;

export default TableCell;
