import React, { useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EntryEditor from '../EntryEditor/EntryEditor';

type ListNewItemInput = {
  onSave: (entryName: string) => void;
  isFocused?: boolean;
  defaultValue?: string;
  onFocus?: () => void;
};

const newItemFormSchema = yup.object().shape({
  name: yup.object().required(),
});

const ListNewItem = ({ defaultValue = '', onSave }: ListNewItemInput) => {
  const [focused, setFocused] = useState(false);

  const newItemFormMethods = useForm({
    defaultValues: {
      name: defaultValue,
    },
    resolver: yupResolver(newItemFormSchema),
  });

  const saveChanges = async (data: any) => {
    await onSave(JSON.stringify(data.name));
    setFocused(false);
  };

  const clearEntry = () => {
    setFocused(false);
  };
  return (
    <FormProvider {...newItemFormMethods}>
      <form
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        data-html2canvas-ignore="true"
      >
        <Controller
          name="name"
          render={(controllerProps) => (
            <EntryEditor
              onSave={async (e) => {
                newItemFormMethods.setValue('name', e);
                await newItemFormMethods.handleSubmit(saveChanges)();
              }}
              isFocused={focused}
              onClose={clearEntry}
              errorMessage={newItemFormMethods.errors.name?.message?.replace(
                'name',
                'Entry',
              )}
              isSubmitting={newItemFormMethods.formState.isSubmitting}
              clearOnSave
            />
          )}
        />
      </form>
    </FormProvider>
  );
};

export default ListNewItem;
