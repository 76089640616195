import styled from 'styled-components';
import { HandlerContainer } from '../Initiative/Initative';
import { Handler } from '../Initiative/Handler';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, StyledClearButton } from '../NewEntryV2/NewEntryV2';
import { useBoundStore } from '../../states';
import { Draggable } from 'react-beautiful-dnd';
import StarterKit from '@tiptap/starter-kit';
import { useEditor, EditorContent } from '@tiptap/react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const TextEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 12px;
  padding-left: 0;

  background: #ffffff;

  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

const TextEntryContent = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
  /* identical to box height */

  color: #202020;

  display: flex;
  flex-direction: row;

  .ProseMirror {
    width: 100%;

    overflow-wrap: anywhere;

    p {
      margin: 0 !important;
    }

    h2,
    h1,
    h3 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .ProseMirror-focused {
    outline: none;
  }
`;

const StyledEditor = styled(EditorContent)`
  width: 100%;
`;

const ExistingEntry = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ExistingEntryText = styled.span`
  width: 100%;
  overflow-wrap: anywhere;
`;

export type TextEntryProps = {
  id: string;
  name: string;
  index: number;
  className?: string;
};

const TextEntry = (props: TextEntryProps) => {
  const { id, index, name, className } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isHoverHandler, setIsHoverHandler] = useState<boolean>(false);
  const [updateEntry, deleteTextEntry] = useBoundStore((state) => [
    state.updateEntry,
    state.deleteTextEntry,
  ]);
  const alert = useAlert();

  const editor = useEditor({
    extensions: [StarterKit],
    content: props.name || '',
    autofocus: 'end',
    editable: true,
  });

  const onDeleteEntry = async (id: string) => {
    try {
      await deleteTextEntry(id);
      alert.success('Delete entry successfully!');
    } catch (error: any) {
      alert.error(error.message);
    } finally {
      setIsOpenModal(false);
    }
  };

  const onEditEntry = async (id: string) => {
    if (!editor) {
      return;
    }

    if (editor.state.doc.textContent.length === 0) {
      return;
    } else {
      await updateEntry(id, editor.state.doc.textContent);
      setEditing(false);
    }
  };

  const onClose = () => {
    if (!editor) {
      return;
    }
    editor.commands.setContent(name);
    setEditing(false);
  };

  return (
    <Draggable draggableId={id} index={index || 0}>
      {(provided, snapshot) => (
        <TextEntryContainer
          className={className}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <HandlerContainer
            {...provided.dragHandleProps}
            onMouseEnter={() => setIsHoverHandler(true)}
            onMouseLeave={() => setIsHoverHandler(false)}
          >
            <Handler color={isHoverHandler ? '#60BFBF' : ''} />
          </HandlerContainer>
          <TextEntryContent>
            {!editing ? (
              <>
                <ExistingEntry>
                  <ExistingEntryText onClick={() => setEditing(true)}>
                    {name}
                  </ExistingEntryText>
                  <StyledClearButton
                    type="button"
                    hoverColor="#D93B3B"
                    onClick={() => setIsOpenModal(true)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </StyledClearButton>
                </ExistingEntry>
                <ConfirmationModal
                  isOpen={isOpenModal}
                  label="Do you want to delete this entry?"
                  onConfirm={() => onDeleteEntry(id)}
                  onCancel={() => setIsOpenModal(false)}
                />
              </>
            ) : (
              <>
                <StyledEditor editor={editor} autoFocus />
                <ButtonGroup>
                  <StyledClearButton
                    type="submit"
                    hoverColor="#56B6B9"
                    onClick={() => onEditEntry(id)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </StyledClearButton>
                  <StyledClearButton
                    type="button"
                    hoverColor="#D93B3B"
                    onClick={() => onClose()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </StyledClearButton>
                </ButtonGroup>
              </>
            )}
          </TextEntryContent>
        </TextEntryContainer>
      )}
    </Draggable>
  );
};

export default TextEntry;
