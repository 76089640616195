import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Loading from '../../components/AppLoading';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageControl from '../../components/PageControl/PageControl';
import SwitchTab from '../../components/SwitchTab/SwitchTab';
import CompareList from '../../components/CompareList/CompareList';
import CompareGrid from '../../components/CompareGrid/CompareGrid';
import { Link, useLocation } from 'react-router-dom';
import {
  Document,
  EntryChange,
  CompareScope,
  UsersChangeType,
} from '../../common/type';
import { compare } from '../../graphql/queries';
import { isJust, withDefault } from '../../maybe';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 880px;
  padding-top: 30px;
  align-self: center;
`;

const CanvasContainer = styled.div`
  flex-grow: 1;
  padding: 30px;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  border-radius: 15px;
  background: ${(props) => props.theme.colors.toggle.background};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.theme.colors.toggle.active};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const ActionBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HistoryLabel = styled.p`
  margin-right: 10px;
`;

type ComparisionOutput = {
  compare: {
    document: Document;
    changes: [EntryChange];
    users?: [
      {
        fullName: string;
        id: string;
      },
    ];
  };
};
const Compare = () => {
  const query = new URLSearchParams(useLocation().search);
  const selectedView = query.get('view');

  const alert = useAlert();
  const [fetching, setFetching] = useState(true);
  const [viewMode, setViewMode] = useState(selectedView || 'list');
  const {
    id: documentID,
    comparisionInput,
  }: { id: string; comparisionInput: string } = useParams();
  const [startDate, endDate] = comparisionInput.split('..');
  const [compareScope, setCompareScope] = useState<CompareScope>();
  const [changes, setChanges] = useState<EntryChange[]>();
  const [usersChange, setUsersChange] = useState<UsersChangeType[]>();

  useEffect(() => {
    const fetchComparision = async () => {
      try {
        const queryResult = (await API.graphql(
          graphqlOperation(compare, {
            input: {
              documentID,
              startDate,
              endDate,
            },
          }),
        )) as GraphQLResult<ComparisionOutput>;

        const {
          document: fetchedDocument,
          changes,
          users,
        } = queryResult.data?.compare ?? {};

        if (!!users) {
          setUsersChange(users);
        }
        if (!!fetchedDocument && !!changes) {
          const topicOrder = withDefault(fetchedDocument.topics, [])
            .filter(isJust)
            .map((topic) => topic.key);

          const topicsAsMap = _.keyBy(
            withDefault(fetchedDocument.topics, [])
              .filter(isJust)
              .map((topic) => ({
                ...topic,
                entryOrder: withDefault(topic.entryOrder, []).filter(isJust),
              })),
            'key',
          );
          const domains = _.keyBy(
            withDefault(fetchedDocument.domains, []).filter(isJust),
            'key',
          );
          const doc = _.omit(fetchedDocument, 'entries') as Document;
          setCompareScope({
            document: doc,
            domains,
            topicOrder,
            topics: topicsAsMap,
          });
          setChanges(changes);
          setFetching(false);
        }
      } catch (error: any) {
        alert.show(error.errors[0]?.message || error.message);
      }
    };

    fetchComparision();
  }, [documentID, endDate, startDate]);

  if (fetching) return <Loading />;

  return (
    <PageContainer>
      <PageHeader
        fluid={viewMode === 'canvas'}
        variant="dark"
        header={compareScope?.document?.name}
        actionComponent={
          <SwitchTab
            viewMode={viewMode}
            onViewChange={(nextViewMode) => setViewMode(nextViewMode)}
          />
        }
        breadcrumb={{
          to: `/documents/${documentID}`,
          label: 'Back to document',
        }}
      />
      <PageControl
        fluid={viewMode === 'canvas'}
        variant="dark"
        rightSideComponent={
          <ActionBarContainer>
            <HistoryLabel>History</HistoryLabel>
            <CheckBoxWrapper>
              <Link to={'/documents/' + documentID}>
                <CheckBox
                  readOnly={true}
                  checked={true}
                  id="checkbox"
                  type="checkbox"
                />
                <CheckBoxLabel htmlFor="checkbox" />
              </Link>
            </CheckBoxWrapper>
          </ActionBarContainer>
        }
      />
      {viewMode === 'list' && compareScope && (
        <ListContainer>
          <CompareList
            scope={compareScope}
            changes={changes}
            usersChange={usersChange}
          />
        </ListContainer>
      )}
      {viewMode === 'canvas' && compareScope && (
        <CanvasContainer>
          <CompareGrid
            scope={compareScope}
            changes={changes}
            usersChange={usersChange}
          />
        </CanvasContainer>
      )}
    </PageContainer>
  );
};

export default Compare;
