import React, { useCallback, useEffect, useRef, useState } from 'react';
import GraphiQL from 'graphiql';
import GraphiQLExplorer from 'graphiql-explorer';
import { buildClientSchema, getIntrospectionQuery, parse } from 'graphql';
import 'graphiql/graphiql.css';
import type { GraphQLSchema } from 'graphql';
import { Auth } from 'aws-amplify';
import awsExports from '../../awsExports.json';
import { createGraphiQLFetcher } from '@graphiql/toolkit';

const defaultFetcher = createGraphiQLFetcher({
  url: awsExports.GraphQlApiUrl,
});

const fetcher = (params: any, header?: any) => {
  const body = {
    operationName: 'IntrospectionQuery',
    query: params,
  };
  return fetch(awsExports.GraphQlApiUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...header,
    },
    body: JSON.stringify(body),
  })
    .then(function (response) {
      return response.text();
    })
    .then(function (responseBody) {
      try {
        return JSON.parse(responseBody);
      } catch (e) {
        return responseBody;
      }
    });
};

const GraphiQLPage: React.FC<{}> = () => {
  const _graphiql = useRef<any>();
  const [schema, setSchema] = useState<GraphQLSchema>();
  const [query, setQuery] = useState<string>();
  const [explorerIsOpen, setExplorerIsOpen] = useState<Boolean>(true);

  const [graphqlHeaderTemplate, setGraphqlHeaderTemplate] = useState({
    Accept: 'application/json, text/plain, */*',
    'Accept-Encoding': 'gzip, deflate',
    'Accept-Language':
      'en-GB,en-US;q=0.9,en;q=0.8,vi-VN;q=0.7,vi;q=0.6,ja-JP;q=0.5,ja;q=0.4',
    Authorization: '',
    'x-amz-user-agent': 'aws-amplify/3.8.22 js',
  });

  const getUserData = useCallback(async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser) {
        const authSession = await Auth.userSession(authenticatedUser);
        const header = {
          ...graphqlHeaderTemplate,
          Authorization: authSession.getIdToken().getJwtToken(),
        };
        fetcher(getIntrospectionQuery(), header).then((result) => {
          const editor = _graphiql.current.getQueryEditor();
          editor.setOption('extraKeys', {
            ...(editor.options.extraKeys || {}),
            'Shift-Alt-LeftClick': _handleInspectOperation,
          });
          setSchema(buildClientSchema(result.data));
        });
        setGraphqlHeaderTemplate(header);
      }
    } catch (error: any) {
      Auth.signOut();
    }
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const _handleInspectOperation = (
    cm: any,
    mousePos: { line: Number; ch: Number },
  ) => {
    const parsedQuery = parse(query || '');

    if (!parsedQuery) {
      console.error("Couldn't parse query document");
      return null;
    }

    var token = cm.getTokenAt(mousePos);
    var start = { line: mousePos.line, ch: token.start };
    var end = { line: mousePos.line, ch: token.end };
    var relevantMousePos = {
      start: cm.indexFromPos(start),
      end: cm.indexFromPos(end),
    };

    var position = relevantMousePos;

    var def = parsedQuery.definitions.find((definition) => {
      if (!definition.loc) {
        console.log('Missing location information for definition');
        return false;
      }

      const { start, end } = definition.loc;
      return start <= position.start && end >= position.end;
    });

    if (!def) {
      console.error(
        'Unable to find definition corresponding to mouse position',
      );
      return null;
    }

    var operationKind =
      def.kind === 'OperationDefinition'
        ? def.operation
        : def.kind === 'FragmentDefinition'
        ? 'fragment'
        : 'unknown';

    var operationName =
      def.kind === 'OperationDefinition' && !!def.name
        ? def.name.value
        : def.kind === 'FragmentDefinition' && !!def.name
        ? def.name.value
        : 'unknown';

    var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

    var el = document.querySelector(selector);
    el && el.scrollIntoView();
  };

  const _handleEditQuery = (query?: string): void => setQuery(query);

  const _handleToggleExplorer = () => {
    setExplorerIsOpen(!explorerIsOpen);
  };
  return (
    <div className="graphiql-container">
      <GraphiQLExplorer
        schema={schema}
        query={query}
        onEdit={_handleEditQuery}
        onRunOperation={(operationName: any) =>
          _graphiql.current.handleRunQuery(operationName)
        }
        explorerIsOpen={explorerIsOpen}
        onToggleExplorer={_handleToggleExplorer}
      />
      <GraphiQL
        ref={_graphiql}
        fetcher={defaultFetcher}
        schema={schema}
        query={query}
        onEditQuery={_handleEditQuery}
        headerEditorEnabled={true}
        headers={JSON.stringify(graphqlHeaderTemplate)}
      >
        <GraphiQL.Toolbar>
          <GraphiQL.Button
            onClick={() => _graphiql.current.handlePrettifyQuery()}
            label="Prettify"
            title="Prettify Query (Shift-Ctrl-P)"
          />
          <GraphiQL.Button
            onClick={() => _graphiql.current.handleToggleHistory()}
            label="History"
            title="Show History"
          />
          <GraphiQL.Button
            onClick={_handleToggleExplorer}
            label="Explorer"
            title="Toggle Explorer"
          />
        </GraphiQL.Toolbar>
      </GraphiQL>
    </div>
  );
};

export default GraphiQLPage;
