import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import Button from '../../components/Button/Button';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import { ReactComponent as PropellaIcon } from '../SignIn/PropellaIcon.svg';
import { ReactComponent as PropellaName } from '../SignIn/PropellaName.svg';

const StyledPropellaIcon = styled(PropellaIcon)`
  width: 100%;
  width: 290px;
  margin-bottom: 40px;
  .cls-1 {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const StyledPropellaName = styled(PropellaName)`
  width: 400px;
  margin-bottom: 20px;
  .cls-1 {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const SloganContainer = styled.div`
  font-size: 38px;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 100px;
`;

const FormErrorMessage = styled.div`
  border: 1px solid ${(props) => props.theme.colors.globalError};
  background-color: ${(props) => props.theme.colors.globalError};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.white};
  padding: 20px;
  box-sizing: border-box;
  width: 360px;
  margin-bottom: 40px;
`;

const PageContainer = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-areas: 'banner form';
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  background-color: ${(props) => props.theme.colors.white};
`;

const BannerContainer = styled.div`
  grid-area: banner / banner / banner / banner;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.signin.container};
`;

const FormContainer = styled.form`
  grid-area: form / form / form / form;
  display: flex;
  margin: 0 128px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
`;

const Header = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 50px;
  color: ${(props) => props.theme.colors.theme};
  font-size: 24px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.theme};
`;

const ControllerContainer = styled.div`
  width: 360px;
  min-height: 131px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
`;

const ActionContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: reverse-row;
  justify-content: space-between;
  align-items: center;
  width: 360px;
`;

const confirmationSchema = yup.object().shape({
  email: yup.string().email().required(),
  code: yup.string().required(),
});

type confirmUserInput = {
  email: string;
  code: string;
};
const ConfirmEmail = () => {
  const queryString = new URLSearchParams(useLocation().search);
  const confirmationEmail = queryString.get('email');

  const [confirmErrorMessage, setConfirmErrorMessage] =
    useState<string | null>();
  const [confirmSuccess, setConfirmSuccess] = useState(false);

  const confirmationFormMethods = useForm({
    resolver: yupResolver(confirmationSchema),
    defaultValues: {
      email: confirmationEmail,
      code: '',
    },
  });

  const confirmUser = () => {
    confirmationFormMethods.handleSubmit(async (data: confirmUserInput) => {
      setConfirmErrorMessage(null);
      try {
        await Auth.confirmSignUp(data.email, data.code);

        setConfirmSuccess(true);
      } catch (error: any) {
        if (
          error.message &&
          error.message ===
            'Invalid verification code provided, please try again.'
        ) {
          setConfirmErrorMessage(error.message);
        } else {
          setConfirmErrorMessage(
            'Something went wrong. Please try again later.',
          );
        }
      }
    })();
  };

  return (
    <PageContainer>
      <BannerContainer>
        <StyledPropellaIcon />
        <StyledPropellaName />
        <SloganContainer>Beyond your business</SloganContainer>
      </BannerContainer>
      <FormProvider {...confirmationFormMethods}>
        <FormContainer
          onSubmit={confirmationFormMethods.handleSubmit(confirmUser)}
        >
          <Header>Confirm email</Header>
          {confirmErrorMessage && (
            <FormErrorMessage data-cy="signup-confirm-email-error-message" >{confirmErrorMessage}</FormErrorMessage>
          )}
          {confirmSuccess ? (
            <ControllerContainer data-cy="confirmed-email-label">
              Your email is confirmed. Please <Link to="/signin" data-cy="signin-link">sign in</Link>{' '}
              to continue.
            </ControllerContainer>
          ) : (
            <>
              <ContextTextField name="email" label="Email address" dataAttribute="confirm-email-text-field" />
              <ContextTextField name="code" label="Verification Code" dataAttribute="verify-code-text-field" />
              <ActionContainer>
                <StyledLink to="/signin" data-cy="exist-account-link">Already have an account?</StyledLink>
                <Button
                  type="submit"
                  variant="primary"
                  data-cy="confirm-email-button"
                  disabled={confirmationFormMethods.formState.isSubmitting}
                >
                  Confirm
                </Button>
              </ActionContainer>
            </>
          )}
        </FormContainer>
      </FormProvider>
    </PageContainer>
  );
};

export default ConfirmEmail;
