import { DocumentV2 } from '@propella/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ItemIcon } from '../PlanItem/ItemIcon.svg';

const DocumentItemContainer = styled.div`
  height: 110px;
  box-sizing: border-box;

  background-color: #fbfdff;
  border: 1px solid #eaeff4;
  border-left: 13px solid #7ecbcb;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 33px;

  a {
    text-decoration: none;
  }

  margin-top: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DocumentInforation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;

const PlanItemSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemName = styled.p`
  font-size: 16px;
  color: #021740;
  letter-spacing: 0;
  margin: 0;

  margin-bottom: 5px;
`;

const ItemType = styled.p`
  font-size: 12px;
  color: #021740;
  letter-spacing: 0;
  margin: 0;
`;

const ContributeButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 110px;
  background-color: transparent;

  color: #60bfbf;
  font-size: 14px;

  box-sizing: border-box;
  border: 1px solid #60bfbf;
  border-radius: 20px;

  cursor: pointer;

  span {
    color: #8ad0d0;
    letter-spacing: 0;
    text-decoration: none;
  }
`;

export type DocumentListItemProps = {
  name: DocumentV2['name'];
  id: DocumentV2['id'];
  basePath?: string;
};

const DocumentListItem = (props: DocumentListItemProps) => {
  const { name, id, basePath = '/v2/documents/' } = props;
  return (
    <DocumentItemContainer>
      <PlanItemSection>
        <ItemIcon />
        <DocumentInforation>
          <ItemName>{name}</ItemName>
          <ItemType>100 Day Plan</ItemType>
        </DocumentInforation>
      </PlanItemSection>
      <PlanItemSection>
        <Link
          to={{
            pathname: basePath + id,
          }}
        >
          <ContributeButton type="button">Contribute</ContributeButton>
        </Link>
      </PlanItemSection>
    </DocumentItemContainer>
  );
};

export default DocumentListItem;
