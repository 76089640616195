import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as _ from 'lodash';
import Modal from 'styled-react-modal';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import PageHeader from '../../components/PageHeader/PageHeader';
import Button from '../../components/Button/Button';
import Loading from '../../components/AppLoading';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDizzy, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { useAlert } from 'react-alert';
import { API, graphqlOperation } from 'aws-amplify';
import { isAllowed } from '../../utils';
import { ACTION } from '../../common/constant';
import { COLORS } from '../../common/color';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { finalizeIteration, startIteration } from '../../graphql/mutations';
import { getCurrentIteration, getIteration } from '../../graphql/queries';
import { Iteration, Maybe, Task } from '@propella/core';
import Select from '../../components/Select/Select';
import { isJust, withDefault } from '../../maybe';
import Label from '../../components/Label/Label';
import { SelectOptions, DocumentTemplateSetting } from '../../common/type';
import Box from '../../components/Box';
import PlanItem from '../../components/PlanItem/PlanItem';
import { SwitchBarOption } from '../../components/SwitchBar/SwitchBar';
import { ReactComponent as InfographicSvg } from './infographic.svg';
import { useBoundStore } from '../../states';
import DocumentListItem from '../../components/DocumentListItem/DocumentListItem';
import { Link } from 'react-router-dom';
import RectButton from '../../components/Button/RectButton';

type SelectContainerProps = {
  current: Iteration;
};

type StartIterationOutput = {
  startIteration: Iteration;
};

type GetIterationOutput = {
  getIteration: Iteration;
};

type FinalizeIterationOutput = {
  finalizeIteration: Iteration;
};

type GetCurrentIterationQueryResult = {
  getCurrentIteration: Iteration;
};

type GetOrgDocumentQueryResult = {
  getOrgDocumentTemplates: {
    templates: DocumentTemplateSetting[];
  };
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
  height: 100%;
`;

const PageSubHeader = styled.div`
  font-weight: 900;
  color: ${(props) => props.theme.colors.pageHeader.text};
  font-size: 18px;
  width: 500px;
`;

const SelectContainer = styled.div<SelectContainerProps>`
  width: 220px;

  [class*='container'] {
    position: static !important;
  }

  .react-select__control {
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    :before {
      content: '';
      display: block;
      border-radius: 5px;
      background-color: ${(props) => (props.current.endAt ? 'gray' : 'red')};
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
  }
  .react-select__option {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .react-select__menu {
    z-index: 999;
    width: 220px;
  }
`;

const PerItemContainer = styled.div`
  margin-top: 20px;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  height: 300px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.task.background};
`;

const EmptyContent = styled.div`
  max-width: 340px;
  width: 100%;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.pageHeader.text};
  font-size: 14px;
`;

const DizzyContainer = styled.div`
  margin-right: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled.div`
  width: 40rem;
  max-height: 60%;
  overflow-x: hidden;
  display: block;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 220px;
  margin-bottom: 150px;
  padding-bottom: 30px;
  border-radius: 5px;
`;

const FormContainer = styled.form`
  grid-area: form / form / form / form;
  display: flex;
  margin: 30px 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  ${Label} {
    font-size: 18px;
    font-weight: 500;
  }
`;

const StyledStartButton = styled(Button)`
  align-self: flex-end;
  margin-top: 10px;
`;

const StyledBox = styled(Box)`
  margin-top: 10px;
`;

const ButtonText = styled.span`
  margin-left: 8px;
`;

const nameIteration = yup.object().shape({
  name: yup.string().required(),
});

const Plan: React.FC = () => {
  const alert = useAlert();
  const { currentMembership } = useBoundStore((state) => state.auth);
  const [iteration, setIteration] = useState<Iteration>();
  const [finalizing, setFinalizing] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [stageFilter] = useState<SwitchBarOption | undefined>({
    value: 'all',
    label: 'All',
  });
  const [tasks, setTasks] = useState<Task[]>([]);
  const filteredTasks = useMemo<Task[]>(() => {
    if (!stageFilter || stageFilter.value === 'all') return tasks;

    return tasks.filter((task) => task.stage === stageFilter.value);
  }, [stageFilter, tasks]);

  const [enabledModules, fetchDocuments] = useBoundStore((state) => [
    state.auth.enabledModules,
    state.fetchDocuments,
  ]);

  const planDocuments = useBoundStore((state) => state.plan.documents);

  const currentRole = currentMembership?.role;

  const grantedActions = currentRole?.grantedActions ?? [''];

  const nameIterationFormMethods = useForm({
    resolver: yupResolver(nameIteration),
    defaultValues: {
      name: '',
    },
  });

  const startNewIteration = async (name: string) => {
    setFetching(true);

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(startIteration, {
          input: {
            name: name,
          },
        }),
      )) as GraphQLResult<StartIterationOutput>;

      if (queryResult.data?.startIteration.id) {
        const fetchResult = (await API.graphql(
          graphqlOperation(getIteration, {
            id: queryResult.data.startIteration.id,
          }),
        )) as GraphQLResult<GetIterationOutput>;

        if (!!fetchResult.data) {
          setIteration(fetchResult.data.getIteration);
        }
      }
      window.location.reload();
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }

    setFetching(false);
    setIsOpenModal(false);
  };

  const finalizeCurrentIteration = async (): Promise<void> => {
    setFinalizing(true);

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(finalizeIteration, {
          id: iteration?.id,
        }),
      )) as GraphQLResult<FinalizeIterationOutput>;

      if (queryResult.data?.finalizeIteration.id) {
        const fetchResult = (await API.graphql(
          graphqlOperation(getIteration, {
            id: queryResult.data.finalizeIteration.id,
          }),
        )) as GraphQLResult<GetIterationOutput>;

        if (!!fetchResult.data) {
          setIteration(fetchResult.data.getIteration);
        }
      }
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }

    setFinalizing(false);
  };

  useEffect(() => {
    const fetchCurrentIteration = async () => {
      setFetching(true);

      try {
        const queryResult = (await API.graphql(
          graphqlOperation(getCurrentIteration),
        )) as GraphQLResult<GetCurrentIterationQueryResult>;
        const currentIteration = queryResult.data?.getCurrentIteration;
        if (!!currentIteration) {
          setIteration(currentIteration);
        }
      } catch (error: any) {
        alert.show(
          error.errors?.length ? error.errors[0]?.message : error.message,
        );
      }

      setFetching(false);
    };

    fetchCurrentIteration();
  }, [alert]);

  useEffect(() => {
    if (enabledModules?.includes('100-dp')) {
      fetchDocuments();
    }
  }, []);

  useEffect(() => {
    if (iteration && iteration.tasks) {
      setTasks(iteration.tasks.filter(isJust));
    }
    if (!!iteration) {
      if (!!iteration.endAt) {
        startNewIteration('Default Iteration');
      } else if (iteration.name !== 'Default Iteration') {
        finalizeCurrentIteration();
        startNewIteration('Default Iteration');
      }
    }
  }, [iteration]);

  const canFinalizeIteration = (grantedActions: Maybe<string>[]) =>
    isAllowed(grantedActions, [ACTION.FINALIZE_ITERATION, ACTION.ADMINISTER]);

  const canStartIteration = (grantedActions: Maybe<string>[]) =>
    isAllowed(grantedActions, [ACTION.FINALIZE_ITERATION, ACTION.ADMINISTER]);

  if (fetching || finalizing) return <Loading />;

  const fetchIteration = async (value: string) => {
    setFetching(true);

    try {
      const fetchResult = (await API.graphql(
        graphqlOperation(getIteration, {
          id: value,
        }),
      )) as GraphQLResult<GetIterationOutput>;

      if (!!fetchResult.data) {
        setIteration(fetchResult.data.getIteration);
      }
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }

    setFetching(false);
  };

  const createIterationSelectOptions = (
    iterations: Maybe<Iteration>[],
  ): SelectOptions => {
    const nonNullIterations = iterations.filter(isJust);

    return _.orderBy(nonNullIterations, 'endAt', 'desc').map(
      (iterationEntry) => ({
        id: iterationEntry.id,
        name: !iterationEntry.endAt
          ? iterationEntry.name
          : `${iterationEntry.name} (Closed)`,
      }),
    );
  };

  return (
    <PageContainer data-cy="plan-page">
      <PageHeader variant="light" header="Plan" />
      <Container>
        <Row justifyContent="center">
          <Col col={9} style={{ marginTop: '20px', marginBottom: '10px' }}>
            <InfographicSvg width="100%" height="100%" />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledBox
              header="Documents"
              actionComponent={
                <Link to="/v2/c/documents">
                  <RectButton type="button">Create document</RectButton>
                </Link>
              }
            >
              {iteration &&
                filteredTasks.length > 0 &&
                filteredTasks
                  .sort((a, b) => (Number(a.id) - Number(b.id) > 0 ? 1 : -1))
                  .map((item) => (
                    <PerItemContainer key={item.id}>
                      <PlanItem
                        task={item}
                        isActive={!iteration.endAt}
                        currentRole={currentRole}
                        iterationId={iteration.id}
                      />
                    </PerItemContainer>
                  ))}
              {enabledModules?.includes('100-dp') &&
                planDocuments.map((item) => (
                  <DocumentListItem
                    key={item.id}
                    name={item.name}
                    id={item.id}
                  />
                ))}
              {(!iteration || filteredTasks.length === 0) &&
                planDocuments.length === 0 && (
                  <EmptyContainer>
                    <DizzyContainer>
                      <FontAwesomeIcon
                        icon={faDizzy}
                        color={COLORS.text}
                        size="6x"
                      />
                    </DizzyContainer>
                    <EmptyContent>
                      <PageSubHeader>No documents are available</PageSubHeader>
                      <Text>
                        Press the “create document” button to get started.
                      </Text>
                    </EmptyContent>
                  </EmptyContainer>
                )}
            </StyledBox>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default Plan;
