import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import ListNewItem from '../ListNewItem/ListNewItem';
import DraggableList from '../DraggableList/DraggableList';
import TopicContainer from '../TopicContainer/TopicContainer';
import TopicHeader from '../TopicHeader/TopicHeader';
import TopicDescription from '../TopicDescription/TopicDescription';
import ItemList from '../ItemList/ItemList';
import TopicIcons from '../TopicIcons/TopicIcons';
import { isJust, withDefault } from '../../maybe';
import { useBoundStore } from '../../states';
import _ from 'lodash';
import { Droppable } from 'react-beautiful-dnd';

type TopicInput = {
  topicId: string;
  readOnly?: boolean;
};
const NewEntrySlot = styled.div`
  grid-area: newEntry / newEntry / newEntry / newEntry;
  padding: 0px 10px 15px 10px;
`;

const Topic: React.FC<TopicInput> = ({ topicId, readOnly }) => {
  const topic = useBoundStore((state) => state.document.topics[topicId]);
  const createEntry = useBoundStore((state) => state.createEntry, shallow);

  const onSaveNewEntry = async (entryName: string) => {
    await createEntry(entryName, topic.key);
  };

  if (!topic) return null;

  const topicName =
    topic.name === 'Shareholder Value' ? 'Stakeholder Value' : topic.name;

  return (
    <Droppable droppableId={topic.key}>
      {(provided, snapshot) => (
        <TopicContainer
          topicColor={topic.fullDomain.fgColor}
        >
          <TopicHeader>
            <TopicIcons topicName={topic.name} />
            {topicName}
          </TopicHeader>
          <TopicDescription>
            <ReactMarkdown children={withDefault(topic.description, '')} />
          </TopicDescription>
          <ItemList>
            <DraggableList
              provided={provided}
              snapshot={snapshot}
              sortedEntryIds={topic.sortedEntryIds}
              droppableId={topic.key}
              readOnly={readOnly}
            />
          </ItemList>
          <NewEntrySlot>
            {!readOnly && <ListNewItem onSave={onSaveNewEntry} />}
          </NewEntrySlot>
        </TopicContainer>
      )}
    </Droppable>
  );
};

export default Topic;
