export const ACTION = {
  ADMINISTER: 'administer',
  READ_DOCUMENT: 'read-document',
  CONTRIBUTE_BUSINESS_MODEL_CANVAS: 'contribute-business-modal-canvas',
  CONTRIBUTE_COMPETITIVE_LANDSCAPE: 'contribute-competitive-landscape',
  CONTRIBUTE_STRATEGIC_DRIVERS: 'contribute-strategic-drivers',
  CONTRIBUTE_STRATEGIC_INSIGHTS: 'contribute-strategic-insights',
  CONTRIBUTE_ONE_PAGE_STRATEGIC_AGILITY_PLAN:
    'contribute-one-page-strategic-agility-plan',
  START_ITERATION: 'start-iteration',
  FINALIZE_ITERATION: 'finalize-iteration',
  READ_OKR: 'read-okr',
  EDIT_OKR: 'edit-okr',
  UPDATE_KEYRESULT_PROGRESS: 'update-key-result-progress',
};

export const ADMIN_PERMISSION = [
  {
    id: ACTION.ADMINISTER,
    name: 'Administer',
  },
];

export const PLAN_PERMISSIONS = [
  {
    id: ACTION.START_ITERATION,
    name: 'Start Iteration',
  },
  {
    id: ACTION.FINALIZE_ITERATION,
    name: 'Finalize Iteration',
  },
];

export const CONTRIBUTE_PERMISSIONS = [
  {
    id: ACTION.READ_DOCUMENT,
    name: 'Read Documents',
  },
  {
    id: ACTION.CONTRIBUTE_BUSINESS_MODEL_CANVAS,
    name: 'Contribute Business Model Canvas',
  },
  {
    id: ACTION.CONTRIBUTE_COMPETITIVE_LANDSCAPE,
    name: 'Contribute Competitive Landscape',
  },
  {
    id: ACTION.CONTRIBUTE_STRATEGIC_DRIVERS,
    name: 'Contribute Strategic Drivers',
  },
  {
    id: ACTION.CONTRIBUTE_STRATEGIC_INSIGHTS,
    name: 'Contribute Strategic Insights',
  },
  {
    id: ACTION.CONTRIBUTE_ONE_PAGE_STRATEGIC_AGILITY_PLAN,
    name: 'Contribute One Page Strategic Agility Plan',
  },
];

export const OKR_PERMISSIONS = [
  {
    id: ACTION.READ_OKR,
    name: 'Read Objective Key Results',
  },
  {
    id: ACTION.EDIT_OKR,
    name: 'Edit Objective Key Results',
  },
  {
    id: ACTION.UPDATE_KEYRESULT_PROGRESS,
    name: `Update Key Results' Progress`,
  },
];

export const DATE_OPTIONS = [
  {
    id: '7',
    name: 'Last 7 days',
  },
  {
    id: '14',
    name: 'Last 14 days',
  },
  {
    id: '28',
    name: 'Last 28 days',
  },
  {
    id: '0',
    name: 'Custom',
  },
];
