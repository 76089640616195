import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import Progress from './Progress';
import { useForm, Controller } from 'react-hook-form';
import { isJust, withDefault } from '../../maybe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  updateKeyResultProgress as UpdateKeyResultProgressMutation,
  updateKeyResultName as UpdateKeyResultNameMutation,
  deleteKeyResult as DeleteKeyResultMutation,
} from '../../graphql/mutations';
import { useAlert } from 'react-alert';
import { API, graphqlOperation } from 'aws-amplify';
import { ObjectiveStore } from '../../pages/OKR/CurrentObjectives';
import {
  Objective as GraphQLObjective,
  KeyResult as GraphQLKeyResult,
  MutationUpdateKeyResultProgressArgs,
  MutationUpdateKeyResultNameArgs,
  MutationDeleteKeyResultArgs,
} from '../../graphql/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as KeyResultIcon } from './key.svg';
import { ReactComponent as TripleDots } from './tripleDots.svg';

const KeyResultContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 20px;
  background-color: #ffffff;
`;

const KeyResultForm = styled.form`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 38px;
  background-color: #ffffff;
`;

const KeyResultNameText = styled.h2`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  width: 100%;
  margin-left: 10px;

  cursor: auto;
`;

const NewKeyResultInput = styled.input`
  width: 100%;
  border: 0;
  resize: none;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.item.container};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};

const SaveButton = styled(MenuButton)`
  /* pointer-events: none; */
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;
const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const DeleteButton = styled(MenuButton)`
  margin-right: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }
  :disabled {
    pointer-events: none;
  }
`;

const TripleDotsContainer = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;
`;

export type KeyResultProps = Partial<GraphQLKeyResult> & {
  color: string;
  className?: string;
  parentObjectiveID?: GraphQLObjective['parentObjectiveID'];
};

const editKeyResultSchema = yup.object().shape({
  name: yup.string().required(),
});

const KeyResult: React.FC<KeyResultProps> = ({
  color,
  progress = 0,
  name,
  id,
  objectiveID,
  parentObjectiveID,
  className = undefined,
}) => {
  const [, dispatch] = useContext(ObjectiveStore);
  const alert = useAlert();
  const [editing, setEditing] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const localProgress = withDefault(progress, 0);

  const editKeyResultFormMethods = useForm({
    resolver: yupResolver(editKeyResultSchema),
    defaultValues: {
      name,
    },
  });

  useEffect(() => {
    if (!editing) {
      editKeyResultFormMethods.reset({
        name,
      });
    }
  }, [name]);

  if (!id) return null;

  const changeKeyResultProgress = async (nextValue: number) => {
    const args = {
      input: {
        id,
        progress: nextValue,
      },
    } as MutationUpdateKeyResultProgressArgs;

    const queryResult = (await API.graphql(
      graphqlOperation(UpdateKeyResultProgressMutation, args),
    )) as GraphQLResult<{
      updateKeyResultProgress: GraphQLKeyResult;
    }>;

    if (!!queryResult.data) {
      if (parentObjectiveID && isJust(parentObjectiveID)) {
        dispatch({
          type: 'updateSubKeyResult',
          payload: queryResult.data.updateKeyResultProgress,
          meta: {
            parentObjectiveID,
          },
        });
      } else {
        dispatch({
          type: 'updateKeyResult',
          payload: queryResult.data.updateKeyResultProgress,
        });
      }
    }
  };

  const updateKeyResultName = async (newName?: string) => {
    if (!newName) return;

    const args = {
      input: {
        id,
        name: newName,
      },
    } as MutationUpdateKeyResultNameArgs;

    const queryResult = (await API.graphql(
      graphqlOperation(UpdateKeyResultNameMutation, args),
    )) as GraphQLResult<{
      updateKeyResultName: GraphQLKeyResult;
    }>;

    if (!!queryResult.data) {
      if (parentObjectiveID && isJust(parentObjectiveID)) {
        dispatch({
          type: 'updateSubKeyResult',
          payload: queryResult.data.updateKeyResultName,
          meta: {
            parentObjectiveID,
          },
        });
      } else {
        dispatch({
          type: 'updateKeyResult',
          payload: queryResult.data.updateKeyResultName,
        });
      }

      editKeyResultFormMethods.reset({
        name: newName,
      });
      setEditing(false);
    }
  };

  const deleteKeyResult = async () => {
    const args: MutationDeleteKeyResultArgs = {
      input: {
        id,
      },
    };
    setDeleting(true);

    try {
      await API.graphql(graphqlOperation(DeleteKeyResultMutation, args));

      if (parentObjectiveID) {
        dispatch({
          type: 'deleteSubKeyResult',
          payload: {
            id,
            objectiveID,
          },
          meta: {
            parentObjectiveID,
          },
        });
      } else {
        dispatch({
          type: 'deleteKeyResult',
          payload: {
            id,
            objectiveID,
          },
        });
      }
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }

    setDeleting(false);
  };

  if (editing) {
    return (
      <KeyResultForm
        onSubmit={editKeyResultFormMethods.handleSubmit((data) =>
          updateKeyResultName(data.name),
        )}
      >
        <Controller
          name="name"
          control={editKeyResultFormMethods.control}
          render={(fieldProps) => (
            <NewKeyResultInput
              autoFocus
              required
              tabIndex={1}
              placeholder="Enter a title for key result"
              {...fieldProps}
              disabled={editKeyResultFormMethods.formState.isSubmitting}
            />
          )}
        />
        <DeleteButton
          tabIndex={4}
          onClick={() => deleteKeyResult()}
          disabled={deleting}
        >
          <FontAwesomeIcon icon={faTrash} size="1x" />
        </DeleteButton>
        <SaveButton
          type="submit"
          tabIndex={2}
          disabled={editKeyResultFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faCheck} size="1x" />
        </SaveButton>
        <CancelButton
          tabIndex={3}
          onClick={() => setEditing(false)}
          disabled={editKeyResultFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </CancelButton>
      </KeyResultForm>
    );
  }

  return (
    <KeyResultContainer className={className}>
      <KeyResultIcon />
      <KeyResultNameText onClick={() => setEditing(true)}>
        {name}
      </KeyResultNameText>

      <Progress
        color={color}
        progress={localProgress}
        onChange={(nextValue) => changeKeyResultProgress(nextValue)}
      />
      {/* <TripleDotsContainer> */}
        <TripleDots />
      {/* </TripleDotsContainer> */}
    </KeyResultContainer>
  );
};

export default KeyResult;
