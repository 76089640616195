import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Redirect, useParams } from 'react-router-dom';
import Loading from '../components/AppLoading';
import GlobalErrorMessage from '../components/GlobalErrorMessage/GlobalErrorMessage';
import { acceptInvitation } from '../graphql/mutations';
import { Membership } from '@propella/core';
import { useBoundStore } from '../states';

type AcceptInvitationOutput = {
  errors: Object;
  data: {
    acceptInvitation: Membership;
  };
};

type CatchedError = {
  errors: [
    {
      message: string;
    },
  ];
};

interface InvitationAcceptState {
  loading: boolean;
  errorMessage?: string | null;
  organizationID?: string | null;
}

const AcceptInvitation = () => {
  const { appendOrganization } = useBoundStore();

  const [acceptState, setAcceptState] = useState<InvitationAcceptState>({
    loading: true,
    errorMessage: null,
    organizationID: null,
  });
  const { id: invitationCode }: { id: string } = useParams();

  useEffect(() => {
    const userAcceptInvitation = async (invitationCode: string) => {
      try {
        const { errors, data } = (await API.graphql(
          graphqlOperation(acceptInvitation, {
            input: {
              invitationID: invitationCode,
            },
          }),
        )) as AcceptInvitationOutput;
        if (!errors) {
          setAcceptState({
            loading: false,
            organizationID: data.acceptInvitation.organization?.id || '',
          });

          if (data.acceptInvitation.organization) {
            appendOrganization(data.acceptInvitation.organization);
          }
        }
      } catch (error: any) {
        setAcceptState({
          loading: false,
          errorMessage: (error as CatchedError).errors[0].message,
        });
      }
    };

    userAcceptInvitation(invitationCode);
  }, []);

  if (acceptState.loading) return <Loading />;

  if (acceptState.errorMessage)
    return (
      <div>
        <GlobalErrorMessage message={acceptState.errorMessage} />
      </div>
    );

  return (
    <Redirect
      to={{
        pathname: '/plan',
        state: {
          organizationID: acceptState.organizationID,
        },
      }}
    />
  );
};

export default AcceptInvitation;
