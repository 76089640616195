import React, { useMemo, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { formatDistanceToNow, parseJSON } from 'date-fns';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faPalette,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as TripleStar } from './triple.svg';
import { ReactComponent as Handle } from './handle.svg';
import Progress from './Progress';
import KeyResult from './KeyResult';
import { calculateProgressFromKRandSubObj } from './utils';
import SubObjective from './SubObjective';
import {
  MutationDeleteObjectiveArgs,
  MutationUpdateObjectiveArgs,
  Objective as GraphQLObjective,
} from '../../graphql/types';
import {
  deleteObjective as DeleteObjectiveMutation,
  updateObjective as UpdateObjectiveMutation,
} from '../../graphql/mutations';
import { isJust, withDefault } from '../../maybe';
import NewKeyResult from './NewKeyResult';
import NewSubObjective from './NewSubObjective';
import { ObjectiveStore } from '../../pages/OKR/CurrentObjectives';
import { API, graphqlOperation } from 'aws-amplify';
import { yupResolver } from '@hookform/resolvers/yup';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import Assignee from '../Assignee';
import ColorsPalette from '../ColorPalette/ColorPalette';

type InputProps = {
  textColor?: string;
};

export type ObjectiveProps = GraphQLObjective;

const ObjContainer = styled.div<Partial<ObjectiveProps>>`
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => props.color};
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 26px;
`;

const ObjHeader = styled.div<Partial<ObjectiveProps>>`
  background-color: ${(props) => props.color};
  color: #ffffff;

  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ObjHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  padding-right: 20px;
`;

const ObjName = styled.h1`
  font-size: 20px;
  font-weight: 400;
  margin: 0;
`;

const ObjCompletedStatus = styled.span`
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
`;

type ObjInCompletedStatusProps = {
  color: string;
};

const ObjInCompletedStatus = styled.span<ObjInCompletedStatusProps>`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
  color: ${(props) => props.color || '#ffffff'};
  filter: brightness(1.7);
`;

const DueDateText = styled.span`
  font-weight: 700;
  filter: brightness(1.25);
`;

const ObjNameAndStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const StyledHandle = styled(Handle)`
  margin-left: 15px;

  .handlersvg {
    fill: #ffffff;
  }
`;

const ObjContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledKeyResult = styled(KeyResult)`
  border-top: 1px dashed #c8cad1;

  &:first-child {
    border-top: 0;
  }
`;

const SubObjectiveSection = styled.div`
  padding: 22px 24px 22px 25px;
  background-color: #eff2f4;
`;

interface MenuButtonProps {
  textColor?: string;
}

const MenuButton = styled.button<MenuButtonProps>`
  background-color: transparent;
  color: ${(props) => props.textColor || '#fffffff'};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};

const ColorPicker = styled(MenuButton)`
  margin-right: 22px;
`;

const SaveButton = styled(MenuButton)`
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const DeleteButton = styled(MenuButton)`
  margin-right: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }
  :disabled {
    pointer-events: none;
  }
`;

type FormContainerProps = {
  bgColor?: string;
  textColor?: string;
};

const FormContainer = styled.form<FormContainerProps>`
  background-color: inherit;
  color: ${(props) => props.textColor || '#ffffff'};

  border-radius: 6px;
  min-height: 100px;
  width: 100%;
  padding: 0 36px 0 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UpdateObjectiveInput = styled.input<InputProps>`
  width: 100%;
  border: 0;
  resize: none;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  background-color: transparent;
  color: inherit;

  margin-bottom: 10px;

  &:focus {
    outline: none;
  }
`;

const NameAndDueDateSection = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const DueDateButton = styled.button<InputProps>`
  background-color: transparent;
  border: 0;
  font-weight: 700;
  color: ${(props) => props.textColor || '#ffffff'};

  max-width: 360px;
  height: 20px;

  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
    filter: brightness(1.25);
  }
`;

const updateObjSchema = yup.object().shape({
  name: yup.string().required(),
  color: yup.string().required(),
  expectedEndDate: yup.string().nullable(),
});

const Objective: React.FC<ObjectiveProps> = ({
  id,
  color,
  expectedEndDate,
  name,
  keyResults = [],
  subObjectives = [],
  assignees = [],
}) => {
  const currentTime = new Date().toString();
  const [objectiveScope, dispatch] = useContext(ObjectiveStore);
  const [editing, setEditing] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const localKeyResult = withDefault(keyResults, []).filter(isJust);
  const localSubObj = withDefault(subObjectives, []).filter(isJust);
  const [bgColor, setBgColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const updateObjFormMethod = useForm({
    resolver: yupResolver(updateObjSchema),
    defaultValues: {
      name: name,
      color: color,
      expectedEndDate: expectedEndDate,
    },
  });

  useEffect(() => {
    if (!editing) {
      updateObjFormMethod.reset({
        name,
        color,
      });
    }
  }, [color]);

  const localColor = useMemo(() => {
    return updateObjFormMethod.getValues().color || color || '#ffffff';
  }, [updateObjFormMethod.watch('color')]);

  function hexToRgbb(hex : string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : {r : 0, g : 0, b : 0};
  };

  useEffect(() => {
    setBgColor(updateObjFormMethod.getValues().color || '#ffffff');
    if (bgColor !== '') {
      const bgRgbb = hexToRgbb(bgColor);   

      const brightness = Math.round(
        (bgRgbb.r * 299 + bgRgbb.g * 587 + bgRgbb.b * 114) / 1000,
      );
      
      setTextColor(brightness > 125 ? '#000000' : '#ffffff');
    }
  }, [updateObjFormMethod.watch('color')]);

  const currentProgress = useMemo(
    () => calculateProgressFromKRandSubObj(localKeyResult, localSubObj),
    [localKeyResult, localSubObj],
  );

  const objIsCompleted = useMemo<boolean>(
    () => currentProgress === 100,
    [currentProgress],
  );

  const updateObjective = async (
    newName: string,
    newColor: string,
    newExpectedEndDate: string | null,
  ) => {
    const args = {
      input: {
        id,
        name: newName,
        color: newColor,
        expectedEndDate: newExpectedEndDate,
      },
    } as MutationUpdateObjectiveArgs;
    setEditing(true);

    const queryResult = (await API.graphql(
      graphqlOperation(UpdateObjectiveMutation, args),
    )) as GraphQLResult<{
      updateObjective: GraphQLObjective;
    }>;

    const updatedObjective = queryResult.data?.updateObjective;

    if (!!updatedObjective) {
      dispatch({
        type: 'updateObjective',
        payload: updatedObjective,
        meta: {
          id,
          name,
          color,
          expectedEndDate,
        },
      });

      updateObjFormMethod.reset({
        name: newName,
        color: newColor,
        expectedEndDate: newExpectedEndDate,
      });
      setEditing(false);
    }
  };

  const deleteObjective = async () => {
    const args: MutationDeleteObjectiveArgs = {
      input: {
        id,
      },
    };
    setDeleting(true);

    try {
      await API.graphql(graphqlOperation(DeleteObjectiveMutation, args));

      dispatch({
        type: 'deleteObjective',
        payload: { id },
        meta: {
          name,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ObjContainer color={localColor}>
      <ObjHeader color={localColor}>
        {editing ? (
          <FormContainer
            bgColor={bgColor}
            textColor={textColor}
            onSubmit={updateObjFormMethod.handleSubmit(async (data) => {
              let pickedColor = withDefault(data.color, localColor);
              let pickedEndDate = withDefault(
                data.expectedEndDate,
                !!expectedEndDate ? expectedEndDate : null,
              );
              await updateObjective(data.name, pickedColor, pickedEndDate);
            })}
          >
            <Controller
              name="color"
              control={updateObjFormMethod.control}
              render={(fieldProps) => (
                <Popover
                  isOpen={colorPickerOpen}
                  positions={['bottom', 'top']}
                  onClickOutside={() => setColorPickerOpen(false)}
                  align="start"
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor={'#ffffff'}
                      arrowSize={0}
                    >
                      <ColorsPalette
                        onChange={(newColor) => fieldProps.onChange(newColor)}
                      />
                    </ArrowContainer>
                  )}
                >
                  <ColorPicker
                    textColor="inherit"
                    tabIndex={2}
                    onClick={() => setColorPickerOpen(!colorPickerOpen)}
                    disabled={updateObjFormMethod.formState.isSubmitting}
                  >
                    <FontAwesomeIcon icon={faPalette} size="1x" />
                  </ColorPicker>
                </Popover>
              )}
            />
            <NameAndDueDateSection>
              <Controller
                name="name"
                control={updateObjFormMethod.control}
                render={(fieldProps) => (
                  <UpdateObjectiveInput
                    {...fieldProps}
                    autoFocus
                    tabIndex={1}
                    disabled={updateObjFormMethod.formState.isSubmitting}
                    placeholder="Enter a title for the objective"
                    textColor={textColor}
                  />
                )}
              />
              <Controller
                name="expectedEndDate"
                control={updateObjFormMethod.control}
                render={(fieldProps) => (
                  <Popover
                    isOpen={datePickerOpen}
                    positions={['bottom', 'top']}
                    onClickOutside={() => setDatePickerOpen(false)}
                    align="start"
                    content={({ position, childRect, popoverRect }) => (
                      <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'#ffffff'}
                        arrowSize={0}
                      >
                        <DatePicker
                          inline
                          minDate={moment().toDate()}
                          selected={
                            fieldProps.value
                              ? new Date(fieldProps.value)
                              : new Date()
                          }
                          onChange={(date) =>
                            fieldProps.onChange(moment(date).toISOString())
                          }
                        />
                      </ArrowContainer>
                    )}
                  >
                    <DueDateButton
                      type="button"
                      textColor={textColor}
                      tabIndex={3}
                      onClick={() => setDatePickerOpen(!datePickerOpen)}
                      disabled={updateObjFormMethod.formState.isSubmitting}
                    >
                      {fieldProps.value && fieldProps.value !== ''
                        ? 'Due in ' +
                          formatDistanceToNow(parseJSON(fieldProps.value)) +
                          ` (${moment(fieldProps.value).format(
                            'MMM DD, YYYY',
                          )})`
                        : 'Add due date'}
                    </DueDateButton>
                  </Popover>
                )}
              />
            </NameAndDueDateSection>
            <DeleteButton
              type="button"
              textColor="inherit"
              tabIndex={6}
              onClick={() => deleteObjective()}
              disabled={updateObjFormMethod.formState.isSubmitting}
            >
              <FontAwesomeIcon icon={faTrash} size="1x" />
            </DeleteButton>
            <SaveButton
              type="submit"
              textColor="inherit"
              tabIndex={4}
              disabled={updateObjFormMethod.formState.isSubmitting}
            >
              <FontAwesomeIcon icon={faCheck} size="1x" />
            </SaveButton>
            <CancelButton
              textColor="inherit"
              type="button"
              tabIndex={5}
              onClick={() => setEditing(false)}
              disabled={updateObjFormMethod.formState.isSubmitting}
            >
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </CancelButton>
          </FormContainer>
        ) : (
          <>
            <ObjHeaderSection onClick={() => setEditing(true)}>
              <TripleStar />
              <ObjNameAndStatusContainer>
                {objIsCompleted && (
                  <ObjCompletedStatus>Completed</ObjCompletedStatus>
                )}
                {!objIsCompleted && (
                  <ObjInCompletedStatus color={localColor}>
                    {!!expectedEndDate &&
                    Date.parse(expectedEndDate) < Date.parse(currentTime)
                      ? `Overdue ${' '}`
                      : `Due in ${' '}`}

                    <DueDateText>
                      {expectedEndDate && isJust(expectedEndDate)
                        ? formatDistanceToNow(parseJSON(expectedEndDate))
                        : `Not set`}
                    </DueDateText>
                  </ObjInCompletedStatus>
                )}
                <ObjName>{name}</ObjName>
              </ObjNameAndStatusContainer>
            </ObjHeaderSection>
            <ObjHeaderSection>
              <Assignee
                assignableUsers={objectiveScope.assignableUsers}
                assignees={withDefault(assignees, []).filter(isJust)}
                objectiveId={id}
              />
              <Progress
                color={localColor}
                progress={currentProgress}
                disabled
                inverted
              />
            </ObjHeaderSection>
          </>
        )}
      </ObjHeader>
      <ObjContent>
        {_.orderBy(
          localKeyResult,
          (keyResult) =>
            keyResult.createdAt ? new Date(keyResult.createdAt) : new Date(),
          'asc',
        ).map((keyResult) => (
          <StyledKeyResult
            name={keyResult.name}
            key={keyResult.id}
            progress={keyResult.progress}
            id={keyResult.id}
            color={localColor}
            objectiveID={id}
          />
        ))}
        <NewKeyResult objectiveID={id} />

        <SubObjectiveSection>
          {localSubObj.length > 0 &&
            _.orderBy(
              localSubObj,
              (subObj) =>
                subObj.createdAt ? new Date(subObj.createdAt) : new Date(),
              'desc',
            ).map((subObj) => (
              <SubObjective
                {...subObj}
                key={subObj.id}
                color={localColor}
                parentObjectiveID={id}
              />
            ))}
          <NewSubObjective parentObjectiveID={id} />
        </SubObjectiveSection>
      </ObjContent>
    </ObjContainer>
  );
};

export default Objective;
