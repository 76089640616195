import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import 'react-popper-tooltip/dist/styles.css';
import TopicContainer from '../TopicContainer/TopicContainer';
import TopicHeader from '../TopicHeader/TopicHeader';
import TopicDescription from '../TopicDescription/TopicDescription';
import ItemList from '../ItemList/ItemList';
import BaseListItem from '../BaseListItem/BaseListItem';
import TopicIcons from '../TopicIcons/TopicIcons';
import { Domain, EntryChange, Topic, UsersChangeType } from '../../common/type';
import Contributors from '../Contributors/Contributors';
import diff from 'node-htmldiff';
import ReactDOMServer from 'react-dom/server';
import EntryViewer from '../EntryViewer/EntryViewer';
import { ListRow, SubListRow } from '@propella/core';
import { withDefault } from '../../maybe';

type ItemContainerProps = {
  type: string;
};

type CompareTopicProps = {
  changes?: EntryChange[];
  topic: Topic;
  domain: Domain;
  cell: ListRow | SubListRow;
  usersChange?: UsersChangeType[];
};

const ItemContainer = styled(BaseListItem)<ItemContainerProps>`
  color: ${(props) =>
    props.type === 'remove'
      ? props.theme.colors.borderItem
      : props.theme.colors.black};
  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  :last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: none;
  }
  border-radius: 0;
  border-left: 5px solid;
  border-left-color: ${(props) => {
    if (props.type === 'create') return props.theme.colors.topic.create;
    if (props.type === 'remove') return props.theme.colors.error;
    if (props.type === 'update') return props.theme.colors.topic.update;
    return props.theme.colors.white;
  }};
  svg {
    color: ${(props) => props.theme.colors.borderItem};
  }
`;

const DiffChange = styled.div`
  ins {
    color: green;
  }
  del {
    color: red;
  }
`;
const TopicContributors = styled.div`
  padding: 15px;
`;

const ContentChange = styled.p`
  flex-grow: 1;
`;

const CompareTopic: React.FC<CompareTopicProps> = ({
  domain,
  changes,
  topic,
  usersChange,
}) => {
  const validateList = (editorID: string[], ownerID: string[]) => {
    if (editorID !== null && ownerID !== null && editorID !== ownerID) {
      return editorID.concat(ownerID);
    }
    return editorID;
  };

  const generateDiffContent = (entryFrom: any, entryTo: any) => {
    let from = '<p></p>';
    let to = '<p></p>';
    if (!!entryFrom) {
      from = ReactDOMServer.renderToString(
        <EntryViewer entry={JSON.parse(entryFrom) as JsonDocument} />,
      );
    }
    if (!!entryTo) {
      to = ReactDOMServer.renderToString(
        <EntryViewer entry={JSON.parse(entryTo) as JsonDocument} />,
      );
    }
    const diffResult = diff(from, to);
    return (
      <DiffChange
        dangerouslySetInnerHTML={{
          __html: diffResult,
        }}
      ></DiffChange>
    );
  };

  return (
    <TopicContainer topicColor={domain.fgColor}>
      <TopicHeader>
        <TopicIcons topicName={topic.name} />
        {topic.name}
      </TopicHeader>
      <TopicDescription>
        <ReactMarkdown children={withDefault(topic.description, '')} />
      </TopicDescription>
      <TopicContributors>
        {changes?.length !== 0 && (
          <Contributors usersChange={usersChange} limit={5} />
        )}
      </TopicContributors>
      <ItemList>
        {changes?.map((change, index) => (
          <ItemContainer key={index} type={change.type}>
            {change.type === 'update' && (
              <ContentChange>
                {generateDiffContent(change.from, change.to)}
              </ContentChange>
            )}
            {change.type !== 'update' && (
              <ContentChange>
                <EntryViewer entry={JSON.parse(change.name) as JsonDocument} />
              </ContentChange>
            )}
            <Contributors
              listContributors={validateList(change.editorID, change.ownerID)}
              usersChange={usersChange}
              limit={5}
            />
          </ItemContainer>
        ))}
      </ItemList>
    </TopicContainer>
  );
};

export default CompareTopic;
