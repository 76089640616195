import React from 'react';
import styled from 'styled-components';
import { TSelectableItemProps, createSelectable } from 'react-selectable-fast';

type PlaceholderCellProps = TSelectableItemProps & {
  gridArea: string;
  x: number;
  y: number;
};

type CellContainerProps = Pick<PlaceholderCellProps, 'gridArea'>;

const CellContainer = styled.div<CellContainerProps>`
  grid-area: ${(props) => props.gridArea};
  border: 2px dashed;
  border-color: #dcdee3;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  min-height: 300px;
`;

const PlaceholderCell: React.FC<PlaceholderCellProps> = ({
  gridArea,
  selectableRef,
  x,
  y,
}) => {
  return (
    <CellContainer
      className="cube"
      data-posx={x}
      data-posy={y}
      data-placeholder={true}
      gridArea={gridArea}
      ref={selectableRef}
    >
    </CellContainer>
  );
};

export default createSelectable(PlaceholderCell);
