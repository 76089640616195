import styled from 'styled-components';

const ClearButton = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ClearButton;