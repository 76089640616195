import React from 'react';

export type HandlerProps = {
  color?: string;
};

export const Handler = ({color}: HandlerProps) => {
  return (
    <svg
      width="10"
      height="23"
      viewBox="0 0 10 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2.5" r="2" fill={color ? color : "#F2F2F2"} />
      <circle cx="8" cy="2.5" r="2" fill={color ? color : "#F2F2F2"} />
      <circle cx="8" cy="8.5" r="2" fill={color ? color : "#F2F2F2"} />
      <circle cx="2" cy="8.5" r="2" fill={color ? color : "#F2F2F2"} />
      <circle cx="2" cy="14.5" r="2" fill={color ? color : "#F2F2F2"} />
      <circle cx="8" cy="14.5" r="2" fill={color ? color : "#F2F2F2"} />
      <circle cx="8" cy="20.5" r="2" fill={color ? color : "#F2F2F2"} />
      <circle cx="2" cy="20.5" r="2" fill={color ? color : "#F2F2F2"} />
    </svg>
  );
};
