import styled from 'styled-components';

const CanvasItemList = styled.div`
  height: 100%;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.borderItem};
  border-bottom: 0;
  border-top: 0;
  padding: 15px 10px;
`;

export default CanvasItemList;
