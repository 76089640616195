import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { useAlert } from 'react-alert';
import { COLORS } from '../../common/color';
import { useBoundStore } from '../../states';
import RectButton from '../Button/RectButton';

export type KeyResultProps = {
  id: string;
  initiativeID: string;
  name: string;
  className?: string;
  isWithinKanbanBoard?: boolean;
};

type ClearButtonProps = {
  hoverColor: string;
};

export const KeyResultContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 12px;

  display: flex;
  flex-direction: row;
`;

const ClearButton = styled.button<ClearButtonProps>`
  border: 0;
  background-color: transparent;
  margin-left: auto;
  outline: 0;
  color: #aeafaf;

  &:hover {
    color: ${(props) => props.hoverColor};
    cursor: pointer;
  }
`;

const LoadingContainer = styled.div`
  width: 16px;
  height: 16px;
`;

const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
`;

const ConfirmButtonsContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const StyledRectButton = styled(RectButton)`
  color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  background-color: ${(props) => props.theme.colors.white};
  border-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  :hover {
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
    color: ${(props) => props.theme.colors.white};
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
  }
`;

const StyledModal = styled.div`
  width: 40rem;
  max-height: 60%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 220px;
  margin-bottom: 150px;
  padding-bottom: 30px;
  border-radius: 15px;
`;

const KeyResult = (props: KeyResultProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const alert = useAlert();
  const [isDeleting, setIsDeleting] = useState(false);

  const { name, className } = props;

  const [deleteInitiativeKeyResult, deleteInitiativeKeyResultKanban] =
    useBoundStore((state) => [
      state.deleteInitiativeKeyResult,
      state.deleteInitiativeKeyResultKanban,
    ]);

  const deleteInitiativeKeyResultAction = async (
    initiativeID: string,
    id: string,
  ) => {
    setIsDeleting(true);
    try {
      if (props.isWithinKanbanBoard) {
        await deleteInitiativeKeyResultKanban(initiativeID, id);
      } else {
        await deleteInitiativeKeyResult(initiativeID, id);
      }
      alert.success('Delete key result successfully');
    } catch (error) {
      alert.error('Delete key result failed');
    } finally {
      setIsDeleting(false);
      setIsOpenModal(false);
    }
  };

  return (
    <KeyResultContainer className={className}>
      <span>{name}</span>
      {!isDeleting && (
        <ClearButton hoverColor="#d93b3b">
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => {
              setIsOpenModal(true);
            }}
          />
        </ClearButton>
      )}
      <Modal
        isOpen={isOpenModal}
        onBackgroundClick={() => setIsOpenModal(false)}
        onEscapeKeydown={() => setIsOpenModal(false)}
      >
        <StyledModal>
          <StyledLabel>Do you want to delete this key result?</StyledLabel>
          <ConfirmButtonsContainer>
            <StyledRectButton
              type="button"
              onClick={() =>
                deleteInitiativeKeyResultAction(props.initiativeID, props.id)
              }
            >
              Yes
            </StyledRectButton>
            <StyledRectButton
              type="button"
              variant="secondary"
              onClick={() => setIsOpenModal(false)}
            >
              No
            </StyledRectButton>
          </ConfirmButtonsContainer>
        </StyledModal>
      </Modal>
      {isDeleting && (
        <LoadingContainer>
          <ReactLoading type="spin" color={COLORS.deleting} />
        </LoadingContainer>
      )}
    </KeyResultContainer>
  );
};

export default KeyResult;
