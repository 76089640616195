import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';

type PageControlContainerProps = {
  variant: string;
};

type PageControlProps = {
  variant: string;
  text?: string;
  leftSideComponent?: React.ReactElement;
  rightSideComponent: React.ReactElement;
  fluid?: boolean;
};
const PageControlContainer = styled.div<PageControlContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 80px;
  font-family: 'Open Sans', sans-serif;
  background-color: ${(props) =>
    props.variant === 'dark'
      ? props.theme.colors.pageControl.darkBg
      : props.theme.colors.white};
  color: ${(props) =>
    props.variant === 'dark'
      ? props.theme.colors.white
      : props.theme.colors.pageControl.text};
  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.variant === 'light'
      ? `1px solid ${props.theme.colors.pageControl.border}`
      : 'none'};
`;

type ContentContainerProps = {
  fluid: boolean | undefined;
};

const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) => (props.fluid ? '0 30px' : '0')};
  height: 100%;
`;

const PageControl: React.FC<PageControlProps> = ({
  variant,
  text,
  leftSideComponent,
  rightSideComponent,
  fluid,
}) => {
  return (
    <PageControlContainer variant={variant}>
      <Container>
        <Row alignItems="center" style={{ height: '100%' }}>
          <Col col={12}>
            <ContentContainer fluid={fluid}>
              <div>{leftSideComponent}</div>
              <div>{rightSideComponent}</div>
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </PageControlContainer>
  );
};

export default PageControl;
