import { ACTION } from './common/constant';
import { Maybe } from '@propella/core';
import { isJust } from './maybe';

const isAllowed = (
  grantedActions: Maybe<Maybe<string>[]> | undefined,
  requiredActions: string[],
) =>
  Array.isArray(grantedActions)
    ? grantedActions
        .filter(isJust)
        .some((action: string) => requiredActions.includes(action))
    : false;

const canContributeDocument = (
  grantedActions: Maybe<Maybe<string>[]> | undefined,
  itemName: string | undefined,
) => {
  if (
    itemName === 'businessModelCanvas' ||
    itemName === 'Business Model Canvas'
  )
    return isAllowed(grantedActions, [
      ACTION.CONTRIBUTE_BUSINESS_MODEL_CANVAS,
      ACTION.ADMINISTER,
    ]);
  else if (
    itemName === 'competitiveLandscape' ||
    itemName === 'Competitive Landscape'
  )
    return isAllowed(grantedActions, [
      ACTION.CONTRIBUTE_COMPETITIVE_LANDSCAPE,
      ACTION.ADMINISTER,
    ]);
  else if (
    itemName === 'strategicDrivers' ||
    itemName === 'strategicAgilityPlan' ||
    itemName === 'Strategic Drivers' ||
    itemName === 'Strategic Agility Plan'
  )
    return isAllowed(grantedActions, [
      ACTION.CONTRIBUTE_STRATEGIC_DRIVERS,
      ACTION.ADMINISTER,
    ]);
  else if (
    itemName === 'strategicInsights' ||
    itemName === 'Strategic Insights'
  ) {
    return isAllowed(grantedActions, [
      ACTION.CONTRIBUTE_STRATEGIC_INSIGHTS,
      ACTION.ADMINISTER,
    ]);
  } else if (
    itemName === 'onePageStrategicAgilityPlan' ||
    itemName === 'One Page Strategic Agility Plan'
  ) {
    return isAllowed(grantedActions, [
      ACTION.CONTRIBUTE_ONE_PAGE_STRATEGIC_AGILITY_PLAN,
      ACTION.ADMINISTER,
    ]);
  }
  return false;
};
export { isAllowed, canContributeDocument };
