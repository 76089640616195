import React from 'react';
import diff from 'node-htmldiff';
import styled from 'styled-components';
import CanvasTopicContainer from '../CanvasTopicContainer/CanvasTopicContainer';
import CanvasTopicHeader from '../CanvasTopicHeader/CanvasTopicHeader';
import CanvasItemList from '../CanvasItemList/CanvasItemList';
import BaseListItem from '../BaseListItem/BaseListItem';
import TopicIcons from '../TopicIcons/TopicIcons';
import {
  GridCell,
  Topic,
  Domain,
  EntryChange,
  UsersChangeType,
} from '../../common/type';
import EntryViewer from '../EntryViewer/EntryViewer';
import Contributors from '../Contributors/Contributors';
import ReactDOMServer from 'react-dom/server';
import { Initiative, TextEntry } from '@propella/core';
import CompareInitiativeComponent from './CompareInitiative';

type ItemContainerProps = {
  type: string;
};

type CompareCanvasTopicInput = {
  changes?: EntryChange[];
  usersChange?: UsersChangeType[];
  children?: React.ReactNode;
  gridArea: string;
  backgroundColor?: string;
  query?: string;
};

const TopicContainer = styled.div<CompareCanvasTopicInput>`
  grid-area: ${(props) => props.gridArea};
  background: #eff2f4;
  border-radius: 5px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 100%;
`;

const TopicBorder = styled.div<
  Pick<CompareCanvasTopicInput, 'backgroundColor'>
>`
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  width: 100%;
  height: 8px;
`;

const TopicList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eff2f4;
  height: 100%;
  border-radius: 0;
  border: none;
`;

const TopicContent = styled.div`
  padding: 10px;
  height: 100%;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ItemContainer = styled(BaseListItem)<ItemContainerProps>`
  padding-right: 5px;
  color: ${(props) =>
    props.type === 'remove'
      ? props.theme.colors.borderItem
      : props.theme.colors.black};
  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  :last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: none;
  }
  border-radius: 0;
  justify-content: space-between;
  border-left: 5px solid;
  border-left-color: ${(props) => {
    if (props.type === 'create') return props.theme.colors.topic.create;
    if (props.type === 'remove') return props.theme.colors.error;
    if (props.type === 'update') return props.theme.colors.topic.update;
    return props.theme.colors.white;
  }};
  svg {
    color: ${(props) => props.theme.colors.borderItem};
  }
`;

const TopicNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopicName = styled.span`
  margin-left: 10px;
`;

const ContentChange = styled.p`
  flex-grow: 1;
`;

const DiffChange = styled.div`
  ins {
    color: green;
  }
  del {
    color: red;
  }
`;

const TopicContributors = styled.div`
  padding: 15px;
`;

const ContributorsContainer = styled.div`
  margin-left: 7px;
`;

const generateDiffContent = (entryFrom: any, entryTo: any) => {
  let from = '<p></p>';
  let to = '<p></p>';
  if (!!entryFrom) {
    from = ReactDOMServer.renderToString(
      <EntryViewer entry={JSON.parse(entryFrom) as JsonDocument} />,
    );
  }
  if (!!entryTo) {
    to = ReactDOMServer.renderToString(
      <EntryViewer entry={JSON.parse(entryTo) as JsonDocument} />,
    );
  }
  const diffResult = diff(from, to);
  return (
    <DiffChange
      dangerouslySetInnerHTML={{
        __html: diffResult,
      }}
    ></DiffChange>
  );
};

const CompareStaticTopicV2: React.FC<CompareCanvasTopicInput> = ({
  changes,
  usersChange,
  gridArea,
  backgroundColor,
  query,
}) => {
  const validateList = (editorID: string[], ownerID: string[]) => {
    if (editorID !== null && ownerID !== null && editorID !== ownerID) {
      return editorID.concat(ownerID);
    }
    return editorID;
  };

  const renderEntry = (
    index: number,
    change: any,
  ) => {
    if (change.entryType === 'entry') {
      
      return (
        <>
          {change.type === 'update' && (
            <ContentChange>
              {generateDiffContent(change.from, change.to)}
            </ContentChange>
          )}
          {change.type !== 'update' && (
            <ContentChange>
              <EntryViewer entry={JSON.parse(change.name) as JsonDocument} />
            </ContentChange>
          )}
          <Contributors
            listContributors={validateList(change.editorID, change.ownerID)}
            usersChange={usersChange}
            limit={2}
          />
        </>
      );
    }

    if (change.entryType === 'initiative') {

      return (
        <>
          <CompareInitiativeComponent
            id={change.entryID}
            name={change.name}
            color="#000000"
            change={change}
          />
          <ContributorsContainer>
            <Contributors
              listContributors={validateList(change.editorID, change.ownerID)}
              usersChange={usersChange}
              limit={2}
            />
          </ContributorsContainer>
        </>
        );
    }

    return null;
  };

  return (
    <TopicContainer gridArea={gridArea}>
      <TopicBorder backgroundColor={backgroundColor} />
      <TopicContent>
        <TopicList>
          {changes?.map((change, index) => (
            <ItemContainer key={index} type={change.type}>
              {renderEntry(index, change)}
            </ItemContainer>
          ))}
        </TopicList>
      </TopicContent>
    </TopicContainer>
  );
};

export default CompareStaticTopicV2;
