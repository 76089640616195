import { useEffect, ComponentType } from 'react';
import { Hub } from 'aws-amplify';
import styled from 'styled-components';
import _ from 'lodash';
import { ThemeProvider } from 'styled-components';
import {
  transitions,
  positions,
  Provider as AlertProvider,
  AlertComponentPropsWithStyle,
} from 'react-alert';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { HubCallback } from '@aws-amplify/core/lib/Hub';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Plan from './pages/Plan/Plan';
import { DocumentDetail, Compare } from './pages/Documents';
import AppLoading from './components/AppLoading';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import AppContainer from './components/AppContainer/AppContainer';
import { appTheme } from './common/color';
import { ModalProvider } from 'styled-react-modal';

import OnboardOrganization from './pages/Organizations/OnboardOrganization';
import SwitchOrganization from './pages/SwitchOrganization';
import OrganizationSettings from './pages/Organizations/OrganizationSettings';
import NewRole from './pages/Organizations/NewRole';
import EditRole from './pages/Organizations/EditRole';
import InviteMember from './pages/Organizations/InviteMember';
import AcceptInvitation from './pages/AcceptInvitation';
import Profile from './pages/Profile/Profile';
import TemplateDesigner from './pages/Templates/Designer';
import DesignerContainer from './components/DesignerContainer';
import CurrentObjectives from './pages/OKR/CurrentObjectives';
import GraphiQLPage from './components/GraphiQLPage';
import OneHundredDayPlanning from './pages/100DP/100DP';

import 'emoji-mart/css/emoji-mart.css';

import { GlobalStyle } from './common/globalStyles';
import { useBoundStore } from './states';
import SupportRoute from './components/SupportRoute';
import OrganizationList from './pages/Support/OrganizationList';
import DocumentV2 from './pages/DocumentV2/DocumentV2';
import CompareDocumentV2 from './pages/DocumentV2/CompareDocumentV2';
import NewDocument from './pages/DocumentV2/NewDocument';
import CreateDocument from './pages/DocumentV2/CreateDocument';
import OneHundredDocList from './pages/100DP/100DocList';

const MessageContainer = styled.div<{ messageType?: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  background-color: ${(props) =>
    props.messageType === 'success'
      ? '#60bfbf'
      : props.theme?.colors?.globalError};
  color: ${(props) => props.theme?.colors?.white};
`;

const StyledGraphiQL = styled.div`
  height: 100vh;
`;
const GlobalErrorMessage: ComponentType<AlertComponentPropsWithStyle> = ({
  style,
  message,
  options,
  close,
}) => {
  return (
    <MessageContainer style={style} messageType={options.type}>
      <span>{message}</span>
      <FontAwesomeIcon icon={faTimes} onClick={() => close()} />
    </MessageContainer>
  );
};

function App() {
  const { isReady } = useBoundStore((state) => state.auth);
  const { initialize, nukeAllThing } = useBoundStore();
  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const authListener: HubCallback = (capsule) => {
      switch (capsule.payload.event) {
        case 'signIn':
          initialize();
          break;
        case 'signOut':
          nukeAllThing();
          break;
        default:
          break;
      }
    };
    Hub.listen('auth', authListener);

    return () => {
      Hub.remove('auth', authListener);
    };
  }, []);

  if (!isReady) return <AppLoading />;

  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyle />
      <ModalProvider>
        <AlertProvider
          position={positions.TOP_CENTER}
          template={GlobalErrorMessage}
          transition={transitions.FADE}
          containerStyle={{ width: '100%' }}
          timeout={10000}
        >
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/plan" />
              </Route>
              <Route exact path="/signup">
                <SignUp />
              </Route>
              <Route exact path="/signin">
                <SignIn />
              </Route>
              <Route path="/signup/:invitationCode/email/:email/fullName/:fullName">
                <SignUp />
              </Route>
              <Route path="/signin/:invitationCode">
                <SignIn />
              </Route>
              <Route path="/confirm">
                <ConfirmEmail />
              </Route>
              <Route path="/resetpassword">
                <ResetPassword />
              </Route>
              <Route path="/graphql">
                <StyledGraphiQL>
                  <GraphiQLPage></GraphiQLPage>
                </StyledGraphiQL>
              </Route>
              <PrivateRoute path="/organization/onboard">
                <OnboardOrganization />
              </PrivateRoute>
              <PrivateRoute path="/switch">
                <SwitchOrganization />
              </PrivateRoute>

              <PrivateRoute path="/templates/:id">
                <DesignerContainer>
                  <TemplateDesigner />
                </DesignerContainer>
              </PrivateRoute>
              <PrivateRoute path="/invitation/accept/:id">
                <AcceptInvitation />
              </PrivateRoute>
              <AppContainer>
                <>
                  <PrivateRoute path="/plan">
                    <Plan />
                  </PrivateRoute>
                  <PrivateRoute path="/100-day-plannings">
                    <OneHundredDayPlanning />
                  </PrivateRoute>
                  <PrivateRoute path="/profile">
                    <Profile />
                  </PrivateRoute>
                  <PrivateRoute path="/documents/:id/compare/:comparisionInput">
                    <Compare />
                  </PrivateRoute>
                  <PrivateRoute path="/documents/:id" exact>
                    <DocumentDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/objectives">
                    <CurrentObjectives />
                  </PrivateRoute>
                  <PrivateRoute path="/v2/c/documents" exact>
                    <CreateDocument />
                  </PrivateRoute>
                  <PrivateRoute path="/v2/documents/:id" exact>
                    <DocumentV2 />
                  </PrivateRoute>
                  <PrivateRoute path="/v2/documents/:id/compare/:comparisionInput">
                    <CompareDocumentV2 />
                  </PrivateRoute>
                  <PrivateRoute path="/v2/document/new">
                    <NewDocument />
                  </PrivateRoute>
                  <AdminRoute path="/organization/settings">
                    <OrganizationSettings />
                  </AdminRoute>
                  <AdminRoute path="/organization/new-role">
                    <NewRole />
                  </AdminRoute>
                  <AdminRoute path="/organization/role/:id">
                    <EditRole />
                  </AdminRoute>
                  <AdminRoute path="/organization/invite">
                    <InviteMember />
                  </AdminRoute>
                  <SupportRoute path="/support">
                    <OrganizationList />
                  </SupportRoute>
                </>
              </AppContainer>
            </Switch>
          </Router>
        </AlertProvider>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
