import styled from 'styled-components';
import Label from '../Label/Label';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

type TextFieldProps = {
  value: string;
  label: string;
  type?: string;
  error?: boolean | undefined;
  dataAttribute?: string;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  autoComplete?: string;
  onChange: (value: string) => void;
};

type ContainerProps = {
  hasError: boolean | undefined;
  focus: boolean;
};

const TextFieldContainer = styled.div<ContainerProps>`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.textfield.text};
  cursor: text;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => {
    if (props.hasError) return props.theme.colors.error;
    if (props.focus) return props.theme.colors.textfield.focusBorder;
    return props.theme.colors.textfield.border;
  }};
  background-color: ${(props) => {
    if (props.hasError) return props.theme.colors.textfield.errorBackground;
    if (props.focus) return props.theme.colors.textfield.focusBackground;
    return props.theme.colors.white;
  }};
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  padding: 20px;
  background-color: ${(props) => props.disabled ? props.theme.colors.input.disabled : 'transparent'};
  border: none;
  border-radius: 6px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0px;
  outline: none;
  width: 100%;
  line-height: 1.42857;
  font-family: 'Open Sans', sans-serif;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'normal'};
`;

const ControlContainer = styled.div`
  width: 100%;
`;
const StyledIcon = styled(FontAwesomeIcon)`
margin: 0 20px;
color: ${(props) => props.theme.colors.error};
`;

const TextField: React.FC<TextFieldProps> = ({
  label,
  value = '',
  id,
  onChange,
  error,
  type = 'text',
  dataAttribute,
  placeholder,
  disabled = false,
  autoComplete,
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <ControlContainer>
      <Label hasError={error} id={id}>{label}</Label>
      <TextFieldContainer hasError={error} focus={focused}>
        <StyledInput
          value={value} 
          aria-labelledby={id}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(event) => onChange(event.target.value)}
          type={type}
          data-cy={dataAttribute}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />
        {error && <StyledIcon data-testid="icon" icon={faExclamationCircle} />}
      </TextFieldContainer>
    </ControlContainer>
  );
};

export default TextField;
