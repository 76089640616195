import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ChromePicker } from 'react-color';
import _ from 'lodash';
import { useAlert } from 'react-alert';
import { appendOrganizationColors } from '../../graphql/mutations';
import { isJust, withDefault } from '../../maybe';
import { MutationAppendOrganizationColorsArgs } from '../../graphql/types';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useBoundStore } from '../../states';

export type ColorPaletteProps = {
  onChange: (newColor: string) => void;
};

type ColorPickerProps = {
  isShow: boolean;
};

type SelectedColorProps = {
  isSelected: boolean;
};

const PaletteContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 130px;
`;

const CircleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ColorCircle = styled.div<SelectedColorProps>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.isSelected ? '2px solid #16336b' : '')};
  margin: ${(props) => (props.isSelected ? '2px' : '7px 5px')};
  background-clip: content-box;
  padding: ${(props) => (props.isSelected ? '2px' : '')};
`;

const StyledSubmitButton = styled.input`
  width: 100%;
`;

const ColorPickerContainer = styled.div<ColorPickerProps>`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 7px 5px;
`;

type AppendOrganizationColorsOutput = {
  appendOrganizationColors: {
    colorsPalette: [string];
  };
};

const ColorPalette: React.FC<ColorPaletteProps> = ({ onChange }) => {
  const alert = useAlert();
  const { currentMembership } = useBoundStore((state) => state.auth);
  const listColors = withDefault(
    currentMembership?.organization?.colorsPalette,
    [
      '#77923d',
      '#61487a',
      '#e46c0b',
      '#973635',
      '#02009a',
      '#ff0002',
      '#fffe67',
      '#385e92',
    ],
  ).filter(isJust);
  const [colors, setColors] = useState(listColors);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [pickedColor, setPickedColor] = useState<string>('#22194D');
  const [selectedColor, setSelectedColor] = useState<string>();

  const addNewColor = async (newColor: string) => {
    if (
      !!currentMembership?.organization?.id &&
      !_.includes(currentMembership.organization.colorsPalette, newColor)
    ) {
      const oldColors = [...colors];
      setColors([...colors, newColor]);
      const args = {
        input: {
          id: currentMembership?.organization?.id,
          color: newColor,
        },
      } as MutationAppendOrganizationColorsArgs;

      try {
        const queryResult = (await API.graphql(
          graphqlOperation(appendOrganizationColors, args),
        )) as GraphQLResult<AppendOrganizationColorsOutput>;

        if (queryResult?.data?.appendOrganizationColors) {
          const newColorPalette =
            queryResult?.data?.appendOrganizationColors?.colorsPalette;
          setColors(newColorPalette);
          // @TODO: updat org color
          // updateOrganizationColorPalette(newColorPalette);
        }
      } catch (error: any) {
        setColors(oldColors);
        alert.show(error.errors[0]?.message || error.message);
      }
    }
  };

  return (
    <Container>
      <PaletteContainer>
        <Row>
          {colors.map((color, index) => (
            <Col
              col={3}
              noGutter
              key={color + String(index)}
              onClick={() => {
                setSelectedColor(color);
                onChange(color);
              }}
            >
              <CircleContainer>
                <ColorCircle
                  color={color}
                  isSelected={selectedColor === color}
                />
              </CircleContainer>
            </Col>
          ))}
          <Col col={3} noGutter>
            <CircleContainer
              onClick={() => setColorPickerOpen(!colorPickerOpen)}
            >
              <StyledIcon icon={faPlus} />
            </CircleContainer>
          </Col>
        </Row>
        <Row>
          <ColorPickerContainer isShow={colorPickerOpen}>
            <ChromePicker
              disableAlpha={true}
              color={pickedColor}
              onChange={(color) => {
                setPickedColor(color.hex);
              }}
            />
            <StyledSubmitButton
              type="submit"
              onClick={() => addNewColor(pickedColor)}
            />
          </ColorPickerContainer>
        </Row>
      </PaletteContainer>
    </Container>
  );
};

export default ColorPalette;
