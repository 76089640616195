import { Initiative, TextEntry } from '@propella/core';
import _ from 'lodash';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import {
  NewEntryConfig,
  NewEntryV2Variant,
} from '../../pages/DocumentV2/onePage';
import { useBoundStore } from '../../states';
import InitiativeComponent from '../Initiative/Initative';
import NewEntryV2, { NewEntryFormValues } from '../NewEntryV2/NewEntryV2';
import TextEntryComponent from '../TextEntry/TextEntry';
import { isJust, withDefault } from '../../maybe';

export type TopicContainerProps = {
  gridArea: string;
};

export type TopicListProps = {
  isDraggingOver: boolean;
}

const TopicContainer = styled.div<TopicContainerProps>`
  grid-area: ${(props) => props.gridArea};
  background: #eff2f4;
  border-radius: 5px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 100%;
`;

const TopicBorder = styled.div<Pick<TopicV2Props, 'backgroundColor'>>`
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  width: 100%;
  height: 8px;
`;

const TopicList = styled.div<TopicListProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.isDraggingOver ? props.theme.colors.textfield.focusBackground : "#eff2f4" };
  height:  ${(props) => props.isDraggingOver ? "calc(100% - 70px)" : "100%" };
  border-radius: ${(props) => props.isDraggingOver ? "8px" : "0" };
  border: ${(props) => props.isDraggingOver ? "1px dashed #90d0d0" : "none" };
`;

const StyledNewEntryV2 = styled(NewEntryV2)`
  margin-top: 12px;
`;

const TopicContent = styled.div`
  padding: 10px;
  height: 100%;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


export type TopicV2Props = {
  children?: React.ReactNode;
  gridArea: string;
  backgroundColor?: string;
  newEntry?: NewEntryConfig;
  query?: string;
};

const StyledInitiativeComponent = styled(InitiativeComponent)`
  margin-bottom: 10px;
`;

const StyledTextEntry = styled(TextEntryComponent)`
  margin-bottom: 10px;
`;

export const TopicV2 = (props: TopicV2Props) => {
  const {
    gridArea,
    backgroundColor = 'transparent',
    newEntry: newEntryConfig,
    query,
  } = props; 

  const [documentId, createInitiative, createTextEntry] = useBoundStore(
    (state) => [
      state.documentV2.id,
      state.createInitiative,
      state.createTextEntry,
    ],
  );

  const filteredEntries = useBoundStore((state) => {
    const { entries } = state.documentV2;
    if (!query) return entries;

    const filterCriteria = query.split('AND').reduce((acc, cur) => {
      const [key, value] = cur.split(':=').map((x) => x.trim());
      return { ...acc, [key]: value };
    }, {});

    if (_.isEmpty(filterCriteria)) return entries;
    return entries.filter(_.matches(filterCriteria));
  });

  const onNewEntry = async (name: NewEntryFormValues['name']) => {
    if (!newEntryConfig || !documentId) return;
    const { type, topic } = newEntryConfig;

    if (type === NewEntryV2Variant.INITIATIVE) {
      await createInitiative(name, topic, documentId);
    }

    if (type === NewEntryV2Variant.TEXT) {
      await createTextEntry(name, topic, documentId);
    }
  };

  const renderEntry = (entry: Initiative | TextEntry, index: number) => {
    if (entry.__typename === 'Initiative') {
      const { tags, ...rest } = entry as Initiative;

      return (
        <StyledInitiativeComponent
          {...rest}
          color={backgroundColor}
          key={rest.id}
          index={index}
          tags={tags}
          assigneeIDs={withDefault(rest.assigneeIDs, []).filter(isJust)}
        />
      );
    }
    if (entry.__typename === 'TextEntry') {
      const { name, ...rest } = entry as TextEntry;

      return (
        <StyledTextEntry key={rest.id} name={name} id={rest.id} index={index} />
      );
    }

    return null;
  };

  return (
    <TopicContainer gridArea={gridArea}>
      <TopicBorder backgroundColor={backgroundColor} />
      <TopicContent>
        <Droppable droppableId={newEntryConfig?.topic || ''}>
          {(provided, snapshot) => (
            <>
              <TopicList {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                {filteredEntries.map(renderEntry)}
                {provided.placeholder}
              </TopicList>
            </> 
          )}
        </Droppable>

        <StyledNewEntryV2 createNewEntry={onNewEntry} {...newEntryConfig} />
      </TopicContent>
    </TopicContainer>
  );
};

export default TopicV2;
