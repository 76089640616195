import { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useAlert } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
import { DragDropContext, DragUpdate } from 'react-beautiful-dnd';
import { useParams, useHistory } from 'react-router-dom';
import { Observable, ZenObservable } from 'zen-observable-ts';
import html2canvas from 'html2canvas';
import ListVisualizer from '../../components/ListVisualizer/ListVisualizer';
import GridVisualizer from '../../components/GridVisualizer/GridVisualizer';
import PageHeader from '../../components/PageHeader/PageHeader';
import SwitchTab from '../../components/SwitchTab/SwitchTab';
import Loading from '../../components/AppLoading';
import PageControl from '../../components/PageControl/PageControl';
import Button from '../../components/Button/Button';
import { transitEntry as transitEntryQuery } from '../../graphql/mutations';
import {
  onCreateEntry,
  onUpdateEntry,
  onDeleteEntry,
  onTransition,
} from '../../graphql/subscriptions';
import { canContributeDocument } from '../../utils';
import { Entry } from '@propella/core';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useBoundStore } from '../../states';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import { isJust, isNothing, withDefault } from '../../maybe';

type SubscribeEvent = {
  value: {
    data: {
      onCreateEntry?: Entry;
      onUpdateEntry?: Entry;
      onDeleteEntry?: Entry;
      onTransition?: {
        documentID: string;
        entryID: string;
        topicKey: string;
        rankBeforeEntryID: string;
        rankAfterEntryID: string;
      };
    };
  };
};

const ExportButton = styled(Button)`
  border-color: ${(props) => props.theme.colors.white};
  background-color: transparent;
  margin-right: 10px;
  :hover {
    border-color: ${(props) => props.theme.colors.white};
    background-color: transparent;
    cursor: pointer;
  }
  :active {
    border-color: ${(props) => props.theme.colors.white};
    background-color: transparent;
  }
  svg {
    padding-right: 5px;
  }
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  align-self: center;
`;

const CanvasContainer = styled.div`
  padding: 30px;
`;

const ActionBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDateRangePickerDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  border-radius: 15px;
  background: ${(props) => props.theme.colors.toggle.background};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.theme.colors.toggle.active};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const HistoryLabel = styled.p`
  margin-right: 10px;
`;

type DateRangePickerChangeProps = {
  selection: any;
};

const DocumentDetail = () => {
  const { id: documentID }: { id: string | undefined } = useParams();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const { currentMembership } = useBoundStore((state) => state.auth);
  const [exporting, setExporting] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const initDocumentScope = useBoundStore((state) => state.initDocument);
  const { isReady } = useBoundStore((state) => state.document);
  const [
    patchEntry,
    addEntry,
    transitEntry,
    removeEntry,
    fetchedDocument,
    currentIteration,
    documentName,
  ] = useBoundStore(
    (state) => [
      state.patchEntry,
      state.addEntry,
      state.transitEntry,
      state.removeEntry,
      state.document,
      state.organization.currentIteration,
      state.document.name,
    ],
    () => false,
  );

  const documentTemplate = withDefault(fetchedDocument.template, undefined);

  const [topics, entries] = useBoundStore((state) => [
    state.document.topics,
    state.document.entries,
  ]);
  const params = queryString.parse(location.search);
  const mode = params.viewMode;
  const viewMode =
    mode === 'list' || mode === 'canvas' ? mode.toString() : 'list';

  const onChangeViewMode = (nextViewMode: string) => {
    const viewModeParam = `${location.pathname}?${queryString.stringify({
      viewMode: nextViewMode,
    })}`;
    history.replace(viewModeParam);
  };

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        if (documentID) {
          await initDocumentScope(documentID);
        }
      } catch (error: any) {
        if (error.message) {
          alert.error(error.message);
        } else {
          alert.show('Something went wrong. Please try again later.');
        }
      }
    };
    fetchDocument();
  }, []);

  useEffect(() => {
    const createEntryListenerObservable = API.graphql(
      graphqlOperation(onCreateEntry, {
        documentID,
      }),
    );
    let createEntryListener: ZenObservable.Subscription;
    if (createEntryListenerObservable instanceof Observable) {
      createEntryListener = createEntryListenerObservable.subscribe({
        next: (entryData: SubscribeEvent) => {
          const newEntry = entryData.value?.data?.onCreateEntry;
          if (newEntry && !isJust(entries[newEntry.id])) {
            addEntry(newEntry);
          }
        },
      });
    }

    const updateEntryListenerObservable = API.graphql(
      graphqlOperation(onUpdateEntry, {
        documentID,
      }),
    );
    let updateEntryListener: ZenObservable.Subscription;
    if (updateEntryListenerObservable instanceof Observable) {
      updateEntryListener = updateEntryListenerObservable.subscribe({
        next: (entryData: SubscribeEvent) => {
          const updatedEntry = entryData.value?.data?.onUpdateEntry;
          if (updatedEntry) {
            patchEntry(updatedEntry);
          }
        },
      });
    }

    const deleteEntryListenerObservable = API.graphql(
      graphqlOperation(onDeleteEntry, {
        documentID,
      }),
    );
    let deleteEntryListener: ZenObservable.Subscription;
    if (deleteEntryListenerObservable instanceof Observable) {
      deleteEntryListener = deleteEntryListenerObservable.subscribe({
        next: (entryData: SubscribeEvent) => {
          const removedEntry = entryData.value?.data?.onDeleteEntry;
          if (removedEntry) {
            removeEntry(removedEntry);
          }
        },
      });
    }

    const transitionListenerObservable = API.graphql(
      graphqlOperation(onTransition, {
        documentID,
      }),
    );
    let transitionListener: ZenObservable.Subscription;
    if (transitionListenerObservable instanceof Observable) {
      transitionListener = transitionListenerObservable.subscribe({
        next: (transitionData: SubscribeEvent) => {
          const transitionResult = transitionData.value?.data?.onTransition;
          if (transitionResult) {
            transitEntry(
              transitionResult.entryID,
              transitionResult.topicKey,
              transitionResult.rankAfterEntryID,
              transitionResult.rankBeforeEntryID,
            );
          }
        },
      });
    }

    return () => {
      !!createEntryListener && createEntryListener.unsubscribe();
      !!updateEntryListener && updateEntryListener.unsubscribe();
      !!deleteEntryListener && deleteEntryListener.unsubscribe();
      !!transitionListener && transitionListener.unsubscribe();
    };
  }, [documentID]);

  const handleTransitionEntries = async ({
    destination,
    source,
    draggableId,
  }: DragUpdate) => {
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let rankBeforeEntryID, rankAfterEntryID, newTopicKey, localTransitionResult;

    const sourceTopic = topics[source.droppableId];
    const destinationTopic = topics[destination.droppableId];

    if (!sourceTopic || !destinationTopic) return;

    const originalSourceTopicEntryOrder = sourceTopic.sortedEntryIds || [];

    const oldTopicKey = source.droppableId;
    const oldRankAfterID = originalSourceTopicEntryOrder[source.index - 1];
    const oldRankBeforeID = originalSourceTopicEntryOrder[source.index + 1];

    if (sourceTopic === destinationTopic) {
      const updatedEntryOrder = Array.from(originalSourceTopicEntryOrder);
      updatedEntryOrder.splice(source.index, 1);
      updatedEntryOrder.splice(destination.index, 0, draggableId.toString());

      rankAfterEntryID = updatedEntryOrder[destination.index - 1];
      rankBeforeEntryID = updatedEntryOrder[destination.index + 1];
      newTopicKey = source.droppableId;

      localTransitionResult = {
        entryID: draggableId,
        rankBeforeEntryID,
        rankAfterEntryID,
        topicKey: newTopicKey,
      };
    } else {
      const sourceTopicEntryOrder = Array.from(originalSourceTopicEntryOrder);
      sourceTopicEntryOrder.splice(source.index, 1);

      const destinationTopicEntryOrder = Array.from(
        destinationTopic.sortedEntryIds || [],
      );
      destinationTopicEntryOrder.splice(
        destination.index,
        0,
        draggableId.toString(),
      );

      rankAfterEntryID =
        destinationTopicEntryOrder[destination.index - 1] || null;
      rankBeforeEntryID =
        destinationTopicEntryOrder[destination.index + 1] || null;
      newTopicKey = destination.droppableId;

      localTransitionResult = {
        entryID: draggableId,
        rankBeforeEntryID,
        rankAfterEntryID,
        topicKey: newTopicKey,
      };
    }

    transitEntry(
      localTransitionResult.entryID,
      localTransitionResult.topicKey,
      localTransitionResult.rankAfterEntryID,
      localTransitionResult.rankBeforeEntryID,
    );
    try {
      await API.graphql(
        graphqlOperation(transitEntryQuery, {
          input: localTransitionResult,
        }),
      );
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);

      transitEntry(
        localTransitionResult.entryID,
        oldTopicKey,
        oldRankAfterID,
        oldRankBeforeID,
      );
    }
  };

  const onComparisionDateChange = (selection: any) => {
    setSelectionRange(new Array(selection as any));
    const startDate = moment(selection.startDate).format('DDMMYYYY');
    const endDate = moment(selection.endDate).format('DDMMYYYY');

    const compareViewQString = viewMode === 'canvas' ? '?view=canvas' : '';

    if (startDate !== endDate) {
      history.replace(
        `/documents/${documentID}/compare/${startDate}..${endDate}${compareViewQString}`,
      );
    }
  };

  if (!isReady) {
    return <Loading />;
  }

  const fetchedDocumentType = withDefault(fetchedDocument.template, undefined);

  const getDocumentTypeFormatted = (documentType: string) => {
    if (documentType === 'businessModelCanvas') {
      return 'Business Model Canvas';
    } else if (documentType === 'competitiveLandscape') {
      return 'Competitive Landscape';
    } else if (documentType === 'strategicInsights') {
      return 'Strategic Insights';
    } else if (documentType === 'strategicAgilityPlan') {
      return 'Strategic Drivers';
    } else if (documentType === 'onePageStrategicAgilityPlan') {
      return 'One Page Strategic Agility Plan';
    }
  };

  const printCanvasLayout = async () => {
    setExporting(true);

    let documentTemplatePath = '';
    if (documentTemplate === 'businessModelCanvas') {
      documentTemplatePath = '/BMC.docx';
    }

    if (documentTemplate === 'competitiveLandscape') {
      documentTemplatePath = '/Landscape.docx';
    }

    if (documentTemplate === 'strategicAgilityPlan') {
      documentTemplatePath = '/StrategicDrivers.docx';
    }

    if (documentTemplate === 'strategicInsights') {
      documentTemplatePath = '/Insights.docx';
    }

    if (documentTemplate === 'onePageStrategicAgilityPlan') {
      documentTemplatePath = '/OnePage.docx';
    }

    if (documentTemplatePath === '') {
      return;
    }

    const templateContent: string = await new Promise((resolve, reject) => {
      PizZipUtils.getBinaryContent(
        process.env.PUBLIC_URL + documentTemplatePath,
        (error, content) => {
          if (error) {
            reject(error);
          } else {
            resolve(content);
          }
        },
      );
    });

    const zip = new PizZip(templateContent);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    const blockToText = (block: JsonBlock): string => {
      if (block.type === 'text') return block.text;
      return (block.content || []).map(blockToText).join(' ');
    };

    const renderData = Object.keys(topics).reduce((acc, topicKey) => {
      return {
        ...acc,
        [topicKey]: topics[topicKey].sortedEntryIds.map((entryID) => {
          const entryContent = JSON.parse(
            entries[entryID].name,
          ) as JsonDocument;

          const plainTextContent = entryContent.content
            .map(blockToText)
            .join(' ');

          return {
            name: plainTextContent,
          };
        }),
      };
    }, {});

    const today = new Date();
    const day = today.getDate() < 9 ? '0' + today.getDate() : today.getDate();
    const month =
      today.getMonth() < 9
        ? '0' + (today.getMonth() + 1)
        : today.getMonth() + 1;
    const date = today.getFullYear() + '-' + month + '-' + day;

    doc.setData({
      date,
      organization_name: currentMembership?.organization?.name,
      document_name: documentName,
      ...renderData,
    });

    doc.render();

    const out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    let exportTime = new Date();

    const formatDate =
      exportTime.getFullYear() +
      ('0' + (exportTime.getMonth() + 1)).slice(-2) +
      ('0' + exportTime.getDate()).slice(-2) +
      ('0' + exportTime.getHours()).slice(-2) +
      ('0' + exportTime.getMinutes()).slice(-2);

    saveAs(
      out,
      `${currentMembership?.organization?.name} - ${documentName} - ${formatDate}.docx`,
    );

    setExporting(false);
  };

  if (exporting) return <Loading />;

  return (
    <PageContainer>
      <PageHeader
        fluid={viewMode === 'canvas'}
        variant="dark"
        header={
          fetchedDocument?.name === 'Strategic Agility Plan'
            ? 'Strategic Drivers'
            : fetchedDocument?.name
        }
        actionComponent={
          <ActionBarContainer>
            {viewMode === 'canvas' && (
              <ExportButton
                variant="primary"
                onClick={printCanvasLayout}
                disabled={exporting}
              >
                <FontAwesomeIcon icon={faFileUpload} />
                Export DOCX
              </ExportButton>
            )}
            <SwitchTab
              viewMode={viewMode}
              onViewChange={(nextViewMode) => onChangeViewMode(nextViewMode)}
            />
          </ActionBarContainer>
        }
        breadcrumb={{ to: '/plan', label: 'Plan' }}
        documentType={getDocumentTypeFormatted(String(fetchedDocumentType))}
      />
      <PageControl
        fluid={viewMode === 'canvas'}
        variant="dark"
        rightSideComponent={
          <ActionBarContainer>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowDateRangePicker(false);
              }}
            >
              <StyledDateRangePickerDiv>
                <HistoryLabel>History</HistoryLabel>
                <CheckBoxWrapper>
                  <CheckBox
                    onClick={(e) =>
                      setShowDateRangePicker(
                        (e.target as HTMLInputElement).checked,
                      )
                    }
                    readOnly={true}
                    checked={showDateRangePicker}
                    id="checkbox"
                    type="checkbox"
                  />
                  <CheckBoxLabel htmlFor="checkbox" />
                </CheckBoxWrapper>
                {showDateRangePicker && (
                  <StyledDateRangePicker
                    ranges={selectionRange}
                    onChange={(item) =>
                      onComparisionDateChange(
                        (item as DateRangePickerChangeProps).selection,
                      )
                    }
                    editableDateInputs={true}
                    inputRanges={[]}
                    dateDisplayFormat={'dd/MM/yyyy'}
                  />
                )}
              </StyledDateRangePickerDiv>
            </OutsideClickHandler>
          </ActionBarContainer>
        }
      />
      <DragDropContext
        onDragEnd={(dragDropData: any) => handleTransitionEntries(dragDropData)}
      >
        {viewMode === 'canvas' &&
          !!fetchedDocument.views &&
          !!fetchedDocument.topics &&
          !!fetchedDocument.domains && (
            <CanvasContainer id="canvasLayout">
              <h3 id="documentName" style={{ display: 'none' }}>
                Document: {fetchedDocument.name}
              </h3>
              <h3 id="organizationName" style={{ display: 'none' }}>
                Organization: {currentMembership?.organization?.name}
              </h3>
              <h3 id="currentIteration" style={{ display: 'none' }}>
                Iteration: {currentIteration?.name}
              </h3>
              <h3 id="exportDate" style={{ display: 'none' }} />
              <GridVisualizer
                readOnly={
                  !(
                    (canContributeDocument(
                      currentMembership?.role?.grantedActions,
                      fetchedDocumentType,
                    ) ||
                      !!fetchedDocument.isUserTemplate) &&
                    isJust(currentIteration) &&
                    isNothing(currentIteration.endAt)
                  )
                }
              />
            </CanvasContainer>
          )}
        {viewMode === 'list' && (
          <ListContainer>
            {!!fetchedDocument.views &&
              !!fetchedDocument.topics &&
              !!fetchedDocument.domains && (
                <ListVisualizer
                  readOnly={
                    !(
                      (canContributeDocument(
                        currentMembership?.role?.grantedActions,
                        fetchedDocumentType,
                      ) ||
                        !!fetchedDocument.isUserTemplate) &&
                      isJust(currentIteration) &&
                      isNothing(currentIteration.endAt)
                    )
                  }
                />
              )}
          </ListContainer>
        )}
      </DragDropContext>
    </PageContainer>
  );
};

export default DocumentDetail;
