import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { COLORS } from '../common/color';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const AppLoading = () => {
  return (
    <Container>
      <ReactLoading type="spin" color={COLORS.loading} />
    </Container>
  );
};
export default AppLoading;
