import React from 'react';
import _ from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import TextField from '../TextField/TextField';
import StyledErrorMessage from '../ErrorMessage/ErrorMessage';
import FieldContainer from '../FieldContainer/FieldContainer';

type ContextTextFieldProps = {
  name: string;
  label: string;
  defaultValue?: string;
  type?: string;
  dataAttribute?: string;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  autoComplete?: string;
};

const ContextTextField: React.FC<ContextTextFieldProps> = ({
  name,
  label,
  defaultValue = '',
  type,
  dataAttribute,
  disabled = false,
  value,
  placeholder,
  autoComplete,
}) => {
  const { control, errors } = useFormContext();
  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={(controllerProps) => (
          <TextField
            label={label}
            type={type ? type : 'text'}
            onChange={controllerProps.onChange}
            value={value || controllerProps.value}
            error={_.get(errors, name)}
            dataAttribute={dataAttribute}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <StyledErrorMessage>
            {message.replace(name, label)}
          </StyledErrorMessage>
        )}
      />
    </FieldContainer>
  );
};

export default ContextTextField;
