import React from 'react';
import styled from 'styled-components';
import BaseSelect from 'react-select';
import Label from '../Label/Label';
import { SelectOptions, SelectOption, LabelProps } from '../../common/type';

type SelectProps = {
  label: string;
  defaultValue?: SelectOption;
  hasError: boolean;
  options: SelectOptions;
  onSelectionChange: (arg: string) => void;
  dataAttribute?: string;
  id?: string;
};

const StyledSelect = styled(BaseSelect)<LabelProps>`
  font-size: 14px;
  color: ${(props) => props.theme.colors.theme};
  font-family: 'Open Sans', sans-serif;

  .react-select__control {
    align-items: center;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    position: relative;
    box-sizing: border-box;
    padding: 12px 12px;
    outline: 0px !important;
    background-color: ${(props) =>
      props.hasError
        ? props.theme.colors.textfield.errorBackground
        : props.theme.colors.white};
    border-color: ${(props) =>
      props.hasError
        ? props.theme.colors.error
        : props.theme.colors.textfield.border};
    color: ${(props) => props.theme.colors.theme};

    :hover {
      border-color: ${(props) =>
        props.hasError
          ? props.theme.colors.error
          : props.theme.colors.textfield.border} !important;
    }
  }

  .react-select__control--is-focused {
    border-color: ${(props) =>
      props.theme.colors.textfield.focusBorder} !important;
    background-color: ${(props) =>
      props.theme.colors.textfield.focusBackground};

    :hover {
      border-color: ${(props) =>
      props.theme.colors.textfield.focusBorder} !important;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option {
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.theme};
    padding: 20px;

    :active {
      background-color: ${(props) =>
        props.theme.colors.textfield.focusBackground};
    }
  }

  .react-select__option--is-focused {
    background-color: ${(props) =>
      props.theme.colors.textfield.focusBackground};
  }

  .react-select__option--is-selected {
    background-color: ${(props) =>
      props.theme.colors.textfield.focusBackground};
  }
`;

const Select: React.FC<SelectProps> = ({
  options,
  onSelectionChange,
  defaultValue,
  label = '',
  hasError = false,
  dataAttribute,
  id,
}) => {
  const baseSelectOptions = options.map((opt) => ({
    value: opt.id,
    label: opt.name,
  }));

  return (
    <div>
      {label && <Label hasError={hasError} id={id}>{label}</Label>}
      <StyledSelect
        hasError={hasError}
        aria-labelledby={id}
        defaultValue={
          defaultValue
            ? { value: defaultValue?.id, label: defaultValue?.name }
            : {}
        }
        classNamePrefix="react-select"
        options={baseSelectOptions}
        onChange={(data: { value: string; label: string }) =>
          onSelectionChange(data.value)
        }
        data-cy={dataAttribute}
      />
    </div>
  );
};

export default Select;
