import styled from 'styled-components';

const LegendTitle = styled.h3`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  margin-bottom: 8px;
  box-sizing: border-box;
  height: 38px;
`;

export default LegendTitle;