import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ContextMultiSelect from '../../components/ContextMultiSelect/ContextMultiSelect';
import PageHeader from '../../components/PageHeader/PageHeader';
import Button from '../../components/Button/Button';
import Loading from '../../components/AppLoading';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import { OrganizationRole } from '../../common/type';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  ADMIN_PERMISSION,
  PLAN_PERMISSIONS,
  CONTRIBUTE_PERMISSIONS,
  OKR_PERMISSIONS,
} from '../../common/constant';
import { updateOrganizationRole } from '../../graphql/mutations';
import { getOrganizationRole } from '../../graphql/queries';
import { isJust, withDefault } from '../../maybe';
import { useBoundStore } from '../../states';

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  flex-grow: 1;
  padding-top: 30px;
  width: 100%;
  max-width: 880px;
  align-self: center;
`;

const MultiSelectContainer = styled.div`
  margin-bottom: 30px;
`;

const newRoleSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    grantedAdminActions: yup.array().of(yup.string()).required(),
    grantedPlanActions: yup.array().of(yup.string()).required(),
    grantedContributeActions: yup.array().of(yup.string()).required(),
    grantedOKRActions: yup.array().of(yup.string()).required(),
  })
  .test('grantedAdminActions', 'Atleast 1 permission is required', (data) => {
    if (!data) return false;
    if (
      !data.grantedAdminActions ||
      !data.grantedContributeActions ||
      !data.grantedPlanActions ||
      !data.grantedOKRActions
    )
      return false;

    return (
      [
        ...data.grantedAdminActions,
        ...data.grantedPlanActions,
        ...data.grantedContributeActions,
        ...data.grantedOKRActions,
      ].length > 0
    );
  });

type GetOrganizationRoleOutput = {
  getOrganizationRole: OrganizationRole;
};

type EditRoleFromOutput = {
  name: string;
  id: string;
  grantedAdminActions: string[];
  grantedPlanActions: string[];
  grantedContributeActions: string[];
  grantedOKRActions: string[];
  organizationID: string;
};

type EditRoleFormProps = {
  defaultValues: OrganizationRole | undefined;
  onUpdateRole: SubmitHandler<EditRoleFromOutput>;
};

const EditRoleForm = ({ defaultValues, onUpdateRole }: EditRoleFormProps) => {
  const updateRoleFormMethods = useForm({
    resolver: yupResolver(newRoleSchema),
    defaultValues,
  });

  const grantedActions = withDefault(
    defaultValues?.grantedActions || [],
    [],
  ).filter(isJust);

  return (
    <PageContainer onSubmit={updateRoleFormMethods.handleSubmit(onUpdateRole)}>
      <PageHeader
        variant="light"
        header="Edit organization role"
        breadcrumb={{
          to: '/organization/settings',
          label: 'Settings',
        }}
        actionComponent={
          <Button
            variant="primary"
            type="submit"
            disabled={updateRoleFormMethods.formState.isSubmitting}
          >
            Save changes
          </Button>
        }
      />
      <FormProvider {...updateRoleFormMethods}>
        <FormContainer>
          <ContextTextField name="name" label="Name" />
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedAdminActions"
              label="Admin Permisstions"
              options={ADMIN_PERMISSION}
              defaultValue={grantedActions.filter((action) =>
                ADMIN_PERMISSION.some(
                  (adminPermission) => adminPermission.id === action,
                ),
              )}
            />
          </MultiSelectContainer>
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedPlanActions"
              label="Plan Permissions"
              options={PLAN_PERMISSIONS}
              defaultValue={grantedActions.filter((action) =>
                PLAN_PERMISSIONS.some(
                  (planPermission) => planPermission.id === action,
                ),
              )}
            />
          </MultiSelectContainer>
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedContributeActions"
              label="Contribute Permissions"
              options={CONTRIBUTE_PERMISSIONS}
              defaultValue={grantedActions.filter((action) =>
                CONTRIBUTE_PERMISSIONS.some(
                  (contributePermission) => contributePermission.id === action,
                ),
              )}
            />
          </MultiSelectContainer>
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedOKRActions"
              label="OKR Permissions"
              options={OKR_PERMISSIONS}
              defaultValue={withDefault(defaultValues?.grantedActions, [])
                .filter(isJust)
                .filter((action) =>
                  OKR_PERMISSIONS.some(
                    (adminPermission) => adminPermission.id === action,
                  ),
                )}
            />
          </MultiSelectContainer>
        </FormContainer>
      </FormProvider>
    </PageContainer>
  );
};

const EditRole = () => {
  const history = useHistory();
  const alert = useAlert();
  const { id }: { id: string } = useParams();
  const [role, setRole] = useState<OrganizationRole>();
  const { currentMembership } = useBoundStore((state) => state.auth);
  const { initialize } = useBoundStore();

  useEffect(() => {
    const getRole = async () => {
      try {
        const queryResult = (await API.graphql(
          graphqlOperation(getOrganizationRole, {
            id,
          }),
        )) as GraphQLResult<GetOrganizationRoleOutput>;

        const existingRole = queryResult.data?.getOrganizationRole;
        if (existingRole) {
          setRole(existingRole);
        }
      } catch (error: any) {
        alert.show(error.errors[0]?.message || error.message);
      }
    };

    getRole();
  }, [alert, id]);

  const updateRole = async (data: EditRoleFromOutput) => {
    try {
      await API.graphql(
        graphqlOperation(updateOrganizationRole, {
          input: {
            name: data.name,
            grantedActions: [
              ...data.grantedAdminActions,
              ...data.grantedPlanActions,
              ...data.grantedContributeActions,
              ...data.grantedOKRActions,
            ],
            id,
          },
        }),
      );
      if (currentMembership?.role && id === currentMembership.role.id) {
        initialize();
      }
      history.replace('/organization/settings');
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }
  };

  if (!role) return <Loading />;

  return <EditRoleForm defaultValues={role} onUpdateRole={updateRole} />;
};

export default EditRole;
