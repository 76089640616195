import styled from 'styled-components';
import SlideDown from 'react-slidedown';
import { ReactComponent as AngleDown } from './AngleDown.svg';
import { ReactComponent as Plus } from './Plus.svg';
import { useState } from 'react';
import { Initiative } from '@propella/core';
import { isJust, withDefault } from '../../maybe';

export type ModalKeyResultProps = {
  keyResults?: Initiative['keyResults'];
};

const KeyResultContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  cursor: pointer;
  padding: 0 12px;

  border-radius: 4px;
  overflow: hidden;

  span {
    margin-left: 12px;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    color: #202020;
  }

  &:hover {
    background-color: #f7f7f7;
  }
`;

const KeyResultItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #202020;

  padding: 5px 44px;

  border-radius: 4px;
  overflow: hidden;

  margin-top: 8px;

  &:hover {
    background-color: #f7f7f7;
  }
`;

const KeyResultText = styled.span`
  margin-left: 12px;
`;

const AddNewKeyResultContainer = styled(KeyResultItemContainer)`
  color: #27c95e;
`;

const KeyResultList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalKeyResult = (props: ModalKeyResultProps) => {
  const { keyResults = [] } = props;

  return (
    <KeyResultContainer>
      <ListTitle>
        <AngleDown />
        <span>Key Results</span>
      </ListTitle>
      <KeyResultList>
        {withDefault(keyResults, [])
          .filter(isJust)
          .map((keyResult) => (
            <KeyResultItemContainer key={keyResult.id}>
              {keyResult?.name}
            </KeyResultItemContainer>
          ))}
      </KeyResultList>
      {/* <AddNewKeyResultContainer>
        <Plus />
        <KeyResultText>Add a new key result</KeyResultText>
      </AddNewKeyResultContainer> */}
    </KeyResultContainer>
  );
};

export default ModalKeyResult;
