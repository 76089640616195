import styled from 'styled-components';

const RectButton = styled.button<ButtonProps>`
  outline: none;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding: 10px 30px;
  border: 1px solid;
  border-radius: ${(props) => (props.isRound ? '40px' : '6px')};
  border-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.button.primaryRectBackground};
  background-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.white
      : props.theme.colors.button.primaryRectBackground};
  color: ${(props) =>
    props.variant === 'secondary' ? props.theme.colors.button.secondaryRect : props.theme.colors.white};
  :hover {
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectHoverBorder
        : props.theme.colors.button.primaryRectHoverBackground};
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.white
        : props.theme.colors.button.primaryRectHoverBackground};
    color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectHover
        : props.theme.colors.white};
  }
  :active {
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectActiveBorder
        : props.theme.colors.button.primaryRectActiveBackground};
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.white
        : props.theme.colors.button.primaryRectActiveBackground};
    color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectActive
        : props.theme.colors.white};
  }
  :disabled {
    background: ${(props) => props.theme.colors.button.disabledBackground};
    border-color: ${(props) => props.theme.colors.button.disabledBackground};
    cursor: not-allowed;
    text-decoration: none;
    color: ${(props) => props.theme.colors.button.disabled} !important;
  }
`;

export default RectButton;
