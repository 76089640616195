import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import BlockForm, { BlockFormOutput } from './BlockForm';

type PreviewStaticCell = Cell & {
  onDeleteBlock: (id: string) => void;
  onEditBlock: (data: BlockFormOutput) => void;
};

type PreviewContainerProps = {
  gridArea: string;
  backgroundColor?: string;
};

const PreviewContainer = styled.div<PreviewContainerProps>`
  grid-area: ${(props) => props.gridArea};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.listitem.border};
  color: #ffffff;
  display: grid;
  grid-template-areas:
    'topicName'
    'formControl';
  grid-template-rows: 1fr auto;
`;

const NameContainer = styled.div`
  grid-area: topicName / topicName / topicName / topicName;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
`;

const FormControlSection = styled.div`
  grid-area: formControl / formControl / formControl / formControl;
  display: flex;
  justify-content: space-between;

  padding: 10px;
`;

const MenuButton = styled.button`
  background-color: transparent;
  color: inherit;
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};
const SaveButton = styled(MenuButton)`
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const PreviewStaticCell: React.FC<PreviewStaticCell> = ({
  x,
  y,
  w,
  h,
  color,
  name,
  id,
  type,
  onDeleteBlock,
  onEditBlock,
}) => {
  const [editing, setEditing] = useState<boolean>(false);

  if (editing) {
    return (
      <PreviewContainer
        gridArea={[y, x, y + h, x + w].join(' / ')}
        className="cube"
      >
        <BlockForm
          onClearSelection={() => setEditing(false)}
          onFormSubmit={(formOutput) => {
            onEditBlock(formOutput);
            setEditing(false);
          }}
          defaultValue={{ name, color, type }}
        />
      </PreviewContainer>
    );
  }

  return (
    <PreviewContainer
      gridArea={[y, x, y + h, x + w].join(' / ')}
      className="cube"
      backgroundColor={color}
    >
      <NameContainer>{name}</NameContainer>
      <FormControlSection>
        <SaveButton onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon={faEdit} size="1x" />
        </SaveButton>
        <CancelButton onClick={() => id && onDeleteBlock(id)}>
          <FontAwesomeIcon icon={faTrash} size="1x" />
        </CancelButton>
      </FormControlSection>
    </PreviewContainer>
  );
};

export default PreviewStaticCell;
