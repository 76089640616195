import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';

type FilterButtonProps = {
  isActive: boolean;
};

const FilterButton = styled.button<FilterButtonProps>`
  background-color: ${(props) => (props.isActive ? '#021740' : 'transparent')};
  color: ${(props) => (props.isActive ? '#ffffff' : '#021740')};
  border-radius: 16px;
  border: 0;
  outline: none;
  height: 32px;
  width: 100%;

  font-family: 'Open Sans', sans-serif;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export type SwitchBarOption = {
  value: string;
  label: string;
};

export type SwitchBarProps = {
  options: SwitchBarOption[];
  selectedOption?: SwitchBarOption;
  onSelect: (opt: SwitchBarOption) => void;
};

const SwitchBar: React.FC<SwitchBarProps> = ({
  options = [],
  selectedOption = {},
  onSelect,
}) => {
  return (
    <Container>
      <Row>
        {options.map((opt, optIndex) => (
          <Col col={3} lg={2} key={String(optIndex)}>
            <FilterButton
              isActive={opt.value === selectedOption.value}
              type="button"
              onClick={() => onSelect(opt)}
            >
              {opt.label}
            </FilterButton>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SwitchBar;
