import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Auth } from 'aws-amplify';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import awsExports from './awsExports.json';

const awsconfig = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: awsExports.UserPoolId,
  aws_user_pools_web_client_id: awsExports.AppClientIDWeb,
  oauth: {},
  aws_appsync_graphqlEndpoint: awsExports.GraphQlApiUrl,
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

Amplify.configure({
  ...awsconfig,
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
