import {
  faCalendar,
  faTags,
  faCheckCircle,
  faAngleDown,
  faAngleUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as TagIcon } from './Tag.svg';
import { ReactComponent as CalendarIcon } from './Calendar.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import KeyResult from './KeyResult';
import { Handler } from './Handler';
import Tag, { TagProps } from './Tag';
import SlideDown from 'react-slidedown';
import { useAlert } from 'react-alert';
import { API, graphqlOperation } from 'aws-amplify';
import 'react-slidedown/lib/slidedown.css';
import { Initiative, User } from '@propella/core';
import NewKeyResult from './NewKeyResult';
import { isJust, withDefault } from '../../maybe';
import { Draggable } from 'react-beautiful-dnd';
import { useBoundStore } from '../../states';
import { format, formatDistanceToNow } from 'date-fns';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Contributors from '../Contributors/Contributors';
import { getUsers } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { UsersChangeType } from '../../common/type';

type ClearButtonProps = {
  hoverColor: string;
};

const InitiativeContainer = styled.div`
  border-radius: 6px;
  overflow: hidden;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const InitiativeCard = styled.div`
  border-radius: 6px;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);

`;

const InitiativeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  margin-right: 12px;
`;

const InitName = styled.h4`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  line-break: anywhere;
`;

const InitButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DueDateGroup = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #aeafaf;
`;

const RealDueDate = styled.span<Pick<InitiativeProps, 'color'>>`
  color: ${(props) => props.color}; ;
`;

const KeyResultList = styled.div`
  padding: 12px 16px;
`;

const ClearButton = styled.button<ClearButtonProps>`
  color: #aeafaf;
  border: 0;
  background-color: transparent;

  &:hover {
    color: ${(props) => props.hoverColor};
    cursor: pointer;
  }
`;

export type KeyResult = {
  name: string;
};

export type InitiativeProps = {
  id: Initiative['id'];
  name: Initiative['name'];
  dueDate?: Initiative['dueDate'];
  color: string;
  keyResults?: Initiative['keyResults'];
  tags?: Initiative['tags'];
  className?: string;
  index?: number;
  isWithinKanbanBoard?: boolean;
  assigneeIDs?: string[];
};

const InitNameInput = styled.textarea`
  border: 0;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  width: 100%;
  resize: none;
`;

const StyledKeyResult = styled(KeyResult)`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const HandlerContainer = styled.div`
  min-width: 34px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const InitCardContent = styled.div`
  padding: 12px 0;
  padding-right: 12px;
  width: 100%;
  cursor: text;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-right: 10px;
`;

const ContributorsContainer = styled.div`
  margin-left: 7px;
`;

const ProgressIndicatorContainer = styled.div`
  border: 0.6px solid #aeafaf;
  border-radius: 12px;
  height: 16px;
  display: flex;
  align-items: center;
  padding: 3px 6px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 14px;
`;

const StyledTag = styled(Tag)`
  margin: 5px;
`;

type getUsersOutput = {
  getUsers: User[];
};

const InitiativeComponent = (props: InitiativeProps) => {
  const {
    name,
    color = '#aeafaf',
    keyResults = [],
    tags = [],
    id,
    className,
    index,
    dueDate,
    isWithinKanbanBoard,
    assigneeIDs,
  } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isHoverHandler, setIsHoverHandler] = useState<boolean>(false);
  const alert = useAlert();
  const [deleteInitiative, openEntryModal] = useBoundStore((state) => [
    state.deleteInitiative,
    state.openEntryModal,
  ]);
  const [listUsers, setListUsers] = useState<UsersChangeType[]>([]);
  const [listAssignees, setListAssignees] = useState<string[]>([]);
  const [showKeyResults, setShowKeyResults] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onDeleteInitiative = async (id: string) => {
    try {
      await deleteInitiative(id);
      alert.success('Delete initiative successfully');
    } catch (e) {
      alert.error('There are some errors occur when deleting the initiative.');
    } finally {
      setIsOpenModal(false);
    }
  };

  const getAssignedUsers = async (assigneeIDs: string[]) => {
    if (assigneeIDs.length === 0) return [];
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(getUsers, {
          input: {
            userIDs: assigneeIDs,
          },
        }),
      )) as GraphQLResult<getUsersOutput>;
      setListAssignees(queryResult.data?.getUsers.map((user) => user.id) || []);
      setListUsers(
        queryResult.data?.getUsers.map((user) => ({
          id: user.id,
          fullName: isJust(user.fullName) ? user.fullName : '',
        })) || [],
      );
    } catch (error: any) {
      alert.error(error.message);
    }
  };

  useEffect(() => {
    getAssignedUsers(assigneeIDs || []);
  }, []);

  const handleInput = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + 'px';
    }
  };
  useEffect(() => {
    handleInput();
  }, []);

  return (
    <Draggable draggableId={id} index={index || 0}>
      {(provided, snapshot) => (
        <InitiativeContainer
          className={className}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <InitiativeCard>
            <HandlerContainer
              {...provided.dragHandleProps}
              onMouseOver={() => setIsHoverHandler(true)}
              onMouseLeave={() => setIsHoverHandler(false)}
            >
              <Handler color={isHoverHandler ? '#60BFBF' : ''} />
            </HandlerContainer>
            <InitCardContent>
              <ContentRow>
                <ContentColumn
                  onClick={() => openEntryModal(id, color)}
                  style={{ cursor: 'pointer', width: '100%' }}
                >
                  <InitName>{name}</InitName>
                </ContentColumn>
                {!isWithinKanbanBoard && (
                  <ContentColumn>
                    <ClearButton hoverColor="#d93b3b">
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => {
                          setIsOpenModal(true);
                        }}
                      />
                    </ClearButton>
                  </ContentColumn>
                )}
                {isWithinKanbanBoard && (
                  <ContributorsContainer>
                    <Contributors
                      listContributors={listAssignees}
                      usersChange={listUsers}
                      limit={2}
                    />
                  </ContributorsContainer>
                )}
              </ContentRow>
              <ContentRow onClick={() => openEntryModal(id, color)}>
                <ContentColumn style={{ cursor: 'pointer', width: '100%' }}>
                  <IconContainer>
                    <CalendarIcon />
                  </IconContainer>
                  <DueDateGroup>
                    {isJust(dueDate) ? (
                      <>
                        <RealDueDate color={color}>
                          {format(new Date(dueDate), 'EEE dd MMM')}
                        </RealDueDate>{' '}
                        - {formatDistanceToNow(new Date(dueDate))}
                      </>
                    ) : (
                      <RealDueDate color={color}>No due date</RealDueDate>
                    )}
                  </DueDateGroup>
                </ContentColumn>
              </ContentRow>
              <ContentRow>
                <ContentColumn
                  onClick={() => openEntryModal(id, color)}
                  style={{ cursor: 'pointer' }}
                >
                  <IconContainer>
                    <TagIcon />
                  </IconContainer>
                  <ContentColumn style={{ flexWrap: 'wrap' }}>
                    {withDefault(tags, [])
                      .filter(isJust)
                      .map((tag) => (
                        <StyledTag
                          key={tag.name}
                          name={tag.name}
                          color={tag.color || color}
                        />
                      ))}
                  </ContentColumn>
                </ContentColumn>
                <ContentColumn
                  onClick={() => setShowKeyResults(!showKeyResults)}
                  style={{ cursor: 'pointer' }}
                >
                  <ClearButton
                    style={{ cursor: 'pointer' }}
                    hoverColor="#60BFBF"
                  >
                    {!showKeyResults ? (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        style={{ fontSize: '15px', marginLeft: '10px' }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAngleUp}
                        style={{ fontSize: '15px', marginLeft: '10px' }}
                      />
                    )}
                  </ClearButton>
                </ContentColumn>
              </ContentRow>
            </InitCardContent>
          </InitiativeCard>
          <SlideDown style={{ transitionDuration: '.3s' }}>
            {showKeyResults && (
              <KeyResultList>
                {withDefault(keyResults, [])
                  .filter(isJust)
                  .map((keyResult) => (
                    <StyledKeyResult
                      key={keyResult.id}
                      name={keyResult.name}
                      id={keyResult.id}
                      initiativeID={id}
                      isWithinKanbanBoard={isWithinKanbanBoard}
                    />
                  ))}
                <NewKeyResult
                  initiativeId={id}
                  isWithinKanbanBoard={isWithinKanbanBoard}
                />
              </KeyResultList>
            )}
          </SlideDown>
          <ConfirmationModal
            label="Do you want to delete this initiative?"
            isOpen={isOpenModal}
            onConfirm={() => onDeleteInitiative(id)}
            onCancel={() => setIsOpenModal(false)}
          />
        </InitiativeContainer>
      )}
    </Draggable>
  );
};

export default InitiativeComponent;
