import { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import Button from '../../components/Button/Button';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import { ReactComponent as PropellaIcon } from '../SignIn/PropellaIcon.svg';
import { ReactComponent as PropellaName } from '../SignIn/PropellaName.svg';
import { useBoundStore } from '../../states';

const StyledPropellaIcon = styled(PropellaIcon)`
  width: 100%;
  width: 290px;
  margin-bottom: 40px;
  .cls-1 {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const StyledPropellaName = styled(PropellaName)`
  width: 400px;
  margin-bottom: 20px;
  .cls-1 {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const SloganContainer = styled.div`
  font-size: 38px;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 100px;
`;

const FormErrorMessage = styled.div`
  border: 1px solid ${(props) => props.theme.colors.globalError};
  background-color: ${(props) => props.theme.colors.globalError};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.white};
  padding: 20px;
  box-sizing: border-box;
  width: 360px;
  margin-bottom: 40px;
`;

const PageContainer = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-areas: 'banner form';
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  background-color: ${(props) => props.theme.colors.white};
`;

const BannerContainer = styled.div`
  grid-area: banner / banner / banner / banner;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.signin.container};
`;

const FormContainer = styled.form`
  grid-area: form / form / form / form;
  margin: 0 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
`;

const Header = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 50px;
  color: ${(props) => props.theme.colors.theme};
  font-size: 24px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.theme};
`;

const ActionContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: reverse-row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const signUpSchema = yup.object().shape({
  email: yup.string().email().required(),
  fullName: yup.string().required(),
  password: yup.string().required(),
});

const SignUp = () => {
  const {
    invitationCode,
    email,
    fullName,
  }: { invitationCode: string; email: string; fullName: string } = useParams();
  const { profile } = useBoundStore((state) => state.auth);
  const history = useHistory();

  const [signUpErrorMessage, setSignUpErrorMessage] = useState<string | null>();

  const signUpFormMethods = useForm({
    resolver: yupResolver(signUpSchema),
  });

  const signUp = async (data: {
    email: string;
    password: string;
    fullName: string;
  }) => {
    setSignUpErrorMessage(null);
    try {
      await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          'custom:full_name': data.fullName,
          ...(invitationCode
            ? { 'custom:invitation_code': invitationCode }
            : {}),
        },
      });
      history.replace(`/confirm?email=${data.email}`);
    } catch (error: any) {
      if (error.message) {
        setSignUpErrorMessage(error.message);
      } else {
        setSignUpErrorMessage('Something went wrong. Please try again later.');
      }
    }
  };

  if (profile) {
    if (invitationCode) {
      history.replace(`/invitation/accept/${invitationCode}`);
    } else {
      history.replace('/plan');
    }
  }

  return (
    <PageContainer>
      <BannerContainer>
        <StyledPropellaIcon />
        <StyledPropellaName />
        <SloganContainer>Beyond your business</SloganContainer>
      </BannerContainer>
      <FormProvider {...signUpFormMethods}>
        <FormContainer onSubmit={signUpFormMethods.handleSubmit(signUp)}>
          <Header>{invitationCode ? 'You are invited!' : 'Sign up'}</Header>
          {signUpErrorMessage && (
            <FormErrorMessage>{signUpErrorMessage}</FormErrorMessage>
          )}
          {!!email && (
            <ContextTextField
              name="email"
              label="Email address"
              defaultValue={email}
              disabled={true}
              dataAttribute="signup-email-text-field"
            />
          )}
          {!email && (
            <ContextTextField
              name="email"
              label="Email address"
              dataAttribute="signup-email-text-field"
            />
          )}
          {!!fullName && (
            <ContextTextField
              name="fullName"
              label="Full name"
              defaultValue={fullName}
              disabled={true}
              dataAttribute="signup-name-text-field"
            />
          )}
          {!fullName && (
            <ContextTextField
              name="fullName"
              label="Full name"
              dataAttribute="signup-name-text-field"
            />
          )}
          <ContextTextField
            name="password"
            label="Password"
            type="password"
            dataAttribute="signup-password-text-field"
          />
          <ActionContainer>
            <StyledLink
              to={invitationCode ? `/signin/${invitationCode}` : '/signin'}
              data-cy="signin-link"
            >
              Already have an account?
            </StyledLink>
            <Button
              type="submit"
              variant="primary"
              data-cy="signup-button"
              disabled={signUpFormMethods.formState.isSubmitting}
            >
              Sign up
            </Button>
          </ActionContainer>
        </FormContainer>
      </FormProvider>
    </PageContainer>
  );
};

export default SignUp;
