/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentIteration = /* GraphQL */ `
  query GetCurrentIteration {
    getCurrentIteration {
      id
      name
      tasks {
        id
        name
        type
        resourceID
        isUserTemplate
        stage
        finalized
        documentType
        contributors {
          fullName
        }
      }
      organizationID
      startAt
      startedBy
      endAt
      endedBy
      createdAt
      updatedAt
      otherIterations {
        id
        name
        endAt
      }
    }
  }
`;
export const getIteration = /* GraphQL */ `
  query GetIteration($id: ID!) {
    getIteration(id: $id) {
      id
      name
      tasks {
        id
        name
        type
        resourceID
        isUserTemplate
        stage
        contributors {
          fullName
        }
      }
      organizationID
      startAt
      startedBy
      endAt
      endedBy
      createdAt
      updatedAt
      otherIterations {
        id
        name
        endAt
      }
    }
  }
`;

export const compare = /* GraphQL */ `
  query Compare($input: ComparisionInput) {
    compare(input: $input) {
      document {
        id
        name
        template
        organizationID
        domains {
          key
          name
          bgColor
          fgColor
          topicNameColor
          ordering
        }
        topics {
          key
          name
          domain
          description
          nameBgColor
          descriptionBgColor
          selectors
        }
        views {
          list {
            rows {
              topicKey
              key
              header
              items {
                topicKey
              }
            }
          }
          grid {
            cells {
              x
              y
              w
              h
              header
              headerColor
              topicKey
              hideHeader
              bgColor
              fgColor
            }
          }
        }
      }
      changes {
        type
        entryID
        name
        topicKey
        editorID
        ownerID
        from
        to
      }
      users {
        id
        fullName
      }
    }
  }
`;

export const compareDocumentV2 = /* GraphQL */ `
  query CompareDocumentV2($input: ComparisionDocumentV2Input) {
    compareDocumentV2(input: $input) {
      document {
        id
        name
        organizationID
        domains {
          key
          name
          bgColor
          fgColor
          topicNameColor
          ordering
        }
        topics {
          key
          name
          domain
          description
          nameBgColor
          descriptionBgColor
          selectors
        }
        views {
          list {
            rows {
              topicKey
              key
              header
              items {
                topicKey
              }
            }
          }
          grid {
            cells {
              x
              y
              w
              h
              header
              headerColor
              topicKey
              hideHeader
              bgColor
              fgColor
            }
          }
        }
      }
      changes {
        type
        entryID
        name
        topicKey
        editorID
        ownerID
        from
        to
        entryType
      }
      users {
        id
        fullName
      }
    }
  }
`;

export const getOrganizationRole = /* GraphQL */ `
  query GetOrganizationRole($id: ID!) {
    getOrganizationRole(id: $id) {
      id
      name
      grantedActions
      organizationID
      accessPermissions
      editPermissions
    }
  }
`;
export const listOrganizationRoles = /* GraphQL */ `
  query ListOrganizationRoles(
    $filter: ModelOrganizationRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      name
      template
      domains {
        key
        name
        bgColor
        fgColor
        topicNameColor
        ordering
      }
      topics {
        key
        name
        domain
        description
        nameBgColor
        descriptionBgColor
        entryOrder
        selectors
      }
      entryRank
      organizationID
      objectives {
        nextToken
      }
      entries {
        nextToken
      }
      accessPermissions
      editPermissions
      contributions {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntry = /* GraphQL */ `
  query GetEntry($id: ID!) {
    getEntry(id: $id) {
      id
      name
      overview
      tags
      meta
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      lastEditorID
      activities {
        nextToken
      }
      accessPermissions
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const listEntrys = /* GraphQL */ `
  query ListEntrys(
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        overview
        tags
        meta
        documentID
        lastEditorID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      entryID
      entry {
        id
        name
        overview
        tags
        meta
        documentID
        lastEditorID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      actorID
      organizationID
      action
      field
      fieldDisplayName
      from {
        displayValue
        value
      }
      to {
        displayValue
        value
      }
      datetime
      accessPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      actor {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        documentID
        entryID
        actorID
        organizationID
        action
        field
        fieldDisplayName
        datetime
        accessPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      roles {
        items {
          id
          name
        }
      }
      memberships {
        items {
          id
          user {
            fullName
            email
          }
          role {
            id
            name
          }
        }
        nextToken
      }
      avatarUrl
      invitations {
        items {
          id
          roleID
          email
          fullName
          role {
            name
          }
        }
        nextToken
      }
      colorsPalette
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fullName
      email
      avatarUrl
      memberships {
        items {
          organization {
            id
            name
            avatarUrl
            colorsPalette
            currentIterationID
            enabledModules
            roles {
              items {
                id
                grantedActions
              }
            }
          }
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const getUsers = /* GraphQL */ `
  query GetUsers($input: GetUsersInput) {
    getUsers(input: $input) {
      id
      fullName
    }
  }
`;

export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      email
      fullName
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      organizationID
      status
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        fullName
        roleID
        organizationID
        status
        owner
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invitationByOrganization = /* GraphQL */ `
  query InvitationByOrganization(
    $organizationID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationByOrganization(
      organizationID: $organizationID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        fullName
        roleID
        organizationID
        status
        owner
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      organizationID
      userID
      invitationID
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships(
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        userID
        invitationID
        roleID
        owner
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const membershipByUserQuery = /* GraphQL */ `
  query MembershipByUser($userID: ID, $organizationID: String) {
    membershipByUser(userID: $userID, organizationID: $organizationID) {
      items {
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const documentScopeQuery = /* GraphQL */ `
  query DocumentScope($id: ID!) {
    documentScope(id: $id) {
      iterations {
        id
        name
        organizationID
        endAt
      }
      currentIterationID
      document {
        id
        name
        userTemplate
        template
        domains {
          key
          name
          bgColor
          fgColor
          topicNameColor
          ordering
        }
        topics {
          key
          name
          domain
          description
          nameBgColor
          descriptionBgColor
          selectors
        }
        entryRank
        organizationID
        entries {
          items {
            id
            name
            tags
          }
          nextToken
        }
        views {
          list {
            rows {
              topicKey
              key
              header
              subHeader
              items {
                topicKey
              }
            }
          }
          grid {
            cells {
              x
              y
              w
              h
              header
              headerColor
              headerAlign
              topicKey
              hideHeader
              bgColor
              fgColor
            }
          }
        }
        accessPermissions
        editPermissions
        contributions {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const getOrganizationForSetting = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      avatarUrl
      owner
      createdAt
      updatedAt
      colorsPalette
      activeTemplates {
        id
        order
      }
      roles {
        items {
          id
          name
          grantedActions
        }
      }
      enabledModules
      invitations {
        items {
          id
          roleID
          email
          fullName
          role {
            name
          }
        }
        nextToken
      }
      memberships {
        items {
          id
          user {
            fullName
            email
          }
          role {
            id
            name
          }
        }
        nextToken
      }
    }
  }
`;

export const getOrgDocumentTemplate = /* GraphQL */ `
  query GetOrgDocumentTemplate {
    getOrgDocumentTemplates {
      templates {
        createdAt
        documentTemplateID
        domains {
          bgColor
          fgColor
          key
          name
          ordering
          topicNameColor
        }
        id
        isPublished
        name
        stage
        topics {
          description
          descriptionBgColor
          domain
          entryOrder
          key
          name
          nameBgColor
          selectors
        }
        updatedAt
        version
      }
    }
  }
`;

export const getDocumentTemplates = /* GraphQL */ `
  query GetDocumentTemplates {
    getDocumentTemplates {
      templates {
        id
        name
        domains {
          key
          name
          bgColor
          fgColor
          topicNameColor
          ordering
        }
        topics {
          key
          name
          domain
          description
          nameBgColor
          descriptionBgColor
          selectors
        }
        createdAt
        updatedAt
        version
        isPublished
      }
    }
  }
`;

export const getObjectiveScope = /* GraphQL */ `
  query GetObjectiveScope {
    getObjectiveScope {
      objectives {
        id
        name
        expectedEndDate
        color
        createdAt
        keyResults {
          id
          name
          progress
          createdAt
        }
        subObjectives {
          id
          name
          createdAt
          keyResults {
            id
            name
            progress
            createdAt
          }
          assignees {
            id
            fullName
            avatarUrl
          }
        }
        assignees {
          id
          fullName
          avatarUrl
        }
      }
      assignableUsers {
        id
        fullName
        email
        avatarUrl
      }
    }
  }
`;

export const getTemplateById = /* GraphQL */ `
  query GetDocumentTemplate($input: GetDocumentTemplateInput) {
    getDocumentTemplate(input: $input) {
      id
      isPublished
      name
      stage
      designerMeta {
        blocks {
          color
          description
          h
          id
          name
          originatedFrom {
            color
            description
            h
            id
            name
            type
            w
            x
            y
          }
          type
          w
          x
          y
        }
        numberOfColumn
        numberOfRow
      }
      version
    }
  }
`;

export const cusGetOrganizations = /* GraphQL */ `
  query GetOrganizations {
    cusGetOrganizations {
      id
      name
      owner
      createdAt
    }
  }
`;

export const getAllOrgInitiatives = /* GraphQL */ `
  query GetAllOrgInitiatives {
    getAllOrgInitiatives {
      id
      name
      dueDate
      assigneeIDs
      description
      strategicObjective
      successFactors
      ohdpRank
      ohdpHorizon
      comments
      status
      investments
      tags {
        id
        name
        color
      }
      topicKey
      keyResults {
        id
        name
      }
    }
  }
`;

export const getDocumentV2 = /* GraphQL */ `
  query GetDocumentV2($id: ID!) {
    getDocument(id: $id) {
      id
      oneHundredDayPlan {
        assignableUsers {
          avatarUrl
          email
          fullName
          id
        }
        selectedInitiativeIds
      }
      entryRank
      name
      type
      entries {
        ... on TextEntry {
          __typename
          id
          name
          tags {
            id
            name
            color
          }
          topicKey
        }
        ... on Initiative {
          __typename
          id
          name
          dueDate
          assigneeIDs
          description
          strategicObjective
          successFactors
          ohdpRank
          ohdpHorizon
          status
          comments
          investments
          tags {
            id
            name
            color
          }
          topicKey
          keyResults {
            id
            name
          }
        }
      }
    }
  }
`;

export const getDocuments = /* GraphQL */ `
  query GetDocuments {
    getDocuments {
      id
      name
      type
    }
  }
`;

export const getMasterList = /* GraphQL */ `
  query GetMasterList {
    getMasterlistScope {
      id
      organizationID
      prioritizedInitiativeIds
    }
  }
`;
