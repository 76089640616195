import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { getMonth, getYear, formatDistanceToNow, parse } from 'date-fns';
import { ReactComponent as PrevMonth } from './prevMonth.svg';
import { ReactComponent as NextMonth } from './nextMonth.svg';

import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef, useState } from 'react';
import {
  ParameterLabel,
  ParamterContainer,
} from '../ModalParameter/ModalParameter';
import { ReactComponent as AngleDown } from '../ModalParameter/AngleDown.svg';
import { ReactComponent as Calendar } from './Calendar.svg';

const DatePickerContainer = styled.div<Pick<DatePickerPopoverProps, 'color'>>`
  background: #ffffff;
  border: 1px solid #e1e4e6;
  border-radius: 20px;

  box-sizing: border-box;
  padding: 24px 30px;

  display: flex;
  flex-direction: column;

  .react-datepicker__header {
    background-color: #ffffff !important;
  }

  .react-datepicker__day-name {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #858585;

    width: 36px;
    height: 36px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day {
    width: 36px;
    height: 36px;

    background: #ffffff;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #222222;

    &:hover {
      background: #f7f7f7;
    }
  }

  .react-datepicker__day--selected {
    background: ${(props) => props.color || '#aeafaf'} !important;
    border-radius: 27px;

    color: #ffffff;

    &:hover {
      background: ${(props) => props.color || '#aeafaf'} !important;
    }
  }
`;

const DPHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: #ffffff;
`;

const MonthYear = styled.h3`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  /* identical to box height, or 222% */

  color: #202020;
`;

const DPMonthButton = styled.button`
  width: 24px;
  height: 24px;
  left: 0px;
  top: 8px;

  background: #f7f7f7;
  border-radius: 4px;
  box-shadow: none;
  border: 0;
  outline: 0;

  cursor: pointer;
`;

const SelectedDate = styled.div<Pick<DatePickerPopoverProps, 'color'>>`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: right;

  color: ${(props) => props.color || '#aeafaf'};
`;

const DayLeft = styled.span`
  color: #aeafaf;
  font-size: 10px;
`;

const ClearDueDateButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid #d93b3b;
  border-radius: 6px;

  background-color: #ffffff;

  height: 36px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  color: #d93b3b;

  margin-top: 24px;

  cursor: pointer;
`;

export type DatePickerPopoverProps = {
  color?: string;
  selectedDate?: Date | undefined;
  onDateChange: (date: Date | undefined) => void;
};

const DatePickerPopover = (props: DatePickerPopoverProps) => {
  const { color, selectedDate, onDateChange } = props;
  const [startDate, setStartDate] = useState<Date | undefined>();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <ParamterContainer onClick={onClick} ref={ref}>
      <ParameterLabel>
        <Calendar />
        <SelectedDate color={color}>
          <span>{value || 'Due Date'}</span>
          <DayLeft>
            {value &&
              formatDistanceToNow(parse(value, 'EEE dd MMM', new Date()))}
          </DayLeft>
        </SelectedDate>
      </ParameterLabel>
      <AngleDown />
    </ParamterContainer>
  ));

  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => date && onDateChange(date)}
      dateFormat="EEE dd MMM"
      customInput={<CustomInput />}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
        <DPHeader>
          <DPMonthButton onClick={decreaseMonth}>
            <PrevMonth />
          </DPMonthButton>
          <MonthYear>
            {months[getMonth(date)]} {getYear(date)}
          </MonthYear>
          <DPMonthButton onClick={increaseMonth}>
            <NextMonth />
          </DPMonthButton>
        </DPHeader>
      )}
      calendarContainer={({ children }) => (
        <DatePickerContainer className="react-datepicker" color={color}>
          {children}
          <ClearDueDateButton onClick={() => onDateChange(undefined)}>
            Clear due date
          </ClearDueDateButton>
        </DatePickerContainer>
      )}
    />
  );
};

export default DatePickerPopover;
