import styled from "styled-components";

const TopicDescription = styled.div`
  grid-area: topicDescription / topicDescription / topicDescription / topicDescription;
  padding: 15px;
  padding-top: 0;
  color: ${(props) => props.theme.colors.topic.additional};
  font-size: 12px;
`;

export default TopicDescription;
