import React, { useEffect, useState, useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PageHeader from '../../components/PageHeader/PageHeader';
import Button from '../../components/Button/Button';
import ContextSelect from '../../components/ContextSelect/ContextSelect';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import Loading from '../../components/AppLoading';
import { Organization, OrganizationRole } from '../../common/type';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createInvitation } from '../../graphql/mutations';
import { getOrganization } from '../../graphql/queries';
import { isJust, withDefault } from '../../maybe';
import { useBoundStore } from '../../states';
import _ from 'lodash';

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  flex-grow: 1;
  padding-top: 30px;
  width: 100%;
  max-width: 880px;
  align-self: center;
`;

const inviteMemberSchema = yup.object().shape({
  email: yup.string().email().required(),
  fullName: yup.string().required(),
  roleID: yup.string().required(),
});

type GetOrganizationOutput = {
  getOrganization: Organization;
};
const InviteMember = () => {
  const { currentMembership } = useBoundStore((state) => state.auth);

  const organizationID = withDefault(
    currentMembership?.organization?.id || '',
    '',
  );
  const history = useHistory();
  const alert = useAlert();
  const [organizationRoles, setOrganizationRoles] = useState<
    OrganizationRole[]
  >([]);
  const [membershipEmails, setMembershipEmails] = useState<string[]>([]);

  const fetchOrganization = useCallback(async () => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(getOrganization, { id: organizationID }),
      )) as GraphQLResult<GetOrganizationOutput>;

      if (!!queryResult.data?.getOrganization?.roles?.items) {
        setOrganizationRoles(queryResult.data.getOrganization?.roles?.items);
      }
      if (!!queryResult.data?.getOrganization?.memberships?.items) {
        setMembershipEmails(
          withDefault(queryResult.data?.getOrganization?.memberships?.items, [])
            .map((item) => withDefault(item.user?.email || '', ''))
            .filter((item) => isJust(item) && item !== ''),
        );
      }
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }
  }, [alert, organizationID]);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  const inviteMemberFormMethods = useForm({
    resolver: yupResolver(inviteMemberSchema),
  });

  const contributorRole = organizationRoles.find((role) => role.name === "Contributor");

  const inviteMember = async (data: {
    email: string;
    fullName: string;
    roleID: string;
  }) => {
    if (membershipEmails.find((item) => item === data.email)) {
      alert.show(`User ${data.email} is already in the organization.`);
    } else {
      try {
        await API.graphql(
          graphqlOperation(createInvitation, {
            input: {
              email: data.email,
              fullName: data.fullName,
              roleID: data.roleID,
            },
          }),
        );
        history.replace('/organization/settings');
      } catch (error: any) {
        alert.show(error.errors[0]?.message || error.message);
      }
    }
  };
  if (organizationRoles.length === 0) return <Loading />;

  return (
    <FormProvider {...inviteMemberFormMethods}>
      <PageContainer
        onSubmit={inviteMemberFormMethods.handleSubmit(inviteMember)}
      >
        <PageHeader
          variant="light"
          header="Invite a new member"
          breadcrumb={{
            to: '/organization/settings',
            label: 'Settings',
          }}
          actionComponent={
            <Button
              variant="primary"
              type="submit"
              disabled={inviteMemberFormMethods.formState.isSubmitting}
            >
              Invite
            </Button>
          }
        />
        <FormContainer>
          <ContextTextField name="email" label="Email address" />
          <ContextTextField name="fullName" label="Full name" />
          <ContextSelect
            name="roleID"
            label="Role"
            options={organizationRoles}
            defaultValue={contributorRole ? {id: contributorRole.id, name: contributorRole.name} : undefined}
          />
        </FormContainer>
      </PageContainer>
    </FormProvider>
  );
};

export default InviteMember;
