import React from 'react';
import { ReactComponent as Artboard } from './Artboard.svg';
import { ReactComponent as Partners } from './Partners.svg';
import { ReactComponent as Cost } from './Cost.svg';
import { ReactComponent as Customer } from './Customer.svg';
import { ReactComponent as Resources } from './Resources.svg';
import { ReactComponent as Revenue } from './Revenue.svg';
import { ReactComponent as Value } from './Value.svg';
import { ReactComponent as Mountains } from './Mountains.svg';
import { ReactComponent as Channel } from './Channel.svg';

type TopicIconsInput = {
  topicName: string
}
const TopicIcons: React.FC<TopicIconsInput> = ({ topicName }) => {
  const ListIcons = [
    { name: 'Key Partner', icon: <Partners /> },
    { name: 'Key Activities', icon: <Mountains /> },
    { name: 'Key Resources', icon: <Resources /> },
    { name: 'Value Propositions', icon: <Value /> },
    { name: 'Customer Relationships', icon: <Customer /> },
    { name: 'Channels', icon: <Channel /> },
    { name: 'Customer Segments', icon: <Artboard /> },
    { name: 'Cost Structure', icon: <Cost /> },
    { name: 'Revenue Streams', icon: <Revenue /> },
  ];

  const icon = ListIcons.find((item) => item.name === topicName);
  if (icon) return icon.icon;
  return null;
};

export default TopicIcons;
