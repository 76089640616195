import { v4 } from 'uuid';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Selecto, { OnSelect } from 'react-selecto';
import { API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import PlaceholderCell from './PlaceholderCell';
import SelectedCell, { NewBlockFormOutput } from './SelectedCell';
import { isJust, withDefault } from '../../maybe';
import PreviewDataCell from './PreviewDataCell';
import PreviewStaticCell from './PreviewStaticCell';
import RectButton from '../../components/Button/RectButton';
import Loading from '../../components/AppLoading';
import {
  createDocumentTemplate,
  updateDocumentTemplate,
} from '../../graphql/mutations';
import { useHistory, useParams } from 'react-router-dom';
import { getTemplateById } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { DocumentTemplateOutput } from '@propella/core';
import { useAlert } from 'react-alert';
import Modal from 'styled-react-modal';

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  margin: 20px;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-area: content / content / content / content;
`;

const DesignerHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid #a3b4c4;
`;

const TemplateNameInput = styled.input`
  font-size: 24px;
  color: #0b1b3c;
  letter-spacing: 0;

  width: 100%;

  border: 0;

  &:focus {
    outline: none;
  }
`;

const DesignerContentContainer = styled.div`
  display: grid;
  grid-template-areas:
    'content insertRight'
    'insertBelow empty';
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;

  margin: 10px;
`;

const AddButton = styled.button`
  border: 2px solid #60bfbf;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f4fbfb;

  color: #60bfbf;

  cursor: pointer;
`;

const AddColumnButton = styled(AddButton)`
  grid-area: insertRight / insertRight / insertRight / insertRight;
  margin: 20px 0;
`;

const AddRowButton = styled(AddButton)`
  grid-area: insertBelow / insertBelow / insertBelow / insertBelow;
  margin: 0 20px;
`;

const DesignerButtonGroup = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  ${RectButton} {
    margin-left: 10px;
  }
`;

const StyledModal = styled.div`
  width: 40rem;
  max-height: 60%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 220px;
  margin-bottom: 150px;
  padding-bottom: 30px;
  border-radius: 15px;
`;

const ConfirmButtonsContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
`;

const StyledRectButton = styled(RectButton)`
  color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  background-color: ${(props) => props.theme.colors.white};
  border-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  :hover {
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
    color: ${(props) => props.theme.colors.white};
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
  }
`;

const TemplateDesigner = () => {
  const history = useHistory();
  const alert = useAlert();
  const { id: templateId }: { id: string | undefined } = useParams();
  const [fetching, setFetching] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [column, setColumn] = useState<number>(3);
  const [row, setRow] = useState<number>(3);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [selectedCells, setSelectedCells] = useState<Cell[]>([]);
  const [blocks, setBlocks] = useState<Cell[]>([]);
  const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] =
    useState<DocumentTemplateOutput>();

  const gridCells = useMemo<Cell[]>(() => {
    let horizonalPos = Array.from(Array(row).keys());
    let verticalPos = Array.from(Array(column).keys());

    const baseGrid = _.flatMap(horizonalPos, (y) => {
      return verticalPos.map((x) => ({
        x: x + 1,
        y: y + 1,
        w: 1,
        h: 1,
        type: 'placeholder',
      }));
    });

    const cellsUsedByBlocks = _.flatten(
      blocks.map((block) => block.originatedFrom),
    );

    const usedCells = [...cellsUsedByBlocks, ...selectedCells].filter(isJust);

    const usedCellsByObject = _.keyBy(usedCells, (c) => c.x + '-' + c.y);

    const availableCells = baseGrid.filter(
      (cell) => !usedCellsByObject[`${cell.x}-${cell.y}`],
    );

    const cellsWithBlocks = [...availableCells, ...blocks];

    if (selectedCells.length > 0) {
      const selectedX = selectedCells.map((cell) => cell.x);
      const selectedY = selectedCells.map((cell) => cell.y);

      const groupedX = _.min(selectedX) || 0;
      const groupedY = _.min(selectedY) || 0;

      const groupedHeight = (_.max(selectedY) || 0) + 1 - groupedY;
      const groupedWidth = (_.max(selectedX) || 0) + 1 - groupedX;

      return [
        ...cellsWithBlocks,
        {
          x: groupedX,
          y: groupedY,
          h: groupedHeight,
          w: groupedWidth,
          type: 'selected',
        },
      ];
    }

    return cellsWithBlocks;
  }, [row, column, selectedCells]);

  useEffect(() => {
    const fetchTemplate = async () => {
      const getTemplateResponse = (await API.graphql(
        graphqlOperation(getTemplateById, {
          input: {
            id: templateId,
          },
        }),
      )) as GraphQLResult<{ getDocumentTemplate: DocumentTemplateOutput }>;

      const existingTemplate = getTemplateResponse.data?.getDocumentTemplate;
      setCurrentTemplate(existingTemplate);

      if (existingTemplate) {
        setIsNew(false);
        setName(withDefault(existingTemplate.name, ''));
        const designerMeta = existingTemplate.designerMeta;

        if (designerMeta) {
          const validBlocks = withDefault(designerMeta.blocks, []).filter(
            isJust,
          );

          setRow(withDefault(designerMeta.numberOfRow, 3));
          setColumn(withDefault(designerMeta.numberOfColumn, 3));
          setBlocks(validBlocks as any);
          setSelectedCells([]);
        }
      }

      setFetching(false);
    };

    fetchTemplate();
  }, []);

  useEffect(() => {
    console.log(currentTemplate);
  }, [currentTemplate]);

  const onSelectEnd = (data: OnSelect): any => {
    if (selectedCells.length > 0) return;
    const { selected } = data;

    const nonPlaceholderSelected = selected
      .map((s) => s.getAttribute('data-placeholder'))
      .filter((isPlaceholder) => isPlaceholder !== 'true');

    if (nonPlaceholderSelected.length > 0) return;

    const selectedCellPositions = selected.map((s) => {
      const posX = s.getAttribute('data-posx');
      const posY = s.getAttribute('data-posy');

      return {
        x: _.parseInt(posX || '0'),
        y: _.parseInt(posY || '0'),
        w: 1,
        h: 1,
        type: 'selected',
      };
    });

    setSelectedCells(selectedCellPositions);
  };

  const clearSelection = () => {
    setSelectedCells([]);
  };

  const createNewBlock = (data: NewBlockFormOutput, cell: Cell) => {
    const newBlock: Cell = {
      ...cell,
      ...data,
      originatedFrom: Array.from(selectedCells),
      id: v4(),
    };

    setBlocks([...blocks, newBlock]);

    setSelectedCells([]);
  };

  const editBlock = (id: string, data: NewBlockFormOutput, cell: Cell) => {
    const updatedBlock: Cell = {
      ...cell,
      ...data,
      id,
    };

    setBlocks([...blocks.filter((block) => block.id !== id), updatedBlock]);
    setSelectedCells([]);
  };

  const removeBlock = (id: string) => {
    const removedBlocks = blocks.filter((block) => block.id !== id);
    setBlocks(removedBlocks);
    setSelectedCells([]);
  };

  const renderCell = (cell: Cell, index: number) => {
    if (cell.type === 'static') {
      return (
        <PreviewStaticCell
          {...cell}
          onDeleteBlock={(blockId) => removeBlock(blockId)}
          onEditBlock={(updatedBlock) =>
            editBlock(cell.id || '', updatedBlock, cell)
          }
        />
      );
    }

    if (cell.type === 'data') {
      return (
        <PreviewDataCell
          {...cell}
          onDeleteBlock={(blockId) => removeBlock(blockId)}
          onEditBlock={(updatedBlock) =>
            editBlock(cell.id || '', updatedBlock, cell)
          }
        />
      );
    }

    if (cell.type === 'selected') {
      return (
        <SelectedCell
          {...cell}
          gridArea={[cell.y, cell.x, cell.y + cell.h, cell.x + cell.w].join(
            ' / ',
          )}
          onClearSelection={() => clearSelection()}
          onCreateNewBlock={(data) => createNewBlock(data, cell)}
        />
      );
    }

    if (cell.type === 'placeholder') {
      return (
        <PlaceholderCell
          key={index}
          x={cell.x}
          y={cell.y}
          gridArea={[cell.y, cell.x, cell.y + cell.h, cell.x + cell.w].join(
            ' / ',
          )}
        />
      );
    }
  };

  const saveChanges = async () => {
    if (submiting) return;

    setSubmiting(true);
    if (blocks.length === 0) return;

    const dataBlocks = blocks.filter((block) => block.type === 'data');

    const domains = dataBlocks.map((block) => ({
      key: block.id,
      name: block.name,
      bgColor: block.color,
      fgColor: block.color,
      topicNameColor: '#ffffff',
      ordering: 0,
    }));

    const topics = dataBlocks.map((block) => ({
      key: block.id,
      name: block.name,
      domain: block.id,
      description: block.description || '',
      nameBgColor: '',
      descriptionBgColor: '',
      entryOrder: [],
      selectors: [block.id],
    }));

    const cellInputs = blocks.map((block) => {
      if (block.type === 'data') {
        return {
          x: block.x,
          y: block.y,
          w: block.w,
          h: block.h,
          header: block.name,
          topicKey: block.id,
          hideHeader: false,
          bgColor: block.color,
          fgColor: block.color,
        };
      }

      return {
        x: block.x,
        y: block.y,
        w: block.w,
        h: block.h,
        header: block.name,
        hideHeader: false,
        bgColor: block.color,
        fgColor: block.color,
      };
    });

    const listInputs = dataBlocks.map((block) => ({
      topicKey: block.id,
      key: block.id,
      header: block.name,
    }));

    const newTemplateArgs = {
      id: templateId,
      name,
      stage: '',
      domains,
      topics,
      viewConfig: {
        grid: {
          cells: cellInputs,
        },
        list: {
          rows: listInputs,
        },
      },
      designerMeta: {
        numberOfRow: row,
        numberOfColumn: column,
        blocks,
      },
    };
    if (isNew) {
      try {
        await API.graphql(
          graphqlOperation(createDocumentTemplate, {
            input: newTemplateArgs,
          }),
        );
        alert.success('Template changes are saved.');
      } catch (error: any) {
        if (error.errors[0].message) {
          alert.error(error.errors[0].message);
        } else {
          alert.error('Something went wrong. Please try again later.');
        }
      }

      setIsNew(false);
    } else {
      try {
        await API.graphql(
          graphqlOperation(updateDocumentTemplate, {
            input: {
              ...newTemplateArgs,
              id: templateId,
            },
          }),
        );
        alert.success('Template changes are saved.');
      } catch (error: any) {
        if (error.errors[0].message) {
          alert.error(error.errors[0].message);
        } else {
          alert.error('Something went wrong. Please try again later.');
        }
      }
    }

    setSubmiting(false);
  };

  if (fetching) return <Loading />;

  return (
    <>
      <DesignerHeader>
        <TemplateNameInput
          type="text"
          placeholder="Template Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <DesignerButtonGroup>
          <RectButton onClick={() => saveChanges()} disabled={submiting}>
            Save
          </RectButton>
          <RectButton
            variant="secondary"
            onClick={() => history.push('/profile')}
          >
            Close
          </RectButton>
        </DesignerButtonGroup>
      </DesignerHeader>
      <DesignerContentContainer>
        <AddColumnButton onClick={() => setColumn(column + 1)}>
          <FontAwesomeIcon icon={faAngleRight} size="1x" />
        </AddColumnButton>
        <AddRowButton onClick={() => setRow(row + 1)}>
          <FontAwesomeIcon icon={faAngleDown} size="1x" />
        </AddRowButton>
        <Selecto
          dragContainer={'.elements'}
          selectableTargets={['.cube']}
          hitRate={10}
          selectByClick={true}
          selectFromInside={true}
          ratio={0}
          onSelectEnd={onSelectEnd}
        ></Selecto>
        <GridContainer className="elements selecto-area">
          {gridCells.map((grid, index) => renderCell(grid, index))}
        </GridContainer>
      </DesignerContentContainer>
    </>
  );
};

export default TemplateDesigner;
