import { Extension } from '@tiptap/core';

interface ShortcutsOptions {
  saveFunction: (data: any) => Promise<void>;
}

export const Shortcuts = Extension.create<ShortcutsOptions>({
  name: 'keyboardShortcuts',
  addKeyboardShortcuts() {

    return {
      'Mod-Shift-7': () =>
        this.editor.chain().focus().toggleOrderedList().run(),
      'Mod-Shift-8': () => this.editor.chain().focus().toggleBulletList().run(),
    };
  },
});
