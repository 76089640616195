import React from 'react';
import styled from 'styled-components';

export type TableTagProps = {
  color?: string;
};

const TagContainer = styled.div<TableTagProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;

  box-sizing: border-box;
  height: 30px;

  background-color: ${({ color }) => color || '#D6D5D5'};
  border-radius: 4px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  cursor: pointer;
`;

export default TagContainer;
