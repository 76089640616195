import { useEffect, useReducer, useState, createContext } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { API, graphqlOperation } from 'aws-amplify';
import {
  Objective as GraphQLObjective,
  Iteration as GraphQLIteration,
  KeyResult as GraphQLKeyResult,
  User as GraphQLUser,
} from '@propella/core';
import { Observable, ZenObservable } from 'zen-observable-ts';
import { getCurrentIteration, getObjectiveScope } from '../../graphql/queries';
import {
  onKeyResultUpdate,
  onObjectiveUpdate,
  onObjectiveDelete,
  onKeyResultDelete,
} from '../../graphql/subscriptions';
import { reducer, ObjectiveScope } from './objectiveScope';
import PageHeader from '../../components/PageHeader/PageHeader';
import NewObjective from '../../components/NewObjective/NewObjective';
import Objective from '../../components/Objective/Objective';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import Loading from '../../components/AppLoading';
import { useAlert } from 'react-alert';
import { useBoundStore } from '../../states';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
  height: 100%;
`;

type SubscribeEvent = {
  value: {
    data: {
      onObjectiveUpdate?: GraphQLObjective;
      onObjectiveDelete?: GraphQLObjective;
    };
  };
};

type KeyResultSubcribeEvent = {
  value: {
    data: {
      onKeyResultUpdate: GraphQLKeyResult;
      onKeyResultDelete?: GraphQLKeyResult;
    };
  };
};

export const ObjectiveStore = createContext<[ObjectiveScope, Function]>([
  { objectives: [], assignableUsers: [] },
  () => {},
]);

const CurrentObjectives = () => {
  const alert = useAlert();
  const [fetching, setFetching] = useState<boolean>(true);
  const { currentMembership } = useBoundStore((state) => state.auth);
  const [objectiveScope, dispatch] = useReducer(reducer, {
    objectives: [],
    assignableUsers: [],
  }) as [ObjectiveScope, Function];

  useEffect(() => {
    const fetchObjectiveScope = async () => {
      setFetching(true);

      try {
        const iterationQueryResult = (await API.graphql(
          graphqlOperation(getCurrentIteration),
        )) as GraphQLResult<{
          getCurrentIteration: GraphQLIteration;
        }>;

        const objectiveScopeQueryResult = (await API.graphql(
          graphqlOperation(getObjectiveScope),
        )) as GraphQLResult<{
          getObjectiveScope: {
            objectives: GraphQLObjective[];
            assignableUsers: GraphQLUser[];
          };
        }>;

        if (!!iterationQueryResult.data) {
          dispatch({
            type: 'switchIteration',
            payload: iterationQueryResult.data.getCurrentIteration,
          });
        }

        if (!!objectiveScopeQueryResult.data) {
          dispatch({
            type: 'init',
            payload: objectiveScopeQueryResult.data.getObjectiveScope,
          });
        }
      } catch (error: any) {
        alert.show(error.errors[0]?.message || error.message);
      }

      setFetching(false);
    };

    fetchObjectiveScope();
  }, [alert]);

  useEffect(() => {
    if (currentMembership?.organization?.id) {
      const objectiveUpdateObservable = API.graphql(
        graphqlOperation(onObjectiveUpdate, {
          organizationID: currentMembership?.organization?.id,
        }),
      );

      let objectiveUpdateListener: ZenObservable.Subscription;
      if (objectiveUpdateObservable instanceof Observable) {
        objectiveUpdateListener = objectiveUpdateObservable.subscribe({
          next: (entryData: SubscribeEvent) => {
            const updatedObjective = entryData.value.data.onObjectiveUpdate;

            if (updatedObjective) {
              dispatch({
                type: 'applyObjectiveUpdates',
                payload: updatedObjective,
              });
            }
          },
        });
      }

      const keyResultUpdateObservable = API.graphql(
        graphqlOperation(onKeyResultUpdate, {
          organizationID: currentMembership?.organization?.id,
        }),
      );

      let keyResultUpdateListener: ZenObservable.Subscription;

      if (keyResultUpdateObservable instanceof Observable) {
        keyResultUpdateListener = keyResultUpdateObservable.subscribe({
          next: (entryData: KeyResultSubcribeEvent) => {
            const updatedKeyResult = entryData.value.data.onKeyResultUpdate;

            if (updatedKeyResult) {
              dispatch({
                type: 'applyKeyResultUpdates',
                payload: updatedKeyResult,
              });
            }
          },
        });
      }

      const objectiveDeleteObservable = API.graphql(
        graphqlOperation(onObjectiveDelete, {
          organizationID: currentMembership?.organization?.id,
        }),
      );

      let objectiveDeleteListener: ZenObservable.Subscription;

      if (objectiveDeleteObservable instanceof Observable) {
        objectiveDeleteListener = objectiveDeleteObservable.subscribe({
          next: (entryData: SubscribeEvent) => {
            const deletedObjective = entryData.value.data.onObjectiveDelete;

            if (deletedObjective) {
              if (deletedObjective.parentObjectiveID) {
                dispatch({
                  type: 'deleteSubObjective',
                  payload: deletedObjective,
                  meta: {
                    parentObjectiveID: deletedObjective.parentObjectiveID,
                  },
                });
              } else {
                dispatch({
                  type: 'deleteObjective',
                  payload: deletedObjective,
                });
              }
            }
          },
        });
      }

      const keyResultDeleteObservable = API.graphql(
        graphqlOperation(onKeyResultDelete, {
          organizationID: currentMembership?.organization?.id,
        }),
      );

      let keyResultDeleteListener: ZenObservable.Subscription;

      if (keyResultDeleteObservable instanceof Observable) {
        keyResultDeleteListener = keyResultDeleteObservable.subscribe({
          next: (entryData: KeyResultSubcribeEvent) => {
            const deletedKeyResult = entryData.value.data.onKeyResultDelete;

            if (deletedKeyResult) {
              if (!deletedKeyResult.parentObjectiveID) {
                dispatch({
                  type: 'deleteKeyResult',
                  payload: deletedKeyResult,
                });
              } else {
                dispatch({
                  type: 'deleteSubKeyResult',
                  payload: deletedKeyResult,
                  meta: {
                    parentObjectiveID: deletedKeyResult.parentObjectiveID,
                  },
                });
              }
            }
          },
        });
      }
      return () => {
        objectiveUpdateListener.unsubscribe();
        keyResultUpdateListener.unsubscribe();
        objectiveDeleteListener.unsubscribe();
        keyResultDeleteListener.unsubscribe();
      };
    }
  }, [currentMembership?.organization?.id]);

  if (fetching) return <Loading />;

  return (
    <ObjectiveStore.Provider value={[objectiveScope, dispatch]}>
      <PageContainer>
        <PageHeader header="OKR / KPI" variant="light" />
        <Container>
          <Row>
            <Col col={12}>
              <NewObjective />
            </Col>
          </Row>
          {_.orderBy(
            objectiveScope.objectives,
            (obj) => (obj.createdAt ? new Date(obj.createdAt) : new Date()),
            ['desc'],
          ).map((obj) => (
            <Row key={obj.id}>
              <Col col={12}>
                <Objective {...obj} />
              </Col>
            </Row>
          ))}
        </Container>
      </PageContainer>
    </ObjectiveStore.Provider>
  );
};

export default CurrentObjectives;
