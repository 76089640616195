import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Maybe, Task } from '@propella/core';
import { ReactComponent as ItemIcon } from './ItemIcon.svg';
import { ReactComponent as ContributorIcon } from './Contributor.svg';
import { ReactComponent as ClosedIcon } from './ClosedItemIcon.svg';
import { isJust, withDefault } from '../../maybe';
import { AuthState, Dictionary } from '../../common/type';
import { canContributeDocument, isAllowed } from '../../utils';
import { ACTION } from '../../common/constant';

export type PlanItemProps = {
  task: Task;
  isActive: boolean;
  currentRole: AuthState['currentRole'];
  iterationId: string;
};

const ItemContainer = styled.div<Partial<PlanItemProps>>`
  height: 110px;
  box-sizing: border-box;

  background-color: ${(props) => (props.isActive ? '#fbfdff' : '#FCFEFF')};
  border: 1px solid ${(props) => (props.isActive ? '#eaeff4' : '#FE7777')};
  border-left: 13px solid ${(props) => (props.isActive ? '#7ecbcb' : '#FF6868')};
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 33px;

  a {
    text-decoration: none;
  }
`;

const PlanItemSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 25px;
  display: flex;
  align-items: center;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemName = styled.p`
  font-size: 16px;
  color: #021740;
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 5px;
`;

const ItemType = styled.p`
  font-size: 12px;
  color: #021740;
  letter-spacing: 0;
  margin: 0;
  font-style: italic;
`

const ItemStatus = styled.p<Partial<PlanItemProps>>`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => (props.isActive ? '#8ad0d0' : '#FE7777')};
  letter-spacing: 0;
  margin: 0;
`;

const StageName = styled.p`
  font-size: 14px;
  color: #021740;
  letter-spacing: 0;

  margin: 0;
`;

const ContributorCounter = styled.div<Partial<PlanItemProps>>`
  color: ${(props) => (props.isActive ? '#8ad0d0' : '#FE7777')};
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  margin-right: 30px;
  margin-left: 40px;
  width: 50px;

  text-align: right;

  span {
    margin-right: 5px;
  }

  svg {
    fill: ${(props) => (props.isActive ? '#8ad0d0' : '#FE7777')};
  }
`;

const ContributeButton = styled.button<Partial<PlanItemProps>>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 110px;
  background-color: transparent;

  color: #60bfbf;
  font-size: 14px;

  box-sizing: border-box;
  border: 1px solid ${(props) => (props.isActive ? '#60bfbf' : '#021740')};
  border-radius: 20px;

  cursor: pointer;

  span {
    color: ${(props) => (props.isActive ? '#8ad0d0' : '#021740')};
    letter-spacing: 0;
    text-decoration: none;
  }
`;

const NoViewButton = styled.div`
  width: 110px;
`;

const stageLabel: Dictionary<String> = {
  factGathering: 'Fact Gathering',
  insights: 'Insights',
};

const PlanItem: React.FC<PlanItemProps> = ({
  isActive = false,
  task,
  currentRole,
  iterationId,
}) => {
  const taskName = withDefault(task.name, '');
  const taskStage = withDefault(task.stage, '');
  const itemContributors = withDefault(task.contributors, []).filter(isJust);
  const taskDocumentType = withDefault(task.documentType, '');

  const canViewDocument = (
    grantedActions: Maybe<Maybe<string>[]> | undefined,
  ) => isAllowed(grantedActions, [ACTION.READ_DOCUMENT, ACTION.ADMINISTER]);

  const isViewable = canViewDocument(currentRole?.grantedActions);

  const getDocumentTypeFormatted = (documentType: string) => {
    if (documentType === 'businessModelCanvas') {
      return 'Business Model Canvas';
    } else if (documentType === 'competitiveLandscape') {
      return 'Competitive Landscape';
    } else if (documentType === 'strategicInsights') {
      return 'Strategic Insights';
    } else if (documentType === 'strategicAgilityPlan') {
      return 'Strategic Drivers';
    } else if (documentType === 'onePageStrategicAgilityPlan') {
      return 'One Page Strategic Agility Plan';
    }
  };

  const isEditable =
    (canContributeDocument(
      currentRole?.grantedActions,
      !!taskDocumentType ? taskDocumentType : taskName,
    ) ||
      task.isUserTemplate) &&
    isActive;

  return (
    <ItemContainer isActive={isActive}>
      <PlanItemSection>
        <IconContainer>
          {!isActive ? <ClosedIcon /> : <ItemIcon />}
        </IconContainer>
        <NameContainer>
          <ItemName>{taskName}</ItemName>
          <ItemType>{getDocumentTypeFormatted(taskDocumentType) || taskName}</ItemType>
        </NameContainer>
      </PlanItemSection>
      <PlanItemSection>
        <ContributorCounter isActive={isActive}>
          <span>{itemContributors.length}</span>
          <ContributorIcon />
        </ContributorCounter>
        {isViewable ? (
          <Link
            to={{
              pathname: `/${task.type}s/${task.resourceID}`,
              state: { iterationId },
            }}
          >
            <ContributeButton type="button" isActive={isActive}>
              {!isEditable ? <span>View</span> : <span>Contribute</span>}
            </ContributeButton>
          </Link>
        ) : (
          <NoViewButton />
        )}
      </PlanItemSection>
    </ItemContainer>
  );
};

export default PlanItem;
