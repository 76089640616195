import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

type PageHeaderProps = {
  variant?: string;
  header: string | undefined;
  actionComponent?: React.ReactElement;
  breadcrumbLabel?: string;
  breadcrumbTo?: string;
  breadcrumb?: { to: string; label: string };
  fluid?: boolean;
  documentType?: string;
  className?: string;
};

type HeaderContainerProps = {
  variant?: string;
};

type ContentContainerProps = {
  fluid: boolean;
};
const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-family: 'Open Sans', sans-serif;
  background-color: ${(props) =>
    props.variant === 'dark'
      ? props.theme.colors.pageHeader.darkBg
      : props.theme.colors.white};
  color: ${(props) =>
    props.variant === 'dark'
      ? props.theme.colors.white
      : props.theme.colors.pageHeader.text};
  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.variant === 'dark'
      ? 'none'
      : `1px solid ${props.theme.colors.pageHeader.border}`};
`;

const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.fluid ? '0 30px' : '0')};
  height: 100%;
`;

const Header = styled.div`
  font-size: 28px;
  font-weight: 900;
`;

const DocumentType = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
  font-style: italic;
`;

const Breadcrumb = styled.div`
  span {
    color: inherit;
    font-size: 11px;
    font-weight: lighter;
  }
  font-size: 13px;
`;
const StyledLink = styled(Link)`
  span {
    text-decoration: underline;
  }
  color: inherit;
`;

const IconContainer = styled.span`
  padding-right: 5px;
  max-width: 20px;
  font-size: 17px;
  color: inherit;
`;
const NameContainer = styled.div``;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;

  ${Button} {
    border-radius: 0 5px 5px 0;
  }

  .react-select__control {
    border-radius: 5px 0 0 5px;
    height: 41px;

    &:last-child {
    }
  }
`;

const PageHeader: React.FC<PageHeaderProps> = ({
  variant,
  header,
  actionComponent,
  breadcrumbLabel,
  breadcrumbTo,
  breadcrumb,
  fluid = false,
  documentType,
  className
}) => {
  return (
    <HeaderContainer variant={variant} className={className}>
      <Container>
        <Row alignItems="center" style={{ height: '100%' }}>
          <Col col={12}>
            <ContentContainer fluid={fluid}>
              <NameContainer>
                {breadcrumb?.to && (
                  <Breadcrumb>
                    <StyledLink to={breadcrumb.to}>
                      <IconContainer>
                        <FontAwesomeIcon
                          size="lg"
                          icon={faAngleLeft}
                        ></FontAwesomeIcon>
                      </IconContainer>
                      {breadcrumb.label}
                    </StyledLink>
                  </Breadcrumb>
                )}
                <Header>{header}</Header>
                {documentType && (
                  <DocumentType>{documentType}</DocumentType>
                )}
              </NameContainer>
              <ActionContainer>{actionComponent}</ActionContainer>
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </HeaderContainer>
  );
};

export default PageHeader;
