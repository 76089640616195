import { useState } from 'react';
import { Popover, PopoverProps } from 'react-tiny-popover';
import styled from 'styled-components';
import { ReactComponent as AngleDown } from './AngleDown.svg';

export type ModalParameterProps = {
  children?: React.ReactNode;
  popoverContent: PopoverProps['content'];
};

export const ParamterContainer = styled.div`
  width: 100%;
  min-height: 68px;

  box-sizing: border-box;

  padding: 26px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`;

export const ParameterLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #aeafaf;

  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
`;

const ModalParameter = (props: ModalParameterProps) => {
  const { children, popoverContent } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top', 'bottom', 'right', 'left']}
      content={popoverContent}
      containerStyle={{
        zIndex: '40',
      }}
    >
      <ParamterContainer onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <ParameterLabel>{children}</ParameterLabel>
        <AngleDown />
      </ParamterContainer>
    </Popover>
  );
};

export default ModalParameter;
