import create from 'zustand';
import { createAuthSlice } from './auth';
import { createCustomerSupportSlice } from './customerSupport';
import { createDocumentSlice } from './document';
import { createDocumentV2Slice } from './documentV2';
import { StoreInterface } from './interfaces';
import { createOrganizationSlice } from './organization';
import { createPlanSlice } from './plan';
import { createMasterListSlice } from './masterList';

export const useBoundStore = create<StoreInterface>()((...a) => ({
  ...createCustomerSupportSlice(...a),
  ...createAuthSlice(...a),
  ...createDocumentSlice(...a),
  ...createOrganizationSlice(...a),
  ...createDocumentV2Slice(...a),
  ...createPlanSlice(...a),
  ...createMasterListSlice(...a),
}));
