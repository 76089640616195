import { Tag } from '@propella/core';
import styled from 'styled-components';
import { withDefault } from '../../maybe';

const TagContainer = styled.div<Pick<TagProps, 'color'>>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 10px;
  gap: 10px;

  border-radius: 2px;
  box-sizing: border-box;
  

  background: ${(props) => correctOpacity(props.color || '#aeafaf')};
  color: ${(props) => props.color};

  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const TagName = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 1;
`;

export type TagProps = {
  name: Tag['name'];
  color?: Tag['color'];
  className?: string;
};

function correctOpacity(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.15)`;
}

const TagComponent = (props: TagProps) => {
  const { name, color, className } = props;
  return (
    <TagContainer color={withDefault(color, '#aeafaf')} className={className}>
      <TagName>{name}</TagName>
    </TagContainer>
  );
};

export default TagComponent;
