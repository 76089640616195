import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import AppLoading from '../../components/AppLoading';
import Box from '../../components/Box';
import RectButton from '../../components/Button/RectButton';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Table } from '../../components/Table';
import TableBody from '../../components/Table/TableBody';
import TableCell from '../../components/Table/TableCell';
import TableColumn from '../../components/Table/TableColumn';
import TableHeader from '../../components/Table/TableHeader';
import TableRow from '../../components/Table/TableRow';
import { useBoundStore } from '../../states';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
  height: 100%;
`;

export const OrganizationList = () => {
  const [fetchOrganizations] = useBoundStore(
    (state) => [state.fetchOrganizations],
    () => false,
  );

  const [isReady, organizations] = useBoundStore((state) => [
    state.customerSupport.isReady,
    state.customerSupport.organizations,
  ]);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  if (!isReady) return <AppLoading />;

  return (
    <PageContainer>
      <PageHeader header="Customer Support Dashboard" variant="light" />
      <Container>
        <Row>
          <Col col={12}>
            <Box header="Organizations">
              <Table>
                <TableHeader>
                  <tr>
                    <TableColumn>Organization Name</TableColumn>
                    <TableColumn>Owner</TableColumn>
                    <TableColumn />
                  </tr>
                </TableHeader>
                <TableBody>
                  {organizations.map((organization) => (
                    <TableRow key={organization.id}>
                      <TableCell>{organization.name}</TableCell>
                      <TableCell>{organization.owner}</TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/switch?organizationID=${organization.id}&continue=/organization/settings`}
                        >
                          <RectButton variant="secondary">Switch</RectButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default OrganizationList;
