import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import DraggableItem from './DraggableItem';

type DraggableListProps = {
  sortedEntryIds?: string[];
  readOnly?: boolean;
  droppableId: string;
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
};

export type StyledListProps = {
  isDraggingOver: boolean;
}

const StyledList = styled.div<StyledListProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  height: 100%;
  & p {
    margin: 0;
  }

  background-color: ${(props) => props.isDraggingOver ? props.theme.colors.textfield.focusBackground : "#eff2f4" };
  min-width: 100%;
  border-radius: ${(props) => props.isDraggingOver ? "8px" : "0" };
  border: ${(props) => props.isDraggingOver ? "1px dashed #90d0d0" : "none" };

`;

const DraggableList: React.FC<DraggableListProps> = ({
  droppableId,
  readOnly,
  sortedEntryIds = [],
  provided,
  snapshot,
}) => {
  return (
    <StyledList {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
      {_.uniq(sortedEntryIds).map((entryId, index) => (
        <DraggableItem
          entryId={entryId}
          itemIndex={index}
          key={entryId}
          readOnly={readOnly}
          isDraggingOver={snapshot.isDraggingOver}
        />
      ))}
      {provided.placeholder}
    </StyledList>
  );
};

export default DraggableList;
