import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';

type BaseSwitchButtonProps = {
  selected: boolean
}

type SwitchTabInput = {
  viewMode: string;
  onViewChange: (arg: string) => void;
};

const BaseSwitchButton = styled.button<BaseSwitchButtonProps>`
  padding: 13px 26px;
  font-size: 14px;
  outline: none;
  background-color: ${(props) => {
    if (props.selected) return props.theme.colors.white;
    return props.theme.colors.switchTab;
  }};
  color: ${(props) => {
    if (props.selected) return props.theme.colors.switchTab;
    return props.theme.colors.white;
  }};
  border: 1px solid ${(props) => props.theme.colors.white};
`;
const CanvasSwitchButton = styled(BaseSwitchButton)`
  border-radius: 0 21px 21px 0;
  border-left: ${(props) =>
    props.selected ? 'normal' : 'none'};
`;
const ListSwitchButton = styled(BaseSwitchButton)`
    border-radius: 21px 0 0 21px;
    border-right: ${(props) =>
    props.selected ? 'normal' : 'none'};
`;
const IconWrapper = styled.span`
  margin-right: 10px;
`;

const SwitchTab: React.FC<SwitchTabInput> = ({
  viewMode,
  onViewChange,
}) => {
  const changeView = (nextView: string) => {
    onViewChange(nextView);
  };

  return (
    <>
      <ListSwitchButton
        selected={viewMode === 'list'}
        onClick={() => changeView('list')}
      >
        <IconWrapper>
          <FontAwesomeIcon icon={faBars} />
        </IconWrapper>
        List View
      </ListSwitchButton>
      <CanvasSwitchButton
        selected={viewMode === 'canvas'}
        onClick={() => changeView('canvas')}
      >
        <IconWrapper>
          <FontAwesomeIcon icon={faTh} />
        </IconWrapper>
        Canvas View
      </CanvasSwitchButton>
    </>
  );
};
export default SwitchTab;
