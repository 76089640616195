import React, { useState, useEffect } from 'react';
import { useForm, Controller, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Draggable,
  DraggableProvidedDraggableProps,
} from 'react-beautiful-dnd';
import 'react-popper-tooltip/dist/styles.css';
import ReactLoading from 'react-loading';
import { useAlert } from 'react-alert';
import BaseListItem from '../BaseListItem/BaseListItem';
import { COLORS } from '../../common/color';
import { Entry } from '../../common/type';
import { Handler } from '../Initiative/Handler';
import EntryEditor from '../EntryEditor/EntryEditor';
import EntryViewer from '../EntryViewer/EntryViewer';
import OutsideClickHandler from 'react-outside-click-handler';
import { useBoundStore } from '../../states';
import { isJust, withDefault } from '../../maybe';
import _ from 'lodash';
import { DocumentSlice } from '../../states/interfaces';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

type DraggableItemProps = {
  entryId: string;
  itemIndex: number;
  readOnly?: boolean;
  isDraggingOver: boolean;
};

type StyledListItemProps = {
  isDraggingOver: boolean;
  isDragging: boolean;
};

type VerticalGroupContainerProps =
  | DraggableProvidedDraggableProps
  | {
      hidden: boolean;
    };
type StyledEditContainerProps = {
  isDraggingOver: boolean;
  isDragging: boolean;
};
type OnDeleteInput = {
  id: string;
  name: string;
};

type NewEntryInput = {
  name: string;
};


const StyledListItem = styled(BaseListItem)<StyledListItemProps>`
  :first-child {
    border-top-left-radius: ${(props) =>
      props.isDraggingOver && !props.isDragging ? '0' : '5px'};
    border-top-right-radius: ${(props) =>
      props.isDraggingOver && !props.isDragging ? '0' : '5px'};
  }
  :last-child {
    margin-bottom: 0;
    border-bottom-left-radius: ${(props) =>
      props.isDraggingOver ? '0' : '5px'};
    border-bottom-right-radius: ${(props) =>
      props.isDraggingOver ? '0' : '5px'};
    border-bottom: none;
  }
  border-radius: ${(props) => (props.isDragging ? '5px' : '0')};
`;

const VerticalGripContainer = styled.div<VerticalGroupContainerProps>`
  margin-right: 10px;
  color: ${(props) => props.theme.colors.item.container};
  box-sizing: border-box;
  width: 11px;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  cursor: text;
  overflow-wrap: anywhere;
  & p {
    margin: 0;
  }
`;

const LoadingContainer = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 7px;
  div {
    width: 16px !important;
  }
`;

const StyledEditContainer = styled.form<StyledEditContainerProps>`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.listitem.border};
  border-bottom-color: ${(props) => props.theme.colors.listitem.bottom};
  border-top: none;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  :first-child {
    border-top-left-radius: ${(props) =>
      props.isDraggingOver && !props.isDragging ? '0' : '8px'};
    border-top-right-radius: ${(props) =>
      props.isDraggingOver && !props.isDragging ? '0' : '8px'};
  }
  :last-child {
    margin-bottom: 0;
    border-bottom-left-radius: ${(props) =>
      props.isDraggingOver ? '0' : '8px'};
    border-bottom-right-radius: ${(props) =>
      props.isDraggingOver ? '0' : '8px'};
    border-bottom: none;
  }
  border-radius: ${(props) => (props.isDragging ? '8px' : '0')};

  > div {
    width: 100%;
  }
`;
const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  color: ${(props) => props.theme.colors.item.container};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
`;

const DeleteButton = styled(ActionButton)`
  padding: 0;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }
`;

const editItemFormSchema = yup.object().shape({
  name: yup.object().required(),
});

const DraggableItem: React.FC<DraggableItemProps> = ({
  itemIndex,
  readOnly,
  isDraggingOver,
  entryId,
}) => {
  const selector = (state: DocumentSlice) => state.document.entries[entryId];
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isHoverHandler, setIsHoverHandler] = useState<boolean>(false);
  const alert = useAlert();

  const store = useBoundStore;
  const [entry, setEntry] = useState(selector(store.getState()));

  useEffect(() => {
    const callback = () => {
      setEntry(selector(store.getState()));
    };

    const newEntry = store.getState().document.entries[entryId];
    if (!_.isEqual(entry, newEntry)) {
      setEntry(newEntry);
    }

    const unsubscribe = store.subscribe(callback);
    callback();
    return unsubscribe;
  }, [entry, selector]);

  const [deleteEntry, editEntry, toggleEntryEditing] = useBoundStore(
    (state) => [state.deleteEntry, state.editEntry, state.toggleEntryEditing],
    () => false,
  );

  const editingFormMethods: UseFormMethods<{ name: string }> = useForm({
    defaultValues: {
      name: entry.name,
    },
    shouldUnregister: false,
    resolver: yupResolver(editItemFormSchema),
  });

  const onDeleting = async (deletedEntry: Entry) => {
    const entryTags = withDefault(deletedEntry.tags, []).filter(isJust);
    await deleteEntry(deletedEntry.id);
  };

  const submitEditEntry = async ({ name: newEntryName }: NewEntryInput) => {
    await editEntry(entry.id, JSON.stringify(newEntryName));
  };

  useEffect(() => {
    editingFormMethods.setValue('name', entry.name);
  }, [entry]);

  const deleteEntryAction = async (deletedEntry: Entry) => {
    try {
      await onDeleting(deletedEntry);
      alert.success('Delete entry successfully');
    } catch (error: any) {
      alert.error(error.message);
    } finally {
      setIsOpenModal(false);
    }
  };

  return (
    <Draggable draggableId={entry.id} index={itemIndex} key={entry.id}>
      {(providedProps, snapshot) =>
        !entry.editing ? (
          <>
            <StyledListItem
              {...providedProps.draggableProps}
              ref={providedProps.innerRef}
              isDraggingOver={isDraggingOver}
              isDragging={snapshot.isDragging}
            >
              <VerticalGripContainer
                {...providedProps.dragHandleProps}
                onMouseOver={() => setIsHoverHandler(true)}
                onMouseLeave={() => setIsHoverHandler(false)}
                hidden={!!readOnly}
              >
                <Handler color={isHoverHandler ? '#60BFBF' : ''} />
              </VerticalGripContainer>
              <ContentContainer
                onClick={() =>
                  !readOnly &&
                  !entry.deleting &&
                  toggleEntryEditing(entry.id, true)
                }
              >
                <EntryViewer entry={JSON.parse(entry.name) as JsonDocument} />
              </ContentContainer>
              {!readOnly && !entry.deleting && (
                <DeleteButton
                  onClick={() => setIsOpenModal(true)}
                  disabled={entry.deleting}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </DeleteButton>
              )}
              {!readOnly && entry.deleting && (
                <LoadingContainer>
                  <ReactLoading type="spin" color={COLORS.deleting} />
                </LoadingContainer>
              )}
            </StyledListItem>
            <ConfirmationModal
              label='Do you want to delete this entry?'
              onConfirm={() => deleteEntryAction(entry)}
              onCancel={() => setIsOpenModal(false)}
              isOpen={isOpenModal}
            />
          </>
        ) : (
          <StyledEditContainer
            {...providedProps.draggableProps}
            ref={providedProps.innerRef}
            isDraggingOver={isDraggingOver}
            isDragging={snapshot.isDragging}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                toggleEntryEditing(entry.id, false);
              }}
            >
              <div
                style={{ display: 'none' }}
                {...providedProps.dragHandleProps}
              />
              <Controller
                control={editingFormMethods.control}
                name="name"
                render={() => (
                  <EntryEditor
                    defaultValue={JSON.parse(entry.name) as JsonDocument}
                    onSave={async (e) => {
                      editingFormMethods.setValue('name', e);
                      await editingFormMethods.handleSubmit(submitEditEntry)();
                    }}
                    onClose={() => toggleEntryEditing(entry.id, false)}
                    errorMessage={editingFormMethods.errors.name?.message?.replace(
                      'name',
                      'Entry',
                    )}
                    isSubmitting={editingFormMethods.formState.isSubmitting}
                    isFocused={true}
                    inlineErrorMessage={true}
                  />
                )}
              />
            </OutsideClickHandler>
          </StyledEditContainer>
        )
      }
    </Draggable>
  );
};

export default DraggableItem;
