import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import * as yup from 'yup';
import axios from 'axios';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import Box from '../../components/Box';
import RectButton from '../../components/Button/RectButton';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import { useBoundStore } from '../../states';
import Modal from 'styled-react-modal';
import { withDefault } from '../../maybe';
import { useHistory } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { ErrorMessage } from '@hookform/error-message';
import StyledErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { Organization } from '../../common/type';

const organizationDetailSchema = yup.object({
  name: yup.string().required(),
  avatarUrl: yup.string(),
  businessNumber: yup.string(),
  primaryEmail: yup.string().email().required(),
  countryCode: yup.string(),
  addressLine: yup.string(),
  postalCode: yup.string(),
  suburb: yup.string(),
  region: yup.string(),
});

export type OrganizationDetailFormValues = yup.InferType<
  typeof organizationDetailSchema
>;

const StyledModal = Modal.styled`
  width: 40rem;
  max-height: 60%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 220px;
  margin-bottom: 150px;
  padding-bottom: 30px;
  border-radius: 15px;
`;

const StyledRectButton = styled(RectButton)`
  background-color: ${(props) => props.theme.colors.item.hover};
  border-color: ${(props) => props.theme.colors.item.hover};

  :hover {
    background-color: ${(props) => props.theme.colors.item.hover};
    border-color: ${(props) => props.theme.colors.item.hover};
  }
`;

const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
  width: 90%;
  text-align: start;
`;

const TextFieldContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.textfield.text};
  cursor: text;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  border-radius: 8px;
  margin-top: 20px;
  width: 90%;
  background-color: ${(props) => props.theme.colors.white};
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  padding: 10px;
  margin-right: 20px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.input.disabled : 'transparent'};
  border: none;
  border-radius: 6px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0px;
  outline: none;
  width: 100%;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.textfield.border};
  line-height: 1.42857;
  font-family: 'Open Sans', sans-serif;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'normal')};
  &:focus {
    border-color: ${(props) => props.theme.colors.textfield.focusBorder};
    background-color: ${(props) =>
      props.theme.colors.textfield.focusBackground};
  }
`;

const StyledIconPreview = styled.img`
  height: 50%;
  width: 50%;
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  margin-bottom: 46px;
`;

const StyledHeader = styled.div`
  color: #5a5f69;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
  margin-bottom: 10px;
  font-size: 12px;
`;

const BoldStyled = styled.div`
  font-weight: 700;
  font-style: italic;
  display: inline;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledIconInput = styled.input`
  width: fit-content;
`

type getUserAvatarUrlOutput = {
  getUploadAvatarUrl: {
    avatarUrl: string;
    uploadUrl: string;
  };
};

const getAvatarUrlsQuery = `
  query GetAvatarUrls($input: GetUploadAvatarInput!) {
    getUploadAvatarUrl(input: $input) {
      uploadUrl
      avatarUrl
    }
  }
`;

type OrganizationDetailsProps = {
  organization: Organization | undefined;
};

const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
  organization,
}) => {
  const alert = useAlert();
  const [activeDeleteButton, setActiveDeleteButton] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [organizationName, updateOrganizationDetails, deleteOrganization] =
    useBoundStore((state) => [
      state.organization.name,
      state.updateOrganizationDetails,
      state.deleteOrganization,
    ]);

  const { currentMembership, organizations, profile } = useBoundStore(
    (state) => state.auth,
  );

  const organizationID = withDefault(currentMembership?.organization.id, '');

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const updateOrganizationDetailsForm = useForm<OrganizationDetailFormValues>({
    resolver: yupResolver(organizationDetailSchema),
    defaultValues: {
      name: organizationName,
      businessNumber: organization?.businessNumber || '',
      primaryEmail: organization?.primaryEmail,
      avatarUrl: organization?.avatarUrl || '',
      addressLine: organization?.addressLine || '',
      postalCode: organization?.postalCode || '',
      suburb: organization?.suburb || '',
      region: organization?.region || '',
      countryCode: organization?.countryCode || '',
    },
  });

  const onSubmitOrgDetailsForm: SubmitHandler<OrganizationDetailFormValues> =
    async (data) => {
      await updateOrganizationDetails({
        name: data.name,
        businessNumber: data.businessNumber || '',
        avatarUrl: data.avatarUrl || '',
        addressLine: data.addressLine || '',
        postalCode: data.postalCode || '',
        suburb: data.suburb || '',
        region: data.region || '',
        countryCode: data.countryCode || '',
      });
      alert.success('Changes are saved.');
    };
  const history = useHistory();
  const deleteOrganizationAction = async () => {
    try {
      const anotherOrganizations = withDefault(
        organizations?.filter((org) => org.id !== organizationID),
        [],
      );
      if (anotherOrganizations.length === 0) {
        alert.error('Can not delete the last organization.');
        setIsOpenModal(false);
      } else {
        await deleteOrganization(organizationID);
        alert.success('Organization was deleted.');
        history.replace(
          `/switch?organizationID=${anotherOrganizations[0].id}&continue=/plan`,
        );
      }
    } catch (error: any) {
      alert.error(error.errors[0]?.message || error.message);
    }
  };

  const checkInputOrgName = (input: string) => {
    if (input === organizationName) {
      setActiveDeleteButton(true);
    } else {
      setActiveDeleteButton(false);
    }
  };

  const updateAvatar = (setAvatarUrl: any) => async (event: any) => {
    if (event.target.files.length === 0) return;

    setIsUploading(true);
    const avatarFile = event?.target.files[0];
    const fileType = avatarFile?.name.split('.').pop();
    const img = new Image();
    img.src = window.URL.createObjectURL(avatarFile);

    const ratio = await new Promise((resolve) => {
      img.onload = () => {
        resolve(img.height / img.width);
      };
    });

    if (ratio !== 1) {
      updateOrganizationDetailsForm.setError('avatarUrl', {
        message: 'Image must be square.',
      });
      setIsUploading(false);
      return;
    }

    if (fileType !== 'png' && fileType !== 'jpeg') {
      updateOrganizationDetailsForm.setError('avatarUrl', {
        message: 'Image must be type PNG or JPEG.',
      });
      setIsUploading(false);
      return;
    }

    try {
      const getAvatarUploadUrlResult = (await API.graphql(
        graphqlOperation(getAvatarUrlsQuery, {
          input: {
            type: fileType,
          },
        }),
      )) as GraphQLResult<getUserAvatarUrlOutput>;

      if (!getAvatarUploadUrlResult.data?.getUploadAvatarUrl) {
        throw new Error('Error occurs while uploading avatar');
      }

      const { avatarUrl, uploadUrl } =
        getAvatarUploadUrlResult.data.getUploadAvatarUrl;

      await axios.put(uploadUrl, avatarFile, {
        headers: {
          'Content-Type': `image/${fileType}`,
        },
      });
      setAvatarUrl(avatarUrl);
    } catch (error: any) {
      updateOrganizationDetailsForm.setError(
        'avatarUrl',
        error.errors?.message || error.message,
      );
    }
    setIsUploading(false);
  };
  return (
    <Container>
      <Row>
        <Col col={12}>
          <FormProvider {...updateOrganizationDetailsForm}>
            <form
              onSubmit={updateOrganizationDetailsForm.handleSubmit(
                onSubmitOrgDetailsForm,
              )}
            >
              <Box
                header="Organization Details"
                actionComponent={
                  <RectButton type="submit">Save changes</RectButton>
                }
              >
                <SectionHeader>General information</SectionHeader>
                <ContextTextField name="name" label="Name*" />
                <ContextTextField
                  name="businessNumber"
                  label="Business Number"
                />
                <ContextTextField
                  name="primaryEmail"
                  label="Primary Email"
                  defaultValue={withDefault(profile?.email, '')}
                  disabled
                />
                <IconContainer>
                  <StyledHeader>Organization Icon</StyledHeader>
                  <StyledIconContainer>
                  {updateOrganizationDetailsForm.watch('avatarUrl') && (
                    <StyledIconPreview
                      src={
                        profile?.avatarUrl ||
                        updateOrganizationDetailsForm.watch('avatarUrl')
                      }
                    />
                  )}
                  <Controller
                    name="avatarUrl"
                    control={updateOrganizationDetailsForm.control}
                    render={(controlerProps) => (
                      <StyledIconInput
                        type="file"
                        name="avatar"
                        disabled={isUploading}
                        onChange={(event) =>
                          updateAvatar(controlerProps.onChange)(event)
                        }
                        data-cy="avatar-file"
                        onMouseOut={() => !visible}
                        onMouseOver={() => visible}
                        ref={setTriggerRef}
                        title=""
                      />
                    )}
                  />
                  {visible && (
                    <div
                      ref={setTooltipRef}
                      {...getTooltipProps({ className: 'tooltip-container' })}
                    >
                      <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                      Optionally select a square icon in PNG, JPEG format to
                      represent your organization
                    </div>
                  )}
                  </StyledIconContainer>
                  
                  <ErrorMessage
                    errors={updateOrganizationDetailsForm.errors}
                    name="avatarUrl"
                    render={(message) => (
                      <StyledErrorMessage>{message.message}</StyledErrorMessage>
                    )}
                  />
                </IconContainer>
                <SectionHeader>Address</SectionHeader>
                <ContextTextField name="addressLine" label="Address" />
                <ContextTextField name="postalCode" label="Postal Code" />
                <ContextTextField name="suburb" label="Suburb" />
                <ContextTextField name="region" label="Region" />
                <ContextTextField name="countryCode" label="Country" />
              </Box>
            </form>
          </FormProvider>
        </Col>
        <Col col={12}>
          <Box
            header="Delete Organization"
            actionComponent={
              <StyledRectButton
                type="button"
                onClick={() => setIsOpenModal(true)}
              >
                Delete Organization
              </StyledRectButton>
            }
          >
            <StyledModal
              isOpen={isOpenModal}
              onBackgroundClick={() => setIsOpenModal(false)}
              onEscapeKeydown={() => setIsOpenModal(false)}
            >
              <StyledLabel>
                Please type <BoldStyled>{`${organizationName}`}</BoldStyled> to
                confirm.
              </StyledLabel>
              <TextFieldContainer>
                <StyledInput
                  name="orgName"
                  autoComplete="off"
                  onChange={(input) => checkInputOrgName(input.target.value)}
                  autoFocus={true}
                  placeholder={`${organizationName}`}
                />
                <StyledRectButton
                  type="button"
                  disabled={!activeDeleteButton}
                  onClick={() => deleteOrganizationAction()}
                >
                  Delete
                </StyledRectButton>
              </TextFieldContainer>
            </StyledModal>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationDetails;
