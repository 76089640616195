import {
  DocumentV2,
  Entry,
  Initiative,
  InitiativeKeyResult,
  TextEntry,
  Organization,
} from '@propella/core';
import { API, graphqlOperation } from 'aws-amplify';
import produce, { applyPatches, enablePatches, Patch } from 'immer';
import { StateCreator } from 'zustand';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getDocumentV2 } from '../graphql/queries';
import { DocumentV2Slice, StoreInterface } from './interfaces';
import {
  createInitiative,
  createInitiativeKeyResult,
  createTextEntry,
  deleteTextEntry,
  deleteInitiative,
  deleteInitiativeKeyResult,
  transitEntryV2,
  updateDocumentOHDP,
  updateEntry,
  updateInitiativeAssignee,
  updateInitiativeAttributes,
  updateInitiativeDueDate,
  updateInitiativeName,
  updateInitiativeTags,
} from '../graphql/mutations';
import { isJust, withDefault } from '../maybe';
import _ from 'lodash';
import { cells, DataCell } from '../pages/DocumentV2/onePage';
import { WritableDraft } from 'immer/dist/internal';

const isSameEntryRank = (
  firstEntryRank: DocumentV2['entryRank'],
  secondEntryRank: DocumentV2['entryRank'],
): boolean => {
  if (firstEntryRank?.length !== secondEntryRank?.length) {
    return false;
  }

  for (let i = 0; i < firstEntryRank!.length; i++) {
    if (firstEntryRank![i] !== secondEntryRank![i]) {
      return false;
    }
  }

  return true;
};

enablePatches();

export const createDocumentV2Slice: StateCreator<
  StoreInterface,
  [],
  [],
  DocumentV2Slice
> = (set, get) => ({
  documentV2: {
    id: undefined,
    isReady: false,
    name: '',
    type: '',
    entryRank: [],
    entries: [],
    assigneeIDs: [],
  },
  editingEntryModal: {
    isOpen: false,
    entryId: undefined,
    color: undefined,
  },
  hundredDayPlan: {
    selectedInitiativeIds: [],
  },
  assignableUsers: [],
  deleteInitiative: async (initiativeID) => {
    let changes: Patch[] = [];
    let inverseChanges: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const indexOfInitiative =
          draft.documentV2.entryRank.indexOf(initiativeID);
        const newEntryRank = [...draft.documentV2.entryRank];
        newEntryRank.splice(indexOfInitiative, 1);
        draft.documentV2.entryRank = newEntryRank;
        draft.documentV2.entries = draft.documentV2.entries.filter(
          (entry) => entry.id !== initiativeID,
        );
        draft.documentV2.assigneeIDs = draft.documentV2.assigneeIDs.filter(
          (assigneeID) => assigneeID !== initiativeID,
        );
      },
      (patches, inversePatches) => {
        changes = patches;
        inverseChanges = inversePatches;
      },
    );

    set(applyPatches(get(), changes));

    try {
      await API.graphql(
        graphqlOperation(deleteInitiative, {
          input: {
            id: initiativeID,
          },
        }),
      );
    } catch (error) {
      console.log('Error occurs while deleting initiative', error);
      set(applyPatches(get(), inverseChanges));
    }
  },
  deleteInitiativeKeyResult: async (initiativeID, keyResultID) => {
    let changes: Patch[] = [];
    let inverseChanges: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const initIndex = _.findIndex(
          withDefault(draft.documentV2.entries, []),
          (entry) => entry.id === initiativeID,
        );

        (
          draft.documentV2.entries[initIndex] as WritableDraft<Initiative>
        ).keyResults = withDefault(
          (draft.documentV2.entries[initIndex] as WritableDraft<Initiative>)
            .keyResults,
          [],
        ).filter((keyResult) => keyResult?.id !== keyResultID);
      },
      (patches, inversePatches) => {
        changes = patches;
        inverseChanges = inversePatches;
      },
    );

    set(applyPatches(get(), changes));

    try {
      await API.graphql(
        graphqlOperation(deleteInitiativeKeyResult, {
          input: {
            initiativeID: initiativeID,
            keyResultID,
          },
        }),
      );
    } catch (error) {
      console.log('Error occurs while deleting key result', error);
      set(applyPatches(get(), inverseChanges));
    }
  },
  deleteTextEntry: async (entryId) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(deleteTextEntry, {
          input: {
            id: entryId,
          },
        }),
      )) as GraphQLResult<{
        deleteTextEntry: TextEntry;
      }>;

      const removedTextEntry = queryResult.data?.deleteTextEntry;
      if (removedTextEntry) {
        get().afterDeleteTextEntry(removedTextEntry);
      }
    } catch (error: any) {
      throw new Error('There are some errors occur when deleting the entry.');
    }
  },
  afterDeleteTextEntry: (removedEntry: any) => {
    set(
      produce<DocumentV2Slice>((draft) => {
        draft.documentV2.entries = draft.documentV2.entries.filter(
          (entry) => entry.id !== removedEntry.id,
        );
        draft.documentV2.entryRank = draft.documentV2.entryRank.filter(
          (entryID) => entryID !== removedEntry.id,
        );
      }),
    );
  },
  updateInitiativeRank: async (intitaiveId, rankBeforeId) => {
    let changes: Patch[] = [];
    let inverseChanges: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        let newEntryRank = [] as string[];

        if (
          !draft.documentV2.entryRank ||
          draft.documentV2.entryRank.length === 0
        ) {
          newEntryRank = draft.documentV2.entries.map((entry) => entry.id);
        } else {
          newEntryRank = draft.documentV2.entryRank;
        }

        const sourceIndex = newEntryRank.indexOf(intitaiveId);
        const destinationIndex = newEntryRank.indexOf(rankBeforeId);

        const [removed] = newEntryRank.splice(sourceIndex, 1);
        newEntryRank.splice(destinationIndex, 0, removed);

        draft.documentV2.entryRank = newEntryRank;
        // Sort entries following entryRank
        draft.documentV2.entries = draft.documentV2.entries.sort(
          (firstEntry, secondEntry) => {
            const firstEntryIndex = newEntryRank.indexOf(firstEntry.id);
            const secondEntryIndex = newEntryRank.indexOf(secondEntry.id);
            return firstEntryIndex - secondEntryIndex;
          },
        );
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(updateDocumentOHDP, {
          input: {
            id: get().documentV2.id,
            entryRank: get().documentV2.entryRank,
          },
        }),
      )) as GraphQLResult<{ updateDocumentOHDP: DocumentV2 }>;

      const { errors } = queryResult;

      if (errors) {
        throw new Error(errors[0].message);
      }
    } catch (error) {
      console.log('Error occurs while updating document', error);
      set(applyPatches(get(), inverseChanges));
    }
  },
  toggleInitiativeSelection: async (initiativeId: string) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];
    const documentId = get().documentV2.id;

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const isSelected =
          draft.hundredDayPlan.selectedInitiativeIds.includes(initiativeId);
        let newListOfSelectedInitiativeIds: string[] = [];
        if (isSelected) {
          newListOfSelectedInitiativeIds =
            draft.hundredDayPlan.selectedInitiativeIds.filter(
              (id) => id !== initiativeId,
            );
        } else {
          newListOfSelectedInitiativeIds =
            draft.hundredDayPlan.selectedInitiativeIds.concat([initiativeId]);
        }

        draft.hundredDayPlan.selectedInitiativeIds =
          newListOfSelectedInitiativeIds;
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(updateDocumentOHDP, {
          input: {
            id: documentId,
            selectedInitiativeIds: get().hundredDayPlan.selectedInitiativeIds,
          },
        }),
      )) as GraphQLResult<{
        updateDocumentOHDP: DocumentV2;
      }>;

      const { errors } = queryResult;
      if (errors) {
        throw new Error(errors[0].message);
      }
    } catch (error) {
      console.log('Error occurs while updating document', error);
      set(applyPatches(get(), inverseChanges));
    }
  },
  toggleSelectAllInitatives: async () => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const isAllSelected =
          draft.documentV2.entries.length ===
          draft.hundredDayPlan.selectedInitiativeIds.length;

        if (isAllSelected) {
          draft.hundredDayPlan.selectedInitiativeIds = [];
        } else {
          draft.hundredDayPlan.selectedInitiativeIds =
            draft.documentV2.entries.map((entry) => entry.id);
        }
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(updateDocumentOHDP, {
          input: {
            id: get().documentV2.id,
            selectedInitiativeIds: get().hundredDayPlan.selectedInitiativeIds,
          },
        }),
      )) as GraphQLResult<{
        updateDocumentOHDP: DocumentV2;
      }>;

      const { errors } = queryResult;
      if (errors) {
        throw new Error(errors[0].message);
      }
    } catch (error) {
      console.log('Error occurs while updating document', error);
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateDocumentOHDP: async (documentID: string, attributes) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(updateDocumentOHDP, {
          input: { id: documentID, ...attributes },
        }),
      )) as GraphQLResult<{
        updateInitiativeAttributes: DocumentV2;
      }>;

      const { data, errors } = queryResult;
      if (errors) {
        throw new Error(errors[0].message);
      }
      if (!data || !data.updateInitiativeAttributes) {
        throw new Error("Couldn't update document");
      }
    } catch (error) {
      console.error(error);
    }
  },
  fetchDocumentV2: async (id) => {
    set(
      produce<DocumentV2Slice>((draft) => {
        draft.documentV2.isReady = false;
      }),
    );
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(getDocumentV2, {
          id,
        }),
      )) as GraphQLResult<{
        getDocument: DocumentV2;
      }>;

      const { data, errors } = queryResult;

      if (errors) {
        throw new Error(errors[0].message);
      }
      if (!data || !data.getDocument) {
        throw new Error("Couldn't get document");
      }

      set(
        produce<DocumentV2Slice>((draft) => {
          draft.documentV2.id = data.getDocument.id;
          draft.documentV2.entryRank = withDefault(
            data.getDocument.entryRank,
            [],
          ).filter(isJust);
          draft.documentV2.name = withDefault(data.getDocument.name, '');
          draft.documentV2.type = withDefault(data.getDocument.type, '');

          draft.documentV2.entries = withDefault(data.getDocument.entries, [])
            .filter(isJust)
            .sort((a, b) => {
              const aIndex = draft.documentV2.entryRank.indexOf(a.id);
              const bIndex = draft.documentV2.entryRank.indexOf(b.id);
              return aIndex - bIndex;
            });
          draft.assignableUsers = withDefault(
            data.getDocument.oneHundredDayPlan?.assignableUsers,
            [],
          ).filter(isJust);
          draft.hundredDayPlan.selectedInitiativeIds = withDefault(
            data.getDocument.oneHundredDayPlan?.selectedInitiativeIds,
            [],
          ).filter(isJust);
          draft.documentV2.isReady = true;
        }),
      );
    } catch (error) {
      console.error(error);
    }

    set(
      produce<DocumentV2Slice>((draft) => {
        draft.documentV2.isReady = true;
        draft.documentV2.id = id;
      }),
    );
  },
  createInitiative: async (name, topic, documentID) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(createInitiative, {
          input: { name, topicKey: topic, documentID },
        }),
      )) as GraphQLResult<{
        createInitiative: Initiative;
      }>;
      const newInitiative = queryResult.data?.createInitiative;

      if (newInitiative) {
        set(
          produce<DocumentV2Slice>((draft) => {
            draft.documentV2.entryRank.push(newInitiative.id);
            draft.documentV2.entries.push(newInitiative);
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  createInitiativeKeyResult: async (name, initiativeID) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(createInitiativeKeyResult, {
          input: { name, initiativeID },
        }),
      )) as GraphQLResult<{
        createInitiativeKeyResult: InitiativeKeyResult;
      }>;

      const newKeyResult = queryResult.data?.createInitiativeKeyResult;

      if (newKeyResult) {
        set(
          produce<DocumentV2Slice>((draft) => {
            const initiative = draft.documentV2.entries.find(
              (entry) => entry.id === initiativeID,
            ) as Initiative;

            initiative.keyResults = (initiative.keyResults || []).concat([
              newKeyResult,
            ]);
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  createTextEntry: async (name, topic, documentID) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(createTextEntry, {
          input: { name, topicKey: topic, documentID },
        }),
      )) as GraphQLResult<{
        createTextEntry: TextEntry;
      }>;
      const newTextEntry = queryResult.data?.createTextEntry;

      if (newTextEntry) {
        set(
          produce<DocumentV2Slice>((draft) => {
            draft.documentV2.entryRank.push(newTextEntry.id);
            draft.documentV2.entries.push(newTextEntry);
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  handleEntryTransit: async (entryId, source, destination) => {
    if (!destination || !destination.topicKey) {
      return;
    }
    const transitedEntry = get().documentV2.entries.find(
      (entry) => entry.id === entryId,
    );

    const entryType =
      transitedEntry?.__typename === 'TextEntry'
        ? 'text'
        : transitedEntry?.__typename;

    const destinationType = (
      cells.find(
        (cell) => (cell as DataCell).newEntry?.topic === destination.topicKey,
      ) as DataCell
    )?.newEntry?.type;

    if (_.lowerCase(entryType) !== _.lowerCase(destinationType)) {
      return;
    }

    const entriesOfDestination = get().documentV2.entries.filter(
      (entry) => entry.topicKey === destination.topicKey,
    );
    if (transitedEntry && transitedEntry?.topicKey !== destination.topicKey) {
      entriesOfDestination.splice(destination.index, 0, transitedEntry);
    }

    if (transitedEntry && transitedEntry?.topicKey === destination.topicKey) {
      const entryIndex = entriesOfDestination.findIndex((entry) => entry.id === entryId);
      entriesOfDestination.splice(entryIndex, 1);
      entriesOfDestination.splice(destination.index, 0, transitedEntry);
    }

    const rankAfterEntryId = entriesOfDestination[destination.index - 1]?.id;
    const rankBeforeEntryId = entriesOfDestination[destination.index + 1]?.id;

    const inverseChanges = get().transitEntryLocally(
      entryId,
      destination.topicKey,
      rankAfterEntryId,
      rankBeforeEntryId,
    );

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(transitEntryV2, {
          input: {
            entryID: entryId,
            type: entryType,
            topicKey: destination.topicKey,
            rankAfterEntryID: rankAfterEntryId,
            rankBeforeEntryID: rankBeforeEntryId,
          },
        }),
      )) as GraphQLResult<{
        transitEntryV2: DocumentV2;
      }>;

      const updatedEntryRank = queryResult.data?.transitEntryV2.entryRank;

      set(
        produce<DocumentV2Slice>((draft) => {
          const processedEntryRank = withDefault(updatedEntryRank, []).filter(
            isJust,
          );

          if (
            !isSameEntryRank(processedEntryRank, draft.documentV2.entryRank)
          ) {
            draft.documentV2.entryRank = processedEntryRank;
          }
        }),
      );
    } catch (error) {
      console.log('Error while moving entry', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  transitEntryLocally: (
    entryId,
    topicKey,
    rankBeforeEntryId,
    rankAfterEntryId,
  ) => {
    const transitChanges: Patch[] = [];
    const inverseChanges: Patch[] = [];
    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const transitedEntryIndex = draft.documentV2.entries.findIndex(
          (entry) => entry.id === entryId,
        );

        draft.documentV2.entries[transitedEntryIndex].topicKey = topicKey;

        draft.documentV2.entryRank = draft.documentV2.entryRank.filter(
          (entryRankId) => entryRankId !== entryId,
        );
        // Update entry ranking
        if (!rankBeforeEntryId) {
          draft.documentV2.entryRank = _.concat(
            [entryId],
            draft.documentV2.entryRank,
          );
        } else if (!rankAfterEntryId) {
          draft.documentV2.entryRank = _.concat(draft.documentV2.entryRank, [
            entryId,
          ]);
        } else {
          draft.documentV2.entryRank.splice(
            draft.documentV2.entryRank.indexOf(rankBeforeEntryId) + 1,
            0,
            entryId,
          );
        }

        draft.documentV2.entries = draft.documentV2.entries.sort((a, b) => {
          const aIndex = draft.documentV2.entryRank.indexOf(a.id);
          const bIndex = draft.documentV2.entryRank.indexOf(b.id);
          return aIndex - bIndex;
        });
      },
      (patches, inversePatches) => {
        transitChanges.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), transitChanges));

    return inverseChanges;
  },
  openEntryModal: (entryId, color) => {
    set(
      produce<DocumentV2Slice>((draft) => {
        draft.editingEntryModal.isOpen = true;
        draft.editingEntryModal.entryId = entryId;
        draft.editingEntryModal.color = color;
      }),
    );
  },
  closeEntryModal: () => {
    set(
      produce<DocumentV2Slice>((draft) => {
        draft.editingEntryModal.isOpen = false;
        draft.editingEntryModal.entryId = undefined;
      }),
    );
  },
  updateInitiativeAttributesLocally: (entryId, attributes) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const initiativeIndex = draft.documentV2.entries.findIndex(
          (entry) => entry.id === entryId,
        );

        const existingEntry = draft.documentV2.entries[initiativeIndex];

        draft.documentV2.entries[initiativeIndex] = {
          ...existingEntry,
          ...attributes,
        };
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateInitiativeAttributes: async (initiativeId, attributes) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeAttributesLocally(
        initiativeId,
        attributes,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeAttributes, {
          input: {
            id: initiativeId,
            ...attributes,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeAttributes: DocumentV2;
      }>;
    } catch (error) {
      console.log('Error while updating initiative', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateInitiativeTagsLocally: (entryId, tags) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const initiativeIndex = draft.documentV2.entries.findIndex(
          (entry) => entry.id === entryId,
        );

        draft.documentV2.entries[initiativeIndex].tags = tags;
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateInitiativeTags: async (initiativeId, tags) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeTagsLocally(initiativeId, tags);

      (await API.graphql(
        graphqlOperation(updateInitiativeTags, {
          input: {
            entryID: initiativeId,
            tags,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeTags: Initiative;
      }>;

      // const updatedInitiative = queryResult.data?.updateInitiativeTags;
      // console.log('updated', updatedInitiative);
      // if (updatedInitiative) {
      //   set(
      //     produce<DocumentV2Slice>((draft) => {
      //       const initiativeIndex = draft.documentV2.entries.findIndex(
      //         (entry) => entry.id === initiativeId,
      //       );

      //       draft.documentV2.entries[initiativeIndex].tags =
      //         updatedInitiative.tags;
      //     }),
      //   );
      // }
    } catch (error) {
      console.log('update tags', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateInitiativeDueDate: async (initiativeId, dueDate) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeDueDateLocally(
        initiativeId,
        dueDate,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeDueDate, {
          input: {
            entryID: initiativeId,
            dueDate,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeDueDate: Initiative;
      }>;
    } catch (error) {
      console.log('update due date', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateInitiativeDueDateLocally: (initiativeId, dueDate) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const initiativeIndex = draft.documentV2.entries.findIndex(
          (entry) => entry.id === initiativeId,
        );

        const nextDueDate = isJust(dueDate) ? dueDate.toISOString() : undefined;

        (draft.documentV2.entries[initiativeIndex] as Initiative).dueDate =
          nextDueDate;
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateInitiativeNameLocally: (initiativeId, name) => {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const initiativeIndex = draft.documentV2.entries.findIndex(
          (entry) => entry.id === initiativeId,
        );

        (draft.documentV2.entries[initiativeIndex] as Initiative).name = name;
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
  updateInitiativeName: async (initiativeId, name) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeNameLocally(initiativeId, name);

      (await API.graphql(
        graphqlOperation(updateInitiativeName, {
          input: {
            entryID: initiativeId,
            name,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeName: Initiative;
      }>;
    } catch (error) {
      console.log('update name', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateEntry: async (entryId, name) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(updateEntry, {
          input: {
            id: entryId,
            name,
          },
        }),
      )) as GraphQLResult<{
        updateEntry: Entry;
      }>;

      const updatedEntry = queryResult.data?.updateEntry;

      if (updatedEntry) {
        set(
          produce<DocumentV2Slice>((draft) => {
            const entryIndex = draft.documentV2.entries.findIndex(
              (entry) => entry.id === updatedEntry.id,
            );

            draft.documentV2.entries[entryIndex].name = updatedEntry.name;
          }),
        );
      }
    } catch (error) {
      console.log('Error occur while updating entry', error);
    }
  },
  updateInitiativeAssignee: async (entryId, assigneeIds) => {
    let inverseChanges: Patch[] = [];

    try {
      inverseChanges = get().updateInitiativeAssigneeLocally(
        entryId,
        assigneeIds,
      );

      (await API.graphql(
        graphqlOperation(updateInitiativeAssignee, {
          input: {
            id: entryId,
            assigneeIDs: assigneeIds,
          },
        }),
      )) as GraphQLResult<{
        updateInitiativeAssignee: Initiative;
      }>;
    } catch (error) {
      console.log('update assignee', error);

      // Revert changes
      set(applyPatches(get(), inverseChanges));
    }
  },
  updateInitiativeAssigneeLocally(entryId, assigneeIds) {
    let inverseChanges: Patch[] = [];
    let changes: Patch[] = [];

    produce<DocumentV2Slice>(
      get(),
      (draft) => {
        const initiativeIndex = draft.documentV2.entries.findIndex(
          (entry) => entry.id === entryId,
        );

        const existingEntry = draft.documentV2.entries[initiativeIndex];

        draft.documentV2.entries[initiativeIndex] = {
          ...existingEntry,
          assigneeIDs: assigneeIds,
        } as WritableDraft<Initiative>;
      },
      (patches, inversePatches) => {
        changes.push(...patches);
        inverseChanges.push(...inversePatches);
      },
    );

    set(applyPatches(get(), changes));

    return inverseChanges;
  },
});
