import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import RectButton from '../Button/RectButton';

const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
`;

const ConfirmButtonsContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const StyledRectButton = styled(RectButton)`
  color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  background-color: ${(props) => props.theme.colors.white};
  border-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  :hover {
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
    color: ${(props) => props.theme.colors.white};
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
  }
`;

const StyledModal = Modal.styled`
  width: 40rem;
  max-height: 60%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 220px;
  margin-bottom: 150px;
  padding-bottom: 30px;
  border-radius: 15px;
`;

export type ConfirmationModalProps = {
  label: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const [isOpenModal, setIsOpenModal] = useState(props.isOpen);

  const onCloseModal = () => {
    setIsOpenModal(false);
    props.onCancel();
  };

  const onConfirmAction = () => {
    setIsOpenModal(false);
    props.onConfirm();
  };

  useEffect(() => {
    setIsOpenModal(props.isOpen);
  }, [props.isOpen]);
  return (
    <StyledModal
      isOpen={isOpenModal}
      onBackgroundClick={() => onCloseModal()}
      onEscapeKeydown={() => onCloseModal()}
    >
      <StyledLabel>{props.label}</StyledLabel>
      <ConfirmButtonsContainer>
        <StyledRectButton
          type="button"
          onClick={() => {
            onConfirmAction();
          }}
        >
          Yes
        </StyledRectButton>
        <StyledRectButton
          type="button"
          variant="secondary"
          onClick={() => onCloseModal()}
        >
          No
        </StyledRectButton>
      </ConfirmButtonsContainer>
    </StyledModal>
  );
};

export default ConfirmationModal;
