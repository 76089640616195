import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Initiative, Maybe, Tag } from '@propella/core';
import _ from 'lodash';
import { isJust, withDefault } from '../../maybe';
import TagDropDown, { TagDropDownProps } from './TagDropDown';
import {
  businessRisks,
  completionTargets,
  horizons,
  initiativeTypes,
  priorities,
  strategicKRAs,
  status as initiativeStatuses,
} from './values';
import TableInlineText from './TableInlineText';
import InitOwner from './InitOwner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useBoundStore } from '../../states';
import { ReactComponent as Handler } from '../Initiative/handler.svg';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { useAlert } from 'react-alert';
import { MasterListInitiative } from '../../states/interfaces';

export type InitTableRowProps = {
  initiative: MasterListInitiative;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  tableRef: React.MutableRefObject<HTMLTableElement | null | undefined>;
};

const KPIList = styled.div`
  display: flex;
  flex-direction: column;
`;

const KPIItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
`;

const isType = (type: string) => (initiative: Initiative) => {
  return _.some(
    withDefault(initiative.tags, []),
    (tag: Maybe<Tag>) => tag?.name === type,
  );
};

const typeTags: TagDropDownProps['options'] = initiativeTypes.map((type) => ({
  value: type.value || _.kebabCase(type.name),
  name: type.name,
  backgroundColor: type.color + '15',
  textColor: type.color,
}));

const priorityTags: TagDropDownProps['options'] = priorities.map(
  (priority) => ({
    value: priority.value || _.kebabCase(priority.name),
    name: priority.name,
    backgroundColor: priority.color + '15',
    textColor: priority.color,
  }),
);

const horizonTags: TagDropDownProps['options'] = horizons.map((horizon) => ({
  value: horizon.value || _.kebabCase(horizon.name),
  name: horizon.name,
  backgroundColor: horizon.color + '15',
  textColor: horizon.color,
}));

const businessRiskTags: TagDropDownProps['options'] = businessRisks.map(
  (businessRisk) => ({
    value: businessRisk.value || _.kebabCase(businessRisk.name),
    name: businessRisk.name,
    backgroundColor: businessRisk.color + '15',
    textColor: businessRisk.color,
  }),
);

const completionTargetTags: TagDropDownProps['options'] = completionTargets.map(
  (completionTarget) => ({
    value: completionTarget.value,
    name: completionTarget.name,
    backgroundColor: completionTarget.color + '15',
    textColor: completionTarget.color,
  }),
);

const statusTags: TagDropDownProps['options'] = initiativeStatuses.map(
  (status) => ({
    value: status.value,
    name: status.name,
    backgroundColor: status.color + '15',
    textColor: status.color,
  }),
);

const KRAs: TagDropDownProps['options'] = strategicKRAs.map((KRA) => ({
  value: KRA.value || _.kebabCase(KRA.name),
  name: KRA.name,
  backgroundColor: KRA.color + '15',
  textColor: KRA.color,
}));

const topicToHorizon = (initiative: Initiative) => {
  const horizon = _.find(
    horizons,
    (hor) =>
      initiative.ohdpHorizon === hor.value ||
      _.includes(initiative.topicKey, hor.value),
  );
  return horizon?.name;
};

const topicToKRA = (topicKey: Maybe<string>) => {
  const KRA = _.find(strategicKRAs, (kra) => _.includes(topicKey, kra.value));
  return KRA?.name;
};

const tagToPriority = (tags: Maybe<Tag>[]) => {
  const priority = _.find(tags, (tag) => _.startsWith(tag?.id, 'priority-'));
  return priority?.name;
};

const tagToBusinessRisk = (tags: Maybe<Tag>[]) => {
  const businessRisk = _.find(tags, (tag) => _.startsWith(tag?.id, 'risk-'));
  return businessRisk?.name;
};

const tagToCompletionTarget = (tags: Maybe<Tag>[]) => {
  const completionTarget = _.find(tags, (tag) =>
    _.startsWith(tag?.id, 'within-'),
  );
  return completionTarget?.name;
};

const tagToStatus = (tags: Maybe<Tag>[]) => {
  const status = _.find(tags, (tag) => _.startsWith(tag?.id, 'status-'));
  return status?.name;
};

const tagToType = (tags: Maybe<Tag>[]) => {
  const type = _.find(
    tags,
    (tag) => tag?.id === 'tactical' || tag?.id === 'strategic',
  );
  return type?.name;
};

const KRAIds = KRAs.map((KRA) => KRA.value);
const horizonValues = [
  ...horizonTags.map((horizon) => horizon.value),
  'intent',
];

const InitTableRow = (props: InitTableRowProps) => {
  const { initiative, provided, tableRef } = props;
  const alert = useAlert();

  const [
    updateInitiativeTags,
    updateInitiativeAttributes,
    updateDocumentInitiativeAssignee,
    toggleDocumentInitiativeSelection,
    isSelected,
  ] = useBoundStore((state) => [
    state.updateDocumentInitiativeTags,
    state.updateDocumentInitiativeAttributes,
    state.updateDocumentInitiativeAssignee,
    state.toggleDocumentInitiativeSelection,
    state.masterlist.selectedInitiativeIds.includes(initiative.id),
  ]);

  const initiativeHorizon = topicToHorizon(initiative);

  const initiativeKRA = topicToKRA(withDefault(initiative.topicKey, ''));
  const initiativePriority = tagToPriority(withDefault(initiative.tags, []));
  const initiativeBusinessRisk = tagToBusinessRisk(
    withDefault(initiative.tags, []),
  );
  const initiativeType = tagToType(withDefault(initiative.tags, []));
  const initiativeCompletionTarget = tagToCompletionTarget(
    withDefault(initiative.tags, []),
  );
  const initiativeStatus = tagToStatus(withDefault(initiative.tags, []));

  const updatePriority = useCallback(
    async (newPriority: string) => {
      const withoutPriorityTags = withDefault(initiative.tags, [])
        .filter(isJust)
        .filter((tag) => !_.startsWith(tag?.id, 'priority-'));
      const fullTag = _.find(
        priorities,
        (priority) => priority.value === newPriority,
      );

      if (!fullTag) return;

      const newTags = _.concat(withoutPriorityTags, {
        name: fullTag.name,
        id: fullTag.value,
        color: fullTag.color,
      });

      await updateInitiativeTags(
        initiative.documentId || '',
        initiative.id,
        newTags,
      );

      alert.success('Priority updated');
    },
    [initiative.tags],
  );

  const updateTargetEnd = useCallback(
    async (newTargetEnd: string) => {
      const withoutTargetEndTags = withDefault(initiative.tags, [])
        .filter(isJust)
        .filter((tag) => !_.startsWith(tag?.id, 'within-'));
      const fullTag = _.find(
        completionTargets,
        (priority) => priority.value === newTargetEnd,
      );

      if (!fullTag) return;

      const newTags = _.concat(withoutTargetEndTags, {
        name: fullTag.name,
        id: fullTag.value,
        color: fullTag.color,
      });

      await updateInitiativeTags(
        initiative.documentId || '',
        initiative.id,
        newTags,
      );

      alert.success('Target end updated');
    },
    [initiative.tags],
  );

  const updateBusinessRisk = useCallback(
    async (newBusinessRisk: string) => {
      const withoutBusinessRiskTags = withDefault(initiative.tags, [])
        .filter(isJust)
        .filter((tag) => !_.startsWith(tag?.id, 'risk-'));
      const fullTag = _.find(
        businessRisks,
        (businessRisk) => businessRisk.value === newBusinessRisk,
      );

      if (!fullTag) return;

      const newTags = _.concat(withoutBusinessRiskTags, {
        name: fullTag.name,
        id: fullTag.value,
        color: fullTag.color,
      });

      await updateInitiativeTags(
        initiative.documentId || '',
        initiative.id,
        newTags,
      );

      alert.success('Business risk updated');
    },
    [initiative.tags],
  );

  const updateType = useCallback(
    async (newType: string) => {
      const fullTag = _.find(
        initiativeTypes,
        (initiativeType) => initiativeType.value === newType,
      );

      if (!fullTag) return;

      const withoutTypeTags = withDefault(initiative.tags, [])
        .filter(isJust)
        .filter((tag) => tag.id !== 'tactical' && tag.id !== 'strategic');

      const newTags = _.concat(withoutTypeTags, {
        name: fullTag.name,
        id: fullTag.value,
        color: fullTag.color,
      });

      await updateInitiativeTags(
        initiative.documentId || '',
        initiative.id,
        newTags,
      );

      alert.success('Type updated');
    },
    [initiative.tags],
  );

  const updateKRA = useCallback(
    async (newKRA: string) => {
      KRAIds.forEach(async (KRAId) => {
        if (initiative.topicKey && initiative.topicKey.includes(KRAId)) {
          const newTopicKey = initiative.topicKey.replace(KRAId, newKRA);
          await updateInitiativeAttributes(
            initiative.documentId as string,
            initiative.id,
            {
              topicKey: newTopicKey,
            },
          );
        }
      });
    },
    [initiative.topicKey],
  );

  const updateHorizon = useCallback(
    async (newHorizon: string) => {
      const updateHorizonParams = {
        ohdpHorizon: newHorizon,
      } as Record<string, any>;

      // Check if horizon exists in topicKey, if yes then update the topicKey as well

      if(initiative.topicKey?.includes('horizon')) {
        horizonValues.forEach(async (horizonValue) => {
            if (initiative.topicKey && initiative.topicKey.includes(horizonValue)) {
              const newTopicKey = initiative.topicKey.replace(
                horizonValue,
                newHorizon,
              );
              updateHorizonParams.topicKey = newTopicKey;
            }
          });
      } else {
        if (initiative.topicKey === 'strategic-objectives-shareholder-value') {
          const newTopicKey = 'strategic-execution-initiatives-' + newHorizon + '-shareholder-value';
          updateHorizonParams.topicKey = newTopicKey;
        } else if (initiative.topicKey === 'strategic-objectives-systems-and-processes') {
          const newTopicKey = 'strategic-execution-initiatives-' + newHorizon + '-systems-and-processes';
          updateHorizonParams.topicKey = newTopicKey;
        } else if (initiative.topicKey === 'strategic-objectives-risk') {
          const newTopicKey = 'strategic-execution-initiatives-' + newHorizon + '-risk';
          updateHorizonParams.topicKey = newTopicKey;
        } else if (initiative.topicKey === 'strategic-objectives-people') {
          const newTopicKey = 'strategic-execution-initiatives-' + newHorizon + '-people';
          updateHorizonParams.topicKey = newTopicKey;
        } else if (initiative.topicKey === 'strategic-objectives-customers-and-markets') {
          const newTopicKey = 'strategic-execution-initiatives-' + newHorizon + '-customers-and-markets';
          updateHorizonParams.topicKey = newTopicKey;
        } else if (initiative.topicKey === 'strategic-objectives-products-and-services') {
          const newTopicKey = 'strategic-execution-initiatives-' + newHorizon + '-products-and-services';
          updateHorizonParams.topicKey = newTopicKey;
        }
      }

      await updateInitiativeAttributes(
        initiative.documentId as string,
        initiative.id,
        updateHorizonParams,
      );

      alert.success('Horizon updated');
    },
    [initiative.topicKey],
  );

  const updateStatus = useCallback(
    async (newStatus: string) => {
      const withoutStatusTags = withDefault(initiative.tags, [])
        .filter(isJust)
        .filter((tag) => !_.startsWith(tag?.id, 'status-'));
      const fullTag = _.find(
        initiativeStatuses,
        (initiativeStatus) => initiativeStatus.value === newStatus,
      );

      if (!fullTag) return;
      const newTags = _.concat(withoutStatusTags, {
        name: fullTag.name,
        id: fullTag.value,
        color: fullTag.color,
      });

      await updateInitiativeTags(
        initiative.documentId || '',
        initiative.id,
        newTags,
      );
    },
    [initiative.tags],
  );

  const updateTextAttributes =
    (attributesName: string) => async (value: string | string[]) =>
      updateInitiativeAttributes(
        initiative.documentId as string,
        initiative.id,
        {
          [attributesName]: value,
        },
      );
  return (
    <tr ref={(ref) => provided.innerRef(ref)} {...provided.draggableProps}>
      <td {...provided.dragHandleProps}>
        <Handler />
      </td>
      <td>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() =>
            toggleDocumentInitiativeSelection(
              initiative.documentId as string,
              initiative.id,
            )
          }
        />
      </td>
      <td>{initiative.documentName}</td>
      <td>
        <TagDropDown
          options={typeTags}
          currentValue={initiativeType}
          onSelect={updateType}
          tableRef={tableRef}
        />
      </td>
      <td>
        <TagDropDown
          options={priorityTags}
          currentValue={initiativePriority}
          onSelect={updatePriority}
          tableRef={tableRef}
        />
      </td>
      <td>
        <TagDropDown
          options={horizonTags}
          currentValue={initiativeHorizon}
          onSelect={updateHorizon}
          tableRef={tableRef}
        />
      </td>
      <td>
        <TagDropDown
          options={businessRiskTags}
          currentValue={initiativeBusinessRisk}
          onSelect={updateBusinessRisk}
          tableRef={tableRef}
        />
      </td>
      <td>
        <TagDropDown
          options={KRAs}
          currentValue={initiativeKRA}
          onSelect={updateKRA}
          tableRef={tableRef}
        />
      </td>
      <td>
        <InitOwner
          selectedUserIds={withDefault(initiative.assigneeIDs, []).filter(
            isJust,
          )}
          onSelect={(newAssigneeIds) =>
            updateDocumentInitiativeAssignee(
              initiative.documentId as string,
              initiative.id,
              newAssigneeIds,
            )
          }
        />
      </td>
      <td>
        <TableInlineText
          placeholder="Description"
          onSave={updateTextAttributes('name')}
          defaultValue={initiative.name}
          justText={true}
        />
      </td>
      <td>
        <TableInlineText
          placeholder="Strategic Objectives"
          onSave={updateTextAttributes('strategicObjective')}
          defaultValue={withDefault(initiative.strategicObjective, '')}
        />
      </td>
      <td>
        <KPIList>
          {withDefault(initiative.keyResults, [])
            .filter(isJust)
            .map((keyResult) => (
              <KPIItem key={keyResult.id}>
                <FontAwesomeIcon icon={faStar} size="sm" />{' '}
                <span>{keyResult.name}</span>
              </KPIItem>
            ))}
        </KPIList>
      </td>
      <td>
        <TableInlineText
          placeholder="Success Factors"
          onSave={(newFactor) =>
            updateTextAttributes('successFactors')([newFactor])
          }
          defaultValue={withDefault(initiative.successFactors?.[0], '')}
        />
      </td>
      <td>
        <TableInlineText
          placeholder="Comments"
          onSave={updateTextAttributes('comments')}
          defaultValue={withDefault(initiative.comments, '')}
        />
      </td>
      <td>
        <TableInlineText
          placeholder="Investments"
          onSave={updateTextAttributes('investments')}
          defaultValue={withDefault(initiative.investments, '')}
        />
      </td>
      <td>
        <TagDropDown
          options={completionTargetTags}
          currentValue={initiativeCompletionTarget}
          onSelect={updateTargetEnd}
          tableRef={tableRef}
        />
      </td>
      <td>
        <TagDropDown
          options={statusTags}
          currentValue={initiativeStatus}
          onSelect={updateStatus}
          tableRef={tableRef}
        />
      </td>
    </tr>
  );
};

export default InitTableRow;
