import React from 'react';
import styled from 'styled-components';

const UserControlMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  width: 200%;
`;

const UserControlItem = styled.span`
  padding: 8px 20px;

`;

const UserControlPopover: React.FC = () => {
  return (
    <UserControlMenu>
      <UserControlItem>Back to Plan</UserControlItem>
      <UserControlItem>Log out</UserControlItem>
    </UserControlMenu>
  );
};

export default UserControlPopover;
