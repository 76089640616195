import styled from "styled-components";

const ItemList = styled.div`
  grid-area: entries / entries / entries / entries;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
`;

export default ItemList;
