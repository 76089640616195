import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import SubfieldMultiSelect from './SubfieldMultiSelect';
import FieldContainer from '../FieldContainer/FieldContainer';
import StyledErrorMessage from '../ErrorMessage/ErrorMessage';
import { Template } from '../../common/type';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type ContextTemplateInput = {
  name: string;
  label: string;
  defaultValue?: string[];
  options: Template[];
  hasAvatar?: boolean;
};

const ListContainer = styled(FieldContainer)`
  min-height: 0px;
`;

const ErrorSpace = styled.div`
  width: 100%;
  height: 29px;
`;

const ContextTemplateList: React.FC<ContextTemplateInput> = ({
  name,
  label,
  defaultValue = [],
  options,
  hasAvatar,
}) => {
  const { control, errors } = useFormContext();
  return (
    <ListContainer>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={(controllerProps) => (
          <SubfieldMultiSelect
            label={label}
            onSelectionChange={controllerProps.onChange}
            value={controllerProps.value}
            items={options}
            hasError={_.get(errors, name, false)}
            hasAvatar={hasAvatar}
          />
        )}
      />
      {!_.get(errors, name, false) && <ErrorSpace />}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <StyledErrorMessage>
            {message.replace(name, label)}
          </StyledErrorMessage>
        )}
      />
    </ListContainer>
  );
};

export default ContextTemplateList;
