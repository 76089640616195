import React from 'react';
import styled from 'styled-components';
import DesignerNavigation from '../DesignerNavigation/DesignerNavigation';

const DesignerLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const DesignerContainer: React.FC = ({ children }) => {
  return (
    <DesignerLayout>
      <DesignerNavigation />
      {children}
    </DesignerLayout>
  );
};

export default DesignerContainer;
