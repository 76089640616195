import _ from 'lodash';
import { Maybe } from '@propella/core';

export const isJust = <T>(maybeValue: Maybe<T> | undefined): maybeValue is T =>
  !!maybeValue && !_.isUndefined(maybeValue) && !_.isNull(maybeValue);

export const isNothing = <T>(maybeValue: Maybe<T> | undefined) =>
  !isJust(maybeValue);

export const withDefault = <T>(
  maybeValue: Maybe<T> | undefined,
  defaultValue: T,
): T => (isJust(maybeValue) ? maybeValue : defaultValue);
