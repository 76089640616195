import React from 'react';
import styled from 'styled-components';
import LegendTitle from './LegendTitle';

const WithColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const ColorBlock = styled.div<Pick<WithColorProps, 'color'>>`
  width: 96px;
  height: 25px;

  background-color: ${({ color }) => color};
  border-radius: 2px;
`;

export type WithColorProps = {
  name: string;
  color: string;
  className?: string;
};

const WithColor = ({ name, color, className }: WithColorProps) => {
  return (
    <WithColorContainer className={className}>
      <LegendTitle>{name}</LegendTitle>
      <ColorBlock color={color} />
    </WithColorContainer>
  );
};

export default WithColor;
