import { DocumentScope, Entry } from '@propella/core';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { StateCreator } from 'zustand';
import { documentScopeQuery } from '../graphql/queries';
import { DocumentSlice, OrganizationSlice, StoreInterface } from './interfaces';
import produce, { createDraft } from 'immer';
import { isJust, withDefault } from '../maybe';
import _ from 'lodash';
import { createEntry, deleteEntry, updateEntry } from '../graphql/mutations';

export const createDocumentSlice: StateCreator<
  StoreInterface,
  [],
  [],
  DocumentSlice
> = (set, get) => ({
  document: {
    isReady: false,
    id: undefined,
    template: undefined,
    entries: {},
    entryRank: [],
    domains: {},
    topics: {},
    views: {
      canvas: undefined,
      list: undefined,
    },
  },
  initDocument: async (documentID) => {
    set(
      produce<DocumentSlice>((draft) => {
        draft.document.isReady = false;
      }),
    );

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(documentScopeQuery, {
          id: documentID,
        }),
      )) as GraphQLResult<{
        documentScope: DocumentScope;
      }>;

      const { data, errors } = queryResult;

      // @TODO: Handle errors as global
      if (errors) {
        throw new Error(errors[0].message);
      }

      if (!data || !data.documentScope.document) {
        throw new Error("Couldn't get document");
      }

      const parsedEntries = (
        data.documentScope.document?.entries?.items || []
      ).filter(isJust);

      const entryRank = (data.documentScope.document?.entryRank || []).filter(
        isJust,
      );

      const domains = (data.documentScope.document?.domains || []).filter(
        isJust,
      );

      const iterations = (data.documentScope.iterations || []).filter(isJust);
      const currentIteration = iterations.find(
        (iter) => iter.id === data.documentScope?.currentIterationID,
      );
      const entriesById = _.keyBy(parsedEntries, 'id');
      const topicEntries: { [key: string]: string[] } = withDefault(
        data.documentScope?.document?.entryRank,
        [],
      )
        .filter(isJust)
        .reduce((acc, entryID) => {
          return produce<{ [key: string]: string[] }>(acc, (draft) => {
            const entry = entriesById[entryID];

            if (entry) {
              const entryTags = (entry.tags || []).filter(isJust);

              entryTags.forEach((tag) => {
                draft[tag] = draft[tag] || [];
                draft[tag].push(entry.id);
              });
            }
          });
        }, {});

      const domainByKey = _.keyBy(domains, 'key');

      const topics = (data.documentScope.document?.topics || [])
        .filter(isJust)
        .map((topic) => ({
          ...topic,
          sortedEntryIds: topicEntries[topic.key] || [],
          fullDomain: domainByKey[withDefault(topic.domain, 'default')],
        }));

      set(
        produce<DocumentSlice & OrganizationSlice>((draft) => {
          draft.document.id = data.documentScope?.document?.id;
          draft.document.name = data.documentScope?.document?.name;
          draft.document.entries = _.keyBy(parsedEntries, 'id');
          draft.document.entryRank = entryRank;
          draft.document.domains = domainByKey;
          draft.document.template = data.documentScope?.document?.template;
          draft.document.topics = _.keyBy(topics, 'key');
          draft.document.isUserTemplate =
            !!data.documentScope?.document?.userTemplate;

          draft.document.views = {
            canvas: withDefault(
              data.documentScope.document?.views?.grid,
              undefined,
            ),
            list: withDefault(
              data.documentScope.document?.views?.list,
              undefined,
            ),
          };

          draft.organization.iterations = iterations;
          draft.organization.currentIteration = currentIteration;
        }),
      );
    } catch (error: any) {
      throw new Error(error.errors[0].message);
    } finally {
      set(
        produce<DocumentSlice>((draft) => {
          draft.document.isReady = true;
        }),
      );
    }
  },
  createEntry: async (entryContent, topicKey) => {
    const documentID = get().document.id;

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(createEntry, {
          input: {
            documentID,
            name: entryContent,
            tags: [topicKey],
          },
        }),
      )) as GraphQLResult<{
        createEntry: Entry;
      }>;

      const newEntry = queryResult.data?.createEntry;
      if (newEntry) {
        get().addEntry(newEntry);
      }
    } catch (error) {
      console.log(error);
    }
  },
  addEntry: (newEntry) => {
    const topicKey = isJust(newEntry.tags) ? newEntry.tags[0] : null;

    set(
      produce<DocumentSlice>((draft) => {
        if (!draft.document.entries[newEntry.id]) {
          draft.document.entries[newEntry.id] = newEntry;
          draft.document.entryRank.push(newEntry.id);

          if (topicKey) {
            draft.document.topics[topicKey].sortedEntryIds.push(newEntry.id);
          }
        }
      }),
    );
  },
  deleteEntry: async (entryID) => {
    set(
      produce<DocumentSlice>((draft) => {
        draft.document.entries[entryID].deleting = true;
      }),
    );

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(deleteEntry, {
          input: {
            id: entryID,
          },
        }),
      )) as GraphQLResult<{
        deleteEntry: Entry;
      }>;

      const removedEntry = queryResult.data?.deleteEntry;
      if (removedEntry) {
        get().removeEntry(removedEntry);
      }
    } catch (error: any) {
      set(
        produce<DocumentSlice>((draft) => {
          draft.document.entries[entryID].deleting = false;
        }),
      );
      throw new Error('There are some errors occur when deleting the entry.');
      // @TODO: global error
    }
  },
  removeEntry: (removedEntry) => {
    set(
      produce<DocumentSlice>((draft) => {
        delete draft.document.entries[removedEntry.id];
        draft.document.entryRank = draft.document.entryRank.filter(
          (entryID) => entryID !== removedEntry.id,
        );

        (removedEntry.tags || []).filter(isJust).forEach((topicKey) => {
          draft.document.topics[topicKey].sortedEntryIds =
            draft.document.topics[topicKey].sortedEntryIds.filter(
              (entryId) => entryId !== removedEntry.id,
            );
        });
      }),
    );
  },
  toggleEntryEditing: (entryID, isEditing) => {
    set(
      produce<DocumentSlice>((draft) => {
        draft.document.entries[entryID].editing = isEditing;
      }),
    );
  },
  editEntry: async (entryID, entryContent) => {
    try {
      const updateEntryResult = (await API.graphql(
        graphqlOperation(updateEntry, {
          input: {
            id: entryID,
            name: entryContent,
          },
        }),
      )) as GraphQLResult<{
        updateEntry: Entry;
      }>;
      const updatedEntry = updateEntryResult.data?.updateEntry;

      if (updatedEntry) {
        get().patchEntry(updatedEntry);
      }
    } catch (error: any) {
      // @TODO: global error
      set(
        produce<DocumentSlice>((draft) => {
          draft.document.entries[entryID].editing = false;
        }),
      );
    }
  },
  patchEntry: (entry) => {
    set(
      produce<DocumentSlice>((draft) => {
        draft.document.entries[entry.id] = entry;
      }),
    );
  },
  transitEntry: async (
    entryID,
    targetTopicKey,
    rankAfterEntryId,
    rankBeforeEntryId,
  ) => {
    const entry = get().document.entries[entryID];
    const sourceTopicKey = _.head(withDefault(entry.tags, []).filter(isJust));

    set(
      produce<DocumentSlice>((draft) => {
        draft.document.entryRank = draft.document.entryRank.filter(
          (entryRankId) => entryRankId !== entryID,
        );
        // Update entry ranking
        if (!rankBeforeEntryId) {
          draft.document.entryRank = _.concat(draft.document.entryRank, [
            entryID,
          ]);
        } else if (!rankAfterEntryId) {
          draft.document.entryRank = _.concat(
            [entryID],
            draft.document.entryRank,
          );
        } else {
          draft.document.entryRank.splice(
            draft.document.entryRank.indexOf(rankBeforeEntryId),
            0,
            entryID,
          );
        }

        if (sourceTopicKey) {
          // Remove entry from original topic
          draft.document.topics[sourceTopicKey].sortedEntryIds =
            draft.document.topics[sourceTopicKey].sortedEntryIds.filter(
              (entryId) => entryId !== entryID,
            );
        }

        draft.document.topics[targetTopicKey].sortedEntryIds =
          draft.document.topics[targetTopicKey].sortedEntryIds
            .filter((entryId) => entryId !== entryID)
            .concat([entryID])
            .sort(
              (firstEntryId, secondEntryId) =>
                draft.document.entryRank.indexOf(firstEntryId) -
                draft.document.entryRank.indexOf(secondEntryId),
            );

        draft.document.entries[entryID].tags = [targetTopicKey];
      }),
    );
  },
});
