import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'styled-react-modal';
import { useHistory } from 'react-router-dom';
import {
  getOrganizationForSetting,
  getOrgDocumentTemplate,
} from '../../graphql/queries';
import { isJust, withDefault } from '../../maybe';
import PageHeader from '../../components/PageHeader/PageHeader';
import RectButton from '../../components/Button/RectButton';
import Loading from '../../components/AppLoading';
import Box from '../../components/Box';
import { Table } from '../../components/Table';
import TableHeader from '../../components/Table/TableHeader';
import TableColumn from '../../components/Table/TableColumn';
import TableBody from '../../components/Table/TableBody';
import TableRow from '../../components/Table/TableRow';
import TableCell from '../../components/Table/TableCell';
import SwitchBar from '../../components/SwitchBar';
import { SwitchBarOption } from '../../components/SwitchBar/SwitchBar';
import {
  ADMIN_PERMISSION,
  PLAN_PERMISSIONS,
  CONTRIBUTE_PERMISSIONS,
  OKR_PERMISSIONS,
} from '../../common/constant';
import {
  DocumentTemplateSetting,
  Organization,
  Template,
} from '../../common/type';
import _ from 'lodash';
import { getDocumentTemplates } from '../../graphql/queries';
import { FormProvider, useForm } from 'react-hook-form';
import ContextDraggableMultiSelect from '../../components/DraggableMultiSelect/ContextDraggableMultiSelect';
import {
  updateActiveTemplate,
  updateEnabledModules,
  shareDocumentTemplate,
} from '../../graphql/mutations';
import {
  UpdateActiveTemplateOutput,
  UpdateEnabledModulesOutput,
} from '@propella/core';
import { useAlert } from 'react-alert';
import ContextMultiSelect from '../../components/ContextMultiSelect/ContextMultiSelect';
import { useBoundStore } from '../../states';
import OrganizationDetails from './OrganizationDetails';
import Select from '../../components/Select/Select';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
  height: 100%;
`;

const StyledRectButton = styled(RectButton)`
  color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  background-color: ${(props) => props.theme.colors.white};
  border-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryRectBorder
      : props.theme.colors.item.hover};
  :hover {
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
    color: ${(props) => props.theme.colors.white};
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryRectBorder
        : props.theme.colors.item.hover};
  }
`;

const PermissionList = styled.ul`
  display: block;
  margin: 0;
  list-style: none;
  padding: 0;
  word-wrap: break-word;
  word-break: break-word;
  li {
    margin: 0;
    padding: 0;
  }
`;

const PlusIcon = styled(FontAwesomeIcon)`
  top: 0;
  left: 0;
  color: #ffffff;
  margin-right: 10px;
`;

const FilterBarSection = styled.section`
  margin: 32px 0;
  width: 100%;
`;

const ItalicText = styled.span`
  font-style: italic;
`;

const BoldText = styled.span`
  font-style: bold;
`;
const ButtonsContainer = styled.div`
  display: flex;
`;


const SelectContainer = styled.div`
  width: 220px;
  .react-select__control {
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    :before {
      content: '';
      display: block;
      border-radius: 5px;
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
  }
  .react-select__option {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
`;

const StyledModal = styled.div`
  width: 40rem;
  max-height: 60%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 220px;
  margin-bottom: 150px;
  padding-bottom: 30px;
  border-radius: 15px;
`;

const ConfirmButtonsContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
`;

type GetOrgDocumentQueryResult = {
  getOrgDocumentTemplates: {
    templates: DocumentTemplateSetting[];
  };
};

type GetOrganizationOutput = {
  getOrganization: Organization;
};

type DocumentTemplateOutput = {
  getDocumentTemplates: {
    templates: DocumentTemplateSetting[];
  };
};

// const SYSTEM_DOCUMENTS = [
//   {
//     id: 'competitiveLandscape',
//     name: 'Competitive Landscape',
//   },
//   {
//     id: 'businessModelCanvas',
//     name: 'Business Model Canvas',
//   },
//   {
//     id: 'strategicAgilityPlan',
//     name: 'Strategic Agility Plan',
//   },
//   {
//     id: 'strategicInsights',
//     name: 'Strategic Insights',
//   },
//   {
//     id: 'onePageStrategicAgilityPlan',
//     name: 'One Page Strategic Agility Plan',
//   },
// ];

// const updateTemplateSchema = yup.object().shape({
//   documentTemplates: yup.array().of(yup.string()).min(1),
// });

const updateModuleSchema = yup.object().shape({
  enabledModules: yup.array().of(yup.string()).min(0),
});

const settingTabs: SwitchBarOption[] = [
  {
    label: 'Details',
    value: 'details',
  },
  {
    label: 'Roles',
    value: 'roles',
  },
  {
    label: 'Members',
    value: 'members',
  },
  {
    label: 'Modules',
    value: 'modules',
  },
];

const OrganizationSettings = () => {
  const { currentMembership } = useBoundStore((state) => state.auth);
  const alert = useAlert();
  const [fetching, setFetching] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [toBeDeletedRoleId, setToBeDeletedRoleId] = useState<string>('');
  // const [templates, setTemplates] = useState<Template[]>([]);
  const [activeTemplates, setActiveTemplates] = useState<Template[]>([]);
  const [templates, setTemplates] = useState<DocumentTemplateSetting[]>([]);
  const [organization, setOrganization] = useState<Organization>();
  const [currentTab, setCurrentTab] = useState<SwitchBarOption>(settingTabs[0]);

  // const updateTemplateFormMethods = useForm({
  //   resolver: yupResolver(updateTemplateSchema),
  // });
  const fetchOrgState = useBoundStore((state) => state.fetchOrganization);
  const [isReady, orgRoles, orgInvitations, orgMembership] = useBoundStore(
    (state) => [
      state.organization.isReady,
      withDefault(state.organization.roles, []).filter(isJust),
      state.organization.invitations,
      state.organization.memberships,
    ],
  );
  const [updateMemberRole, deleteOrganizationRole] = useBoundStore((state) => [
    state.updateMemberRole,
    state.deleteOrganizationRole,
  ]);

  const updateMemberRoleAction = async (
    membershipID: string,
    roleID: string,
  ) => {
    await updateMemberRole(membershipID, roleID);
    alert.success('Changes are saved.');
  };

  const updateModuleFormMethods = useForm({
    resolver: yupResolver(updateModuleSchema),
  });

  const deleteOrgRoleAction = async (id: string) => {
    try {
      await deleteOrganizationRole(id);
      setIsOpenDeleteModal(false);
      alert.success('Changes are saved.');
    } catch (error: any) {
      setIsOpenDeleteModal(false);
      alert.error(error.message);
    }
  };

  const fetchOrg = async (organizationID: string): Promise<void> => {
    setFetching(true);

    const [orgQueryResult, templateQueryResult] = await Promise.all([
      (await API.graphql(
        graphqlOperation(getOrganizationForSetting, {
          id: organizationID,
        }),
      )) as GraphQLResult<GetOrganizationOutput>,
      (await API.graphql(
        graphqlOperation(getOrgDocumentTemplate),
      )) as GraphQLResult<GetOrgDocumentQueryResult>,
    ]);
    const fetchedOrg = orgQueryResult.data?.getOrganization;
    if (fetchedOrg) {
      setOrganization(fetchedOrg);
    }

    // let allTemplates: Template[];
    // if (templateQueryResult.data) {
    //   allTemplates = [
    //     ...SYSTEM_DOCUMENTS,
    //     ...templateQueryResult.data.getOrgDocumentTemplates.templates.map(
    //       (template) => _.pick(template, ['id', 'name', 'stage']) as Template,
    //     ),
    //   ];
    // } else {
    //   allTemplates = SYSTEM_DOCUMENTS;
    // }

    // if (fetchedOrg?.activeTemplates && fetchedOrg?.activeTemplates.length) {
    //   const nonActiveTemplate = allTemplates.filter(
    //     (template) =>
    //       !fetchedOrg?.activeTemplates.find(
    //         (activeTemplate) => activeTemplate.id === template.id,
    //       ),
    //   );
    //   const sortedActiveTemplate = fetchedOrg?.activeTemplates
    //     .sort((a, b) => (a.order && b.order && a.order > b.order ? 1 : -1))
    //     .map((template) => allTemplates.find((el) => el.id === template.id))
    //     .filter((template) => !!template);

    //   setTemplates([
    //     ...(sortedActiveTemplate as Template[]),
    //     ...nonActiveTemplate,
    //   ]);
    // } else {
    //   setTemplates(allTemplates);
    // }

    if (fetchedOrg?.enabledModules) {
      updateModuleFormMethods.setValue(
        'enabledModules',
        fetchedOrg.enabledModules,
      );
    }

    setFetching(false);
  };

  useEffect(() => {
    const organizationId = withDefault(
      currentMembership?.organization?.id || '',
      '',
    );

    if (organizationId === '') return;
    fetchOrgState(organizationId);
    fetchOrg(organizationId);
  }, [currentMembership]);

  // const updateOrgActiveTemplates = async () => {
  //   const organizationId = withDefault(currentMembership?.organization?.id, '');

  //   const queryResult = (await API.graphql(
  //     graphqlOperation(updateActiveTemplate, {
  //       input: {
  //         organizationID: organizationId,
  //         activeTemplates: activeTemplates,
  //       },
  //     }),
  //   )) as GraphQLResult<{
  //     updateActiveTemplate: UpdateActiveTemplateOutput;
  //   }>;

  //   const updatedOrgActiveTemplates =
  //     queryResult.data?.updateActiveTemplate.activeTemplates;

  //   if (updatedOrgActiveTemplates && organization) {
  //     setOrganization({
  //       ...organization,
  //       activeTemplates: updatedOrgActiveTemplates.filter(isJust) as Template[],
  //     });
  //   }
  //   alert.success('Changes are saved.');
  // };

  const updateOrgEnabledModules = async (data: {
    enabledModules: string[];
  }) => {
    const organizationId = withDefault(currentMembership?.organization?.id, '');

    const queryResult = (await API.graphql(
      graphqlOperation(updateEnabledModules, {
        input: {
          organizationID: organizationId,
          enabledModules: data.enabledModules,
        },
      }),
    )) as GraphQLResult<{
      updateEnabledModules: UpdateEnabledModulesOutput;
    }>;

    const updatedEnabledModules = queryResult.data?.updateEnabledModules;

    if (updatedEnabledModules && organization) {
      setOrganization({
        ...organization,
        enabledModules: updatedEnabledModules.enabledModules,
      });
    }

    alert.success('Changes are saved.');
  };

  const openDeleteModal = (id: string) => {
    setIsOpenDeleteModal(true);
    setToBeDeletedRoleId(id);
  };

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
    setToBeDeletedRoleId('');
  };

  useEffect(() => {
    const getUserDocuments = async (): Promise<void> => {
      setFetching(true);
      const queryResult = (await API.graphql(
        graphqlOperation(getDocumentTemplates),
      )) as GraphQLResult<DocumentTemplateOutput>;
      const fetchedTemplates =
        queryResult?.data?.getDocumentTemplates.templates;
      if (fetchedTemplates) {
        setTemplates(fetchedTemplates);
      }
      setFetching(false);
    };

    getUserDocuments();
  }, []);

  if (fetching || !isReady) {
    return <Loading />;
  }

  return (
    <PageContainer data-cy={organization?.name}>
      <PageHeader header={organization?.name} variant="light" />
      <FilterBarSection>
        <SwitchBar
          options={settingTabs}
          selectedOption={currentTab}
          onSelect={(selectedOpt) => setCurrentTab(selectedOpt)}
        />
      </FilterBarSection>
      {currentTab.value === 'roles' && (
        <Container>
          <Row>
            <Col col={12}>
              <Box
                header="Roles"
                actionComponent={
                  <Link to="/organization/new-role">
                    <RectButton>
                      <PlusIcon size="1x" icon={faPlus} />
                      Add a new role
                    </RectButton>
                  </Link>
                }
              >
                <Table>
                  <TableHeader>
                    <tr>
                      <TableColumn>Organization Role</TableColumn>
                      <TableColumn>Permissions</TableColumn>
                      <TableColumn />
                      <TableColumn />
                    </tr>
                  </TableHeader>
                  <TableBody>
                    {orgRoles.map((role, index) => (
                      <TableRow key={String(index)}>
                        <TableCell>{role.name}</TableCell>
                        <TableCell>
                          <PermissionList>
                            {withDefault(role.grantedActions, []).map(
                              (action, index) => (
                                <li key={String(index)}>
                                  {
                                    ADMIN_PERMISSION.find(
                                      (item) => item.id === action,
                                    )?.name
                                  }
                                  {
                                    PLAN_PERMISSIONS.find(
                                      (item) => item.id === action,
                                    )?.name
                                  }
                                  {
                                    CONTRIBUTE_PERMISSIONS.find(
                                      (item) => item.id === action,
                                    )?.name
                                  }
                                  {
                                    OKR_PERMISSIONS.find(
                                      (item) => item.id === action,
                                    )?.name
                                  }
                                </li>
                              ),
                            )}
                          </PermissionList>
                        </TableCell>
                        <TableCell align="right">
                          <StyledRectButton
                            onClick={() => openDeleteModal(role.id)}
                            variant="primary"
                          >
                            Delete
                          </StyledRectButton>
                        </TableCell>
                        <TableCell align="right">
                          <Link to={`/organization/role/${role.id}`}>
                            <RectButton variant="secondary">Edit</RectButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                    <Modal
                      isOpen={isOpenDeleteModal}
                      onBackgroundClick={() => closeDeleteModal()}
                      onEscapeKeydown={() => closeDeleteModal()}
                    >
                      <StyledModal>
                        <StyledLabel>
                          Do you want to delete this organization role?
                        </StyledLabel>
                        <ConfirmButtonsContainer>
                          <StyledRectButton
                            type="button"
                            onClick={() =>
                              deleteOrgRoleAction(toBeDeletedRoleId)
                            }
                          >
                            Yes
                          </StyledRectButton>
                          <StyledRectButton
                            type="button"
                            variant="secondary"
                            onClick={() => closeDeleteModal()}
                          >
                            No
                          </StyledRectButton>
                        </ConfirmButtonsContainer>
                      </StyledModal>
                    </Modal>
                  </TableBody>
                </Table>
              </Box>
            </Col>
          </Row>
        </Container>
      )}
      {currentTab.value === 'details' && (
        <OrganizationDetails organization={organization} />
      )}
      {/* {currentTab.value === 'templates' && (
        <Container>
          <Row>
            <Col col={12}>
              <FormProvider {...updateTemplateFormMethods}>
                <form
                  onSubmit={updateTemplateFormMethods.handleSubmit(
                    updateOrgActiveTemplates,
                  )}
                >
                  <Box
                    header="Document Templates"
                    actionComponent={
                      <ButtonsContainer>
                        <StyledButton to="/templates">
                          <RectButton variant="secondary">
                            Manage Templates
                          </RectButton>
                        </StyledButton>
                        <RectButton type="submit">Save changes</RectButton>
                      </ButtonsContainer>
                    }
                  >
                    <ContextDraggableMultiSelect
                      name="documentTemplates"
                      label=""
                      options={templates}
                      setActiveTemplates={setActiveTemplates}
                      defaultValue={
                        organization?.activeTemplates?.map(
                          (template) => template.id,
                        ) || []
                      }
                    />
                  </Box>
                </form>
              </FormProvider>
            </Col>
          </Row>
        </Container>
      ) */}
      {currentTab.value === 'modules' && (
        <Container>
          <Row>
            <Col col={12}>
              <FormProvider {...updateModuleFormMethods}>
                <form
                  onSubmit={updateModuleFormMethods.handleSubmit(
                    updateOrgEnabledModules,
                  )}
                >
                  <Box
                    header="Modules"
                    actionComponent={
                      <ButtonsContainer>
                        <RectButton type="submit">Save changes</RectButton>
                      </ButtonsContainer>
                    }
                  >
                    <ContextMultiSelect
                      options={[
                        {
                          id: 'okr',
                          name: 'OKR',
                        },
                        {
                          id: '100-dp',
                          name: '100-Day Planning',
                        },
                      ]}
                      defaultValue={withDefault(organization?.enabledModules, [
                        '100-dp',
                      ]).filter(isJust)}
                      name="enabledModules"
                      label=""
                    />
                  </Box>
                </form>
              </FormProvider>
            </Col>
          </Row>
        </Container>
      )}
      {currentTab.value === 'members' && (
        <Container>
          <Row>
            <Col col={12}>
              <Box
                header="Members"
                actionComponent={
                  <Link to="/organization/invite">
                    <RectButton>
                      <PlusIcon size="1x" icon={faPlus} />
                      Invite a new member
                    </RectButton>
                  </Link>
                }
              >
                <Table>
                  <TableHeader>
                    <tr>
                      <TableColumn>Full name</TableColumn>
                      <TableColumn>Email</TableColumn>
                      <TableColumn>Role</TableColumn>
                      <TableColumn>Status</TableColumn>
                      <TableColumn />
                    </tr>
                  </TableHeader>
                  <TableBody>
                    {withDefault(orgInvitations, [])
                      .filter(
                        (item) =>
                          !organization?.memberships?.items.find(
                            (member) =>
                              member.user && member.user.email === item.email,
                          ),
                      )
                      .map((member) => (
                        <TableRow key={member.id}>
                          <TableCell>{member.fullName}</TableCell>
                          <TableCell>{member.email}</TableCell>
                          <TableCell>{member.role?.name}</TableCell>

                          <TableCell>
                            <ItalicText>Pending</ItalicText>
                          </TableCell>
                          <TableCell align="right">
                            <RectButton
                              variant="secondary"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  window.location.protocol +
                                    '//' +
                                    window.location.hostname +
                                    '/signin/' +
                                    member.id,
                                );
                                alert.success(
                                  'Invite link copied to clipboard',
                                  { timeout: 2000 },
                                );
                              }}
                            >
                              Copy invite link
                            </RectButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    {withDefault(orgMembership, [])
                      .filter((member) => isJust(member))
                      .map(
                        (member) =>
                          isJust(member.user) &&
                          isJust(member.role) && (
                            <TableRow key={member.id}>
                              <TableCell>{member.user.fullName}</TableCell>
                              <TableCell>{member.user.email}</TableCell>
                              {member.role && (
                                <TableCell>
                                  <SelectContainer>
                                    <Select
                                      label=""
                                      defaultValue={{
                                        id: member.role.id || '',
                                        name: member.role.name || '',
                                      }}
                                      options={orgRoles}
                                      onSelectionChange={(newRoleID) => {
                                        if (isJust(member.id)) {
                                          updateMemberRoleAction(
                                            member.id,
                                            newRoleID,
                                          );
                                        }
                                      }}
                                      hasError={false}
                                    />
                                  </SelectContainer>
                                </TableCell>
                              )}
                              <TableCell>
                                <BoldText>Active</BoldText>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          ),
                      )}
                  </TableBody>
                </Table>
              </Box>
            </Col>
          </Row>
        </Container>
      )}
    </PageContainer>
  );
};

export default OrganizationSettings;
