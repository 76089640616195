import styled from 'styled-components';
import { LabelProps } from '../../common/type';

const Label = styled.label<LabelProps>`
  color: ${(props) =>
    props.hasError ? props.theme.colors.error : props.theme.colors.label};
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3333;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 12px;
`;

export default Label;
