import styled from 'styled-components';
import { ReactComponent as TagIcon } from './tag.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { Tag } from '@propella/core';

const TagItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 4px;

  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }
`;

type TagNameContainerProps = {
  strokeColor?: Tag['color'];
};

const TagNameContainer = styled.div<TagNameContainerProps>`
  display: flex;
  flex-direction: row;

  span {
    margin-left: 10px;
  }

  svg {
    stroke: ${(props) => props.strokeColor || '#AEAFAF'};
  }
`;

export type TagItemProps = {
  id: string;
  name: string;
  color?: Tag['color'];
  isSelected?: boolean;
  onSelect?: (id: string) => void;
};

const TagItem = (props: TagItemProps) => {
  const { id, name, color, isSelected = false, onSelect } = props;

  return (
    <TagItemContainer onClick={() => onSelect?.(id)}>
      <TagNameContainer strokeColor={color}>
        <TagIcon />
        <span>{name}</span>
      </TagNameContainer>
      {isSelected && <Checkmark />}
    </TagItemContainer>
  );
};

export default TagItem;
