import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Organization } from '@propella/core';
import { API, graphqlOperation } from 'aws-amplify';
import produce from 'immer';
import { StateCreator } from 'zustand';
import { cusGetOrganizations } from '../graphql/queries';
import { CustomerSupportSlice, StoreInterface } from './interfaces';

export const createCustomerSupportSlice: StateCreator<
  StoreInterface,
  [],
  [],
  CustomerSupportSlice
> = (set, get) => ({
  customerSupport: {
    organizations: [],
    isReady: false,
  },
  fetchOrganizations: async () => {
    set(
      produce<CustomerSupportSlice>((draft) => {
        draft.customerSupport.isReady = false;
      }),
    );
    const queryResult = (await API.graphql(
      graphqlOperation(cusGetOrganizations),
    )) as GraphQLResult<{
      cusGetOrganizations: Organization[];
    }>;

    set(
      produce<CustomerSupportSlice>((draft) => {
        draft.customerSupport.organizations =
          queryResult.data?.cusGetOrganizations || [];
        draft.customerSupport.isReady = true;
      }),
    );
  },
});
