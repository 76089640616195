import React from 'react';
import diff from 'node-htmldiff';
import styled from 'styled-components';
import CanvasTopicContainer from '../CanvasTopicContainer/CanvasTopicContainer';
import CanvasTopicHeader from '../CanvasTopicHeader/CanvasTopicHeader';
import CanvasItemList from '../CanvasItemList/CanvasItemList';
import BaseListItem from '../BaseListItem/BaseListItem';
import TopicIcons from '../TopicIcons/TopicIcons';
import {
  GridCell,
  Topic,
  Domain,
  EntryChange,
  UsersChangeType,
} from '../../common/type';
import EntryViewer from '../EntryViewer/EntryViewer';
import Contributors from '../Contributors/Contributors';
import ReactDOMServer from 'react-dom/server';

type ItemContainerProps = {
  type: string;
};

type CompareCanvasTopicInput = {
  cell: GridCell;
  domain: Domain;
  topic: Topic;
  changes?: EntryChange[];
  usersChange?: UsersChangeType[];
};

const ItemContainer = styled(BaseListItem)<ItemContainerProps>`
  color: ${(props) =>
    props.type === 'remove'
      ? props.theme.colors.borderItem
      : props.theme.colors.black};
  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  :last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: none;
  }
  border-radius: 0;
  justify-content: space-between;
  border-left: 5px solid;
  border-left-color: ${(props) => {
    if (props.type === 'create') return props.theme.colors.topic.create;
    if (props.type === 'remove') return props.theme.colors.error;
    if (props.type === 'update') return props.theme.colors.topic.update;
    return props.theme.colors.white;
  }};
  svg {
    color: ${(props) => props.theme.colors.borderItem};
  }
`;

const TopicNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopicName = styled.span`
  margin-left: 10px;
`;

const ContentChange = styled.p`
  flex-grow: 1;
`;

const DiffChange = styled.div`
  ins {
    color: green;
  }
  del {
    color: red;
  }
`;

const TopicContributors = styled.div`
  padding: 15px;
`;

const generateDiffContent = (entryFrom: any, entryTo: any) => {
  let from = '<p></p>';
  let to = '<p></p>';
  if (!!entryFrom) {
    from = ReactDOMServer.renderToString(
      <EntryViewer entry={JSON.parse(entryFrom) as JsonDocument} />,
    );
  }
  if (!!entryTo) {
    to = ReactDOMServer.renderToString(
      <EntryViewer entry={JSON.parse(entryTo) as JsonDocument} />,
    );
  }
  const diffResult = diff(from, to);
  return (
    <DiffChange
      dangerouslySetInnerHTML={{
        __html: diffResult,
      }}
    ></DiffChange>
  );
};

const CompareCanvasTopic: React.FC<CompareCanvasTopicInput> = ({
  cell,
  domain,
  topic,
  changes,
  usersChange,
}) => {
  const validateList = (editorID: string[], ownerID: string[]) => {
    if (editorID !== null && ownerID !== null && editorID !== ownerID) {
      return editorID.concat(ownerID);
    }
    return editorID;
  };

  return (
    <CanvasTopicContainer
      gridArea={[cell.y, cell.x, cell.y + cell.h, cell.x + cell.w]
        .map((x) => `${x}`)
        .join(' / ')}
      topBorderColor={domain.fgColor}
    >
      <CanvasTopicHeader>
        <TopicNameContainer>
          <TopicIcons topicName={topic.name} />
          <TopicName>{topic.name}</TopicName>
        </TopicNameContainer>
        <TopicContributors>
          {changes?.length !== 0 && (
            <Contributors usersChange={usersChange} limit={5} />
          )}
        </TopicContributors>
      </CanvasTopicHeader>
      <CanvasItemList>
        {changes?.map((change, index) => (
          <ItemContainer key={index} type={change.type}>
            {change.type === 'update' && (
              <ContentChange>
                {generateDiffContent(change.from, change.to)}
              </ContentChange>
            )}
            {change.type !== 'update' && (
              <ContentChange>
                <EntryViewer entry={JSON.parse(change.name) as JsonDocument} />
              </ContentChange>
            )}
            <Contributors
              listContributors={validateList(change.editorID, change.ownerID)}
              usersChange={usersChange}
              limit={5}
            />
          </ItemContainer>
        ))}
      </CanvasItemList>
    </CanvasTopicContainer>
  );
};

export default CompareCanvasTopic;
