import React from 'react';
import styled from 'styled-components';
import LegendContentContainer from './LegendContent';

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  margin-bottom: 20px;
`;

export type LegendProps = {
  children?: React.ReactNode;
  width?: string;
  name: string;
  columns?: number;
};

const Legend = ({ children, width, name, columns }: LegendProps) => {
  return (
    <LegendContainer>
      <Title>{name}</Title>
      <LegendContentContainer width={width} columns={columns}>{children}</LegendContentContainer>
    </LegendContainer>
  );
};

export default Legend;
