import styled from 'styled-components';

const Button = styled.button<ButtonProps>`
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding: 10px 30px;
  border: 1px solid;
  border-radius: 40px;
  border-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondaryBorder
      : props.theme.colors.button.primaryBackground};
  background-color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.white
      : props.theme.colors.button.primaryBackground};
  color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.button.secondary
      : props.theme.colors.white};
  :hover {
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryHoverBorder
        : props.theme.colors.button.primaryHoverBackground};
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.white
        : props.theme.colors.button.primaryHoverBackground};
    color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryHover
        : props.theme.colors.white};
  }
  :active {
    border-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryActiveBorder
        : props.theme.colors.button.primaryActiveBackground};
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.white
        : props.theme.colors.button.primaryActiveBackground};
    color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.button.secondaryActive
        : props.theme.colors.white};
  }
  :disabled {
    background: ${(props) => props.theme.colors.button.disabledBackground};
    border-color: ${(props) => props.theme.colors.button.disabledBackground};
    cursor: not-allowed;
    text-decoration: none;
    color: ${(props) => props.theme.colors.button.disabled} !important;
  }
`;

export default Button;
