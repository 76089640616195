import React from 'react';
import styled from 'styled-components';
import { GridCell, Maybe } from '@propella/core';

type StaticCellInput = {
  cell: GridCell;
  borderRadius?: string;
  borderWidth?: string;
};

export type StaticContainerProps = {
  gridArea: string;
  backgroundColor: Maybe<string> | undefined;
  headerColor: Maybe<string> | undefined;
  headerAlign: string;
};

const StaticContainer = styled.div<StaticContainerProps>`
  grid-area: ${(props) => props.gridArea};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.white};
  font-weight: 700;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: ${(props) => props.headerColor || props.theme.colors.black};
  text-align: ${(props) => props.headerAlign};
  min-width: 0;
  padding: 5px;
`;

const StaticCell: React.FC<StaticCellInput> = ({ cell }) => {
  const cellHeader =
    cell.header === 'Shareholder Value' ? 'Stakeholder Value' : cell.header;

  return (
    <StaticContainer
      backgroundColor={cell.fgColor}
      gridArea={[cell.y, cell.x, cell.y + cell.h, cell.x + cell.w]
        .map((x) => `${x}`)
        .join(' / ')}
      headerColor={cell.headerColor}
      headerAlign={cell.headerAlign || 'center'}
    >
      {cellHeader}
    </StaticContainer>
  );
};

export default StaticCell;
