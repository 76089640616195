import React from 'react';
import styled from 'styled-components';
import RectButton from '../../components/Button/RectButton';
import CanvasTopicHeader from '../../components/CanvasTopicHeader/CanvasTopicHeader';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import ContextColorField from '../../components/ContextColorField/ContextColorField';
import ContextSelect from '../../components/ContextSelect/ContextSelect';

export interface BlockFormOutput {
  name: string;
  color: string;
  description: string;
  type: string;
}

type BlockFormProps = {
  onClearSelection: () => void;
  onFormSubmit: (formOutput: BlockFormOutput) => void;
  defaultValue?: Partial<BlockFormOutput>;
};

const FormContainer = styled.form`
  background-color: ${(props) => props.theme.colors.listitem.border};

  display: grid;
  grid-template-areas:
    'topicName'
    'formInput'
    'formControl';
  grid-template-rows: minmax(min-content, 93px) 1fr auto;
  border-radius: 6px;
  height: 100%;
  width: 100%;
`;

const NewCellFormSection = styled.div`
  grid-area: formInput / formInput / formInput / formInput;
  border-bottom: 0;
  border-top: 0;
  padding: 10px 15px;
`;

const FormControlSection = styled.div`
  grid-area: formControl / formControl / formControl / formControl;
  display: flex;
  justify-content: flex-end;

  padding: 10px;

  ${RectButton} {
    &:last-child {
      margin-left: 10px;
    }
  }
`;

const BlockSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  color: yup.string().required(),
  type: yup.string().required(),
});

const BlockForm: React.FC<BlockFormProps> = ({
  onClearSelection,
  onFormSubmit,
  defaultValue = {},
}) => {
  const blockFormMethods = useForm({
    resolver: yupResolver(BlockSchema),
    defaultValues: defaultValue,
  });

  const previewColor = useWatch({
    control: blockFormMethods.control,
    name: 'color',
    defaultValue: defaultValue.color || '#813797',
  });

  const previewName = useWatch({
    control: blockFormMethods.control,
    name: 'name',
    defaultValue: defaultValue.name || 'New Block',
  });

  const blockTypes = [
    {
      id: 'data',
      name: 'Data',
    },
    {
      id: 'static',
      name: 'Static',
    },
  ];

  return (
    <FormContainer onSubmit={blockFormMethods.handleSubmit(onFormSubmit)}>
      <CanvasTopicHeader>
        {previewName}
      </CanvasTopicHeader>
      <NewCellFormSection>
        <FormProvider {...blockFormMethods}>
          <ContextTextField name="name" label="Name" />
          <ContextTextField name="description" label="Description" />
          <ContextColorField name="color" label="Color" />
          <ContextSelect
            name="type"
            label="Type"
            options={blockTypes}
            defaultValue={blockTypes.find(
              (type) => type.id === defaultValue?.type,
            )}
          />
        </FormProvider>
      </NewCellFormSection>
      <FormControlSection>
        <RectButton type="submit">Save Block</RectButton>
        <RectButton
          variant="secondary"
          onClick={() => onClearSelection()}
          type="button"
        >
          Cancel
        </RectButton>
      </FormControlSection>
    </FormContainer>
  );
};

export default BlockForm;
