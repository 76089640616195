import styled from 'styled-components';

type CanvasTopicContainerProps = {
  gridArea: string;
  topBorderColor: string;
};
const CanvasTopicContainer = styled.div<CanvasTopicContainerProps>`
  grid-area: ${(props) => props.gridArea};
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.listitem.border};
  border-radius: 6px;
  border-top: 10px solid;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-top-color: ${(props) => props.topBorderColor};
`;

export default CanvasTopicContainer;
