import {
  Objective as GraphQLObjective,
  KeyResult as GraphQLKeyResult,
  Iteration as GraphQLIteration,
  User as GraphQLUser,
  Maybe,
} from '@propella/core';
import _ from 'lodash';
import { isJust, withDefault } from '../../maybe';

export type ObjectiveScope = {
  objectives: GraphQLObjective[];
  iteration?: GraphQLIteration;
  assignableUsers: GraphQLUser[];
};

export type UpdateObjectiveAssigneePayload = {
  objectiveId: string;
  assignees: GraphQLUser[];
};

export type UpdateSubObjectiveAssigneePayload =
  UpdateObjectiveAssigneePayload & {
    parentObjectiveId: string;
  };

export type Action = {
  type: string;
  payload:
    | ObjectiveScope
    | GraphQLKeyResult
    | GraphQLObjective
    | GraphQLIteration
    | GraphQLObjective[]
    | GraphQLUser[]
    | UpdateObjectiveAssigneePayload
    | UpdateSubObjectiveAssigneePayload;
  meta: any;
};

export const reducer = (
  state: ObjectiveScope,
  action: Action,
): ObjectiveScope => {
  switch (action.type) {
    case 'init':
      const initPayload = action.payload as ObjectiveScope;
      return {
        ...state,
        objectives: withDefault(initPayload.objectives, []).filter(isJust),
        assignableUsers: withDefault(initPayload.assignableUsers, []).filter(
          isJust,
        ),
      };
    case 'switchIteration':
      return {
        ...state,
        iteration: action.payload as GraphQLIteration,
      };
    case 'addObjective':
      if (action.payload as GraphQLObjective) {
        return {
          ...state,
          objectives: [action.payload as GraphQLObjective, ...state.objectives],
        };
      }
      return state;
    case 'addKeyResult':
      const newKeyResult = action.payload as GraphQLKeyResult;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === newKeyResult.objectiveID) {
            return {
              ...obj,
              keyResults: [
                ...withDefault(obj.keyResults || [], []).filter(
                  (kR) => kR?.id !== newKeyResult.id,
                ),
                newKeyResult,
              ],
            };
          }
          return obj;
        }),
      };
    case 'addKeyResultToSubObjective':
      const newSubKeyResult = action.payload as GraphQLKeyResult;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === (action.meta.parentObjectiveID as string)) {
            return {
              ...obj,
              subObjectives: withDefault(obj.subObjectives || [], [])
                .filter(isJust)
                .map((subObj) => {
                  if (subObj.id === newSubKeyResult.objectiveID) {
                    return {
                      ...subObj,
                      keyResults: [
                        ...withDefault(subObj.keyResults || [], []).filter(
                          (kR) => kR?.id !== newSubKeyResult.id,
                        ),
                        newSubKeyResult,
                      ],
                    };
                  }

                  return subObj;
                }),
            };
          }
          return obj;
        }),
      };
    case 'addSubObjective': {
      const newSubObj = action.payload as GraphQLObjective;

      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === (action.meta.parentObjectiveID as string)) {
            return {
              ...obj,
              subObjectives: [
                ...withDefault(obj.subObjectives || [], []).filter(
                  (subObj) => subObj?.id !== newSubObj.id,
                ),
                newSubObj,
              ],
            };
          }
          return obj;
        }),
      };
    }
    case 'updateKeyResult':
      const updatedKeyResult = action.payload as GraphQLKeyResult;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === updatedKeyResult.objectiveID) {
            const defaultKeyResults = withDefault(
              obj.keyResults || [],
              [],
            ).filter(isJust);

            const keyResultIndex = _.findIndex(
              defaultKeyResults,
              (kr) => kr.id === updatedKeyResult.id,
            );

            defaultKeyResults.splice(keyResultIndex, 1, updatedKeyResult);

            return {
              ...obj,
              keyResults: defaultKeyResults,
            };
          }
          return obj;
        }),
      };
    case 'updateSubKeyResult':
      const updatedSubKeyResult = action.payload as GraphQLKeyResult;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === (action.meta.parentObjectiveID as string)) {
            return {
              ...obj,
              subObjectives: withDefault(obj.subObjectives || [], [])
                .filter(isJust)
                .map((subObj) => {
                  if (subObj.id === updatedSubKeyResult.objectiveID) {
                    const defaultKeyResults = withDefault(
                      subObj.keyResults || [],
                      [],
                    ).filter(isJust);

                    const keyResultIndex = _.findIndex(
                      defaultKeyResults,
                      (kr) => kr.id === updatedSubKeyResult.id,
                    );

                    defaultKeyResults.splice(
                      keyResultIndex,
                      1,
                      updatedSubKeyResult,
                    );

                    return {
                      ...subObj,
                      keyResults: defaultKeyResults,
                    };
                  }
                  return subObj;
                }),
            };
          }
          return obj;
        }),
      };

    case 'updateSubObjectiveName': {
      const updatedSubObjective = action.payload as GraphQLObjective;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id !== action.meta.parentObjectiveID) return obj;

          return {
            ...obj,
            subObjectives: withDefault(obj.subObjectives, []).map((subObj) => {
              if (subObj?.id !== updatedSubObjective.id) return subObj;

              const mergedSubObjective = {
                ...(subObj || {}),
                name: updatedSubObjective.name,
              } as Maybe<GraphQLObjective>;

              return mergedSubObjective;
            }),
          };
        }),
      };
    }
    case 'updateObjective':
      const updatedObjective = action.payload as GraphQLObjective;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === updatedObjective.id) {
            return {
              ...obj,
              name: updatedObjective.name,
              color: updatedObjective.color,
              expectedEndDate: updatedObjective.expectedEndDate,
            };
          }
          return obj;
        }),
      };

    case 'deleteObjective':
      const deleteObjective = action.payload as GraphQLObjective;
      return {
        ...state,
        objectives: state.objectives
          .filter(isJust)
          .filter((obj) => obj.id !== deleteObjective.id),
      };

    case 'deleteSubObjective':
      const deleteSubObjective = action.payload as GraphQLObjective;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === (action.meta.parentObjectiveID as string)) {
            return {
              ...obj,
              subObjectives: (obj.subObjectives || [])
                .filter(isJust)
                .filter((subObj) => subObj.id !== deleteSubObjective.id),
            };
          }
          return obj;
        }),
      };

    case 'deleteKeyResult':
      const deletedKeyResult = action.payload as GraphQLKeyResult;
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === deletedKeyResult.objectiveID) {
            return {
              ...obj,
              keyResults: (obj.keyResults || [])
                .filter(isJust)
                .filter((kR) => kR.id !== deletedKeyResult.id),
            };
          }

          return obj;
        }),
      };
    case 'deleteSubKeyResult':
      const deletedSubKeyResult = action.payload as GraphQLKeyResult;

      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id === (action.meta.parentObjectiveID as string)) {
            return {
              ...obj,
              subObjectives: withDefault(obj.subObjectives || [], [])
                .filter(isJust)
                .map((subObj) => {
                  if (subObj.id === deletedSubKeyResult.objectiveID) {
                    const defaultKeyResults = withDefault(
                      subObj.keyResults || [],
                      [],
                    ).filter(isJust);

                    return {
                      ...subObj,
                      keyResults: defaultKeyResults.filter(
                        (kR) => kR.id !== deletedSubKeyResult.id,
                      ),
                    };
                  }
                  return subObj;
                }),
            };
          }
          return obj;
        }),
      };
    case 'updateObjectiveAssignee': {
      const { objectiveId, assignees } =
        action.payload as UpdateObjectiveAssigneePayload;

      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id !== objectiveId) return obj;
          return {
            ...obj,
            assignees,
          };
        }),
      };
    }
    case 'updateSubObjectiveAssignee': {
      const { objectiveId, assignees, parentObjectiveId } =
        action.payload as UpdateSubObjectiveAssigneePayload;

      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id !== parentObjectiveId) return obj;
          return {
            ...obj,
            subObjectives: (obj.subObjectives || [])
              .filter(isJust)
              .map((subObj) => {
                if (subObj.id !== objectiveId) return subObj;

                return {
                  ...subObj,
                  assignees,
                };
              }),
          };
        }),
      };
    }

    case 'applyObjectiveUpdates': {
      const updatedObjective = action.payload as GraphQLObjective;

      if (!updatedObjective.parentObjectiveID) {
        const existingObjective = state.objectives.find(
          (obj) => obj.id === updatedObjective.id,
        );

        const mergedObjective = {
          ...(existingObjective || {}),
          ..._.pickBy(updatedObjective, _.identity),
        } as GraphQLObjective;

        return {
          ...state,
          objectives: [
            ...state.objectives.filter((obj) => obj.id !== mergedObjective.id),
            mergedObjective,
          ],
        };
      }

      const existingSubObjective = withDefault(
        state.objectives.find(
          (obj) => obj.id === updatedObjective.parentObjectiveID,
        )?.subObjectives,
        [],
      ).find((subObj) => subObj?.id === updatedObjective.id);

      const mergedObjective = {
        ...(existingSubObjective || {}),
        ..._.pickBy(updatedObjective, _.identity),
      } as GraphQLObjective;

      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id !== mergedObjective.parentObjectiveID) return obj;

          return {
            ...obj,
            subObjectives: [
              ...withDefault(obj.subObjectives, [])
                .filter(isJust)
                .filter((subObj) => subObj.id !== mergedObjective.id),
              mergedObjective,
            ],
          };
        }),
      };
    }

    case 'applyKeyResultUpdates': {
      const updatedKeyResult = action.payload as GraphQLKeyResult;

      if (updatedKeyResult.parentObjectiveID) {
        return {
          ...state,
          objectives: state.objectives.map((obj) => {
            if (obj.id !== updatedKeyResult.parentObjectiveID) return obj;

            return {
              ...obj,
              subObjectives: withDefault(obj.subObjectives, []).map(
                (subObj) => {
                  if (subObj?.id !== updatedKeyResult.objectiveID)
                    return subObj;

                  const existingKeyResult = withDefault(
                    subObj.keyResults,
                    [],
                  ).find((keyResult) => keyResult?.id === updatedKeyResult.id);

                  const mergedKeyResult = {
                    ...(existingKeyResult || {}),
                    ..._.pickBy(updatedKeyResult, _.identity),
                  } as GraphQLKeyResult;

                  return {
                    ...subObj,
                    keyResults: [
                      ...withDefault(subObj.keyResults, []).filter(
                        (keyResult) => keyResult?.id !== updatedKeyResult.id,
                      ),
                      mergedKeyResult,
                    ],
                  };
                },
              ),
            };
          }),
        };
      }
      return {
        ...state,
        objectives: state.objectives.map((obj) => {
          if (obj.id !== updatedKeyResult.objectiveID) return obj;

          const existingKeyResult = withDefault(obj.keyResults, []).find(
            (keyResult) => keyResult?.id === updatedKeyResult.id,
          );

          const mergedKeyResult = {
            ...(existingKeyResult || {}),
            ..._.pickBy(updatedKeyResult, _.identity),
          } as GraphQLKeyResult;

          return {
            ...obj,
            keyResults: [
              ...withDefault(obj.keyResults, []).filter(
                (keyResult) => keyResult?.id !== updatedKeyResult.id,
              ),
              mergedKeyResult,
            ],
          };
        }),
      };
    }
    default:
      return state;
  }
};
