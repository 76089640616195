import React, { useState } from 'react';
import styled from 'styled-components';
import CharacterCount from '@tiptap/extension-character-count';
import StarterKit from '@tiptap/starter-kit';
import { useEditor, EditorContent } from '@tiptap/react';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold,
  faItalic,
  faUnderline,
  faListUl,
  faListOl,
  faCheck,
  faTimes,
  faParagraph,
  faSmile,
} from '@fortawesome/free-solid-svg-icons';
import { BaseEmoji, Picker } from 'emoji-mart';
import { Shortcuts } from './Shortcuts';
import { COLORS } from '../../common/color';
import Measure from 'react-measure';

interface ContainerProps {
  hasError: boolean;
  isFocused?: boolean;
  showFormatMenu?: boolean;
}

const EditorAndMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditorContainer = styled.div<ContainerProps>`
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${(props) => {
    if (props.isFocused) return props.theme.colors.focusBorder;
    if (props.hasError) return props.theme.colors.error;

    return props.theme.colors.borderItem;
  }};
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  position: relative;

  font-size: 14px;
  line-height: 23px;

  .ProseMirror {
    white-space: pre-wrap;
    padding: 5px;
    padding-bottom: 40px;

    overflow-wrap: anywhere;

    p {
      margin: 0 !important;
    }

    h2,
    h1,
    h3 {
      &:first-child {
        margin-top: 0;
      }
    }

    blockquote {
      border-left: 3px solid #dfe1e6;
      padding-left: 10px;
    }

    ul,
    ol {
      margin: 0;
      padding-left: 20px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      margin: 0;
    }
  }

  .ProseMirror-focused {
    outline: none;
  }
`;

interface FormatMenuProps {
  show: boolean;
}

const FormatMenu = styled.div<FormatMenuProps>`
  border-bottom: 1px dashed #dfe1e6;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface MenuButtonProps {
  active: boolean;
}

const MenuButton = styled.button<MenuButtonProps>`
  background-color: transparent;
  color: ${(props) =>
    props.active
      ? props.theme.colors.item.hoverEditor
      : props.theme.colors.item.container};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};

const SaveButton = styled(MenuButton)`
  /* pointer-events: none; */
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const EditorFeedbackText = styled.span`
  position: absolute;
  left: 5px;
  bottom: 5px;
  color: #6c798f;
  font-size: 12px;
`;

const CharacterCountText = styled.span`
  color: #6c798f;
`;

const ErrorMessage = styled.span`
  margin-top: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.error};
`;

const CompactActionMenu = styled.div<FormatMenuProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 100;
`;

const EmojiPickerContainer = styled.div`
  display: inline-block;
`;

const EmojiPickerAnchor = styled.div`
  position: relative;
`;

export interface EntryEditorProps {
  onSave: (data: any) => Promise<void>;
  onClose: () => void;
  defaultValue?: string | JsonDocument;
  errorMessage?: string;
  isFocused?: boolean;
  isSubmitting?: boolean;
  clearOnSave?: boolean;
  inlineErrorMessage?: boolean;
}

const EntryEditor: React.FC<EntryEditorProps> = ({
  onSave,
  onClose,
  defaultValue,
  errorMessage,
  isFocused,
  isSubmitting,
  clearOnSave,
  inlineErrorMessage,
}) => {
  const [showFormatMenu, setShowFormatMenu] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [editorWidth, setEditorWidth] = useState(0);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Underline,
      CharacterCount.configure({
        limit: 280,
      }),
      Shortcuts.configure({
        saveFunction: async (savedContent) => {
          await onSave(savedContent);
        },
      }),
    ],
    content: defaultValue || '',
  });

  const saveChanges = async () => {
    if (!editor) {
      return;
    }
    // Check length for require case
    if (editor.state.doc.textContent.length === 0) {
      await onSave(undefined);
    } else {
      const jsonData = editor.getJSON();
      await onSave(jsonData);
    }

    if (clearOnSave) {
      editor.commands.clearContent(true);
    }
  };

  const closeEditor = () => {
    if (!editor) {
      return;
    }
    editor.commands.clearContent(true);
    setShowFormatMenu(false);
    onClose();
  };

  const insertEmoji = (emoji: BaseEmoji) => {
    editor?.chain().focus().insertContent(`${emoji.native} `).run();
  };

  if (!editor) return null;

  return (
    <Measure
      bounds
      onResize={(contentRect) => setEditorWidth(contentRect.bounds?.width || 0)}
    >
      {({ measureRef }) => (
        <EditorAndMessageContainer ref={measureRef}>
          <EditorContainer
            hasError={!!errorMessage}
            isFocused={isFocused}
            showFormatMenu={showFormatMenu}
          >
            <FormatMenu show={showFormatMenu}>
              <ButtonGroup>
                <MenuButton
                  active={editor.isActive('bold')}
                  onClick={() => editor.chain().focus().toggleBold().run()}
                >
                  <FontAwesomeIcon icon={faBold} size="1x" />
                </MenuButton>
                <MenuButton
                  active={editor.isActive('italic')}
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                >
                  <FontAwesomeIcon icon={faItalic} size="1x" />
                </MenuButton>
                <MenuButton
                  active={editor.isActive('underline')}
                  onClick={() => editor.chain().focus().toggleUnderline().run()}
                >
                  {/* Underline icon is 2 pixel up unlike the rest of the icon. so idk */}
                  <FontAwesomeIcon
                    icon={faUnderline}
                    size="1x"
                    style={{ marginTop: 2 }}
                  />
                </MenuButton>
                <MenuButton
                  active={editor.isActive('orderedList')}
                  onClick={() =>
                    editor.chain().focus().toggleOrderedList().run()
                  }
                >
                  <FontAwesomeIcon icon={faListOl} size="1x" />
                </MenuButton>
                <MenuButton
                  active={editor.isActive('bulletList')}
                  onClick={() =>
                    editor.chain().focus().toggleBulletList().run()
                  }
                >
                  <FontAwesomeIcon icon={faListUl} size="1x" />
                </MenuButton>
                <EmojiPickerContainer>
                  <MenuButton
                    active={showEmojiPicker}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    <FontAwesomeIcon icon={faSmile} size="1x" />
                  </MenuButton>
                  <EmojiPickerAnchor>
                    {showEmojiPicker && (
                      <Picker
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 101,
                        }}
                        emojiSize={18}
                        onSelect={(emoji: BaseEmoji) => insertEmoji(emoji)}
                      />
                    )}
                  </EmojiPickerAnchor>
                </EmojiPickerContainer>
              </ButtonGroup>
              <ButtonGroup>
                <MenuButton
                  active={showFormatMenu}
                  onClick={() => setShowFormatMenu(!showFormatMenu)}
                  disabled={isSubmitting}
                >
                  <FontAwesomeIcon icon={faParagraph} size="1x" />
                </MenuButton>
                {!isSubmitting && (
                  <SaveButton active={false} onClick={saveChanges}>
                    <FontAwesomeIcon icon={faCheck} size="1x" />
                  </SaveButton>
                )}
                {isSubmitting && (
                  <SaveButton active={false} onClick={saveChanges}>
                    <ReactLoading
                      type="spin"
                      color={COLORS.loading}
                      width={16}
                      height={16}
                    />
                  </SaveButton>
                )}
                <CancelButton
                  active={false}
                  onClick={closeEditor}
                  disabled={isSubmitting}
                >
                  <FontAwesomeIcon icon={faTimes} size="1x" />
                </CancelButton>
              </ButtonGroup>
            </FormatMenu>
            <CompactActionMenu show={!showFormatMenu}>
              <ButtonGroup>
                <MenuButton
                  active={showFormatMenu}
                  onClick={() => setShowFormatMenu(!showFormatMenu)}
                  disabled={isSubmitting}
                >
                  <FontAwesomeIcon icon={faParagraph} size="1x" />
                </MenuButton>
                {!isSubmitting && (
                  <SaveButton
                    active={false}
                    onClick={saveChanges}
                    disabled={isSubmitting}
                  >
                    <FontAwesomeIcon icon={faCheck} size="1x" />
                  </SaveButton>
                )}
                {isSubmitting && (
                  <SaveButton active={false} onClick={saveChanges}>
                    <ReactLoading
                      type="spin"
                      color={COLORS.loading}
                      width={16}
                      height={16}
                    />
                  </SaveButton>
                )}
                <CancelButton
                  active={false}
                  onClick={closeEditor}
                  disabled={isSubmitting}
                >
                  <FontAwesomeIcon icon={faTimes} size="1x" />
                </CancelButton>
              </ButtonGroup>
            </CompactActionMenu>
            <EditorFeedbackText>
              {editorWidth > 180 && (
                <CharacterCountText>
                  {editor.getCharacterCount()} / 280{' '}
                </CharacterCountText>
              )}
              {inlineErrorMessage && errorMessage && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              )}
            </EditorFeedbackText>
            <EditorContent editor={editor} />
          </EditorContainer>
          {!inlineErrorMessage && errorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </EditorAndMessageContainer>
      )}
    </Measure>
  );
};

export default EntryEditor;
