import styled from 'styled-components';

export type StaticTopicV2Props = {
  gridArea: string;
  backgroundColor?: string;
  textColor: string;
  textAlign: 'left' | 'center' | 'right';
  verticalAlign: 'top' | 'middle' | 'bottom';
};

const StaticTopicV2 = styled.div<StaticTopicV2Props>`
  grid-area: ${(props) => props.gridArea};
  background-color: ${(props) => props.backgroundColor || '#ffffff'};
  color: ${(props) => props.textColor || '#000000'};

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  display: flex;

  padding: 10px;
  box-sizing: border-box;

  justify-content: ${(props) => {
    switch (props.textAlign) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
    }
  }};
  align-items: ${(props) => {
    switch (props.verticalAlign) {
      case 'top':
        return 'flex-start';
      case 'middle':
        return 'center';
      case 'bottom':
        return 'flex-end';
    }
  }};

  border-radius: 0;
`;

export default StaticTopicV2;
