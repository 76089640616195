import { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import KeyResult from './KeyResult';
import Progress from './Progress';
import { calculateProgressFromKeyResult } from './utils';
import {
  Objective as GraphQLObjective,
  MutationUpdateObjectiveArgs,
  MutationDeleteObjectiveArgs,
} from '../../graphql/types';
import { isJust, withDefault } from '../../maybe';
import NewKeyResult from './NewKeyResult';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { API, graphqlOperation } from 'aws-amplify';
import {
  updateObjective as UpdateObjectiveNameMutation,
  deleteObjective as DeleteObjectiveMutation,
} from '../../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { ObjectiveStore } from '../../pages/OKR/CurrentObjectives';
import Assignee from '../Assignee';
import Stars from '../Stars/Stars';
import { ReactComponent as TripleDots } from './tripleDots.svg';

export type SubObjectiveProps = GraphQLObjective & {
  color: string;
};

const SubObjectiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: #ffffff;

  margin-top: 22px;

  &:first-child {
    margin-top: 0;
  }
`;

const SubObjectiveHeader = styled.div`
  padding: 17px 24px 18px 22px;
  min-height: 58px;

  display: flex;
  align-items: center;
`;

const SubObjectiveSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubObjectiveName = styled.h2`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  margin-left: 16px;
  width: 100%;
`;

const KeyResultContainer = styled.div`
  border-top: 1px solid #c8cad1;
`;

const StyledKeyResult = styled(KeyResult)`
  border-top: 1px dashed #c8cad1;
  padding-right: 16px;
  padding-left: 18px;

  &:first-child {
    border-top: 0;
  }
`;

const SubObjectiveForm = styled.form`
  background: #ffffff;
  min-height: 58px;

  display: flex;
  align-items: center;

  padding: 17px 24px 18px 22px;
`;

const NewKeyResultInput = styled.input`
  width: 100%;
  border: 0;
  resize: none;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.item.container};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};

const ColorPicker = styled(MenuButton)`
  margin-right: 22px;
`;

const DeleteButton = styled(MenuButton)`
  margin-right: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }
  :disabled {
    pointer-events: none;
  }
`;

const SaveButton = styled(MenuButton)`
  /* pointer-events: none; */
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const editSubObjectiveSchema = yup.object().shape({
  name: yup.string().required(),
});

const SubObjective = ({
  id,
  color,
  name,
  parentObjectiveID,
  assignees,
  keyResults = [],
}: SubObjectiveProps) => {
  const [objectiveStore, dispatch] = useContext(ObjectiveStore);
  const [editing, setEditing] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const localKeyResult = withDefault(keyResults, [])
    .filter(isJust)
    .filter((kR) => !!kR);

  const currentProgress = useMemo(
    () => calculateProgressFromKeyResult(localKeyResult),
    [localKeyResult],
  );

  const editSubObjectiveFormMethods = useForm({
    resolver: yupResolver(editSubObjectiveSchema),
    defaultValues: {
      name,
    },
  });

  useEffect(() => {
    if (!editing)
      editSubObjectiveFormMethods.reset({
        name,
      });
  }, [name]);

  const updateSubObjectiveName = async (newName: string) => {
    const args = {
      input: {
        id,
        name: newName,
      },
    } as MutationUpdateObjectiveArgs;
    setEditing(true);

    const queryResult = (await API.graphql(
      graphqlOperation(UpdateObjectiveNameMutation, args),
    )) as GraphQLResult<{
      updateObjective: GraphQLObjective;
    }>;

    const updatedObjectiveName = queryResult.data?.updateObjective;

    if (updatedObjectiveName) {
      dispatch({
        type: 'updateSubObjectiveName',
        payload: updatedObjectiveName,
        meta: {
          parentObjectiveID,
        },
      });

      editSubObjectiveFormMethods.reset({
        name: newName,
      });

      setEditing(false);
    }
  };

  const deleteSubObjective = async () => {
    const args: MutationDeleteObjectiveArgs = {
      input: {
        id,
      },
    };
    setDeleting(true);

    try {
      await API.graphql(graphqlOperation(DeleteObjectiveMutation, args));

      if (parentObjectiveID) {
        dispatch({
          type: 'deleteSubObjective',
          payload: { id },
          meta: {
            parentObjectiveID,
          },
        });
        setEditing(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <SubObjectiveContainer>
      {editing ? (
        <SubObjectiveForm
          onSubmit={editSubObjectiveFormMethods.handleSubmit(async (data) => {
            await updateSubObjectiveName(data.name);
            editSubObjectiveFormMethods.reset();
          })}
        >
          <Controller
            control={editSubObjectiveFormMethods.control}
            name="name"
            render={(fieldProps) => (
              <NewKeyResultInput
                tabIndex={1}
                autoFocus
                disabled={editSubObjectiveFormMethods.formState.isSubmitting}
                placeholder="Enter a title for the objective"
                required
                {...fieldProps}
              />
            )}
          />
          <DeleteButton
            tabIndex={4}
            onClick={() => deleteSubObjective()}
            disabled={editSubObjectiveFormMethods.formState.isSubmitting}
          >
            <FontAwesomeIcon icon={faTrash} size="1x" />
          </DeleteButton>
          <SaveButton
            type="submit"
            tabIndex={2}
            disabled={editSubObjectiveFormMethods.formState.isSubmitting}
          >
            <FontAwesomeIcon icon={faCheck} size="1x" />
          </SaveButton>
          <CancelButton
            tabIndex={3}
            onClick={() => setEditing(false)}
            disabled={editSubObjectiveFormMethods.formState.isSubmitting}
          >
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </CancelButton>
        </SubObjectiveForm>
      ) : (
        <SubObjectiveHeader>
          <SubObjectiveSection
            onClick={() => setEditing(true)}
            style={{ width: '100%' }}
          >
            <Stars level={3} color={color} />
            <SubObjectiveName>{name}</SubObjectiveName>
          </SubObjectiveSection>
          <SubObjectiveSection>
            <Assignee
              objectiveId={id}
              parentObjectiveId={withDefault(parentObjectiveID, '')}
              assignableUsers={withDefault(objectiveStore.assignableUsers, [])}
              assignees={withDefault(assignees, []).filter(isJust)}
            />
            <Progress
              color={color}
              progress={currentProgress}
              noCheckbox
              disabled
              noBorder
            />
            <TripleDots />
          </SubObjectiveSection>
        </SubObjectiveHeader>
      )}
      {localKeyResult.length > 0 && (
        <KeyResultContainer>
          {_.orderBy(
            localKeyResult,
            (keyResult) =>
              keyResult.createdAt ? new Date(keyResult.createdAt) : new Date(),
            'asc',
          ).map((keyResult) => (
            <StyledKeyResult
              {...keyResult}
              key={keyResult.id}
              color={color}
              parentObjectiveID={parentObjectiveID}
              objectiveID={id}
            />
          ))}
        </KeyResultContainer>
      )}
      <NewKeyResult
        objectiveID={id}
        parentObjectiveID={withDefault(parentObjectiveID, undefined)}
      />
    </SubObjectiveContainer>
  );
};

export default SubObjective;
