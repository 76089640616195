// my-theme.ts
import { DefaultTheme } from 'styled-components';
export const appTheme: DefaultTheme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    error: '#da6161',
    globalError: '#d13c3c',
    label: '#5A5F69',
    loading: '#1C2734',
    theme: '#021740',
    borderItem: '#e3e8e8',
    focusBorder: '#90d0d0',
    switchTab: '#28303C',
    signin: {
      container: '#001e42',
      header: '#021740',
      styledButton: '#fcbf4b',
    },
    documentItem: {
      active: '#60bfbf',
      locked: '#C3C6CA',
      background: '#F7FCFD',
      iconBg: '#fbfbfb',
      icon: '#e4e5e7',
    },
    topic: {
      header: '#46535d',
      create: '#aace8e',
      additional: '#889299',
      update: '#5ba3d5',
    },
    pageHeader: {
      darkBg: '#252C39',
      text: '#0B1B3C',
      border: '#ddd',
    },
    pageControl: {
      darkBg: '#192231',
      text: '#0B1B3C',
      border: '#edeef1',
    },
    listitem: {
      border: '#eff2f4',
      bottom: '#e1e7e7',
      drop: '#90d0d0',
    },
    item: {
      container: '#d7d7d7',
      hover: '#d93b3b',
      hoverSave: '#56b6b9',
      hoverEditor: '#021740',
    },
    sideNav: {
      selectedBg: '#214272',
      backGround: '#153363',
      addBg: '#092150',
      wrapper: '#021740',
      header: '#16336b',
      label: '#153c89',
      active: '#031436',
    },
    task: {
      background: '#fcfcfc',
    },
    textfield: {
      text: '#021740',
      border: '#EEEFF2',
      focusBorder: '#ABDFDF',
      errorBackground: '#FFF2F1',
      focusBackground: '#f3ffff',
    },
    checkbox: {
      container: '#4d545f',
      boder: '#3f4753',
      tick: '#192231',
      background: '#f3ffff',
    },
    toggle: {
      background: '#bebebe',
      active: '#4fbe79',
    },
    button: {
      container: '#56B6B9',
      primaryBackground: '#021740',
      primaryHoverBackground: '#032B6F',
      primaryActiveBackground: '#032B6F',
      secondaryBorder: '#818CA0',
      secondary: '#0D2249',
      secondaryHoverBorder: '#8296B8',
      secondaryHover: '#0E3576',
      secondaryActiveBorder: '#818694',
      secondaryActive: '#0C1732',
      primaryRectBackground: '#60BFBF',
      primaryRectHoverBackground: '#54CFCF',
      primaryRectActiveBackground: '#42A7A7',
      secondaryRectBorder: '#B0E0E0',
      secondaryRect: '#60BFBF',
      secondaryRectHoverBorder: '#AAE8E8',
      secondaryRectHover: '#54CFCF',
      secondaryRectActiveBorder: '#A1D4D4',
      secondaryRectActive: '#42A7A7',
      disabledBackground: 'rgba(9, 30, 66, 0.04)',
      disabled: 'rgb(165, 173, 186)',
    },
    link: {
      enabled: '#021740',
    },
    input: {
      disabled: '#eeeff2',
    },
    wysiwyg: {
      button: {
        color: '#889299',
        active: '#021740',
      },
      border: '#d7d7d7',
    },
  },
};

export const COLORS = {
  text: '#0B1B3C',
  white: '#ffffff',
  loading: '#1C2734',
  deleting: '#d93b3b',
  saving: '#56b6b9',
  activeSideNav: '#031436',
  disabled: '#eeeff2',
};
