import styled from 'styled-components';

type TopicContainerProps = {
  topicColor: string
}
const TopicContainer = styled.div<TopicContainerProps>`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  grid-template-areas:
    'topicName entries'
    'topicDescription entries'
    'topicContributorsCompare entries'
    'topicContributors newEntry';
  grid-template-columns: minmax(auto, 220px) auto;
  grid-template-rows: auto 1fr auto auto;
  display: grid;
  width: 100%;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 50px;
  }
  border-left: 10px solid;
  border-left-color: ${(props) => props.topicColor};
  background-color: ${(props) => props.theme.colors.listitem.border};
  border-radius: 5px;
`;

export default TopicContainer;
