import React, { useRef } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import TableTag from './TableTag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export type TagOption = {
  value: string;
  name: string;
  backgroundColor?: string;
  textColor: string;
};

export type TagDropDownProps = {
  options?: TagOption[];
  currentValue?: string;
  defaultValue?: TagOption;
  readonly?: boolean;
  onSelect?: (value: string) => Promise<void>;
  tableRef: React.MutableRefObject<HTMLTableElement | undefined | null>;
};

const TagText = styled.span<Pick<TagOption, 'textColor'>>`
  color: ${({ textColor }) => textColor || '#000000'};
  white-space: nowrap;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: #ffffff;
`;

const InnerContainer = styled.div``;

const TagDropDown = ({
  options,
  currentValue,
  readonly = false,
  onSelect,
  tableRef,
}: TagDropDownProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  if (!options) return null;

  const selectedValue = options.find(
    (option) => option.name === currentValue,
  ) || {
    name: 'Select',
    textColor: '#202020',
    backgroundColor: '#20202015',
  };

  if (!selectedValue) return <div>Placeholder</div>;

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top']}
      parentElement={tableRef.current || undefined}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={() => (
        <PopoverContainer>
          {options.map((option) => (
            <TableTag
              key={option.value}
              color={option.backgroundColor || `${option.textColor}15`}
              onClick={async () => {
                await onSelect?.(option.value);
                setIsPopoverOpen(false);
              }}
            >
              <TagText textColor={option.textColor}>{option.name}</TagText>
            </TableTag>
          ))}
        </PopoverContainer>
      )}
    >
      <InnerContainer>
        <StyledButton
          onClick={() => !readonly && setIsPopoverOpen(!isPopoverOpen)}
        >
          <TableTag
            color={
              selectedValue.backgroundColor || `${selectedValue.textColor}15`
            }
          >
            <TagText textColor={selectedValue.textColor}>
              {selectedValue.name}
            </TagText>
            {!readonly && (
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{ marginLeft: '7px' }}
                color={selectedValue.textColor}
              />
            )}
          </TableTag>
        </StyledButton>
      </InnerContainer>
    </Popover>
  );
};

export default TagDropDown;
