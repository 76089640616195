import { API, graphqlOperation } from 'aws-amplify';
import produce from 'immer';
import { StateCreator } from 'zustand';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getDocuments } from '../graphql/queries';
import { PlanSlice, PlanState, StoreInterface } from './interfaces';
import { Document, DocumentV2 } from '@propella/core';
import { createDocumentV2, createDocument } from '../graphql/mutations';

export const createPlanSlice: StateCreator<
  StoreInterface,
  [],
  [],
  PlanSlice
> = (set, get) => ({
  plan: {
    isReady: false,
    documents: [],
  },
  fetchDocuments: async () => {
    set(
      produce<PlanState>((draft) => {
        draft.plan.isReady = false;
      }),
    );

    try {
      const queryResult = (await API.graphql(
        graphqlOperation(getDocuments),
      )) as GraphQLResult<{
        getDocuments: Document[];
      }>;

      const documents = queryResult.data?.getDocuments;

      set(
        produce<PlanState>((draft) => {
          draft.plan.documents = documents || [];
          draft.plan.isReady = true;
        }),
      );
    } catch (error) {
      console.log('error fetching documents', error);
    }
  },
  createDocument: async (type, name) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(createDocument, {
          input: {
            type,
            name,
          },
        }),
      )) as GraphQLResult<{
        createDocument: Document;
      }>;

      const newDocument = queryResult.data?.createDocument;

      if (newDocument) {
        return newDocument.id;
      }
    } catch (error) {
      console.log('error creating document', error);

      throw error;
    }
  },
  createDocumentV2: async (type, name) => {
    try {
      const queryResult = (await API.graphql(
        graphqlOperation(createDocumentV2, {
          input: {
            type,
            name,
          },
        }),
      )) as GraphQLResult<{
        createDocumentV2: DocumentV2;
      }>;

      const newDocument = queryResult.data?.createDocumentV2;

      if (newDocument) {
        return newDocument.id;
      }
    } catch (error) {
      console.log('error creating document', error);

      throw error;
    }
  },
});
