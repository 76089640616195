import styled from 'styled-components';

const CanvasTopicHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 12px 15px 15px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  color: ${(props) => props.theme.colors.topic.header};
  > svg {
    padding-right: 10px;
  }
  justify-content: space-between;
  overflow: hidden;
`;

export default CanvasTopicHeader;
