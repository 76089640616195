import { useAlert } from 'react-alert';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ContextMultiSelect from '../../components/ContextMultiSelect/ContextMultiSelect';
import PageHeader from '../../components/PageHeader/PageHeader';
import Button from '../../components/Button/Button';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import {
  ADMIN_PERMISSION,
  PLAN_PERMISSIONS,
  CONTRIBUTE_PERMISSIONS,
  OKR_PERMISSIONS,
} from '../../common/constant';
import { createOrganizationRole } from '../../graphql/mutations';
import { isJust } from '../../maybe';
import { useBoundStore } from '../../states';

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  flex-grow: 1;
  padding-top: 30px;
  width: 100%;
  max-width: 880px;
  align-self: center;
`;

const MultiSelectContainer = styled.div`
  margin-bottom: 30px;
`;

const newRoleSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    grantedAdminActions: yup.array().of(yup.string()).required(),
    grantedPlanActions: yup.array().of(yup.string()).required(),
    grantedContributeActions: yup.array().of(yup.string()).required(),
    grantedOKRActions: yup.array().of(yup.string()).required(),
  })
  .test('grantedAdminActions', 'Atleast 1 permission is required', (data) => {
    if(!data) return false;
    if (
      !data.grantedAdminActions ||
      !data.grantedContributeActions ||
      !data.grantedPlanActions ||
      !data.grantedOKRActions
    )
      return false;

    return (
      [
        ...data.grantedAdminActions,
        ...data.grantedPlanActions,
        ...data.grantedContributeActions,
        ...data.grantedOKRActions
      ].length > 0
    );
  });

type CreateRoleFormData = {
  name: string;
  id: string;
  grantedAdminActions: string[];
  grantedPlanActions: string[];
  grantedContributeActions: string[];
  grantedOKRActions: string[];
  organizationID: string;
};

const NewRole = () => {
  const history = useHistory();
  const alert = useAlert();
  const { currentMembership } = useBoundStore((state) => state.auth);

  const newRoleFormMethods = useForm({
    resolver: yupResolver(newRoleSchema),
  });

  const createNewRole = async (data: CreateRoleFormData) => {
    if (
      !currentMembership ||
      !currentMembership.organization ||
      !isJust(currentMembership.organization)
    ) {
      return;
    }

    try {
      await API.graphql(
        graphqlOperation(createOrganizationRole, {
          input: {
            name: data.name,
            grantedActions: [
              ...data.grantedAdminActions,
              ...data.grantedPlanActions,
              ...data.grantedContributeActions,
            ],
            organizationID: currentMembership.organization.id,
          },
        }),
      );
      history.replace('/organization/settings');
    } catch (error: any) {
      alert.show(error.errors[0]?.message || error.message);
    }
  };

  return (
    <FormProvider {...newRoleFormMethods}>
      <PageContainer onSubmit={newRoleFormMethods.handleSubmit(createNewRole)}>
        <PageHeader
          variant="light"
          header="Add a new role"
          breadcrumb={{
            to: '/organization/settings',
            label: 'Settings',
          }}
          actionComponent={
            <Button
              type="submit"
              variant="primary"
              disabled={newRoleFormMethods.formState.isSubmitting}
            >
              Finish
            </Button>
          }
        />
        <FormContainer>
          <ContextTextField name="name" label="Name" />
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedAdminActions"
              label="Admin Permissions"
              options={ADMIN_PERMISSION}
            />
          </MultiSelectContainer>
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedPlanActions"
              label="Plan Permissions"
              options={PLAN_PERMISSIONS}
            />
          </MultiSelectContainer>
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedContributeActions"
              label="Contribute Permissions"
              options={CONTRIBUTE_PERMISSIONS}
            />
          </MultiSelectContainer>
          <MultiSelectContainer>
            <ContextMultiSelect
              name="grantedOKRActions"
              label="OKR Permissions"
              options={OKR_PERMISSIONS}
            />
          </MultiSelectContainer>
        </FormContainer>
      </PageContainer>
    </FormProvider>
  );
};

export default NewRole;
