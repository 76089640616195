import { useState } from 'react';
import styled from 'styled-components';
import {
  NewEntryLabel,
  ButtonGroup,
  StyledClearButton,
} from '../NewEntryV2/NewEntryV2';
import { KeyResultContainer } from './KeyResult';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoundStore } from '../../states';
import { Initiative } from '@propella/core';

export type NewKeyResultProps = {
  className?: string;
  initiativeId: Initiative['id'];
  isWithinKanbanBoard?: boolean;
};

const StyledNewKeyResult = styled(KeyResultContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NewKeyResultInput = styled.input`
  border: 0;
  outline: 0;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  width: 100%;
`;

const newKeyResultSchema = yup.object({
  name: yup.string().required(),
});

export type NewKeyResultFormValues = yup.InferType<typeof newKeyResultSchema>;

const NewKeyResult = (props: NewKeyResultProps) => {
  const { className } = props;
  const [createInitiativeKeyResult, createInitiativeKeyResultKanban] =
    useBoundStore((state) => [
      state.createInitiativeKeyResult,
      state.createInitiativeKeyResultKanban,
    ]);
  const [editing, setEditing] = useState<boolean>(false);

  const newKeyResultForm = useForm<NewKeyResultFormValues>({
    resolver: yupResolver(newKeyResultSchema),
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async (values: NewKeyResultFormValues) => {
    if (props.isWithinKanbanBoard) {
      createInitiativeKeyResultKanban(values.name, props.initiativeId);
    } else {
      await createInitiativeKeyResult(values.name, props.initiativeId);
    }
    newKeyResultForm.reset();
    setEditing(false);
  };

  if (editing) {
    return (
      <form onSubmit={newKeyResultForm.handleSubmit(onSubmit)}>
        <StyledNewKeyResult>
          <Controller
            name="name"
            control={newKeyResultForm.control}
            render={(field) => <NewKeyResultInput {...field} autoFocus />}
          />
          <ButtonGroup>
            <StyledClearButton type="submit" hoverColor="#56B6B9">
              <FontAwesomeIcon icon={faCheck} />
            </StyledClearButton>
            <StyledClearButton
              type="button"
              hoverColor="#D93B3B"
              onClick={() => setEditing(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </StyledClearButton>
          </ButtonGroup>
        </StyledNewKeyResult>
      </form>
    );
  }

  return (
    <StyledNewKeyResult className={className} onClick={() => setEditing(true)}>
      {/* <NewKeyResultInput /> */}
      <NewEntryLabel>Add a new Key Result</NewEntryLabel>
    </StyledNewKeyResult>
  );
};

export default NewKeyResult;
