import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { COLORS } from '../../common/color';
import Avatar from '../Avatar/Avatar';
import { UsersChangeType } from '../../common/type';

type AvatarWrapperProps = {
  position?: number;
  hover?: boolean;
};

type MemberProps = {
  userID: string;
  username: string | undefined;
};

type ListProps = {
  listContributors?: string[];
  usersChange?: UsersChangeType[];
  limit: number;
};

const AvartarWrapper = styled.div<AvatarWrapperProps>`
  margin-top: ${(props) => (props.hover ? '-4px' : '0px')};
  margin-bottom: ${(props) => (props.hover ? '4px' : '0px')};
  z-index: ${(props) => (props.hover ? '1' : '0')};
`;

const AvatarBorder = styled.div`
  padding: 3px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  border-radius: 15px;
  margin-left: -7px;
`;

const ContributorsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MemberContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  > div:first-child {
    margin-left: 0;
  }
`;
const AdditionalContainer = styled.div`
  color: ${(props) => props.theme.colors.black};
`;

const AdditionalWrapper = styled.div`
  background-color: #eeeeee;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: -7px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoldText = styled.p`
  font-weight: 800;
`;

const Member = ({ userID, username }: MemberProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <>
      <AvartarWrapper hover={visible} ref={setTriggerRef}>
        <AvatarBorder>
          <Avatar id={userID} size="small" />
        </AvatarBorder>
      </AvartarWrapper>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {username}
        </div>
      )}
    </>
  );
};

const Contributors: React.FC<ListProps> = ({
  listContributors,
  usersChange,
  limit,
}) => {
  const [list, setList] = useState<
    { id: string; fullName: string | undefined }[]
  >([]);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  useEffect(() => {
    if (listContributors?.length && usersChange?.length) {
      const contributors = listContributors.map((item, index) => ({
        id: item,
        fullName: usersChange.find((user) => user.id === item)?.fullName,
      }));
      const uniqList = _.uniqBy(list.concat(contributors), 'id');
      setList(uniqList);
    }
    if (!listContributors?.length && usersChange?.length) setList(usersChange);
  }, [listContributors, usersChange]);

  return (
    <ContributorsWrapper>
      <MemberContainer>
        {list?.length > 0 &&
          list.map((item, index) => (
            <div key={index}>
              {index < limit && (
                <Member userID={item.id} username={item.fullName} />
              )}
            </div>
          ))}
      </MemberContainer>
      <AdditionalContainer>
        {list && list.length > limit && (
          <AdditionalWrapper ref={setTriggerRef}>
            <BoldText>{list.length - limit}</BoldText>
          </AdditionalWrapper>
        )}
        {visible && list && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'tooltip-container' })}
          >
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            {list.map((item, index) => {
              if (index !== 0) {
                return ', ' + item.fullName;
              } else {
                return item.fullName;
              }
            })}
          </div>
        )}
      </AdditionalContainer>
    </ContributorsWrapper>
  );
};

export default Contributors;
