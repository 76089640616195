import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { faPlus, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createKeyResult as CreateKeyResultMutation } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { ObjectiveStore } from '../../pages/OKR/CurrentObjectives';
import {
  KeyResult as GraphQLKeyResult,
  MutationCreateKeyResultArgs,
} from '../../graphql/types';
import { isJust } from '../../maybe';

const KeyResultForm = styled.form`
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 38px;

  border-top: 1px solid #c8cad1;
`;

const NonEditingContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 24px;

  border-top: 1px solid #c8cad1;
  background-color: #ffffff;
`;

const NewKeyResultText = styled.span`
  font-family: inherit;
  font-size: 16px;
  color: #aeafaf;
  letter-spacing: 0;
`;

const NewKeyResultInput = styled.input`
  width: 100%;
  border: 0;
  resize: none;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.item.container};
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

MenuButton.defaultProps = {
  type: 'button',
};

const SaveButton = styled(MenuButton)`
  /* pointer-events: none; */
  :hover {
    color: ${(props) => props.theme.colors.item.hoverSave};
  }
`;

const CancelButton = styled(MenuButton)`
  margin-left: 10px;
  :hover {
    color: ${(props) => props.theme.colors.item.hover};
  }

  :disabled {
    pointer-events: none;
  }
`;

const newKeyResultSchema = yup.object().shape({
  name: yup.string().required(),
});

export type NewKeyResultProps = {
  objectiveID: string;
  parentObjectiveID?: string;
};

const NewKeyResult: React.FC<NewKeyResultProps> = ({
  objectiveID,
  parentObjectiveID,
}) => {
  const [, dispatch] = useContext(ObjectiveStore);
  const [editing, setEditing] = useState<boolean>(false);
  const newKeyResultFormMethods = useForm({
    resolver: yupResolver(newKeyResultSchema),
    defaultValues: {
      name: '',
    },
  });

  const createKeyResult = async (name: string) => {
    const createKeyResultArgs: MutationCreateKeyResultArgs = {
      input: {
        name,
        objectiveID,
      },
    };

    const queryResult = (await API.graphql(
      graphqlOperation(CreateKeyResultMutation, createKeyResultArgs),
    )) as GraphQLResult<{
      createKeyResult: GraphQLKeyResult;
    }>;

    const createdKeyResult = queryResult?.data?.createKeyResult;

    if (createdKeyResult) {
      if (parentObjectiveID && isJust(parentObjectiveID)) {
        dispatch({
          type: 'addKeyResultToSubObjective',
          payload: createdKeyResult,
          meta: {
            parentObjectiveID,
          },
        });
      } else {
        dispatch({
          type: 'addKeyResult',
          payload: createdKeyResult,
        });
      }

      setEditing(false);
    }
  };

  if (editing) {
    return (
      <KeyResultForm
        onSubmit={newKeyResultFormMethods.handleSubmit(async (data) => {
          await createKeyResult(data.name);
          newKeyResultFormMethods.reset();
        })}
      >
        <Controller
          control={newKeyResultFormMethods.control}
          name="name"
          render={(fieldProps) => (
            <NewKeyResultInput
              autoFocus
              tabIndex={1}
              placeholder="Enter a title for key result"
              {...fieldProps}
            />
          )}
        />
        <SaveButton
          type="submit"
          tabIndex={2}
          disabled={newKeyResultFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faCheck} size="1x" />
        </SaveButton>
        <CancelButton
          tabIndex={3}
          onClick={() => setEditing(false)}
          disabled={newKeyResultFormMethods.formState.isSubmitting}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </CancelButton>
      </KeyResultForm>
    );
  }

  return (
    <NonEditingContainer onClick={() => setEditing(true)}>
      <NewKeyResultText>Add new key result</NewKeyResultText>
      <FontAwesomeIcon icon={faPlus} size="1x" color="#aeafaf" />
    </NonEditingContainer>
  );
};

export default NewKeyResult;
