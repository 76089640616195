import React, { useState } from 'react';
import styled from 'styled-components';
import OrganizationSelectPopover from './OrganizationSelectPopover';
import Avatar from '../Avatar/Avatar';
import { Popover } from 'react-tiny-popover';
import { NavLink, Link } from 'react-router-dom';
import { isAllowed } from '../../utils';
import { ReactComponent as PropellaBrand } from './PropellaBrand.svg';
import packageInfo from '../../../package.json';
import { COLORS } from '../../common/color';
import { ACTION } from '../../common/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faHeadset,
} from '@fortawesome/free-solid-svg-icons';
import { isJust, withDefault } from '../../maybe';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import { useBoundStore } from '../../states';

type SideNavProps = {
  menuItems: Array<{
    label: string;
    icon: JSX.Element;
    path: string;
  }>;
  switchOrganization: (organizationId: string) => void;
  signOut: () => void;
};

const StyledLogo = styled(PropellaBrand)`
  width: 100%;
  max-width: 170px;
`;

const NavWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 220px;
  background-color: ${(props) => props.theme.colors.sideNav.wrapper};
`;

const NavHeader = styled.div`
  box-sizing: border-box;
  height: 100px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.sideNav.header};
`;

const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  height: 80px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  padding: 0px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.colors.sideNav.header};
`;

const IconWrapper = styled.span`
  margin-right: 20px;
`;

const OrganizationSwitcher = styled.div`
  height: 80px;
  background-color: ${(props) => props.theme.colors.sideNav.addBg};
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  padding: 20px 30px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.sideNav.header};
`;

const MenuTextContainer = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
`;

const UserControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  min-height: 230px;
  box-sizing: border-box;
  align-self: end;
`;

const UserFullName = styled.div`
  margin-top: 25px;
  color: ${(props) => props.theme.colors.white};
  max-width: 90px;
  text-align: center;
`;

const SignOutButton = styled.button`
  border: none;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
  outline: none;
  background-color: transparent;
  margin-top: 50px;
  cursor: pointer;
`;

const VersionContainer = styled.div`
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VersionLabel = styled.div`
  color: ${(props) => props.theme.colors.sideNav.label};
  font-size: 10px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  :active {
    text-decoration: none;
  }
`;

const SideNav = ({
  menuItems = [],
  signOut,
  switchOrganization,
}: SideNavProps) => {
  const [isOrgSelectOpen, setOrgSelectOpen] = useState(false);
  const { currentMembership, profile, isCustomerSupport } = useBoundStore((state) => state.auth);

  const activeNavStyle = {
    backgroundColor: `${COLORS.activeSideNav}`,
    fontWeight: 700,
  };
  const canAccessSetting = (
    grantedActions: string[] | undefined,
    label: string,
  ) => {
    if (label === 'Settings')
      return isAllowed(grantedActions, [ACTION.ADMINISTER]);
    return true;
  };

  const enabledModules = currentMembership?.organization?.enabledModules || [
    '100-dp',
  ];

  const filteredMenuItems = menuItems.filter((item) => {
    if (item.label === 'OKR / KPI') return enabledModules.includes('okr');
    if (item.label === 'Execute') return enabledModules.includes('100-dp');

    return true;
  });

  return (
    <NavWrapper>
      <Link to="/plan">
        <NavHeader>
          <StyledLogo />
        </NavHeader>
      </Link>
      <Popover
        isOpen={isOrgSelectOpen}
        onClickOutside={() => setOrgSelectOpen(false)}
        positions={['right']}
        align="start"
        content={
          <OrganizationSelectPopover switchOrganization={switchOrganization} />
        }
      >
        <OrganizationSwitcher
          onClick={() => setOrgSelectOpen(!isOrgSelectOpen)}
        >
          <div style={{ marginRight: '10px' }}>
            {!!currentMembership?.organization?.avatarUrl ? (
              <OrganizationAvatar
                avatarUrl={currentMembership.organization.avatarUrl}
              />
            ) : (
              <Avatar
                id={withDefault(currentMembership?.organization.id, '')}
              />
            )}
          </div>

          <MenuTextContainer>
            {currentMembership?.organization.name}
          </MenuTextContainer>
          {isOrgSelectOpen ? (
            <FontAwesomeIcon icon={faChevronRight} color="white" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} color="white" />
          )}
        </OrganizationSwitcher>
      </Popover>
      <NavMenu>
        {filteredMenuItems.map((menuItem, index) => (
          <StyledNavLink
            key={String(index)}
            to={menuItem.path}
            activeStyle={activeNavStyle}
          >
            {canAccessSetting(
              withDefault(currentMembership?.role?.grantedActions, []).filter(
                isJust,
              ),
              menuItem.label,
            ) && (
              <MenuItem>
                <IconWrapper>{menuItem.icon}</IconWrapper>
                {menuItem.label}
              </MenuItem>
            )}
          </StyledNavLink>
        ))}
        {isCustomerSupport && (
          <StyledNavLink to={'/support'} activeStyle={activeNavStyle}>
            <MenuItem>
            <IconWrapper>
            <FontAwesomeIcon size="lg" icon={faHeadset} />
            </IconWrapper>
              Support
            </MenuItem>
          </StyledNavLink>
        )}
        
      </NavMenu>
      <Link to="/profile">
        <UserControlContainer>
          <Avatar id={profile?.id || ''} size="large" />
          <UserFullName>{profile?.fullName}</UserFullName>
          <SignOutButton onClick={() => signOut()}>Sign out</SignOutButton>
        </UserControlContainer>
      </Link>

      <VersionContainer>
        <VersionLabel>v {packageInfo.version}</VersionLabel>
      </VersionContainer>
    </NavWrapper>
  );
};

export default SideNav;
