import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import Label from '../Label/Label';

type ColorFieldProps = {
  value: string;
  label: string;
  error?: boolean | undefined;
  disabled?: boolean;
  onChange: (value: string) => void;
};

type ContainerProps = {
  hasError: boolean | undefined;
  focus: boolean;
};

const TextFieldContainer = styled.div<ContainerProps>`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.textfield.text};
  cursor: text;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => {
    if (props.hasError) return props.theme.colors.error;
    if (props.focus) return props.theme.colors.textfield.focusBorder;
    return props.theme.colors.textfield.border;
  }};
  background-color: ${(props) => {
    if (props.hasError) return props.theme.colors.textfield.errorBackground;
    if (props.focus) return props.theme.colors.textfield.focusBackground;
    return props.theme.colors.white;
  }};
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  padding: 20px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.input.disabled : 'transparent'};
  border: none;
  border-radius: 6px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0px;
  outline: none;
  width: 100%;
  line-height: 1.42857;
  font-family: 'Open Sans', sans-serif;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'normal')};
`;

const ControlContainer = styled.div`
  width: 100%;
`;

const StyledCrosshair = styled(FontAwesomeIcon)`
  color: #252c39;
`;

const PickerButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 20px;

  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  :hover {
    color: ${(props) => props.theme.colors.item.hoverEditor};
  }

  :disabled {
    pointer-events: none;
  }
`;

const ColorField: React.FC<ColorFieldProps> = ({
  label,
  error,
  value,
  disabled,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);

  return (
    <ControlContainer>
      <Label hasError={error}>{label}</Label>
      <TextFieldContainer hasError={error} focus={focused}>
        <StyledInput
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(event) => onChange(event.target.value)}
          type="text"
          disabled={disabled}
        />
        <Popover
          isOpen={colorPickerOpen}
          positions={['bottom', 'top']}
          onClickOutside={() => setColorPickerOpen(false)}
          align="start"
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={'#ffffff'}
              arrowSize={0}
            >
              <SketchPicker
                color={value}
                disableAlpha={true}
                onChange={(selectedColor) => {
                  onChange(selectedColor.hex);
                }}
              />
            </ArrowContainer>
          )}
        >
          <PickerButton type="button" onClick={() => setColorPickerOpen(true)}>
            <StyledCrosshair icon={faCrosshairs} />
          </PickerButton>
        </Popover>
      </TextFieldContainer>
    </ControlContainer>
  );
};

export default ColorField;
