export enum NewEntryV2Variant {
  INITIATIVE = 'initiative',
  TEXT = 'text',
}

export type NewEntryConfig = {
  type: NewEntryV2Variant;
  topic: string;
  defaultTags?: string[];
};

export type StaticCell = {
  type: 'static';
  content: string;
  backgroundColor: string;
  textColor: string;
  textAlign: 'left' | 'center' | 'right';
  verticalAlign: 'top' | 'middle' | 'bottom';
  x: number;
  y: number;
  width: number;
  height: number;
};

export type DataCell = {
  type: 'data';
  x: number;
  y: number;
  width: number;
  height: number;
  backgroundColor?: string;
  query: string;
  newEntry?: NewEntryConfig;
};

export const cells: Array<StaticCell | DataCell> = [
  {
    type: 'static',
    content: 'KRA',
    backgroundColor: '#D32B2C',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 1,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'ShareholderValue',
    backgroundColor: '#5E4979',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 2,
    y: 1,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Systems and Processes',
    backgroundColor: '#31839A',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 3,
    y: 1,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Risk',
    backgroundColor: '#943733',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 4,
    y: 1,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'People',
    backgroundColor: '#75913C',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 5,
    y: 1,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Customers and Markets',
    backgroundColor: '#E36B09',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 6,
    y: 1,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Products and Services',
    backgroundColor: '#366090',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 7,
    y: 1,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: '',
    backgroundColor: '#D32B2C',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 2,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Growth and Profitablity',
    backgroundColor: '#5E4979',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 2,
    y: 2,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Resource Optimisation-OPT',
    backgroundColor: '#31839A',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 3,
    y: 2,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Business Resilience',
    backgroundColor: '#943733',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 4,
    y: 2,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'People and Culture-PVP',
    backgroundColor: '#75913C',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 5,
    y: 2,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Competitive Advantage-CVP',
    backgroundColor: '#E36B09',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 6,
    y: 2,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Differentiation',
    backgroundColor: '#366090',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 7,
    y: 2,
    width: 1,
    height: 1,
  },
  {
    type: 'static',
    content: 'Strategic Intent',
    backgroundColor: '#000005',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 3,
    width: 7,
    height: 1,
  },
  {
    type: 'static',
    content: 'Key definition of strategic intent that will enable your vision',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    textAlign: 'left',
    verticalAlign: 'top',
    x: 1,
    y: 4,
    width: 1,
    height: 1,
  },
  {
    type: 'data',
    x: 2,
    y: 4,
    width: 1,
    height: 1,
    backgroundColor: '#5e4979',
    query: 'topicKey := strategic-intent-shareholder-value AND __typename := TextEntry',
    newEntry: {
      type: NewEntryV2Variant.TEXT,
      topic: 'strategic-intent-shareholder-value',
    },
  },
  {
    type: 'data',
    x: 3,
    y: 4,
    width: 1,
    height: 1,
    backgroundColor: '#31839A',
    query: 'topicKey := strategic-intent-systems-and-processes AND __typename := TextEntry',
    newEntry: {
      type: NewEntryV2Variant.TEXT,
      topic: 'strategic-intent-systems-and-processes',
    },
  },
  {
    type: 'data',
    x: 4,
    y: 4,
    width: 1,
    height: 1,
    backgroundColor: '#943733',
    query: 'topicKey := strategic-intent-risk AND __typename := TextEntry',
    newEntry: {
      type: NewEntryV2Variant.TEXT,
      topic: 'strategic-intent-risk',
    },
  },
  {
    type: 'data',
    x: 5,
    y: 4,
    width: 1,
    height: 1,
    backgroundColor: '#75913C',
    query: 'topicKey := strategic-intent-people AND __typename := TextEntry',
    newEntry: {
      type: NewEntryV2Variant.TEXT,
      topic: 'strategic-intent-people',
    },
  },
  {
    type: 'data',
    x: 6,
    y: 4,
    width: 1,
    height: 1,
    backgroundColor: '#E36B09',
    query: 'topicKey := strategic-intent-customers-and-markets AND __typename := TextEntry',
    newEntry: {
      type: NewEntryV2Variant.TEXT,
      topic: 'strategic-intent-customers-and-markets',
    },
  },
  {
    type: 'data',
    x: 7,
    y: 4,
    width: 1,
    height: 1,
    backgroundColor: '#366090',
    query: 'topicKey := strategic-intent-products-and-services AND __typename := TextEntry',
    newEntry: {
      type: NewEntryV2Variant.TEXT,
      topic: 'strategic-intent-products-and-services',
    },
  },
  {
    type: 'static',
    content: 'Strategic Objectives & Key Performance Indicators',
    backgroundColor: '#000005',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 5,
    width: 7,
    height: 1,
  },
  {
    type: 'static',
    content:
      'Key objectives necessary to achieve your Strategic Intent & Lead and lag performance measures - financial and non-financial that define achievement of your strategic objectives',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    textAlign: 'left',
    verticalAlign: 'top',
    x: 1,
    y: 6,
    width: 1,
    height: 1,
  },
  {
    type: 'data',
    x: 2,
    y: 6,
    width: 1,
    height: 1,
    backgroundColor: '#5e4979',
    query:
      'topicKey := strategic-objectives-shareholder-value AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-objectives-shareholder-value',
    },
  },
  {
    type: 'data',
    x: 3,
    y: 6,
    width: 1,
    height: 1,
    backgroundColor: '#31839A',
    query:
      'topicKey := strategic-objectives-systems-and-processes AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-objectives-systems-and-processes',
    },
  },
  {
    type: 'data',
    x: 4,
    y: 6,
    width: 1,
    height: 1,
    backgroundColor: '#943733',
    query: 'topicKey := strategic-objectives-risk AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-objectives-risk',
    },
  },
  {
    type: 'data',
    x: 5,
    y: 6,
    width: 1,
    height: 1,
    backgroundColor: '#75913C',
    query: 'topicKey := strategic-objectives-people AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-objectives-people',
    },
  },
  {
    type: 'data',
    x: 6,
    y: 6,
    width: 1,
    height: 1,
    backgroundColor: '#E36B09',
    query:
      'topicKey := strategic-objectives-customers-and-markets AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-objectives-customers-and-markets',
    },
  },
  {
    type: 'data',
    x: 7,
    y: 6,
    width: 1,
    height: 1,
    backgroundColor: '#366090',
    query:
      'topicKey := strategic-objectives-products-and-services AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-objectives-products-and-services',
    },
  },
  {
    type: 'static',
    content: 'Strategic Execution Initiatives',
    backgroundColor: '#000005',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 7,
    width: 7,
    height: 1,
  },
  {
    type: 'static',
    content: 'HORIZON 1: Defend and extend the current core business',
    backgroundColor: '#585858',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 8,
    width: 7,
    height: 1,
  },
  {
    type: 'static',
    content: 'Optimise the existing business',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    textAlign: 'left',
    verticalAlign: 'top',
    x: 1,
    y: 9,
    width: 1,
    height: 1,
  },
  {
    type: 'data',
    x: 2,
    y: 9,
    width: 1,
    height: 1,
    backgroundColor: '#5e4979',
    query:
      'topicKey := strategic-execution-initiatives-horizon-1-shareholder-value AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-1-shareholder-value',
    },
  },
  {
    type: 'data',
    x: 3,
    y: 9,
    width: 1,
    height: 1,
    backgroundColor: '#31839A',
    query:
      'topicKey := strategic-execution-initiatives-horizon-1-systems-and-processes AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-1-systems-and-processes',
    },
  },
  {
    type: 'data',
    x: 4,
    y: 9,
    width: 1,
    height: 1,
    backgroundColor: '#943733',
    query:
      'topicKey := strategic-execution-initiatives-horizon-1-risk AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-1-risk',
    },
  },
  {
    type: 'data',
    x: 5,
    y: 9,
    width: 1,
    height: 1,
    backgroundColor: '#75913C',
    query:
      'topicKey := strategic-execution-initiatives-horizon-1-people AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-1-people',
    },
  },
  {
    type: 'data',
    x: 6,
    y: 9,
    width: 1,
    height: 1,
    backgroundColor: '#E36B09',
    query:
      'topicKey := strategic-execution-initiatives-horizon-1-customers-and-markets AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-1-customers-and-markets',
    },
  },
  {
    type: 'data',
    x: 7,
    y: 9,
    width: 1,
    height: 1,
    backgroundColor: '#366090',
    query:
      'topicKey := strategic-execution-initiatives-horizon-1-products-and-services AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-1-products-and-services',
    },
  },
  {
    type: 'static',
    content: 'HORIZON 2: Build momentum of emerging new businesses',
    backgroundColor: '#585858',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 10,
    width: 7,
    height: 1,
  },
  {
    type: 'static',
    content: 'Transform and Integrate',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    textAlign: 'left',
    verticalAlign: 'top',
    x: 1,
    y: 11,
    width: 1,
    height: 1,
  },
  {
    type: 'data',
    x: 2,
    y: 11,
    width: 1,
    height: 1,
    backgroundColor: '#5e4979',
    query:
      'topicKey := strategic-execution-initiatives-horizon-2-shareholder-value AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-2-shareholder-value',
    },
  },
  {
    type: 'data',
    x: 3,
    y: 11,
    width: 1,
    height: 1,
    backgroundColor: '#31839A',
    query:
      'topicKey := strategic-execution-initiatives-horizon-2-systems-and-processes AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-2-systems-and-processes',
    },
  },
  {
    type: 'data',
    x: 4,
    y: 11,
    width: 1,
    height: 1,
    backgroundColor: '#943733',
    query:
      'topicKey := strategic-execution-initiatives-horizon-2-risk AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-2-risk',
    },
  },
  {
    type: 'data',
    x: 5,
    y: 11,
    width: 1,
    height: 1,
    backgroundColor: '#75913C',
    query:
      'topicKey := strategic-execution-initiatives-horizon-2-people AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-2-people',
    },
  },
  {
    type: 'data',
    x: 6,
    y: 11,
    width: 1,
    height: 1,
    backgroundColor: '#E36B09',
    query:
      'topicKey := strategic-execution-initiatives-horizon-2-customers-and-markets AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-2-customers-and-markets',
    },
  },
  {
    type: 'data',
    x: 7,
    y: 11,
    width: 1,
    height: 1,
    backgroundColor: '#366090',
    query:
      'topicKey := strategic-execution-initiatives-horizon-2-products-and-services AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-2-products-and-services',
    },
  },
  {
    type: 'static',
    content:
      'HORIZON 3: Value Innovation and explore options for future businesses',
    backgroundColor: '#585858',
    textColor: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    x: 1,
    y: 12,
    width: 7,
    height: 1,
  },
  {
    type: 'static',
    content: 'Experiment/place some bets and see what happen',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    textAlign: 'left',
    verticalAlign: 'top',
    x: 1,
    y: 13,
    width: 1,
    height: 1,
  },
  {
    type: 'data',
    x: 2,
    y: 13,
    width: 1,
    height: 1,
    backgroundColor: '#5e4979',
    query:
      'topicKey := strategic-execution-initiatives-horizon-3-shareholder-value AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-3-shareholder-value',
    },
  },
  {
    type: 'data',
    x: 3,
    y: 13,
    width: 1,
    height: 1,
    backgroundColor: '#31839A',
    query:
      'topicKey := strategic-execution-initiatives-horizon-3-systems-and-processes AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-3-systems-and-processes',
    },
  },
  {
    type: 'data',
    x: 4,
    y: 13,
    width: 1,
    height: 1,
    backgroundColor: '#943733',
    query:
      'topicKey := strategic-execution-initiatives-horizon-3-risk AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-3-risk',
    },
  },
  {
    type: 'data',
    x: 5,
    y: 13,
    width: 1,
    height: 1,
    backgroundColor: '#75913C',
    query:
      'topicKey := strategic-execution-initiatives-horizon-3-people AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-3-people',
    },
  },
  {
    type: 'data',
    x: 6,
    y: 13,
    width: 1,
    height: 1,
    backgroundColor: '#E36B09',
    query:
      'topicKey := strategic-execution-initiatives-horizon-3-customers-and-markets AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-3-customers-and-markets',
    },
  },
  {
    type: 'data',
    x: 7,
    y: 13,
    width: 1,
    height: 1,
    backgroundColor: '#366090',
    query:
      'topicKey := strategic-execution-initiatives-horizon-3-products-and-services AND __typename := Initiative',
    newEntry: {
      type: NewEntryV2Variant.INITIATIVE,
      topic: 'strategic-execution-initiatives-horizon-3-products-and-services',
    },
  },
];
