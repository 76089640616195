import React from 'react';
import styled from 'styled-components';
export interface EntryViewerProps {
  entry: JsonDocument;
}

const EntryViewrContainer = styled.div`
  
  overflow-wrap: anywhere;
  * {
    line-height: 23px;
  }
  p {
    margin: 0 !important;
  }

  h2,
  h1,
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }

  blockquote {
    border-left: 3px solid #dfe1e6;
    padding-left: 10px;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin: 0;
  }
`;

const EntryViewer: React.FC<EntryViewerProps> = ({ entry }) => {
  const renderBlock = (block: JsonBlock, blockIndex: number) => {
    switch (block.type) {
      case 'text':
        return <span>{block.text}</span>;
      case 'orderedList':
        return <ol>{block.content.map(renderBlock)}</ol>;
      case 'listItem':
        return <li>{block.content.map(renderBlock)}</li>;
      case 'bulletList':
        return <ul>{block.content.map(renderBlock)}</ul>;
      case 'blockquote':
        return <blockquote>{block.content.map(renderBlock)}</blockquote>;
      case 'paragraph':
        return <p>{block.content?.map(renderBlock)}</p>;
      default:
        return <div />;
    }
  };

  return (
    <EntryViewrContainer>
      {entry?.content?.map((block, blockIndex) =>
        renderBlock(block, blockIndex),
      )}
    </EntryViewrContainer>
  );
};

export default EntryViewer;
