import React from 'react';
import styled from 'styled-components';
import CanvasTopic from '../CanvasTopic/CanvasTopic';
import StaticCell from './StaticCell';
import { GridCell } from '@propella/core';
import { isJust } from '../../maybe';
import shallow from 'zustand/shallow';
import { useBoundStore } from '../../states';

type GridVisualizerInput = {
  readOnly: boolean;
};
const CanvasContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: minmax(min-content, 300px);
`;

const GridVisualizer: React.FC<GridVisualizerInput> = ({ readOnly }) => {
  const cells = useBoundStore(
    (state) => state.document.views.canvas?.cells || [],
    shallow,
  );

  const renderTopic = (cell: GridCell, cellIndex: number) => {
    if (!isJust(cell)) return null;

    if (!isJust(cell.topicKey)) return <StaticCell cell={cell} />;

    return (
      <CanvasTopic
        cell={cell}
        key={String(cellIndex)}
        topicId={cell.topicKey}
        readOnly={readOnly}
      />
    );
  };

  if (!isJust(cells)) return null;

  return (
    <CanvasContainer id="gridVisualizer">
      {cells.filter(isJust).map(renderTopic)}
    </CanvasContainer>
  );
};

export default GridVisualizer;
