import { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useBoundStore } from '../states';

type SupportRouteProps = {
  children: ReactElement;
  path: string;
  exact?: boolean;
};

const SupportRoute = ({ children, ...rest }: SupportRouteProps) => {
  const { isCustomerSupport } = useBoundStore((state) => state.auth);
  return (
    <Route
      {...rest}
      render={({ location }) => isCustomerSupport ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/plan',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default SupportRoute;
