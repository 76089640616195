import { ErrorMessage } from '@hookform/error-message';
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ColorField from '../ColorField/ColorField';
import StyledErrorMessage from '../ErrorMessage/ErrorMessage';
import FieldContainer from '../FieldContainer/FieldContainer';

interface ContextColorFieldProps {
  name: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
}

const ContextColorField: React.FC<ContextColorFieldProps> = ({
  name,
  label,
  defaultValue,
  disabled,
}) => {
  const { control, errors } = useFormContext();

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={(controllerProps) => (
          <ColorField
            label={label}
            onChange={controllerProps.onChange}
            value={controllerProps.value}
            error={_.get(errors, name)}
            disabled={disabled}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <StyledErrorMessage>
            {message.replace(name, label)}
          </StyledErrorMessage>
        )}
      />
    </FieldContainer>
  );
};

export default ContextColorField;
