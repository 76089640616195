import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../SectionHeader/SectionHeader';
import CompareTopic from './CompareTopic';
import {
  Topic,
  Domain,
  EntryChange,
  Dictionary,
  CompareScope,
  UsersChangeType,
} from '../../common/type';
import { ListRow, ListViewConfig, SubListRow } from '@propella/core';
import { isJust, withDefault } from '../../maybe';
import _ from 'lodash';
import Maybe from 'graphql/tsutils/Maybe';

type CompareListInput = {
  scope: CompareScope;
  changes?: EntryChange[];
  usersChange?: UsersChangeType[];
};
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type SubListProps = {
  header: string;
  rows: ListRow['items'];
  domains: Dictionary<Domain>;
  topics: Dictionary<Topic>;
  changes?: EntryChange[];
  usersChange?: UsersChangeType[];
};

const SubList: React.FC<SubListProps> = ({
  header,
  rows,
  domains,
  topics,
  changes,
  usersChange,
}) => {
  const renderSubListRow = (row: Maybe<SubListRow>, rowIndex: number) => {
    if (!isJust(row) || !row) return null;

    if (!isJust(row.topicKey)) return null;

    const rowTopicKey = row.topicKey || '';
    const rowDomainKey = withDefault(
      topics[rowTopicKey].domain || '',
      'default',
    );
    return (
      <CompareTopic
        key={String(rowIndex)}
        usersChange={usersChange}
        cell={row}
        topic={topics[rowTopicKey]}
        domain={domains[rowDomainKey]}
        changes={changes?.filter((change) => change.topicKey === row.topicKey)}
      />
    );
  };

  return (
    <>
      <SectionHeader>{header}</SectionHeader>
      {rows && isJust(rows) && rows.map(renderSubListRow)}
    </>
  );
};

const CompareList = ({ scope, changes, usersChange }: CompareListInput) => {
  const { document, domains, topics } = scope;

  const rows = _.get(
    document,
    'views.list.rows',
    null,
  ) as ListViewConfig['rows'];

  if (!rows || !isJust(rows)) return null;

  const renderRow = (row: Maybe<ListRow>, rowIndex: number) => {
    if (!isJust(row) || !row) return null;

    if (isJust(row.topicKey)) {
      return (
        <CompareTopic
          key={String(rowIndex)}
          usersChange={usersChange}
          cell={row}
          topic={topics[row.topicKey || '']}
          domain={
            domains[
              withDefault(topics[row.topicKey || ''].domain || '', 'default')
            ]
          }
          changes={changes?.filter(
            (change) => change.topicKey === row.topicKey,
          )}
        />
      );
    }

    return (
      <SubList
        key={String(rowIndex)}
        usersChange={usersChange}
        header={withDefault(row.header || '', '')}
        rows={row.items}
        topics={topics}
        domains={domains}
        changes={changes}
      />
    );
  };

  return <ListContainer>{rows.map(renderRow)}</ListContainer>;
};

export default CompareList;
