export const initiativeTypes = [
  {
    value: 'tactical',
    name: 'Tactical',
    color: '#EBB537',
  },
  {
    value: 'strategic',
    name: 'Strategic',
    color: '#2F43FB',
  },
];

export const priorities = [
  {
    color: '#FF5C5C',
    name: 'Very High',
    value: 'priority-very-high',
  },
  {
    color: '#EBB537',
    name: 'High',
    value: 'priority-high',
  },
  {
    color: '#2F43FB',
    name: 'Medium',
    value: 'priority-medium',
  },
  {
    color: '#75913C',
    name: 'Low',
    value: 'priority-low',
  },
];

export const horizons = [
  {
    value: 'horizon-1',
    name: 'Horizon #1',
    color: '#5E4979',
    description: 'Defend and extend the current core business',
  },
  {
    value: 'horizon-2',
    name: 'Horizon #2',
    color: '#75913C',
    description: 'Build momentum of emerging new businesses',
  },
  {
    value: 'horizon-3',
    name: 'Horizon #3',
    color: '#943733',
    description: 'Value Innovation and explore options for future businesses',
  },
];

export const businessRisks = [
  {
    value: 'risk-extreme',
    color: '#FF5C5C',
    name: 'Extreme',
  },
  {
    color: '#EBB537',
    name: 'High',
    value: 'risk-high',
  },
  {
    color: '#2F43FB',
    name: 'Medium',
    value: 'risk-medium',
  },
  {
    color: '#75913C',
    name: 'Low',
    value: 'risk-low',
  },
];

export const strategicKRAs = [
  {
    value: 'shareholder-value',
    name: 'SHV',
    color: '#5E4979',
    description: 'Shareholder Value',
  },
  {
    value: 'systems-and-processes',
    name: 'S&P',
    color: '#31839A',
    description: 'System and Processes',
  },
  {
    value: 'customers-and-markets',
    name: 'MKT',
    color: '#E36B09',
    description: 'Customers and Markets',
  },
  {
    value: 'people',
    name: 'P&C',
    color: '#75913C',
    description: 'People and Culture',
  },
  {
    value: 'risk',
    name: 'RISK',
    color: '#943733',
    description: 'Enterprise Risk',
  },
  {
    value: 'products-and-services',
    name: 'INN',
    color: '#366090',
    description: 'Innovation',
  },
];

export const completionTargets = [
  {
    value: 'within-30-days',
    name: 'Within 30 Days',
    color: '#FF5C5C',
  },
  {
    value: 'within-60-days',
    name: 'Within 60 Days',
    color: '#EBB537',
  },
  {
    value: 'within-90-days',
    name: 'Within 90 Days',
    color: '#8BC417',
  },
];

export const status = [
  {
    value: 'status-behind-schedule',
    name: 'Behind Schedule',
    color: '#FF5C5C',
  },
  {
    value: 'status-on-track',
    name: 'On Track',
    color: '#EBB537',
  },
  {
    value: 'status-completed',
    name: 'Completed',
    color: '#8BC417',
  },
];
