/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($id: ID) {
    onCreateDocument(id: $id) {
      id
      name
      template
      domains {
        key
        name
        bgColor
        fgColor
        topicNameColor
        ordering
      }
      topics {
        key
        name
        domain
        description
        nameBgColor
        descriptionBgColor
        entryOrder
        selectors
      }
      entryRank
      organizationID
      objectives {
        nextToken
      }
      entries {
        nextToken
      }
      accessPermissions
      editPermissions
      contributions {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEntry = /* GraphQL */ `
  subscription OnCreateEntry($documentID: ID) {
    onCreateEntry(documentID: $documentID) {
      id
      name
      overview
      tags
      meta
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      lastEditorID
      activities {
        nextToken
      }
      accessPermissions
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEntry = /* GraphQL */ `
  subscription OnUpdateEntry($documentID: ID) {
    onUpdateEntry(documentID: $documentID) {
      id
      name
      overview
      tags
      meta
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      lastEditorID
      activities {
        nextToken
      }
      accessPermissions
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEntry = /* GraphQL */ `
  subscription OnDeleteEntry($documentID: ID) {
    onDeleteEntry(documentID: $documentID) {
      id
      name
      overview
      tags
      meta
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      lastEditorID
      activities {
        nextToken
      }
      accessPermissions
      editPermissions
      createdAt
      updatedAt
    }
  }
`;
export const onTransition = /* GraphQL */ `
  subscription OnTransition($documentID: ID!) {
    onTransition(documentID: $documentID) {
      documentID
      entryID
      topicKey
      rankBeforeEntryID
      rankAfterEntryID
    }
  }
`;
export const onCreateContribution = /* GraphQL */ `
  subscription OnCreateContribution {
    onCreateContribution {
      id
      userID
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      contributed
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateContribution = /* GraphQL */ `
  subscription OnUpdateContribution {
    onUpdateContribution {
      id
      userID
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      contributed
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteContribution = /* GraphQL */ `
  subscription OnDeleteContribution {
    onDeleteContribution {
      id
      userID
      documentID
      document {
        id
        name
        template
        entryRank
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      contributed
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization {
    onCreateOrganization {
      id
      name
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      avatarUrl
      roles {
        nextToken
      }
      users
      iterations {
        nextToken
      }
      currentIterationID
      owner
      createdAt
      updatedAt
      invitations {
        nextToken
      }
      memberships {
        nextToken
      }
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization {
    onUpdateOrganization {
      id
      name
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      avatarUrl
      roles {
        nextToken
      }
      users
      iterations {
        nextToken
      }
      currentIterationID
      owner
      createdAt
      updatedAt
      invitations {
        nextToken
      }
      memberships {
        nextToken
      }
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization {
    onDeleteOrganization {
      id
      name
      businessNumber
      primaryEmail
      countryCode
      addressLine
      postalCode
      suburb
      region
      avatarUrl
      roles {
        nextToken
      }
      users
      iterations {
        nextToken
      }
      currentIterationID
      owner
      createdAt
      updatedAt
      invitations {
        nextToken
      }
      memberships {
        nextToken
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      fullName
      email
      owner
      contributions {
        nextToken
      }
      assignments {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      fullName
      email
      owner
      contributions {
        nextToken
      }
      assignments {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      fullName
      email
      owner
      contributions {
        nextToken
      }
      assignments {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        nextToken
      }
    }
  }
`;
export const onCreateMembership = /* GraphQL */ `
  subscription OnCreateMembership($owner: String) {
    onCreateMembership(owner: $owner) {
      id
      organizationID
      userID
      invitationID
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateMembership = /* GraphQL */ `
  subscription OnUpdateMembership($owner: String) {
    onUpdateMembership(owner: $owner) {
      id
      organizationID
      userID
      invitationID
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteMembership = /* GraphQL */ `
  subscription OnDeleteMembership($owner: String) {
    onDeleteMembership(owner: $owner) {
      id
      organizationID
      userID
      invitationID
      roleID
      role {
        id
        name
        grantedActions
        organizationID
        accessPermissions
        editPermissions
        createdAt
        updatedAt
      }
      owner
      accessPermissions
      editPermissions
      createdAt
      updatedAt
      organization {
        id
        name
        businessNumber
        primaryEmail
        countryCode
        addressLine
        postalCode
        suburb
        region
        avatarUrl
        users
        currentIterationID
        owner
        createdAt
        updatedAt
      }
      user {
        id
        fullName
        email
        owner
        createdAt
        updatedAt
      }
    }
  }
`;

export const onObjectiveUpdate = /* GraphQL */ `
  subscription OnObjectiveUpdate($organizationID: ID!) {
    onObjectiveUpdate(organizationID: $organizationID) {
      id
      name
      expectedEndDate
      color
      parentObjectiveID
      createdAt
      keyResults {
        id
        name
        progress
        createdAt
      }
      subObjectives {
        id
        name
        createdAt
        keyResults {
          id
          name
          progress
          createdAt
        }
        assignees {
          id
          fullName
          avatarUrl
        }
      }
      assignees {
        id
        fullName
        avatarUrl
      }
    }
  }
`;

export const onKeyResultUpdate = /* GraphQL */ `
  subscription OnKeyResultUpdate($organizationID: ID!) {
    onKeyResultUpdate(organizationID: $organizationID) {
      id
      name
      progress
      objectiveID
      parentObjectiveID
      organizationID
      createdAt
    }
  }
`;

export const onObjectiveDelete = /* GraphQL */ `
  subscription OnObjectiveDelete($organizationID: ID!) {
    onObjectiveDelete(organizationID: $organizationID) {
      id
      parentObjectiveID
      organizationID
    }
  }
`;

export const onKeyResultDelete = /* GraphQL */ `
  subscription OnKeyResultDelete($organizationID: ID!) {
    onKeyResultDelete(organizationID: $organizationID) {
      id
      objectiveID
      parentObjectiveID
      organizationID
    }
  }
`;
