import {
  faChevronUp,
  faChevronDown,
  faFilter,
  faUsers,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePickerV2 from '../DatePickerV2/DatePickerV2';
import { useBoundStore } from '../../states';
import { isJust, withDefault } from '../../maybe';
import OwnerOption from '../InitiativeTable/OnwerOption';
import { Popover } from 'react-tiny-popover';
import _ from 'lodash';
import { SelectOption } from '../../common/type';
import { DATE_OPTIONS } from '../../common/constant';
import {
  initiativeTypes,
  horizons,
  priorities,
} from '../../components/InitiativeTable/values';
import KanbanFilterCheckbox from '../KanbanFilterCheckbox/KanbanFilterCheckbox';

type FilterItemProps = {
  onClick?: () => void;
};

export type FilterProps = {};

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  height: 40px;
  width: fit-content;
  border: 1px solid #e1e4e6;
  border-radius: 6px;
  background-color: #ffffff;
`;

const FilterItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid #e1e4e6;
  :last-child {
    border: none;
  }
`;

const FilterContent = styled.div<FilterItemProps>`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 0 10px;
`;

const StyledChevron = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const DropDownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
`;

const OwnerDropDown = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  background: #ffffff;
  border: 1px solid rgba(220, 223, 227, 0.5);
  border-radius: 4px;
  margin-top: 20px;
`;

const TagContainer = styled.div`
  color: #c8cece;
  border: 1px solid #c8cece;
  border-radius: 15px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  background-color: #ffffff;

  :hover {
    color: #56b6b9;
    border: 1px solid #56b6b9;
  }
`;

const TagsContainer = styled.div`
  justify-content: end;
  gap: 10px;
  margin: 10px 0;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 295px;
  background-color: #ffffff;
  position: absolute;
  top: 54px;
  right: 0px;

  border: 1px solid #e1e4e6;
  border-bottom: 0;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e1e4e6;
`;

const FilterTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #2d3a45;
  margin: 5px 0;
`;

const FiltersHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClearAllButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #60bfbf;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const CheckboxesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 0 20px;
`;

const CheckboxContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const TotalFiltersNumber = styled.span`
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  margin-left: 4px;
  padding: 2px 5px;
  background-color: #60bfbf;
  color: #ffffff;
`;

const FiltersNumber = styled.span`
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  margin-left: 4px;
  padding: 0 5px;
  background-color: #60bfbf;
  color: #ffffff;
`;

export const KanbanFilter = ({}: FilterProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [openAssignee, setOpenAssignee] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openTypeFilter, setOpenTypeFilter] = useState(false);
  const [openHorizonFilter, setOpenHorizonFilter] = useState(false);
  const [openPriorityFilter, setOpenPriorityFilter] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [selectedInitiativeType, setSelectedInitiativeType] = useState<
    string[]
  >([]);
  const [selectedHorizon, setSelectedHorizon] = useState<string[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectOption>(
    DATE_OPTIONS[3],
  );
  const [selectedFilters, setSelectedFilters] = useState<number>(0);

  const filterType = (id: string) => {
    if (selectedInitiativeType?.includes(id)) {
      setSelectedInitiativeType(
        selectedInitiativeType.filter((type) => type !== id),
      );
    } else {
      setSelectedInitiativeType([...(selectedInitiativeType || []), id]);
    }
  };

  const filterHorizon = (id: string) => {
    if (selectedHorizon?.includes(id)) {
      setSelectedHorizon(selectedHorizon.filter((horizon) => horizon !== id));
    } else {
      setSelectedHorizon([...(selectedHorizon || []), id]);
    }
  };

  const filterPriority = (id: string) => {
    if (selectedPriority?.includes(id)) {
      setSelectedPriority(
        selectedPriority.filter((priority) => priority !== id),
      );
    } else {
      setSelectedPriority([...(selectedPriority || []), id]);
    }
  };

  const [handleKanbanFilterChange] = useBoundStore((state) => [
    state.handleKanbanFilterChange,
  ]);

  const assignableUsers = useBoundStore((state) => withDefault(state.masterlist.assignableUsers, []).filter(isJust));

  const filterAssignee = (id: string) => {
    if (selectedUserIds?.includes(id)) {
      setSelectedUserIds(selectedUserIds.filter((userId) => userId !== id));
    } else {
      setSelectedUserIds([...(selectedUserIds || []), id]);
    }
  };

  useEffect(() => {
    let selectedDate = 0;
    if (dateRange) {
      selectedDate = 1;
    }
    setSelectedFilters( selectedHorizon.length + selectedInitiativeType.length + selectedPriority.length + selectedUserIds.length + selectedDate);

    
  }, [selectedHorizon, selectedInitiativeType, selectedPriority, selectedUserIds, dateRange]);

  useEffect(() => {
    handleKanbanFilterChange(
      selectedUserIds,
      dateRange,
      selectedInitiativeType,
      selectedHorizon,
      selectedPriority,
    );
  }, [
    selectedUserIds,
    dateRange,
    selectedInitiativeType,
    selectedHorizon,
    selectedPriority,
    handleKanbanFilterChange,
  ]);

  const toggleDateFilter = () => {
    setOpenDatePicker(!openDatePicker);
  };
  const toggleAssigneeFilter = () => {
    setOpenAssignee(!openAssignee);
  };

  const toggleTypeFilter = () => {
    setOpenTypeFilter(!openTypeFilter);
  };

  const toggleHorizonFilter = () => {
    setOpenHorizonFilter(!openHorizonFilter);
  };

  const togglePriorityFilter = () => {
    setOpenPriorityFilter(!openPriorityFilter);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const clearAllClick = () => {
    setSelectedUserIds([]);
    setDateRange('');
    setSelectedInitiativeType([]);
    setSelectedHorizon([]);
    setSelectedPriority([]);
    setOpenAssignee(false);
    setOpenDatePicker(false);
    setOpenPriorityFilter(false);
    setOpenHorizonFilter(false);
    setOpenTypeFilter(false);
  };

  return (
    <Container data-testid='filter-container'>
      <FilterContainer>
        <FilterItem>
          <FilterContent onClick={() => toggleFilter()}>
            <StyledFontAwesomeIcon icon={faFilter} size="xs" />
            Filters {selectedFilters > 0 && <TotalFiltersNumber>{selectedFilters}</TotalFiltersNumber>}
            <StyledFontAwesomeIcon
              icon={openFilter ? faChevronDown : faChevronUp}
              size="xs"
            />
          </FilterContent>
          {openFilter && (
            <FiltersContainer>
              <FilterSection>
                <FiltersHeader>
                  <FilterTitle>Filters</FilterTitle>
                  <ClearAllButton onClick={() => clearAllClick()}>
                    Clear All
                  </ClearAllButton>
                </FiltersHeader>
              </FilterSection>
              <FilterSection>
                <FilterTitleContainer onClick={() => toggleAssigneeFilter()}>
                  <FilterTitle>
                    By Assignee{' '}
                    {selectedUserIds.length > 0 && (
                      <FiltersNumber>{selectedUserIds.length}</FiltersNumber>
                    )}
                  </FilterTitle>
                  <StyledFontAwesomeIcon
                    icon={openAssignee ? faChevronDown : faChevronUp}
                    size="xs"
                  />
                </FilterTitleContainer>
                {openAssignee &&
                  assignableUsers.map((user) => (
                    <OwnerOption
                      key={user.id}
                      isSelected={selectedUserIds.includes(user.id)}
                      id={user.id}
                      name={user.fullName}
                      email={user.email}
                      avatarUrl={user.avatarUrl}
                      onSelect={(id) => {
                        filterAssignee(id);
                      }}
                    />
                  ))}
              </FilterSection>
              <FilterSection>
                <FilterTitleContainer onClick={() => toggleDateFilter()}>
                  <FilterTitle>
                    By Date{' '} {!!dateRange && <FiltersNumber>1</FiltersNumber>}
                  </FilterTitle>
                  <StyledFontAwesomeIcon
                    icon={openDatePicker ? faChevronDown : faChevronUp}
                    size="xs"
                  />
                </FilterTitleContainer>
                {openDatePicker && (
                  <DatePickerV2
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    setOpenDatePicker={setOpenDatePicker}
                  />
                )}
              </FilterSection>
              <FilterSection>
                <FilterTitleContainer onClick={() => toggleTypeFilter()}>
                  <FilterTitle>
                    By Type{' '}
                    {selectedInitiativeType.length > 0 && (
                      <FiltersNumber>
                        {selectedInitiativeType.length}
                      </FiltersNumber>
                    )}
                  </FilterTitle>
                  <StyledFontAwesomeIcon
                    icon={openTypeFilter ? faChevronDown : faChevronUp}
                    size="xs"
                    data-testid="filter-chevron"
                  />
                </FilterTitleContainer>
                <CheckboxesContainer data-testid='checkboxes-container'>
                  {openTypeFilter &&
                    initiativeTypes.map((type) => (
                      <CheckboxContainer key={type.value}>
                        <KanbanFilterCheckbox
                          isSelected={selectedInitiativeType.includes(
                            type.value,
                          )}
                          bgColor="#60bfbf"
                          label={type.name}
                          value={type.value}
                          onSelect={() =>
                            filterType(type.value)
                          }
                        />
                      </CheckboxContainer>
                    ))}
                </CheckboxesContainer>
              </FilterSection>
              <FilterSection>
                <FilterTitleContainer onClick={() => toggleHorizonFilter()}>
                  <FilterTitle>
                    By Horizon{' '}
                    {selectedHorizon.length > 0 && (
                      <FiltersNumber>{selectedHorizon.length}</FiltersNumber>
                    )}
                  </FilterTitle>
                  <StyledFontAwesomeIcon
                    icon={openHorizonFilter ? faChevronDown : faChevronUp}
                    size="xs"
                  />
                </FilterTitleContainer>
                <CheckboxesContainer>
                  {openHorizonFilter &&
                    horizons.map((horizon) => (
                      <CheckboxContainer key={horizon.value}>
                        <KanbanFilterCheckbox
                          isSelected={selectedHorizon.includes(horizon.value)}
                          bgColor="#60bfbf"
                          label={horizon.name}
                          value={horizon.value}
                          onSelect={() => filterHorizon(horizon.value)}
                        />
                      </CheckboxContainer>
                    ))}
                </CheckboxesContainer>
              </FilterSection>
              <FilterSection>
                <FilterTitleContainer onClick={() => togglePriorityFilter()}>
                  <FilterTitle>
                    By Priority{' '}
                    {selectedPriority.length > 0 && (
                      <FiltersNumber>{selectedPriority.length}</FiltersNumber>
                    )}
                  </FilterTitle>
                  <StyledFontAwesomeIcon
                    icon={openPriorityFilter ? faChevronDown : faChevronUp}
                    size="xs"
                  />
                </FilterTitleContainer>
                <CheckboxesContainer>
                  {openPriorityFilter &&
                    priorities.map((priority) => (
                      <CheckboxContainer data-testid='filter-item' key={priority.value}>
                        <KanbanFilterCheckbox
                          isSelected={selectedPriority.includes(priority.value)}
                          bgColor="#60bfbf"
                          label={priority.name}
                          value={priority.value}
                          onSelect={() =>
                            filterPriority(priority.value)
                          }
                        />
                      </CheckboxContainer>
                    ))}
                </CheckboxesContainer>
              </FilterSection>
            </FiltersContainer>
          )}
        </FilterItem>
      </FilterContainer>
    </Container>
  );
};

export default KanbanFilter;
