import React from 'react';
import styled from 'styled-components';
import RectButton from '../../components/Button/RectButton';
import CanvasTopicHeader from '../../components/CanvasTopicHeader/CanvasTopicHeader';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import ContextColorField from '../../components/ContextColorField/ContextColorField';
import ContextSelect from '../../components/ContextSelect/ContextSelect';
import BlockForm from './BlockForm';

export interface NewBlockFormOutput {
  name: string;
  color: string;
  type: string;
}

type SelectedCellProps = Cell & {
  gridArea: string;
  onClearSelection: () => void;
  onCreateNewBlock: (formOutput: NewBlockFormOutput) => void;
};

type SelectedContainerProps = Pick<SelectedCellProps, 'gridArea'>;

const SelectedContainer = styled.div<SelectedContainerProps>`
  grid-area: ${(props) => props.gridArea};
  background-color: ${(props) => props.theme.colors.listitem.border};
`;

const SelectedCell: React.FC<SelectedCellProps> = ({
  gridArea,
  onClearSelection,
  onCreateNewBlock,
}) => {
  return (
    <SelectedContainer gridArea={gridArea}>
      <BlockForm
        onClearSelection={onClearSelection}
        onFormSubmit={onCreateNewBlock}
      />
    </SelectedContainer>
  );
};

export default SelectedCell;
