import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../SectionHeader/SectionHeader';
import Topic from '../Topic/Topic';
import { ListRow, Document, ListViewConfig, SubListRow } from '@propella/core';
import { isJust, withDefault } from '../../maybe';
import { useBoundStore } from '../../states';
import shallow from 'zustand/shallow';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const SectionSubHeader = styled.p`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  background-color: none;
  box-sizing: border-box;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #5a5f69;
`;

type SubListInput = {
  header: string;
  subHeader: string;
  rows: ListRow['items'];
  readOnly: boolean;
};

type ListVisualizerInput = {
  readOnly: boolean;
};

const SubList = ({
  header,
  subHeader,
  rows,
  readOnly,
}: SubListInput) => {
  const renderRow = (row: SubListRow, rowIndex: number) => {
    if (!isJust(row.topicKey)) return null;

    return (
      <Topic
        topicId={row.topicKey}
        key={String(rowIndex)}
        readOnly={readOnly}
      />
    );
  };

  return (
    <>
      <SectionHeader>{header}</SectionHeader>
      {subHeader && <SectionSubHeader>{subHeader}</SectionSubHeader>}
      {isJust(rows) && rows.filter(isJust).map(renderRow)}
    </>
  );
};

const ListVisualizer: React.FC<ListVisualizerInput> = ({
  readOnly,
}) => {
  const list = useBoundStore((state) => state.document.views.list, shallow);

  if (!list || !isJust(list.rows)) {
    return <div>This document does not have list view configuration</div>;
  }

  const renderRow = (row: ListRow, rowIndex: number) => {
    if (!isJust(row.topicKey)) {
      return (
        <SubList
          key={String(rowIndex)}
          header={withDefault(row.header, '')}
          subHeader={withDefault(row.subHeader, '')}
          rows={row.items}
          readOnly={readOnly}
        />
      );
    }

    return (
      <Topic
        topicId={row.topicKey}
        key={String(rowIndex)}
        readOnly={readOnly}
      />
    );
  };
  return (
    <ListContainer>{list.rows.filter(isJust).map(renderRow)}</ListContainer>
  );
};

export default ListVisualizer;
