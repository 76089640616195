import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Membership, MembershipOrganizationItem } from '../../common/type';
import { isJust, withDefault } from '../../maybe';
import { Maybe } from '@propella/core';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import { useBoundStore } from '../../states';

type OrganizationPopover = {
  switchOrganization: (organizationID: string) => void;
};

type StyledOrganizationSelectItemProps = {
  isSelected: boolean;
};

const StyledOrganizationSelectItem = styled.div<StyledOrganizationSelectItemProps>`
  height: 80px;
  min-width: 200px;
  max-width: 480px;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.sideNav.selectedBg
      : props.theme.colors.sideNav.backGround};
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const StyledAddOrganization = styled.div`
  height: 80px;
  min-width: 200px;
  max-width: 480px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.sideNav.addBg};
`;

const AddIconContainer = styled.div`
  font-size: 28px;
  width: 36px;
  height: 36px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuTextContainer = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  margin-left: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const OrganizationSelectContainer = styled.div`
  max-height: 100vh;
  overflow-y: scroll;
`;

const OrganizationSelectPopover: React.FC<OrganizationPopover> = ({
  switchOrganization,
}) => {
  const { organizations = [], currentMembership } = useBoundStore((state) => state.auth);

  const innerSwitchOrganization = (orgId: Maybe<string>) => {
    if (!isJust(orgId)) return;
    if (currentMembership?.organization.id === orgId) return;
    switchOrganization(orgId);
  };

  const renderItem = (
    organization: MembershipOrganizationItem,
    itemIndex: number,
  ) => {
    if (!isJust(organization)) return null;

    const orgId = withDefault(organization.id, '');

    return (
      <StyledOrganizationSelectItem
        key={String(itemIndex)}
        isSelected={organization.id === currentMembership?.organization.id}
        onClick={() => innerSwitchOrganization(orgId)}
      >
        {!!organization.avatarUrl ? (
          <OrganizationAvatar avatarUrl={organization.avatarUrl} />
        ) : (
          <Avatar id={withDefault(organization.id, '')} />
        )}
        <MenuTextContainer>{organization.name}</MenuTextContainer>
        {organization.id === currentMembership?.organization.id && (
          <FontAwesomeIcon icon={faCheck} />
        )}
      </StyledOrganizationSelectItem>
    );
  };

  return (
    <OrganizationSelectContainer>
      {organizations.map(renderItem)}
      <StyledLink to="/organization/onboard">
        <StyledAddOrganization>
          <AddIconContainer>
            <FontAwesomeIcon icon={faPlusCircle} />
          </AddIconContainer>

          <MenuTextContainer>Add new organization</MenuTextContainer>
        </StyledAddOrganization>
      </StyledLink>
    </OrganizationSelectContainer>
  );
};

export default OrganizationSelectPopover;
