import React, { useState } from 'react';
import styled from 'styled-components';
import ListNewItem from '../ListNewItem/ListNewItem';
import DraggableList from '../DraggableList/DraggableList';
import CanvasTopicContainer from '../CanvasTopicContainer/CanvasTopicContainer';
import CanvasTopicHeader from '../CanvasTopicHeader/CanvasTopicHeader';
import CanvasItemList from '../CanvasItemList/CanvasItemList';
import TopicIcons from '../TopicIcons/TopicIcons';
import { EntryVisualizer } from '../../common/type';
import { useBoundStore } from '../../states';
import { GridCell } from '@propella/core';
import shallow from 'zustand/shallow';
import StaticCell from '../GridVisualizer/StaticCell';
import { Droppable } from 'react-beautiful-dnd';

const StyledTopicIcons = styled(TopicIcons)`
  padding-right: 5px;
`;

const NewEntryWrapper = styled.div`
  padding: 15px 10px;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.borderItem};
  border-top: 0;
`;

export type CanvasTopicProps = {
  topicId: string;
  readOnly: boolean;
  cell: GridCell;
};

const CanvasTopic: React.FC<CanvasTopicProps> = ({
  topicId,
  readOnly,
  cell,
}) => {
  const topic = useBoundStore((state) => state.document.topics[topicId]);

  const createEntry = useBoundStore((state) => state.createEntry, shallow);

  const onSaveNewEntry = async (entryName: string) => {
    await createEntry(entryName, topic.key);
  };

  const [focusedIndex, setFocusedIndex] = useState(-1);

  const isFocused = (index: number) => focusedIndex === index;

  const topicName =
    topic.name === 'Shareholder Value' ? 'Stakeholder Value' : topic.name;

  return (
    <CanvasTopicContainer
      gridArea={[cell.y, cell.x, cell.y + cell.h, cell.x + cell.w]
        .map((x) => `${x}`)
        .join(' / ')}
      topBorderColor={topic.fullDomain.fgColor}
    >
      {!cell.hideHeader && (
        <CanvasTopicHeader>
          <StyledTopicIcons topicName={topic.name} />
          {topicName}
        </CanvasTopicHeader>
      )}
      <Droppable droppableId={topic.key}>
        {(provided, snapshot) => (
          <CanvasItemList>
            <DraggableList
              provided={provided}
              snapshot={snapshot}
              droppableId={topic.key}
              readOnly={readOnly}
              sortedEntryIds={topic.sortedEntryIds}
            />
          </CanvasItemList>
        )}
      </Droppable>
      <NewEntryWrapper>
        {!readOnly && (
          <ListNewItem
            onSave={onSaveNewEntry}
            isFocused={isFocused(-1)}
            onFocus={() => setFocusedIndex(-1)}
          />
        )}
      </NewEntryWrapper>
    </CanvasTopicContainer>
  );
};

export default CanvasTopic;
