import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import StarterKit from '@tiptap/starter-kit';
import { useEditor, EditorContent } from '@tiptap/react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import EntryViewer from '../EntryViewer/EntryViewer';

type TextFieldContainerProps = {
  editing: boolean;
};

type ClearButtonProps = {
  hoverColor: string;
};

const StaticText = styled.div<{ hasValue: boolean }>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ hasValue }) => (hasValue ? '#202020' : 'inherit')};

  > p {
    margin-top: 0;
  }
`;

const TextFieldContainer = styled.div<TextFieldContainerProps>`
  width: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  min-height: 48px;
  min-width: 200px;

  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  padding: 12px 16px;

  color: ${({ editing }) => (editing ? '#202020' : '#c8cece')};
  background: ${({ editing }) => (editing ? '#f7f7f7' : '#ffffff')};

  &:hover {
    background: #f7f7f7;
    color: #202020;
    cursor: text;
  }

  .ProseMirror {
    padding: 5px;
    padding-bottom: 40px;
    width: 100%;

    overflow-wrap: anywhere;

    p {
      margin: 0 !important;
    }

    h2,
    h1,
    h3 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .ProseMirror-focused {
    outline: none;
  }

  > div {
    width: 100%;
  }
`;

const InlineTextArea = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #202020;

  height: 140px;
  margin-bottom: 30px;

  width: 100%;
  background-color: #f7f7f7;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  bottom: 15px;
  right: 15px;

  z-index: 99;
`;

const ClearButton = styled.button<ClearButtonProps>`
  border: none;
  outline: none;
  background: none;

  color: #aeafaf;

  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;

export type TableInlineTextProps = {
  placeholder?: string;
  onSave?: (value: string) => Promise<void>;
  defaultValue?: string;
  justText?: boolean;
};

const tryParseContent = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const TableInlineText = ({
  placeholder,
  onSave,
  defaultValue = '',
  justText = false,
}: TableInlineTextProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const editor = useEditor({
    extensions: [StarterKit],
    content: tryParseContent(defaultValue) || '',
    autofocus: 'end',
    editable: true,
  });

  const saveChanges = async () => {
    if (!editor) {
      return;
    }

    // Check length for require case
    if (editor.state.doc.textContent.length === 0) {
      console.log('Empty text');
      await onSave?.('');
    } else {
      if (justText) {
        await onSave?.(editor.state.doc.textContent);
      } else {
        const jsonData = editor.getJSON();

        await onSave?.(JSON.stringify(jsonData));
      }
    }

    setEditing(false);
  };

  const renderContent = (value: string) => {
    try {
      const data = JSON.parse(value);
      return <EntryViewer entry={data} />;
    } catch (e) {
      return value;
    }
  };

  if (!editor) return null;

  if (!editing)
    return (
      <TextFieldContainer onClick={() => setEditing(true)} editing={editing}>
        <StaticText hasValue={!!defaultValue}>
          {renderContent(defaultValue || placeholder || '')}
        </StaticText>
      </TextFieldContainer>
    );

  return (
    <TextFieldContainer editing={editing}>
      {/* <Controller
          as={InlineTextArea}
          name="value"
          control={inlineFormMethods.control}
        /> */}
      <EditorContent editor={editor} autoFocus />
      {/* <InlineTextArea name="value" placeholder={placeholder} autoFocus /> */}
      <ButtonContainer>
        <ClearButton
          type="button"
          onClick={() => {
            setEditing(false);
          }}
          hoverColor="#D93B3B"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </ClearButton>
        <ClearButton
          hoverColor="#56B6B9"
          type="button"
          onClick={() => saveChanges()}
        >
          <FontAwesomeIcon icon={faCheck} size="lg" />
        </ClearButton>
      </ButtonContainer>
    </TextFieldContainer>
  );
};

export default TableInlineText;
