import _ from 'lodash';
import styled from 'styled-components';
import SideNavigation from '../SideNavigation/SideNavigation';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';
import { ReactComponent as Plan } from './Plan.svg';
import { ReactComponent as OKR } from './OKR.svg';
import { useBoundStore } from '../../states';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.white};
`;

const SideNavContainer = styled.div`
  width: 220px;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.white};
`;

const PlanIcon = styled(Plan)`
  vertical-align: middle;
`;

const sideNavigationMenuItems = [
  {
    label: 'Plan',
    icon: <PlanIcon />,
    path: '/plan',
  },
  {
    label: 'OKR / KPI',
    icon: <OKR />,
    path: '/objectives',
  },
  {
    label: 'Execute',
    icon: <OKR />,
    path: '/100-day-plannings',
  },
  {
    label: 'Settings',
    icon: <FontAwesomeIcon size="lg" icon={faCog} />,
    path: '/organization/settings',
  },
];

type AppContainerProps = {
  children: React.ReactElement;
};

const AppContainer = (props: AppContainerProps) => {
  const { profile, currentMembership, organizations } = useBoundStore(
    (state) => state.auth,
  );

  const history = useHistory();
  const location = useLocation<{ organizationID: string }>();

  const signOut = async () => {
    await Auth.signOut();
    history.replace('/signin');
  };

  const switchOrganization = (organizationID: string) => {
    const continueLink = window.location.pathname.includes('/documents')
      ? '/plan'
      : window.location.pathname;
    history.replace(
      `/switch?organizationID=${organizationID}&continue=${continueLink}`,
    );
  };

  if (!profile) {
    return <Redirect to="/signin" />;
  }

  if (location.state?.organizationID) {
    return (
      <Redirect
        to={`/switch?organizationID=${location.state.organizationID}`}
      />
    );
  }
  // New user must create a organization before continue
  if (!organizations || !organizations[0]) {
    const location = {
      pathname: '/organization/onboard',
      state: {
        firstOrganization: true,
      },
    };
    return <Redirect to={location} />;
  }

  if (!currentMembership) {
    return <Redirect to={`/switch?organizationID=${organizations[0].id}`} />;
  }

  return (
    <FlexContainer>
      <SideNavContainer>
        <SideNavigation
          signOut={signOut}
          menuItems={sideNavigationMenuItems}
          switchOrganization={switchOrganization}
        />
      </SideNavContainer>
      <ContentContainer>{props.children}</ContentContainer>
    </FlexContainer>
  );
};

export default AppContainer;
