import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import moment from 'moment';
import Select from '../Select/Select';
import RectButton from '../Button/RectButton';
import { SelectOption } from '../../common/type';
import { DATE_OPTIONS } from '../../common/constant';

const CustomHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;

const ChangeMonthButton = styled.button`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: none;
`;

const MonthYear = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

const SelectContainer = styled.div`
  width: 100%;
  margin: 20px 0;

  .react-select__control {
    height: 20px;
    padding: 0;
  }

  .react-select__option {
    padding: 10px;
  }
`;

type CustomerHeaderProps = {
  date: Date;
  changeMonth: (month: number) => void;
  handleSelectionChange: (value: string) => void;
  selectedOption: SelectOption;
};

const CustomHeader = ({
  date,
  changeMonth,
  handleSelectionChange,
  selectedOption,
}: CustomerHeaderProps) => {
  const parsedDate = moment(date);

  const month = parsedDate.format('MMMM');
  const year = parsedDate.format('YYYY');

  const monthAsNumber = parsedDate.month();

  return (
    <>
      <CustomHeaderContainer>
        <ChangeMonthButton
          type="button"
          onClick={() => changeMonth(monthAsNumber - 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} color="#AEAFAF" size="sm" />
        </ChangeMonthButton>
        <MonthYear>
          {month} {year}
        </MonthYear>
        <ChangeMonthButton
          type="button"
          onClick={() => changeMonth(monthAsNumber + 1)}
        >
          <FontAwesomeIcon icon={faChevronRight} color="#AEAFAF" size="sm" />
        </ChangeMonthButton>
      </CustomHeaderContainer>
      <SelectContainer>
        <Select
          label=""
          defaultValue={selectedOption}
          options={DATE_OPTIONS}
          onSelectionChange={(e) => handleSelectionChange(e)}
          hasError={false}
        />
      </SelectContainer>
    </>
  );
};

const CalendarContainer = styled.div`
  border: 1px solid #e1e4e6;
  background: #fff;
  overflow: hidden;

  display: inline-flex;
  flex-direction: column;
  padding: 24px;
  position: relative;

  gap: 20px;
`;

const DatePickerContainer = styled.div`
  .react-datepicker__day {
    margin: 3px;
  }
  .react-datepicker__header {
    background-color: #ffffff !important;
    border: none;
  }
  .react-datepicker__day--in-range {
    margin: 1px 0;
    padding: 2px 3px;
    color: #000000;
    border-radius: 0;
    background-color: #cfecec;
  }
  .react-datepicker__day--range-end,
  .react-datepicker__day--range-start,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range {
    color: #ffffff;
    background: radial-gradient(
      circle at center,
      #60bfbf 0%,
      #60bfbf 70%,
      #cfecec 70%
    );
  }
  .react-datepicker__day--outside-month,
  .react-datepicker__day-name {
    color: #aeafaf;
  }
  .react-datepicker__day--today,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected {
    border-radius: 50%;
  }
  .react-datepicker__day-names {
    border: none;
  }
  .react-datepicker__day--range-start {
    border-radius: 50% 0 0 50%;
  }
  .react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0;
  }
  .react-datepicker__day--range-start.react-datepicker__day--range-end {
    border-radius: 50%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  max-width: 250px;
  gap: 20px;
  input {
    padding: 5px;
  }
  button {
    width: 100%;
  }
`;

const DateContainer = styled.div`
  width: 100%;
  height: 20px;
  padding: 10px 15px;
  border: 1px solid #e1e4e6;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

export type DatePickerV2Props = {
  dateRange: string;
  setDateRange: (value: string) => void;
  selectedOption: SelectOption;
  setSelectedOption: (value: SelectOption) => void;
  setOpenDatePicker: (value: boolean) => void;
};

export const DatePickerV2 = ({
  dateRange,
  setDateRange,
  selectedOption,
  setSelectedOption,
  setOpenDatePicker,
}: DatePickerV2Props) => {
  const [startDate, setStartDate] = useState(
    dateRange
      ? new Date(moment(dateRange?.split('-')[0], 'DD/MM/YY').toString())
      : new Date(),
  );
  const [endDate, setEndDate] = useState(
    dateRange
      ? new Date(moment(dateRange?.split('-')[1], 'DD/MM/YY').toString())
      : new Date(),
  );

  const updateFilter = () => {
    if (startDate && endDate) {
      const range = formatDate(startDate) + ' - ' + formatDate(endDate);
      setDateRange(range);
    }
  };

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setSelectedOption(DATE_OPTIONS[3]);
  };

  const handleSelectionChange = (value: string) => {
    const selected = DATE_OPTIONS.find((item) => item.id === value);
    if (selected) {
      setSelectedOption(selected);
    }
    let date = new Date();
    let start = date.getDate() - Number(value);
    date.setDate(start);
    setEndDate(new Date());

    setStartDate(date);
  };

  const formatDate = (date: any) => {
    const parsedDate = moment(date);

    const month = parsedDate.format('MM');
    const year = parsedDate.format('YY');
    const day = parsedDate.format('DD');
    return date ? day + '/' + month + '/' + year : '';
  };

  const onCancel = () => {
    setOpenDatePicker(false);
  };
  const onSubmit = () => {
    updateFilter();
    setOpenDatePicker(false);
  };

  return (
    <DatePickerContainer data-testid='date-picker'>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        calendarContainer={({ children }) => (
          <CalendarContainer>{children}</CalendarContainer>
        )}
        renderCustomHeader={({ date, changeMonth }) => (
          <CustomHeader
            date={date}
            changeMonth={changeMonth}
            handleSelectionChange={handleSelectionChange}
            selectedOption={selectedOption}
          />
        )}
        selectsRange
        inline
      >
        <InputContainer>
          <DateContainer>{formatDate(startDate)}</DateContainer>
          <DateContainer>{formatDate(endDate)}</DateContainer>
        </InputContainer>
        <InputContainer>
          <RectButton onClick={onCancel} variant="secondary">
            Cancel
          </RectButton>
          <RectButton onClick={onSubmit} variant="primary">
            OK
          </RectButton>
        </InputContainer>
      </DatePicker>
    </DatePickerContainer>
  );
};

export default DatePickerV2;
