import styled from 'styled-components';
import Button from '../../components/Button/Button';
import ContextTextField from '../../components/ContextTextField/ContextTextField';
import * as yup from 'yup';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { ReactComponent as PropellaIcon } from '../SignIn/PropellaIcon.svg';
import { ReactComponent as PropellaName } from '../SignIn/PropellaName.svg';

type ResetPasswordInput = {
  email: string;
};

type ConfirmPasswordInput = {
  email: string;
  code: string;
  password: string;
};
const StyledPropellaIcon = styled(PropellaIcon)`
  width: 100%;
  width: 290px;
  margin-bottom: 40px;
  .frontend-icon {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const StyledPropellaName = styled(PropellaName)`
  width: 400px;
  margin-bottom: 20px;
  .frontend-icon {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const SloganContainer = styled.div`
  font-size: 38px;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 100px;
`;

const PageContainer = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-areas: 'banner form';
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  background-color: ${(props) => props.theme.colors.white};
`;

const BannerContainer = styled.div`
  grid-area: banner / banner / banner / banner;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.signin.container};
`;

const FormContainer = styled.form`
  grid-area: form / form / form / form;
  display: flex;
  margin: 0 128px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
`;

const ControllerContainer = styled.div`
  width: 360px;
  min-height: 131px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
`;

const ActionContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: reverse-row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MessageContainer = styled.div`
  align-self: start;
  margin-bottom: 30px;
  word-wrap: normal;
`;

const EmailContainer = styled.span`
  font-weight: 900;
`;

const StyledLink = styled(Link)`
  margin: 0 auto 0 0;
  color: ${(props) => props.theme.colors.link.enabled};
`;

const verificationCodeSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const confirmPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
  code: yup.string().required(),
  password: yup.string().required(),
});

const ResetPassword = () => {
  const [step, setStep] = useState('confirm-email');
  const alert = useAlert();

  const sendVerificationCodeMethods = useForm({
    resolver: yupResolver(verificationCodeSchema),
  });

  const confirmPasswordMethods = useForm({
    resolver: yupResolver(confirmPasswordSchema),
    shouldUnregister: false,
  });

  const sendVerificationCode = async (
    data: ResetPasswordInput,
  ): Promise<void> => {
    try {
      await Auth.forgotPassword(data.email);
      confirmPasswordMethods.setValue('email', data.email);
      setStep('submit-new-password');
    } catch (error: any) {
      if (error.errors) {
        alert.show(error.errors[0]?.message);
      } else {
        if (error.message === 'Username/client id combination not found.') {
          confirmPasswordMethods.setValue('email', data.email);
          setStep('submit-new-password');
          return;
        }

        alert.show(error.message);
      }
    }
  };

  const confirmPassword = async (data: ConfirmPasswordInput): Promise<void> => {
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
      setStep('reset-password-complete');
    } catch (error: any) {
      if (error.errors) {
        alert.show(error.errors[0]?.message);
      } else {
        alert.show(error.message);
      }
    }
  };

  return (
    <PageContainer>
      <BannerContainer>
        <StyledPropellaIcon />
        <StyledPropellaName />
        <SloganContainer>Beyond your business</SloganContainer>
      </BannerContainer>
      {step === 'confirm-email' && (
        <FormProvider {...sendVerificationCodeMethods}>
          <FormContainer
            onSubmit={sendVerificationCodeMethods.handleSubmit(
              sendVerificationCode,
            )}
          >
            <ContextTextField name="email" label="Email address" />
            <ActionContainer>
              <StyledLink to="/signin">Return to log in</StyledLink>
              <Button type="submit" variant="primary">
                Send recovery code
              </Button>
            </ActionContainer>
          </FormContainer>
        </FormProvider>
      )}
      {step === 'submit-new-password' && (
        <FormProvider {...confirmPasswordMethods}>
          <FormContainer
            onSubmit={confirmPasswordMethods.handleSubmit(confirmPassword)}
            autoComplete="new-password"
          >
            <MessageContainer>
              We sent a recovery code to you at{' '}
              <EmailContainer>
                {confirmPasswordMethods.getValues().email}
              </EmailContainer>
            </MessageContainer>
            <ContextTextField name="code" label="Verification Code" autoComplete="one-time-code" />
            <ContextTextField
              type="password"
              name="password"
              autoComplete="new-password"
              label="New Password"
            />
            <ActionContainer>
              <div />
              <Button type="submit" variant="primary">
                Change password
              </Button>
            </ActionContainer>
          </FormContainer>
        </FormProvider>
      )}
      {step === 'reset-password-complete' && (
        <FormContainer>
          <ControllerContainer>
            Your email is confirmed. Please <Link to="/signin">sign in</Link> to
            continue.
          </ControllerContainer>
        </FormContainer>
      )}
    </PageContainer>
  );
};

export default ResetPassword;
