import React, { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withDefault, isJust } from '../maybe';
import { useBoundStore } from '../states';

type AdminRouteProps = {
  children: ReactElement;
  path: string;
  exact?: boolean;
};

const AdminRoute: React.FC<AdminRouteProps> = ({ children, ...rest }) => {
  const { profile, currentMembership } = useBoundStore((state) => state.auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        profile &&
        currentMembership &&
        withDefault(currentMembership?.role?.grantedActions, [])
          .filter(isJust)
          .some((action) => action === 'administer') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/plan',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
