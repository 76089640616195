import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Label from '../Label/Label';
import { SelectionInput } from '../../common/type';
import { includes } from 'lodash';

type ControlContainerProps = {
  hasError: boolean;
};

type OptionContainerProps = {
  selected: boolean;
};

type TickContainerProps = {
  selected: boolean;
};

type MultiSelectInput = {
  items: SelectionInput[];
  label: string;
  value: string[];
  onSelectionChange: Function;
  hasError: boolean;
};
const ControlContainer = styled.div<ControlContainerProps>`
  width: 100%;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) =>
    props.hasError
      ? props.theme.colors.error
      : props.theme.colors.pageControl.border};
  overflow: hidden;
`;

const OptionContainer = styled.div<OptionContainerProps>`
  color: ${(props) => props.theme.colors.theme};
  cursor: default;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.textfield.focusBackground
      : props.theme.colors.white};
  border-top: none;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) =>
    props.selected
      ? props.theme.colors.textfield.focusBackground
      : props.theme.colors.pageControl.border};
  :last-child {
    border-bottom: none;
  }
`;

const StyledOption = styled.div`
  padding: 18px 15px;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-radius: 6px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0px;
  outline: none;
  width: 100%;
  line-height: 1.42857;
  font-family: 'Open Sans', sans-serif;
`;

const TickContainer = styled.div<TickContainerProps>`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-left: 15px;
  padding: 3px;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${(props) =>
    props.selected
      ? props.theme.colors.textfield.focusBorder
      : props.theme.colors.pageControl.border};
`;
const Inner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.textfield.focusBorder};
`;

const isSelected = (selectedItems: string[], itemId: string): boolean =>
  includes(selectedItems, itemId);

const MultiSelect: React.FC<MultiSelectInput> = ({
  items,
  label,
  value: selectedItems = [],
  onSelectionChange,
  hasError,
}) => {
  const handleSelectionChange = (item: SelectionInput) => {
    const nextSelectedItems = isSelected(selectedItems, item.id)
      ? selectedItems.filter(
          (selectedItemId: string) => selectedItemId !== item.id,
        )
      : selectedItems.concat([item.id]);
    onSelectionChange(nextSelectedItems);
  };
  return (
    <>
      <Label hasError={hasError}>{label}</Label>
      <ControlContainer hasError={hasError}>
        {items.map((item) => (
          <OptionContainer
            key={item.id}
            selected={isSelected(selectedItems, item.id)}
            onClick={() => handleSelectionChange(item)}
          >
            <TickContainer selected={isSelected(selectedItems, item.id)}>
              {isSelected(selectedItems, item.id) && <Inner />}
            </TickContainer>
            <StyledOption>{item.name}</StyledOption>
          </OptionContainer>
        ))}
      </ControlContainer>
    </>
  );
};

export default MultiSelect;
