import styled from 'styled-components';

export type LegendContentProps = {
  width?: string;
  columns?: number;
};

const LegendContentContainer = styled.div<LegendContentProps>`
  /* Business Risk */

  box-sizing: border-box;
  width: ${({ width }) => width || '100%'};
  /* Auto layout */

  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns || 1}, 1fr)`};
  gap: 20px;
  padding: 20px;

  height: 100%;

  background: #ffffff;
  /* Main Gray */

  border: 1px solid #c8cece;
  border-radius: 2px;
`;

export default LegendContentContainer;
