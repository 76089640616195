import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalProps } from 'styled-react-modal';
import ClearButton from '../../components/ClearButton/ClearButton';
import InlineTextField from '../../components/InlineTextField/InlineTextField';
import ModalParameter from '../../components/ModalParameter/ModalParameter';
import { useBoundStore } from '../../states';
import { ReactComponent as CloseModalButton } from '../DocumentV2/CloseModalButton.svg';
import { ReactComponent as Tags } from '../DocumentV2/Tags.svg';
import AssignTagsPopover from '../../components/AssignTagsPopover/AssignTagsPopover';
import { Initiative, Tag } from '@propella/core';
import DatePickerPopover from '../../components/DatePickerPopover/DatePickerPopover';
import { isJust, withDefault } from '../../maybe';
import TagComponent from '../../components/Initiative/Tag';
import ModalKeyResult from '../DocumentV2/ModalKeyResult';

const StyledModal = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  border-bottom: 1px solid #e1e4e6;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const ParameterContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 200px;
  box-sizing: border-box;
  background: #f7f7f7;
  border-left: 1px solid #e1e4e6;
`;

const EntryNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
`;

const BreadCrumbs = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  margin-left: 12px;

  color: #aeafaf;
`;

const ActionButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  color: #aeafaf;

  width: 200px;
  box-sizing: border-box;
`;

const ParameterText = styled.p`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

const StyledTagComponent = styled(TagComponent)`
  margin: 5px;
`;

const ModalDescription = styled.div`
  width: 480px;
  padding: 16px 40px;
`;

export type EditingModalProps = {
    listInitiatives: Initiative[];
};

const EditingModalKanban = (props: EditingModalProps) => {
  const { isOpen, entryId, color } = useBoundStore(
    (state) => state.editingEntryModal,
  );
  const closeModal = useBoundStore((state) => state.closeEntryModal);
  const [editingInitiative, setEdditingInitiative] = useState<Initiative>();
  const [updateInitiativeTagsKanban, updateInitiativeNameKanban, updateInitiativeDueDateKanban] =
    useBoundStore((state) => [
      state.updateInitiativeTagsKanban,
      state.updateInitiativeNameKanban,
      state.updateInitiativeDueDateKanban,
    ]);

    useEffect(() => {
        setEdditingInitiative(props.listInitiatives.find((entry) => entry.id === entryId));
    });


      if (!editingInitiative) {
      return (
        <Modal
          isOpen={isOpen && !!editingInitiative}
          onBackgroundClick={() => closeModal()}
        >
          <StyledModal>Entry not found</StyledModal>
        </Modal>
      );
    }
  

  const onTagsChange = async (tags: Tag[]) => {
    await updateInitiativeTagsKanban(
      !!editingInitiative?.id ? editingInitiative?.id : '',
      tags,
    );
  };

  return (
    <Modal
      isOpen={isOpen && !!editingInitiative}
      onBackgroundClick={() => closeModal()}
    >
        <StyledModal>
          <ModalHeader>
            <EntryNameContainer>
              <BreadCrumbs>One Page Strategic Agility Plan</BreadCrumbs>
              <InlineTextField
                value={editingInitiative.name}
                onSave={(name) => updateInitiativeNameKanban(editingInitiative.id, name)}
              />
            </EntryNameContainer>
            <ActionButtonGroup>
              <ClearButton onClick={() => closeModal()}>
                <CloseModalButton />
              </ClearButton>
            </ActionButtonGroup>
          </ModalHeader>
          <ModalBody>
            <ModalDescription>
              <ModalKeyResult keyResults={editingInitiative.keyResults} />
            </ModalDescription>
            <ParameterContainer>
              <DatePickerPopover
                color={color}
                selectedDate={
                  isJust(editingInitiative.dueDate)
                    ? new Date(editingInitiative.dueDate)
                    : undefined
                }
                onDateChange={(date) =>
                  updateInitiativeDueDateKanban(editingInitiative.id, date)
                }
              />
              <ModalParameter
                popoverContent={
                  <AssignTagsPopover
                    existingTags={editingInitiative.tags}
                    onTagsChange={onTagsChange}
                  />
                }
              >
                <Tags />
                <ParameterText>
                  <span>Tags</span>
                  {withDefault(editingInitiative.tags, [])
                    .filter(isJust)
                    .map((tag, index) => (
                      <StyledTagComponent {...tag} key={index} />
                    ))}
                </ParameterText>
              </ModalParameter>
            </ParameterContainer>
          </ModalBody>
        </StyledModal>
    </Modal>
  );
};

export default EditingModalKanban;
