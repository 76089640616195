import styled from 'styled-components';
import Modal, { ModalProps } from 'styled-react-modal';
import ClearButton from '../../components/ClearButton/ClearButton';
import InlineTextField from '../../components/InlineTextField/InlineTextField';
import ModalParameter from '../../components/ModalParameter/ModalParameter';
import { useBoundStore } from '../../states';
import { ReactComponent as CloseModalButton } from './CloseModalButton.svg';
import { ReactComponent as Tags } from './Tags.svg';
import AssignTagsPopover from '../../components/AssignTagsPopover/AssignTagsPopover';
import { Initiative, Tag } from '@propella/core';
import DatePickerPopover from '../../components/DatePickerPopover/DatePickerPopover';
import { isJust, withDefault } from '../../maybe';
import TagComponent from '../../components/Initiative/Tag';
import ModalKeyResult from './ModalKeyResult';

const StyledModal = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  border-bottom: 1px solid #e1e4e6;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const ParameterContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 200px;
  box-sizing: border-box;
  background: #f7f7f7;
  border-left: 1px solid #e1e4e6;
`;

const EntryNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
`;

const BreadCrumbs = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  margin-left: 12px;

  color: #aeafaf;
`;

const EntryName = styled.div`
  padding: 4px 12px;
  background: #ffffff;
  border-radius: 6px;

  width: 100%;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #202020;

  cursor: text;

  &:hover {
    background: #f7f7f7;
  }
`;

const ActionButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  color: #aeafaf;

  width: 200px;
  box-sizing: border-box;
`;

const ParameterText = styled.p`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

const StyledTagComponent = styled(TagComponent)`
  margin: 5px;
`;

const ModalDescription = styled.div`
  width: 480px;
  padding: 16px 40px;
`;

export type EditingModalProps = {};

const EditingModal = (props: EditingModalProps) => {
  const { isOpen, entryId, color } = useBoundStore(
    (state) => state.editingEntryModal,
  );
  const closeModal = useBoundStore((state) => state.closeEntryModal);
  const editingEntry = useBoundStore((state) =>
    state.documentV2.entries.find((entry) => entry.id === entryId),
  );
  const [updateInitiativeTags, updateInitiativeDueDate, updateInitiativeName] =
    useBoundStore((state) => [
      state.updateInitiativeTags,
      state.updateInitiativeDueDate,
      state.updateInitiativeName,
    ]);

  if (!editingEntry) {
    return (
      <Modal
        isOpen={isOpen && !!editingEntry}
        onBackgroundClick={() => closeModal()}
      >
        <StyledModal>Entry not found</StyledModal>
      </Modal>
    );
  }

  const editingInitiative = editingEntry as Initiative;

  const onTagsChange = async (tags: Tag[]) => {
    await updateInitiativeTags(editingEntry.id, tags);
  };

  return (
    <Modal
      isOpen={isOpen && !!editingEntry}
      onBackgroundClick={() => closeModal()}
    >
      <StyledModal>
        <ModalHeader>
          <EntryNameContainer>
            <BreadCrumbs>One Page Strategic Agility Plan</BreadCrumbs>
            <InlineTextField
              value={editingEntry.name}
              onSave={(name) => updateInitiativeName(editingEntry.id, name)}
            />
          </EntryNameContainer>
          <ActionButtonGroup>
            <ClearButton onClick={() => closeModal()}>
              <CloseModalButton />
            </ClearButton>
          </ActionButtonGroup>
        </ModalHeader>
        <ModalBody>
          <ModalDescription>
            <ModalKeyResult keyResults={editingInitiative.keyResults} />
          </ModalDescription>
          <ParameterContainer>
            <DatePickerPopover
              color={color}
              selectedDate={
                isJust(editingInitiative.dueDate)
                  ? new Date(editingInitiative.dueDate)
                  : undefined
              }
              onDateChange={(date) =>
                updateInitiativeDueDate(editingEntry.id, date)
              }
            />
            <ModalParameter
              popoverContent={
                <AssignTagsPopover
                  existingTags={editingEntry.tags}
                  onTagsChange={onTagsChange}
                />
              }
            >
              <Tags />
              <ParameterText>
                <span>Tags</span>
                {withDefault(editingInitiative.tags, [])
                  .filter(isJust)
                  .map((tag, index) => (
                    <StyledTagComponent {...tag} key={index} />
                  ))}
              </ParameterText>
            </ModalParameter>
          </ParameterContainer>
        </ModalBody>
      </StyledModal>
    </Modal>
  );
};

export default EditingModal;
