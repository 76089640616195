import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import ReactSlider from 'react-slider';
import Checkbox from '../Checkbox/Checkbox';

export type ProgressProps = {
  progress: number;
  color: string;
  disabled?: boolean;
  onChange?: (value: number) => void;
  inverted?: boolean;
  noCheckbox?: boolean;
  noBorder?: boolean;
};

type ProgressContainerProps = {
  borderColor: string;
};

const ProgressButton = styled.button<ProgressContainerProps>`
  border: 1px solid ${(props) => props.borderColor};
  background-color: transparent;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 0;

  box-sizing: border-box;
  height: 30px;
  /* width: 73px; */
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  outline: 0;

  cursor: pointer;
`;

type ProgressCheckContainerProps = {
  bgColor: string;
};

const ProgressCheckboxContainer = styled.div<ProgressCheckContainerProps>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
`;

type ProgressNumberContainerProps = {
  bgColor: string;
};

const ProgressNumberContainer = styled.div<ProgressNumberContainerProps>`
  height: 30px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
`;

const SliderContainer = styled.div`
  background: #ffffff;
  border-radius: 6px;
  border: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  height: 50px;
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

type ProgressNumberProps = {
  color: string;
};

const ProgressNumber = styled.span<ProgressNumberProps>`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.color || '#ffffff'};
  letter-spacing: 0;
  margin: 0;
`;

const StyledSlider = styled(ReactSlider)`
  height: 16px;
  width: 176px;
`;

type StyledTrackProps = {
  index: number;
  color: string;
};

const StyledTrack = styled.div<StyledTrackProps>`
  top: 0;
  bottom: 0;
  height: 8px;
  margin: 4px 0;
  background: ${(props) => (props.index === 1 ? '#EFF2F4' : props.color)};
  border-radius: 999px;
`;

type StyledThumbProps = {
  color: string;
};

const StyledThumb = styled.div<StyledThumbProps>`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
  cursor: grab;
`;

enum ProgressState {
  NotStarted,
  InProgress,
  Completed,
}

function shadeColor(color: string, percent: number) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt(`${(R * (100 + percent)) / 100}`);
  G = parseInt(`${(G * (100 + percent)) / 100}`);
  B = parseInt(`${(B * (100 + percent)) / 100}`);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

const Progress: React.FC<ProgressProps> = ({
  color,
  progress,
  disabled = false,
  onChange,
  inverted = false,
  noCheckbox = false,
  noBorder = false,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [currentProgressValue, setProgressValue] = useState<number>(progress);

  useEffect(() => setProgressValue(progress), [progress]);

  const progressStatus = useMemo<ProgressState>(() => {
    if (currentProgressValue === 0) return ProgressState.NotStarted;
    if (currentProgressValue === 100) return ProgressState.Completed;
    return ProgressState.InProgress;
  }, [currentProgressValue]);

  const objIsCompleted = useMemo<boolean>(() => currentProgressValue === 100, [
    currentProgressValue,
  ]);

  const progressNumberBgColor = useMemo<string>(() => {
    if (inverted) {
      if (progressStatus === ProgressState.Completed) return '#ffffff';
      if (progressStatus === ProgressState.NotStarted)
        return shadeColor(color, 22);
    }

    if (progressStatus === ProgressState.NotStarted) return '#F6F6F7';
    return shadeColor(color, 22);
  }, [progressStatus, inverted, color]);

  const containerBorderColor = useMemo<string>(() => {
    if (noBorder) return progressNumberBgColor;

    if (inverted) return '#ffffff';

    if (progressStatus === ProgressState.NotStarted) return '#C8CAD1';
    return color;
  }, [progressStatus, progressNumberBgColor, inverted]);

  const progressNumberTextColor = useMemo<string>(() => {
    if (inverted) {
      if (progressStatus === ProgressState.Completed) return color;
      return '#ffffff';
    }

    if (progressStatus === ProgressState.NotStarted) return '#C8CAD1';
    return '#ffffff';
  }, [progressStatus, inverted]);

  const progressCheckboxBgColor = objIsCompleted ? '#ffffff' : 'transparent';
  const checkBoxBorderColor = inverted ? '#C1C1CD' : '#C5C6CC';

  const saveAndClose = () => {
    if (onChange) {
      onChange(currentProgressValue);
    }

    setPopoverOpen(false);
  };
 
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'left', 'right']}
      onClickOutside={() => saveAndClose()}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#ffffff'}
          arrowSize={10}
        >
          <SliderContainer>
            <StyledSlider
              max={100}
              min={0}
              defaultValue={currentProgressValue}
              onAfterChange={(value) => {
                if (typeof value === 'number') {
                  setProgressValue(value);
                } else {
                  setProgressValue(value[0]);
                }
              }}
              renderTrack={(props: any, state) => (
                <StyledTrack {...props} index={state.index} color={color} />
              )}
              renderThumb={(props: any) => (
                <StyledThumb {...props} color={color} />
              )}
            />
          </SliderContainer>
        </ArrowContainer>
      )}
    >
      <ProgressButton
        borderColor={containerBorderColor}
        disabled={disabled}
        onClick={() => setPopoverOpen(true)}
      >
        <ProgressNumberContainer bgColor={progressNumberBgColor}>
          <ProgressNumber color={progressNumberTextColor}>
            {`${currentProgressValue}%`}
          </ProgressNumber>
        </ProgressNumberContainer>
        {!noCheckbox && (
          <ProgressCheckboxContainer bgColor={progressCheckboxBgColor}>
            <Checkbox
              isSelected={objIsCompleted}
              bgColor={color}
              borderColor={checkBoxBorderColor}
            />
          </ProgressCheckboxContainer>
        )}
      </ProgressButton>
    </Popover>
  );
};
export default Progress;
