import React, { ReactElement, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useBoundStore } from '../states';

type PrivateOutputInput = {
  children: ReactElement;
  path: string;
  exact?: boolean;
};

const PrivateRoute: React.FC<PrivateOutputInput> = ({ children, ...rest }) => {
  const { profile } = useBoundStore((state) => state.auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        profile ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
