import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import CompareStaticTopicV2 from './CompareStaticTopicV2';
import { CompareScope, EntryChange, UsersChangeType } from '../../common/type';
import { GridCell, GridViewConfig, Maybe } from '@propella/core';
import { isJust, withDefault } from '../../maybe';
import { StaticCell, DataCell, cells } from '../../pages/DocumentV2/onePage';
import StaticTopicV2 from '../../components/TopicV2/StaticTopicV2';
import { topics } from '../../pages/Documents/templates/competitiveLandscape';

type CompareGridInput = {
  scope: CompareScope;
  changes?: EntryChange[];
  usersChange?: UsersChangeType[];
};

const CanvasContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
`;

const CompareGridV2: React.FC<CompareGridInput> = ({
  scope,
  changes,
  usersChange,
}) => {
  const renderCell = (cell: StaticCell | DataCell) => {
    const { type, x, y, width, height } = cell;

    const gridArea = [y, x, y + height, x + width]
      .map((x) => `${x}`)
      .join(' / ');

    if (type === 'static') {
      const staticCell = cell as StaticCell;
      const { content, ...rest } = staticCell;

      return (
        <StaticTopicV2 {...rest} gridArea={gridArea} key={gridArea}>
          {content}
        </StaticTopicV2>
      );
    }

    if (type === 'data') {
      const dataCell = cell as DataCell;

      return (
        <CompareStaticTopicV2
          gridArea={gridArea}
          {...dataCell}
          key={gridArea}
          usersChange={usersChange}
          changes={changes?.filter(
            (change) => change.topicKey === cell.newEntry?.topic,
          )}
        />
      );
    }
  };

  return <CanvasContainer>{cells.map(renderCell)}</CanvasContainer>;
};

export default CompareGridV2;
