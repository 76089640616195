import { Initiative } from '@propella/core';
import React, { useCallback, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useBoundStore } from '../../states';
import { MasterListInitiative } from '../../states/interfaces';
import InitTableRow from './InitTableRow';
import TableInlineText from './TableInlineText';
import TableTag from './TableTag';

export type InitiativeTableProps = {
  initiatives?: MasterListInitiative[];
};

const StyledTable = styled.table`
  border: 0;

  thead {
    background: #f9f9fb;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    th {
      text-align: center;
      height: 40px;
      vertical-align: middle;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #c8cece;
      td {
        vertical-align: top;
        padding: 17px 10px;
      }
    }
  }
`;

const InitiativeTable = ({ initiatives = [] }: InitiativeTableProps) => {
  const alert = useAlert();
  const tableRef = React.useRef<HTMLTableElement | undefined>(
    null,
  ) as React.MutableRefObject<HTMLTableElement | undefined | null>;
  const [updateMasterlistRank] = useBoundStore((state) => [
    state.updateMasterlistRank,
  ]);

  const [isAllInitiativesSelected, toggleSelectAllDocumentInitatives, ids] =
    useBoundStore((state) => [
      state.masterlist.selectedInitiativeIds.length === initiatives.length,
      state.toggleSelectAllDocumentInitatives,
      state.masterlist.selectedInitiativeIds,
    ]);
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const rankBeforeEntry = initiatives[result.destination.index];

      // await updateInitiativeRank(result.draggableId, rankBeforeEntry.id);
      await updateMasterlistRank(result.draggableId, rankBeforeEntry.id);
      alert.success('Initiative order changes saved.');
    },
    [initiatives],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledTable ref={(ref) => (tableRef.current = ref)}>
        <thead>
          <tr>
            <th />
            <th>
              <input
                type="checkbox"
                onChange={() => toggleSelectAllDocumentInitatives()}
                checked={isAllInitiativesSelected}
              />
            </th>
            <th style={{ width: '100px' }}>Document</th>
            <th style={{ width: '100px' }}>Type</th>
            <th style={{ width: '120px' }}>Priority</th>
            <th style={{ width: '200px' }}>Horizon</th>
            <th style={{ width: '120px' }}>Risk</th>
            <th>KRA</th>
            <th>Initiative Owner</th>
            <th>Description</th>
            <th>Strategic Objective</th>
            <th>KPI Measures</th>
            <th>Success Factor</th>
            <th>Comments</th>
            <th>Investment & Resources</th>
            <th>Target End</th>
            <th>Status</th>
          </tr>
        </thead>
        <Droppable droppableId="table">
          {(provided) => (
            <tbody
              ref={(ref) => {
                provided.innerRef(ref);
              }}
              {...provided.droppableProps}
            >
              {initiatives.map((initiative, index) => (
                <Draggable
                  draggableId={initiative.id}
                  index={index}
                  key={initiative.id}
                >
                  {(provided, snapshot) => (
                    <InitTableRow
                      initiative={initiative}
                      provided={provided}
                      snapshot={snapshot}
                      tableRef={tableRef}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </StyledTable>
    </DragDropContext>
  );
};

export default InitiativeTable;
