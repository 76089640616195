import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import CanvasTopic from './CompareCanvasTopic';
import StaticCell from '../GridVisualizer/StaticCell';
import { CompareScope, EntryChange, UsersChangeType } from '../../common/type';
import { GridCell, GridViewConfig, Maybe } from '@propella/core';
import { isJust, withDefault } from '../../maybe';

type CompareGridInput = {
  scope: CompareScope;
  changes?: EntryChange[];
  usersChange?: UsersChangeType[];
};
const CanvasContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
`;

const CompareGrid: React.FC<CompareGridInput> = ({
  scope,
  changes,
  usersChange,
}) => {
  const { document, domains, topics } = scope;
  const cells = _.get(
    document,
    'views.grid.cells',
    null,
  ) as GridViewConfig['cells'];

  if (!cells || !isJust(cells)) return null;

  const renderCell = (cell: Maybe<GridCell>, gridIndex: number) => {
    if (!isJust(cell)) return null;

    if (isJust(cell.topicKey)) {
      const cellDomain =
        domains[
          withDefault(topics[cell.topicKey || ''].domain || '', 'default')
        ];

      return (
        <CanvasTopic
          cell={cell}
          key={String(gridIndex)}
          topic={topics[cell.topicKey || '']}
          domain={cellDomain}
          changes={changes?.filter(
            (change) => change.topicKey === cell.topicKey,
          )}
          usersChange={usersChange}
        />
      );
    }

    return <StaticCell cell={cell} key={String(gridIndex)} />;
  };

  return <CanvasContainer>{cells.map(renderCell)}</CanvasContainer>;
};

export default CompareGrid;
